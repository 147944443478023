.co {
  margin-top: 50px;
  @include break-max($breakpoint-sm) {
    margin-top: 25px;
  }

  .breadcrumbs {
    margin-top: 0;
    border: none;
  }

  .popover__block {
    top: 0;
    right: 0;
  }
}

.co__container {
  position: relative;
  display: flex;
  align-items: flex-start;

  .text {
    font-size: 14px;
    line-height: 18px;
  }

  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }
}

.co__left-col {
  width: 800px;
  max-width: 100%;
  margin-right: 75px;

  @include break-max($breakpoint-md) {
    width: 690px;
    margin-right: 40px;
  }
  @include break-max($breakpoint-sm) {
    width: 730px;
    margin-right: 0;
    margin-bottom: 30px;
  }
  @include break-max($breakpoint-xs) {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
}

.co__slider {
  position: relative;
  width: 800px;
  max-width: 100%;

  .swiper-button-prev,
  .swiper-button-next {
    width: 44px;
    height: 44px;
    &::before {
      width: 10px;
      height: 10px;
      border-width: 2px;
    }
  }

  .swiper-container:not(.swiper-thumbs) {
    // border: 1px solid $color-brand-opaque-20;
    // border-radius: 4px;
    img,iframe {
      width: 800px;
      height: 530px;
      max-width: 100%;
      object-fit: contain;

      @include break-max($breakpoint-md) {
        width: 690px;
        height: 460px;
      }
      @include break-max($breakpoint-sm) {
        width: 730px;
        height: 480px;
      }
      @include break-max($breakpoint-xs) {
        width: 350px;
        height: 235px;
      }
    }
  }

  .swiper-thumbs img {
    width: 115px;
    height: 74px;
    object-fit: cover;
    @include break-max($breakpoint-sm) {
      width: 95px;
      height: 60px;
    }
    @include break-max($breakpoint-xs) {
      height: 50px;
    }
  }
}

.co__info {
  position: sticky;
  top: 100px;
  width: 345px;

  @include break-max($breakpoint-sm) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }

  
}

.co__slide img {
  border-radius: 5px;
  border: 1px solid $color-brand-opaque-30;
}

.swiper-thumbs .swiper-slide-thumb-active img {
  border: 2px solid $color-brand-3;
}

.co__info-body {
  @include break-max($breakpoint-sm) {
    width: 232px;
    margin-right: 70px;
  }

  @include break-max($breakpoint-xs) {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }
  

}

.co__description {
  width: 750px;
  max-width: 100%;

  @include break-max($breakpoint-md) {
    width: 690px;
  }

  @include break-max($breakpoint-sm) {
    width: 730px;
  }

  @include break-max($breakpoint-xs) {
    width: 350px;
  }
}

.co__description-title {
  margin-bottom: 15px;
  @include break-max($breakpoint-md) {
    font-size: 20px;
    line-height: 26px;
  }
  @include break-max($breakpoint-sm) {
    font-size: 16px;
    line-height: 20px;
  }
  @include break-max($breakpoint-xs) {
    font-size: 14px;
    line-height: 18px;
  }
}

.co__description-content {
  font-size: 18px;
  line-height: 24px;
  @include break-max($breakpoint-md) {
    font-size: 17px;
    line-height: 22px;
  }

  @include break-max($breakpoint-sm) {
    
  }

  @include break-max($breakpoint-xs) {
    font-size: 15px;
    line-height: 24px;
  }
}

.co__header {
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $color-brand-opaque-30;

  @include break-max($breakpoint-sm) {
    border: none;
    padding-bottom: 5px;
  }
}

.co__header-content {
  @include break-max($breakpoint-sm) {
    display: flex;
    align-items: center;
  }
}

.co__title {
  margin-bottom: 10px;
}

.co__adress {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 18px;

  @include break-max($breakpoint-sm) {
    margin-bottom: 0;
    margin-right: 12px;
  }
}

.co__on-map {
  display: flex;
  align-items: center;
  color: $color-red;
  &:hover {
    color: $color-red-hover;
  }
  &:active {
    color: $color-red-active;
  }

  svg {
    margin-right: 5px;
  }
}

.co__price {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.co__price-full {
  margin-bottom: 5px;
}

.co__manager {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-radius: 4px;
  margin-bottom: 30px;

  @include break-max($breakpoint-sm) {
    flex-direction: column;
    justify-content: flex-start;
  }
  @include break-max($breakpoint-xs) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.co__manager-info {
  width: 140px;
  @include break-max($breakpoint-sm) {
    text-align: center;
    margin-bottom: 10px;
  }
  @include break-max($breakpoint-xs) {
    text-align: left;
    margin-bottom: 0;
    width: 150px;
  }
}


.co__manager-link {
  margin-top: 7px;
}

.co__manager-btn {
  border-radius: 5px;
  background-color: $color-brand-3;
  color: $color-white;
  padding: 10px 12px;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background-color: $color-brand-3-hover;
  }

  @include break-max($breakpoint-sm) {
    width: 100%;
    justify-content: center;
  }

  @include break-max($breakpoint-xs) {
    width: 140px;
  }

}

.co__items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  justify-content: space-between;
  border-top: 1px solid $color-brand-opaque-30;
  padding-top: 30px;

  @include break-max($breakpoint-sm) {
    justify-content: start;
    column-gap: 70px;
    padding-top: 0;
    border: none;
  }

  @include break-max($breakpoint-xs) {
    border-bottom: 1px solid $color-brand-opaque-30;

    display: flex;
    flex-wrap: wrap;
    gap: 0;
  }
}

.co__items + .co__item {
  margin-top: 20px;
}

.co__item {
  display: flex;
  flex-direction: column;
  @include break-max($breakpoint-xs) {
    width: 50%;
    margin-bottom: 25px;
    &:nth-child(odd) {
      padding-right: 15px;
    }
    &:nth-child(even) {
      padding-left: 15px;
    }
    &_single {
      width: 100%;
      margin-bottom: 0;
      &:nth-child(n) {
        padding: 0;
      }
    }
  }
}

.co__value {
  margin-bottom: 5px;
}

.co__description {
  margin-top: 65px;
  @include break-max($breakpoint-md) {
    margin-top: 40px;
  }
  @include break-max($breakpoint-xs) {
    margin-top: 30px;
  }
  p {
    margin: 25px 0;
  }
}

.co__manager-link {
  color: $color-brand-3;
  transition: all 0.3s;
}

.co__manager-link:hover {
  color: $color-brand-3-hover;
}

.co__video-panel {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  padding: 6.5px 14px;
  font-size: 14px;
  line-height: 17px;
  color: $color-white;
  transition: all 0.3s;
  cursor: pointer;
  svg {
    margin-top: 2px;
    margin-right: 10px;
  }
}


.co__video-icon path {
  fill: $color-white;
 }

 .co__online {
   margin-top: 50px;
   padding-bottom: 15px;
   border-bottom: 1px solid $color-brand-opaque-30;
   display: flex;
   align-items: center;
 }

 .co__online-pic {
   margin-right: 35px;
 }

 .co__mortgage {
   display: flex;
   margin-top: 20px;
   padding-top: 25px;
   border-top: 1px solid $color-brand-opaque-30;
   @include break-max($breakpoint-sm) {
    width: 100%;
  }
  @include break-max($breakpoint-xs) {
    border-top: none;
    padding-top: 0;

    svg {
      display: none;
    }
  }
   .h55 {
     margin-bottom: 10px;
   }

   .text {
     margin-bottom: 15px;
   }
 }

 .co__mortgage-box {
   margin-left: 15px;
   @include break-max($breakpoint-xs) {
    margin-left: 0;
  }
 }

  .co__mortgage-btn {
    padding: 17px 60px;
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }