.profile {
  padding-bottom: 80px;

  @include break-max($breakpoint-xs) {
    .profile__manager {
      flex-direction: row;
      flex-wrap: wrap;
      position: static;
      padding-left: 0;
    }

    .profile__aside {
      margin-left: 10px;
      margin-right: 10px;
      width: calc(100% - 20px);
    }

    .pan-popup-trigger {
      display: flex;
    }

    .profile__manager-contacts .manager-contacts_list {
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;
    }

    .link-hover {
      white-space: nowrap;
    }

    .profile__manager-photo {
      flex-shrink: 0;
      margin-top: 0;
    }

    .profile__manager-content {
      align-items: flex-start;
      margin-top: 10px;
    }

    .profile__info {
      margin: 0 10px;
      width: calc(100% - 20px);
    }

    .lk-form__row {
      padding-left: 0;
    }

    .lk-form__row-content {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    .lk-form__row-title {
      padding-bottom: 0;
    }

    .lk-form__fr {
      margin-right: 0;
    }

    .lk-form__multi-row {
      width: 100%;
    }

    .lk-form__row._bg-gray {
      margin: 0 -10px;
      padding: 0 10px;
    }

    .lk-form__row.for-contacts {
      flex-wrap: nowrap;
      flex-direction: column;

      .input.input_type_form.input_width_available {
        margin-top: 12px;
      }

      .lk-form__fr .input + .input {
        margin-top: 24px;
      }
    }

    .lk-form__socials {
      flex-direction: column;
    }

    .profile__password-form {
      margin-top: 14px;
    }

    .lk-form__footer {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 20px;
    }

    .lk-form__submit,
    .lk-form__submit .button {
      width: 100%
    }

    div.lk-form__reglament {
      position: static;

      a {
        font-weight: bold;
        margin-top: 20px;
        width: 100%;
      }
    }

    .profile__password-form--submit .button {
      margin-top: 5px;
    }
  }

  .profile__header {
    margin-bottom: 40px;

    @include break-max($breakpoint-xs) {
      align-items: flex-start;
      flex-direction: column;
      padding: 25px 10px 0;
    }
  }

  &__grid {
    @include grid();
  }

  &__grid-col {
    @include grid-col();
  }

  &__aside {
    @include grid-size(2);

    @include break-max($breakpoint-md) {
      @include grid-size(2, $grid-gutter-md);
    }

    @include break-max($breakpoint-sm) {
      @include grid-size(12, $grid-gutter-sm);
      @include grid-col();
    }
  }

  &__info {
    @include grid-size(8);

    @include break-max($breakpoint-md) {
      @include grid-size(9, $grid-gutter-md);
    }

    @include break-max($breakpoint-sm) {
      @include grid-size(12, $grid-gutter-sm);
      @include grid-col();
    }
  }

  &__manager {
    display: block;
    flex-direction: column;
    padding: 0 35px;
    text-align: center;

    @include break-max($breakpoint-md) {
      padding: 0 10px;
    }

    @include break-max($breakpoint-sm) {
      padding: 0;
      text-align: left;
      padding-left: 36px;
    }

    &-label {
      font-weight: bold;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include break-max($breakpoint-sm) {
        flex-direction: row;
      }
    }

    &-photo {
      width: 64px;
      height: 64px;
      margin-top: 30px;
      margin-bottom: 12px;
      border-radius: 50%;
      overflow: hidden;

      @include break-max($breakpoint-sm) {
        margin-right: 15px;
      }
    }

    &-contacts {

      & .manager-contacts_item {
        margin-right: 0;
      }

      & .manager-contacts_list {
        justify-content: center;

        @include break-max($breakpoint-sm) {
          flex-direction: column;
          margin: 0 25px;
        }
      }
    }
  }

  &__password-form {

    &--submit {
      display: flex;
      align-items: flex-end;
      height: 100%;
    }
  }

  & .lk-form {
    &__footer {
      position: relative;
      border-top: none;
    }

    &__reglament {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
    }
  }
}

.lk-form {
  &__row {
    display: flex;
    flex-wrap: nowrap;
    border-radius: 5px;

    &._bg-gray {
      background-color: $color-brand-opaque-8;
    }

    @include break-max($break-md) {
      flex-wrap: wrap;
      padding-left: 36px;
    }
  }

  &__footer {
    margin-top: 36px;
    border-top: 1px solid $color-brand-opaque-20;
  }

  &__row-title {
    padding: 36px 24px 36px 36px;
    font-size: 16px;
    font-weight: 700;
    width: 220px;

    @include break-max($break-md) {
      width: 100%;
      padding: 26px 24px 16px 0;
    }
  }

  &__row-aside {
    padding: 36px 0 36px 36px;
    font-size: 16px;
    width: 196px;
    margin-right: 24px;
    background-color: #fff;

    @include break-max($break-md) {
      width: calc(100% + 36px);
      padding: 26px 24px 16px 0;
      margin-right: 0;
      margin-left: -36px;
      padding-left: 36px;
    }
    &.padding_bottom_0 {
      padding-bottom: 0;
    }
  }

  &__row-content {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    padding-bottom: 24px;
    padding-top: 24px;
    @include break-max($break-md) {
      width: 100%;
      padding-top: 0;
    }
  }

  &__fr {
    //flex: 0 1 calc(50% - 30px);
    flex: 0 1 calc(50% - 20px);
    //margin-right: 30px;
    margin-right: 20px;
    padding-top: 12px;

    &_auto {
      flex: 1;
    }

    &_full {
      flex: 1 0 calc(100% - 30px);
    }

    & .input + .input {
      margin-top: 12px;
    }
  }

  &__socials {
    display: flex;
    margin-top: 35px;
    padding-top: 10px;
    width: 100%;
    border-top: 1px solid $color-brand-opaque-30;
  }

  .input_type_form &__password {
    cursor: pointer;

    &-visible {
      width: 30px;
      height: 30px;
      padding-top: 2px;

      & .icon {
        font-size: 30px;
      }
    }
  }

  &__btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * + * {
      margin-left: 30px;
    }
  }

  &__add-param {
    display: flex;
    align-items: center;
    color: $color-brand-3;
    font-weight: 300;
    margin-top: 11px;

    & .button {
      margin-right: 7px;
    }
  }

  &__logo {
    display: inline-flex;
    align-items: center;

    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      border: 6px solid $color-white;
      background-color: $color-brand-opaque-10;
    }

    & .icon {
      width: 42px;
      height: 42px;
    }

    &-load {
      margin: 0 20px;
      color: $color-brand-3;
    }
  }
}

div.profile__access {
  @include break-max($breakpoint-xs) {
    margin: 8px 0 0 0;
  }
}

.manager-content-wrapper {
  flex-grow: 1;
}

.personal_content {
  @include break-max($breakpoint-xs) {
    .profile__title {
      display: flex;
    }

    .profile__header {
      padding: 25px 0 0 10px;
    }
  }
}

.document__row.files__item {
  @include break-max($breakpoint-xs) {
    margin: 0 10px;
    padding: 20px 0 25px;
  }
}

.document__col.document__col_name {
  @include break-max($breakpoint-xs) {
    flex-grow: 1;
    flex-basis: 100%;
    margin-right: 15px;
  }
}

.document__col.document__col_controls {
  @include break-max($breakpoint-xs) {
    align-items: center;

    .icon_watch {
      font-size: 20px;
      fill: #ff2d23;
      margin-right: 5px;
    }
  }
}

.requisites {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  //max-width: 1000px;

  .lk-form__row-content {
    padding-top: 32px;
  }

  .input__control:disabled {
    background-color: #eeeeee;
    color: #2c2e3e;
  }

  .tabs {
    width: 100%;
    margin-top: 20px;
    .tab-bar {
      width: calc(100% - 20px);

      button {
        width: 100%;
      }
    }
  }


  .react-datepicker-wrapper {
    width: 100%;
  }

  .lk-form__row-title {
    //padding-top: 24px;
    //padding-bottom: 24px;
  }

  .lk-form__fr {
    padding-top: 15px;
  }

  .lk-form__fr_full {
    flex-basis: calc(100% - 20px);
  }
  .lk-form__fr_quarter {
    flex-basis: calc(25% - 20px);
  }

  &__hint {
    color: $gray-dark-2;
    font-size: 14px;
    margin-top: 5px;
  }
  &__hint_sz {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  &__info {
    max-width: 600px;
    display: flex;
  }

  &__info-title {
    min-width: 220px;
    white-space: nowrap;
    font-weight: bold;
  }

  &__info-text {
    p {
      font-weight: bold;
    }
  }

  &__text-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__write-manager {
    display: flex;
    align-items: center;
    color: $gray-dark-2;
    cursor: pointer;
    gap: 5px;
    margin-top: 10px;
    transition-duration: .3s;

    svg path {
      transition-duration: .3s;
    }

    &:hover {
      color: #5f616c;

      svg path {
        stroke: #5f616c;
      }
    }
  }

  &__bottom-hints {
    display: flex;
    flex-direction: column;
  }

  &__form-title {
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

.requisites__form-title_bank {
  margin-top: 40px;
}
.requisites__form_bank {
  margin-bottom: 20px;
}

.requisites-bikHint {
  //display: flex;
  //padding-left: 5px;
  position: relative;
  bottom: auto;
  top: 42px;


  &__tooltip {
    position: absolute;
    width: 350px;
    right: -12px;
    bottom: 54px;
    background-color: white;
    box-shadow: 0 2px 10px rgba(143, 149, 172, 0.2);
    opacity: 0;
    border-radius: 5px;
    padding: 16px 24px;
    transition-duration: .3s;
    cursor: default;
    pointer-events: none;

    &:before {
      content: '';
      border: 10px solid transparent;
      border-top: 20px solid white;
      display: inline-block;
      position: absolute;
      bottom: -23px;
      right: 15px;
    }
  }
  &:hover {
    cursor: pointer;

    .requisites-bikHint__tooltip {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__tooltip-title {
    padding-bottom: 10px;
  }

  &__tooltip-string {
    padding-bottom: 3px;
  }

}



.input_type_form .input__alert.requisites-bikHint {
  box-shadow: inset 0 0 0 1px #a4a4a4;

  .icon {
    fill: #a4a4a4;
  }
}




















