@import "../../styles/variables";
@import "../../styles/mixins";

.card__right {
  white-space: nowrap;
}

.public__map-button.for-suburbans .button {
  box-shadow: none;

  &:hover {
    background: none;
    color: #cf2d23;

    .icon {
      fill: #cf2d23;
    }
  }
}

.section.for-suburbans {
  .complex__info-update {
    margin-left: auto;
  }

  .show__map {
    background: url('../../images/png/show-map.png') no-repeat center;
    background-size: cover;
    flex-shrink: 0;
  }

  .flat__nav-link {
    display: flex;
    font-size: 14px;
    line-height: 18px;
  }
}

.section.grey.for-suburbans {
  margin-top: 60px;
}

.complex__suburbans-options {
  border-top: 1px solid $color-brand-opaque-20;
  display: flex;
  justify-content: space-between;
  padding: 32px 0;
}

.component__suburbans-option {
  background: rgba($blue, 0.3);
  border-radius: 5px;
  color: $color-white;
  padding: 30px;
  width: calc(50% - 17px);
}

.component__suburbans-option-title {
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
}

.component__suburbans-option-counter {
  color: $color-brand-3;
  margin-top: 5px;
}

.component__suburbans-option-price {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;
}

.component__suburbans-option-square {
  display: flex;
  margin-top: 8px;

  div {
    width: 50%;
  }
}

.complex__section-about.for-suburbans {
  .complex__about-feature {
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
  }
}

.flat__complex.for-suburbans {
  align-items: center;
  display: flex;

  .show__map {
    margin-left: 25px;
  }
}

.suburban-view-counter {
  align-items: center;
  background: $color-brand-opaque-10;
  border-radius: 50em;
  color: $color-brand-2;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  justify-content: center;
  margin-left: 25px;
  padding: 0 15px 0 12px;
  height: 28px;

  .icon {
    margin-right: 5px;
  }
}

.flat__col-download.for-suburbans {
  .flat__download button {
    margin-right: 22px;
  }
}

.complex__toggle-wrap {
  border-bottom: 1px solid $color-brand-opaque-30;
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  margin-bottom: 70px;
}

.complex__toggle {
  display: flex;
  margin-bottom: 40px;
}

.complex__toggle-tab {
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 0 30px;

  &.is-active {
    background: $color-brand-opaque-10;
    cursor: default;
  }
}

.complex__toggle-content {
  color: #000;
  font-size: 18px;
  line-height: 24px;
}

.complex__toggle-counter {
  align-items: center;
  background: $color-brand-opaque-30;
  border-radius: 50em;
  justify-content: center;
  display: flex;
  font-size: 12px;
  line-height: 15px;
  margin-left: 10px;
  height: 21px;
  width: 21px;
}

.flat__info-title {
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 32px;
}

.flat__info-suburban {
  display: flex;
  flex-direction: column;
}

.flat__price-item.for-suburban {
  display: flex;
  justify-content: space-between;
}

.complex__toggle-wrap.for-suburbans {
  border-bottom: none;

  .complex__toggle {
    justify-content: center;
  }
}

.flat__complex-title-wrap {
  align-items: center;
  display: flex;
}

.assignations-top.suburbans-top {
  .assignations-top__slider {
    padding: 0;
  }
  .slider__nav {
    background-color: transparent;
  }
  .slider__nav-item {
    background-color: #fff;
  }
  .slider__nav-item:hover{
    background-color: #e9eaee;
  }
  .flats__card_compact {
    padding: 0;
    justify-content: flex-start;
  }
  .flats__card-image {
    padding: 0;
    margin: 0px 10px 0 0;
    height: auto;
    min-width: 200px;
    align-self: stretch;
    img {
      object-fit: cover;
      max-height: 100%;
      height: 320px;
    }
  }
  .flats__card-info {
    padding: 43px 12px;
  }
  .flats__card-price.suburbans{
    padding: 13px 0 10px 0;
  }
  #assignations-slider .slider__item:hover img {
    transform: none
}
}

.assignations-top.suburbans-top {
  @include break-max($breakpoint-xs) {
    .assignations-top__title {
      margin-bottom: 25px;
    }

    .assignations-top__slider.slider .complex__slider-info {
      bottom: -10px;
    }
  }
}

.cards__item.for-suburbans {
  @include break-max($breakpoint-xs) {
    background: $color-brand-opaque-8;
    margin: 0;
    padding: 15px 10px;
    width: 100%;
  }
}

.map-card__image.for-suburbans img {
  width: 100%;
}

.section__map.for-suburbans {
  .simplebar-content-wrapper {
    overflow-x: hidden !important;
  }
}

.complex__section-about.for-suburbans {
  margin: 0;
  margin-bottom: 60px;

  .complex__about-col.complex__about-text {
    width: 100%;
  }
}


.redisign-slider {

  .slider {
    border: none;
  }
  .assignations-top.suburbans-top {

    .flats__card-image {
      min-width: 180px;
      margin-right: 0;
      img {
        object-fit: cover;
        max-height: 100%;
        height: 190px;
        border-radius: 20px 0 0 20px;
      }
    }

    .flats__card-info {
      padding: 0;
      background-color: $gray-light;
      border-radius: 20px;
      padding-left: 20px;
      padding-right: 30px;
      box-sizing: border-box;
      margin-left: -20px;
      z-index: 1;
      width: calc(100% - 160px);

      @include break-max($breakpoint-sm) {
        align-self: stretch;
      }
    }
  }

  .slider__item {
    border-right: none;
  }

  .top__title {
    font-size: 14px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: inline-block;
    }
  }
  .card__company {
    border-bottom: 1px solid $gray-dark;
    padding-bottom: 10px;
  }
  .card__address {
    margin-top: 8px;
  }
  .flats__card-price {
    font-size: 14px;
    margin-bottom: 0;
  }
  .assignations-top.suburbans-top .flats__card-price.suburbans {
    padding-bottom: 0;
    padding-top: 8px;
  }

  .assignations-top__title {
    text-align: left;
    margin-bottom: 16px;

    h2.h2 {
      @include h3();
    }
  }

  .slider__nav {
    position: absolute;
    right: auto;
    left: auto;
  }
  .slider__nav-item.prev {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border: 1px solid rgba(143, 149, 172, 0.2);
    border-radius: 50%;
    cursor: pointer;
    background: #fff;
    transition: all 0.3s;

    svg {
      margin-right: 0;
    }
  }

  .slider__nav-item.next {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border: 1px solid rgba(143, 149, 172, 0.2);
    border-radius: 50%;
    cursor: pointer;
    background: #fff;
    transition: all 0.3s;

    svg {
      margin-left: 0;
    }
  }
}


