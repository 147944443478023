.search-result{
    list-style: none;
    padding-left: 0;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    transition: box-shadow 0.15s ease-in-out;
    max-height: 300px;
    z-index: 100;
    box-shadow: 0 2px 10px rgba(143, 149, 172, 0.2);
    ul {
        list-style: none;
    }
  
}
.search-result-option{
    padding: 8px 16px;
    cursor: pointer;
}
.search-result-option:hover{
    background-color: rgba(143, 149, 172, 0.1);
}
