@import "../../styles/variables";
@import "../../styles/mixins";

.cabinet-menu {
  position: fixed;
  top: 150px;
  left: 0;
  background-color: #2C2E3E;
  border-radius: 0 5px 5px 0;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 1;

  .sidebar.menu-nav {
    opacity: 0;
    left: 60px;
    top: 0;
    position: absolute;

    transform: translateX(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;
  }

  li {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: .3s;
    position: relative;
    flex-direction: column;
    font-size: 9px;
    color: white;
    position: relative;

    &__icon:hover {

    }

    &:hover {
      color: #EF372E;

      .cabinet-menu__icon {
        svg {
          transition-duration: .3s;
          path {
            stroke: #EF372E;
          }
          line {
            stroke: #EF372E;
          }
          rect {
            stroke: #EF372E;
          }
        }
      }

      .sidebar.menu-nav {
        opacity: 1;
        transform: translateX(0);
        pointer-events: auto;
      }
    }
  }

}

.sidebar.menu-nav {
  height: auto;
}
.sidebar {
  padding: 16px 16px 8px 16px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  width: 365px;
  display: flex;
  flex-direction: column;

  &__title {
    color: #777A89;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 14px;
  }

  &__list {
    display: flex;
    flex-direction: column;

    a {
      height: 48px;
      border-bottom: 1px solid #DFE1E9;
      color: #2C2E3E;
      display: flex;
      align-items: center;
      font-weight: 400;

      .menu-nav__item-text {
        font-weight: 400;
      }
    }
    a:last-child {
      border-bottom: none;
    }
  }
}

























