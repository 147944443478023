.start-block {
  border: none !important;
  background: url('../../../assets/images/constructor/start-block.jpg') no-repeat center;
  background-size: cover;
  @media (min-width: $break-sm) and (max-width: $breakpoint-md) {
    background-image: url('../../../assets/images/constructor/start-block-horizontal.jpg');
  }
}

.start-block__card {
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (min-width: $break-sm) and (max-width: $breakpoint-md) {
    height: 340px;
  }

  @include break-max($breakpoint-xs) {
    height: 480px;
  }
}

.start-block__box {
  position: relative;
  display: flex;
  align-items: center;
  width: 90%;
  height: 420px;
  background: $color-brand-1;

  @media (min-width: $break-sm) and (max-width: $breakpoint-md) {
    height: 210px;
  }

  @include break-max($breakpoint-xs) {
    width: 270px;
    height: 340px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../../assets/images/constructor/start-block.jpg') no-repeat center;
    filter: blur(40px);
    //background: orange;

    @media (min-width: $break-sm) and (max-width: $breakpoint-md) {
      background-image: url('../../../assets/images/constructor/start-block-horizontal.jpg');
    }
  }
}

.start-block__content {
  position: relative;
  z-index: 1;
  padding: 0 15px;
  text-align: center;

  .h3 {
    display: block;
    margin-bottom: 30px;
  }

  .big-text {
    margin-bottom: 40px;
  }

  .button {
    display: flex;
    align-items: center;

  }


  @media (min-width: $break-sm) and (max-width: $breakpoint-md) {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 75px;

    .button {
      height: 50px;
      margin: 0;
      margin-left: 40px;
    }

    .h3 {
      line-height: 1;
      margin-bottom: 10px;
    }

    .big-text {
      margin-bottom: 0;
    }
  }

  @include break-max($breakpoint-xs) {
    width: 100%;

    .button {
      height: 50px;
    }
  }


}
