@import "../../../../styles/variables";

.map_round_button {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.map_round_button svg {
    margin-right: 0;
    fill: none !important;
}

.fullMap {
    width: 100%;
    height: 100%!important;
}

.map-button-with-tooltip {
    .tooltip {
        position: absolute;
        display: none;
        padding: 16px 25px;
        background-color: $gray-light;
        width: fit-content;
        color: #2C2E3E;
        height: 50px;
        right: 50px;
        border-radius: 25px;
        top: -3px;
        z-index: 9999;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -20px;
            right: 120px;
            width: calc(100% + 60px);
            height: calc(100% + 40px);
            z-index: -1;
        }
    }

    &:hover .tooltip {
        display: flex;
        align-items: center;
    }
}

.ymaps_maps-button.ymaps__checked .ymaps_maps-button-icon_ruler {
    background-image: url(data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iMzIiCiAgaGVpZ2h0PSIzMiIKICB2aWV3Qm94PSIwIDAgMzIgMzIiCj4KICA8ZyB0cmFuc2Zvcm09InJvdGF0ZSgtMTM1IDExLjMyOCAxMS43MjIpIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgPHJlY3QKICAgICAgc3Ryb2tlPSIjZmZmIgogICAgICBzdHJva2Utd2lkdGg9IjEuNSIKICAgICAgeD0iLS4wMDQiCiAgICAgIHk9Ii4yOTgiCiAgICAgIHdpZHRoPSIxMC4wNzMiCiAgICAgIGhlaWdodD0iMjQuMTQyIgogICAgICByeD0iMiIKICAgIC8+CiAgICA8ZyBmaWxsPSIjZmZmIj4KICAgICAgPHBhdGgKICAgICAgICBkPSJNNi4wNCAzLjgyaDMuMDJ2MS40MDdINi4wNHpNNC4wMjUgNy44NDNIOS4wNlY5LjI1SDQuMDI2ek02LjA0IDExLjg2NmgzLjAydjEuNDA4SDYuMDR6TTYuMDQgMTkuOTE0aDMuMDJ2MS40MDhINi4wNHpNNC4wMjUgMTUuODlIOS4wNnYxLjQwOEg0LjAyNnoiCiAgICAgIC8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K);
}