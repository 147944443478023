.empty-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-top: 1px solid $color-brand-opaque-20;
  padding: 110px 0 85px;

  @include break-max($breakpoint-md) {
    padding: 100px 0 95px;
  }

  @include break-max($breakpoint-sm) {
    padding: 70px 0 45px;
  }

  &__image {
    padding-left: 30px;
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 15px;
  }

  &__text {
    width: 365px;
    margin-bottom: 35px;
  }
}