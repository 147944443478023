@charset "utf-8";
.text-input,
.text-area {
  font-family: inherit;
  font-size: 14px;
  padding: 16.5px 15px;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 4px;
  &::placeholder {
    color: $color-brand-3;
  }
  &_full {
    width: 100%;
  }

  &:focus {
    border: 1px solid $color-brand-opaque-20;
  }
}

.text-area {
  resize: vertical;
}

.input {
  $c: &;

  display: inline-block;
  position: relative;

  &_type {
    &_default {
      & #{$c}__control {
        background-color: $color-white;
        padding: 8px 6px;
        border-radius: 4px;
        position: relative;
        color: $color-brand-2;
        box-shadow: 0 0 0 1px $color-brand-opaque-30;

        &::placeholder {
          color: $color-brand-3;
        }
      }
    }

    &_upload-btn {
      height: 50px;
      border-radius: 4px;
      background-color: $color-brand-3;
      text-align: center;
      padding: 0 30px;
      color: $color-white;
      font-size: 14px;
      line-height: 50px;
      white-space: nowrap;
      transition: background-color $transition-default;
      cursor: pointer;

      & #{$c}__control {
        width: 0;
        height: 0;
        position: absolute;
        z-index: -1;
      }

      &:hover {
        background-color: $color-brand-2;
      }
    }

    &_search {
      &,
      &_form,
      &_round {
        position: relative;

        & #{$c}__control {
          background-color: #fff;
          padding: 0 24px 0 40px;
          border-radius: 4px;
          position: relative;
          height: 50px;
          transition: background-color $transition-default;
        }

        .icon {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(13px, -50%);
          fill: $color-brand-3;
        }
      }

      &_form {
        & #{$c}__control {
          box-shadow: 0 0 0 1px $color-brand-opaque-30;
        }
      }

      &_round {
        & #{$c}__control {
          background-color: $color-brand-2;
          border-radius: 50em;
          color: $color-brand-3;
          padding: 8px 24px 8px 40px;
          height: auto;

          &:focus,
          &:active {
            background-color: #fff;
          }

          &::placeholder {
            color: $color-brand-3;
          }
        }

        &_gray {
          & #{$c}__control {
            //background-color: $color-brand-opaque-8;
            background-color: white;
            border: 1px solid #8F95AC;
            &:focus,
            &:active {
              //background-color: $color-brand-opaque-12;
            }
          }
        }
      }
    }

    &_subscribe {
      position: relative;
      min-width: 500px;

      & #{$c}__control {
        background-color: $color-brand-1;
        padding: 17px 66px 17px 20px;
        color: $color-brand-3;

        &::placeholder {
          color: $color-brand-3;
        }
      }

      @include break-max($break-lg) {
        min-width: 460px;
      }
    }

    &_filter {
      & #{$c}__label {
        color: $color-brand-3;
        line-height: 25px;
        margin-bottom: 4px;
        white-space: nowrap;
      }

      & #{$c}__control {
        height: 50px;
        background-color: $color-white;
        padding: 0 16px 0 16px;
        border-radius: 4px;
        color: $color-brand-2;
      }
    }

    &_form {
      & #{$c}__label {
        color: $color-brand-3;
        line-height: 25px;
        margin-bottom: 4px;
        white-space: nowrap;
      }

      & #{$c}__control {
        height: 50px;
        background-color: $color-white;
        padding: 0 16px 0 16px;
        box-shadow: inset 0 0 0 1px $color-brand-opaque-20;
        border-radius: 4px;
        color: $color-brand-2;

        &:disabled {
          background-color: $color-disabled;
          color: $color-brand-opaque-50;
        }

        &--gray {
          background-color: $gray-light;
          box-shadow: none;
        }
      }

      & #{$c}__icon {
        position: absolute;
        height: 25px;
        width: 25px;
        background-color: $color-white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        bottom: calc((50px - 25px) / 2);
        right: calc((50px - 25px) / 2);

        &:hover {
          background-color: $color-white;
        }

        & .icon {
          font-size: 20px;
          fill: $color-red;
        }
      }

      & #{$c}__alert {
        position: absolute;
        height: 22px;
        width: 22px;
        box-shadow: inset 0 0 0 1px $color-red;
        background-color: $color-white;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        bottom: calc((50px - 22px) / 2);
        right: calc((50px - 22px) / 2);

        &:hover {
          background-color: $color-white;
        }

        & .icon {
          font-size: 10px;
          fill: $color-red;
        }
      }

      &_icon-right {
        & #{$c}__control {
          padding: 0 45px 0 16px;
        }
      }

      &_icon-left {
        & #{$c}__control {
          padding: 0 16px 0 45px;
        }

        & #{$c}__icon {
          right: auto;
          left: calc((50px - 25px) / 2);
        }
      }
    }

    &_form-files {
      cursor: pointer;

      & #{$c}__label {
        color: $color-brand-3;
        line-height: 25px;
        margin-bottom: 4px;
        white-space: nowrap;
      }

      & #{$c}__control {
        position: absolute;
        visibility: hidden;
        width: 0;
        height: 0;
        z-index: -1;
      }

      & #{$c}__files {
        display: flex;
        height: 50px;
        background-color: $color-white;
        padding: 11px 16px;
        margin-right: 50px;
        border: 1px dashed $color-brand-opaque-20;
        border-radius: 4px;
        color: $color-brand-2;
        overflow-x: auto;
      }

      & #{$c}__file {
        display: flex;
        align-items: center;
        margin-right: 20px;

        .button_type_delete {
          margin-left: 10px;
        }
      }

      & #{$c}__attach {
        position: absolute;
        height: 50px;
        width: 50px;
        background-color: $color-brand-3;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        right: 0;

        & .icon {
          font-size: 17px;
          stroke: $color-white;
        }
      }
    }
  }

  &_width {
    &_available {
      width: 100%;
    }

    &_small {
      width: 65px;
    }

    &_medium {
      width: 95px;
    }

    &_large {
      width: 120px;
    }
  }

  &__label {
    display: flex;
    align-items: center;

    .info {
      width: 20px;
      height: 20px;
      margin-left: 3px;
    }
  }

  &__control {
    background: none;
    border: 0;
    font-family: 'Formular', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    appearance: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      outline: none;
    }

    &.input__hidden {
      width: 0;
      height: 0;
      position: absolute;
      z-index: -1;
    }
  }

  &__remove {
    height: 50px;
    width: 50px;
    bottom: 0;
    right: 0;
    border-radius: 0 4px 4px 0;
    background-color: #f3f4f6;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer;

    & .icon {
      font-size: 11px;
      stroke: $color-brand-3;
    }
  }

  &_preudo_rub {
    &::after {
      content: 'руб.';
      position: absolute;
      bottom: 0;
      right: 20px;
      height: 50px;
      display: flex;
      align-items: center;
    }
    .input__control {
      padding-right: 55px;
    }
  }
}

input {
  &::-webkit-search-cancel-button {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='8' viewBox='0 0 7 8' fill='none' stroke='%23000' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.66667 1.3L0.333333 6.7' stroke-linecap='round'/%3E%3Cpath d='M0.333333 1.3L5.66667 6.7' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    width: 10px;
    height: 10px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.no-appearance {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  -moz-appearance: textfield;
}

.search_input {
  background-color: $color-white;
  border-radius: 4px;
  align-items: center;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 0 1px $color-brand-opaque-30;

  .input__control {
    background-color: transparent;
    position: relative;
    color: $color-brand-2;
    padding: 8px 6px;
    border-radius: 4px;
    &::placeholder {
      color: $color-brand-3;
    }
  }

  .cross_icon {
    cursor: pointer;
    display: flex;
    margin-right: 6px;
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
  }
}
