@import "../../styles/variables";
@import "../../styles/mixins";

.zl {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding-top: 20px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0C170C;
    opacity: 0;
    visibility: hidden;
    transition: none;
  }

  .swiper-pagination {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .swiper-pagination-bullet {
    display: flex;
    width: 7px;
    height: 7px;
    background: rgba(143, 149, 172, 0.2);
    border-radius: 50%;
    margin-right: 15px;
    &:last-of-type {
      margin-right: 0;
    }
  }

  .swiper-pagination-bullet-active {
    background: #8F95AC;
  }

  a:not(.zl__btn) {
    color: inherit;
  }
  
  &_overlay {
    &::after {
      opacity: 0.8;
      visibility: visible;
      z-index: 2;
      transition: all 0.3s;
    }
  }

  .caps {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    @include break-max($breakpoint-sm) {
      font-size: 11px;
    }
  }

  .text {
    font-size: 16px;
    line-height: 22px;
    @include break-max($breakpoint-md) {
      font-size: 14px;
      line-height: 18px;
    }
    @include break-max($breakpoint-xs) {
      font-size: 15px;
      line-height: 20px;
    }
  }

  .big-text {
    @include break-max($breakpoint-sm) {
      font-size: 17px;
      line-height: 22px;
    }
    @include break-max($breakpoint-xs) {
      font-size: 16px;
    }
  }

  .h2 {
    @include break-max($breakpoint-md) {
      font-size: 28px;
      line-height: 32px;
    }
    @include break-max($breakpoint-sm) {
      font-size: 20px;
      line-height: 24px;
    }
    @include break-max($breakpoint-sm) {
      font-size: 18px;
      line-height: 20px;
    }

  }
  
  .link {
    color: $color-brand-1;

    transition: all 0.3s;

    &:hover {
      color: rgba($color-brand-1, 0.6);
    }

    &:active {
      color: rgba($color-brand-1, 0.5);
    }

  }

  .prev-slide, .next-slide {
    background: none;
    cursor: pointer;
  }

  .prev-slide img {
    transform: rotate(180deg);
  }

}

.zl__header {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 2;
  @include break-max($breakpoint-xs) {
    top: 0;
    padding: 10px 0;
    background: $color-white;
  }
}

.zl__container {
  width: 1500px;
  padding: 0 15px;
  max-width: 100%;
  margin: 0 auto;
  @include break-max($breakpoint-md) {
    width: 1230px;
  }
  @include break-max($breakpoint-sm) {
    width: 750px;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.zl__wrapper {
  width: calc(100% - (100% - 1500px) / 2);
  margin-left: auto;
  padding-left: 15px;

  @include break-max($breakpoint-sm) {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
  }

  &_reverse {
    margin-left: 0;
    margin-right: auto;
    padding-left: 0;
    padding-right: 15px;
  }
}

.zl__bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $color-white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.zl__logo {
  background: $color-white;
  
  @include break-max($breakpoint-md) {
    height: 40px;
  }

  @include break-max($breakpoint-sm) {
    height: 35px;
  }
  @include break-max($breakpoint-xs) {
    height: 30px;
  }
}

.zl__nav {
  display: flex;
  align-items: center;
  width: 1155px;
  max-width: 100%;
  border-radius: 5px;
  background: $color-white;

  @include break-max($breakpoint-md) {
    width: 898px;
    height: 45px;
  }

  @include break-max($breakpoint-sm) {
    width: 615px;
    height: 40px;
  }

  @include break-max($breakpoint-xs) {
    height: 30px;
    background: none;
  }
}

.zl__nav-menu {
  display: flex;
  align-items: center;
  padding-left: 40px;
  height: 50px;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    text-align: left;
    height: auto;
    padding-left: 0;
  }

  li {
    margin-right: 100px;
    @include break-max($breakpoint-md) {
      margin-right: 60px;
    }
    @include break-max($breakpoint-sm) {
      margin-right: 18px;
    }
    @include break-max($breakpoint-xs) {
      width: 100%;
      margin-right: 0;
      padding-top: 12px;
      padding-bottom: 10px;
      border-bottom: 1px solid $color-brand-opaque-10;
    }
    &:last-of-type {
      margin-right: 0;
      @include break-max($breakpoint-xs) {
        border-bottom: 0;
      }
    }
    
  }
}


.zl__mobile-nav {
  @include break-max($breakpoint-xs) {
    width: 80%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;

    min-height: 100vh;
    height: auto;
    padding: 80px 22px 20px;
    background: $color-white;

    text-align: left;
    transform: translateX(2000px);
    transition: all 0.9s;
    z-index: 3;
    &.opened {
      transform: translateX(0);
    }
  }

  .zl__burger {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .zl__btn {
    width: 100%;
    margin-top: auto;
  }
}

.zl__burger {
  display: none;
  @include break-max($breakpoint-xs) {
    display: flex;
    flex-direction: column;
    order: 1;
    background: none;
    padding: 0;
    margin-left: 20px;
    width: 19px;
    height: 16px;
    position: relative;
    z-index: 9;

    &::before, &::after {
      transition: all 0.3s;
    }

    &::after {
      content: '';
      width: 100%;
      height: 2px;
      background: $color-brand-1;
      box-shadow: 0 6px, 0 12px;
    }

    &.opened {
      margin-top: 8px;
    }

    &.opened::before {
      content: '';
      width: 18px;
      height: 2px;
      background: $color-brand-1;
      transform: rotate(-45deg);
    }

    &.opened::after {
      width: 18px;
      box-shadow: none;
      transform: rotate(45deg);
      position: relative;
      top: -2px;
    }
  }
}

.zl__btn {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 0 45px;
  width: 220px;
  height: 50px;
  background: $green;
  border-radius: 4px;
  color: $color-white;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  transition: background 0.3s;

  @include break-max($breakpoint-xs) {
    height: 44px;
  }

  &.caps {
    line-height: 1;

    @include break-max($breakpoint-xs) {
      font-size: 12px;
    }
  }

  &:hover, &:focus {
    background: $green-hover;
  }
  
  &:active {
    background: $green-active;
  }

}


.zl__callback-btn {
  margin-left: auto;

  @include break-max($breakpoint-md) {
    width: 205px;
    height: 45px;
  }

  @include break-max($breakpoint-sm) {
    width: 155px;
    height: 40px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 11px !important;
    height: 30px;
    line-height: unset !important;
  }
}

.zl__main {
  padding-top: 245px;
  position: relative;
  background: url('../../assets/images/zl/bg.jpg') no-repeat top right;
  
  @include break-max($breakpoint-md) {
    background-size: 75%;
    padding-top: 210px;
  }

  @include break-max($breakpoint-sm) {
    background: url('../../assets/images/zl/bg-sm.png') no-repeat;
    background-position-x: right;
    background-position-y: 135px;
    padding-top: 140px;
    background-size: auto;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 70px;
    background-size: contain;
  }
}

.zl__label {
  display: block;
  margin-bottom: 30px;
  color: $green;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  @include break-max($breakpoint-sm) {
    text-align: center;
  }
  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
    font-size: 11px;
    line-height: 18px;
  }
  
}

.zl__h1 {
  width: 815px;
  max-width: 100%;
  font-size: 70px;
  line-height: 1;
  font-weight: 900;
  @include break-max($breakpoint-md) {
    width: 590px;
    font-size: 55px;
    line-height: 55px;
  }
  @include break-max($breakpoint-sm) {
    width: 590px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  @include break-max($breakpoint-xs) {
    width: 330px;
    font-size: 28px;
    line-height: 30px;
  }
}

.zl__sub {
  margin-top: 20px;
  font-size: 21px;
  line-height: 30px;
  color: $color-brand-3;
  @include break-max($breakpoint-sm) {
    text-align: center;
    margin-top: 40px;
  }
  @include break-max($breakpoint-xs) {
    margin-top: 15px;
  }
}

.zl__panel {
  margin-top: 130px;
  display: flex;
  width: 1095px;
  height: 80px;
  max-width: 100%;
  background: $color-white;
  box-shadow: 0px 4px 40px rgba(150, 174, 188, 0.25);

  font-weight: bold;
  font-size: 15px;
  line-height: 26px;
  text-transform: uppercase;

  @include break-max($breakpoint-md) {
    width: 865px;
    height: 70px;
    margin-top: 90px;
  }

  @include break-max($breakpoint-sm) {
    width: 100%;
    margin-top: 530px;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    margin-top: 310px;
    font-size: 12px;
    height: auto;
    align-items: center;
  }
}

.zl__panel-label {
  flex-shrink: 0;
  width: 250px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-right: 1px solid $color-brand-opaque-8;
  @include break-max($breakpoint-md) {
    width: 180px;
  }

  @include break-max($breakpoint-sm) {
    width: 145px;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 15px;
    padding-bottom: 15px;
    color: $green;
  }
  
}

.zl__panel-icon {
  stroke: #8F95AC;
  transition: all 0.3s;
  margin-top: -4px;
  margin-right: 10px;

  @include break-max($breakpoint-xs) {
    margin-right: 0;
    margin-top: 0;
  }
}

.zl__panel-list {
  flex-grow: 1;
  display: flex;
  padding-left: 55px;

  @include break-max($breakpoint-md) {
    padding-left: 22px;
  }

  @include break-max($breakpoint-xs) {
    padding: 0;
  }

  li {
    width: 250px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include break-max($breakpoint-md) {
      width: 182px;
      margin: 0 12px;
    }

    @include break-max($breakpoint-sm) {
      width: 160px;
    }

    @include break-max($breakpoint-xs) {
      width: 95px;
    }
  
  }

  button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 40px;
    padding-top: 5.5px;
    border-bottom: 5.5px solid  $green;
    background: none;
    font: inherit;
    text-transform: uppercase;

    @include break-max($breakpoint-md) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }

    transition: all 0.3s;
        
    &:hover, &:focus {
      color: $green-hover;
      .zl__panel-icon {
        stroke: $green-hover;
      }
    }
    
    &:active {
      color: $green-active;
      .zl__panel-icon {
        stroke: $green-active;
      }
    }
  }

  img {
    margin-right: 10px;
    margin-bottom: 3px;
    @include break-max($breakpoint-xs) {
      margin-right: 0;
    }
  }
}

.zl__title {
  font-weight: 900;
  font-size: 55px;
  line-height: 55px;
  color: #E7EBEF;
  @include break-max($breakpoint-md) {
    font-size: 45px;
    line-height: 45px;
  }
  @include break-max($breakpoint-sm) {
    font-size: 40px;
    line-height: 40px;
  }
  @include break-max($breakpoint-xs) {
    font-size: 24px;
    line-height: 24px;
  }
}

.zl__descr {
  padding: 100px 0;
  @include break-max($breakpoint-md) {
    padding: 70px 0;
  }
  @include break-max($breakpoint-sm) {
    padding: 90px 0 70px;
  }
  @include break-max($breakpoint-xs) {
    padding: 60px 0;
  }
}

.zl__ml {
  padding-left: 125px;
  @include break-max($breakpoint-md) {
    padding-left: 100px;
  }
  @include break-max($breakpoint-sm) {
    padding-left: 65px;
  }
  @include break-max($breakpoint-xs) {
    padding-left: 0;
  }
}

.zl__descr-title {
  width: 675px;
  max-width: 100%;
  margin-bottom: 20px;
  @include break-max($breakpoint-sm) {
    width: 467px;
  }
}

.zl__block {
  display: flex;
  padding-bottom: 70px;
  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.zl__block-img {
  @include break-max($breakpoint-md) {
    width: 590px;
  }
  @include break-max($breakpoint-sm) {
    width: 355px;
  }
}

.zl__block-box {
  width: 472px;
  max-width: 100%;
  margin-left: 125px;
  display: flex;
  flex-direction: column;

  @include break-max($breakpoint-md) {
    margin-left: 70px;
  }

  @include break-max($breakpoint-sm) {
    margin-left: 40px;
  }

  @include break-max($breakpoint-xs) {
    margin-left: 0;
    margin-top: 15px;
  }
}

.zl__block-label {
  display: block;
  margin-bottom: 25px;
  color: $green;
  margin-top: auto;
}

.zl__block-title {
  margin-bottom: 20px;
  font-weight: 900;
}

.zl__block-anchors {
  margin-top: 45px;
  display: flex;
  align-items: center;
  margin-bottom: auto;
  

  @include break-max($breakpoint-sm) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 25px;
    .zl__link {
      margin-top: 10px;
    }
  }
}

.zl__block-btn {
  margin-right: 30px;
  @include break-max($breakpoint-xs) {
    margin-right: 0;
    width: 100%;
  }
}

.link.zl__link {
  font-weight: bold;
  color: $green;
  transition: all 0.3s;
  &:hover {
    color: $green-hover;
  }
  &:active {
    color: $green-active;
  }
}

.zl__houses {
  display: flex;
  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.zl__houses-box {
  display: flex;
  flex-direction: column;
  width: 750px;
  max-width: 100%;
  flex-shrink: 0;
  background: url('../../assets/images/zl/gradient.png') no-repeat;
  @include break-max($breakpoint-md) {
    width: 590px;
  }
  @include break-max($breakpoint-sm) {
    width: 356px;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.zl__houses-content {
  width: 470px;
  max-width: 100%;
  margin: auto;
  color: $color-white;

  @include break-max($breakpoint-md) {
    width: 350px;
  }

  @include break-max($breakpoint-sm) {
    width: 250px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    padding: 40px 20px;
  }

  p + p {
    margin-top: 20px;
  }
}

.zl__houses-title {
  margin-bottom: 20px;
}

.zl__houses-btn {
  margin-top: 30px;
  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.zl__houses-slide {
  position: relative;
  margin-right: -1px;
  img {
    width: 100%;
    height: 576px;
    object-fit: cover;

    @include break-max($breakpoint-md) {
      height: 500px;
    }
    @include break-max($breakpoint-sm) {
      height: 446px;
    }
    @include break-max($breakpoint-xs) {
      height: 350px;
    }
  }
}

.zl__houses-name {
  position: absolute;
  left: 30px;
  bottom: 40px;
  color: $color-white;
  display: flex;
  align-items: center;

  span {
    margin-right: 20px;
  }

  img {
    width: 40px;
    height: 40px;
  }
}

.zl__houses_with-love {

  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }

  .zl__houses-box {
    background: $color-brand-opaque-8;
    
    @include break-max($breakpoint-sm) {
      width: 100%;
    }
  }

  .zl__houses-slider {
    @include break-max($breakpoint-sm) {
      width: 100%;
    }
  }

  .zl__houses-content {
    color: $color-brand-1;
    @include break-max($breakpoint-sm) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 50px 65px;
      text-align: center;
    }
    @include break-max($breakpoint-sm) {
      padding: 40px 20px;
      text-align: left;
    }
  }

}

.zl__build {
  padding-top: 80px;
  padding-bottom: 40px;
  @include break-max($breakpoint-md) {
    padding-bottom: 60px;
  }
  @include break-max($breakpoint-sm) {
    padding-bottom: 30px;
  }
  @include break-max($breakpoint-xs) {
    padding-top: 40px;
  }
}

.zl__build-title {
  width: 646px;
  max-width: 100%;
}

.zl__build-scheme {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  @include break-max($breakpoint-md) {
    padding-bottom: 130px;
  }
  @include break-max($breakpoint-sm) {
    align-items: flex-start;
  }
  @include break-max($breakpoint-xs) {
    padding-top: 200px;
    padding-bottom: 220px;
  }
}

.zl__build-img {
  position: relative;
  top: -120px;
  right: 80px;

  @include break-max($breakpoint-md) {
    width: 670px;
    max-width: 100%;
    top: 0;
    right: 30px;
  }
  @include break-max($breakpoint-sm) {
    width: 505px;
    right: auto;
    left: 0;
    top: 20px;
  }
  @include break-max($breakpoint-xs) {
    width: 330px;
    top: 0;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.zl__build-pic {
  @include break-max($breakpoint-md) {
    width: 140px;
  }
  @include break-max($breakpoint-sm) {
    width: 100px;
  }
}

.zl__build-text {
  margin-top: 10px;
  color: $color-brand-3;
}

.zl__counter {
  display: flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  color: $color-white;
  font-size: 14px;
  line-height: 1;
  border-radius: 50%;
  background: $green;
}

.zl__build-box {
  display: flex;
  align-items: center;
  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }

  .zl__build-text {
    display: none;
    @include break-max($breakpoint-xs) {
      display: block;
    }
  }

}

.zl__build-item {
  position: absolute;
  display: flex;
  flex-direction: column;
}

.zl__build-line {
  width: 205px;
  height: 1px;
  background: $green;
}

.zl__build-item_1 {
  top: 100px;
  left: 0;

  @include break-max($breakpoint-md) {
    top: 120px;
  }

  @include break-max($breakpoint-sm) {
    left: -60px;
  }

  @include break-max($breakpoint-xs) {
    left: 0;
    top: 20px;
  }

  .zl__build-text {
    width: 136px;
    @include break-max($breakpoint-xs) {
      width: 140px;
      padding-left: 20px;
    }
  }

  .zl__build-box + .zl__build-text {
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  .zl__build-line {
    margin-left: 10px;
    @include break-max($breakpoint-md) {
      width: 186px;
    }
    @include break-max($breakpoint-sm) {
      width: 111px;
    }
    @include break-max($breakpoint-xs) {
      width: 1px;
      height: 114px;
      order: 1;
      margin-top: 5px;
      margin-left: 0;
    }
  }

  .zl__build-counter {
    @include break-max($breakpoint-xs) {
      order: 2;
    }
  }

}

.zl__build-item_2 {
  left: 260px;
  bottom: 0;

  @include break-max($breakpoint-md) {
    left: 240px;
  }

  @include break-max($breakpoint-sm) {
    left: 10px;
  }

  @include break-max($breakpoint-sm) {
    left: 0;
    bottom: 40px;
  }
  

  .zl__build-counter {
    order: -1;
  }

  .zl__build-pic {
    order: 1;
  }

  .zl__build-box {
    flex-direction: column;
  }

  .zl__build-text {
    width: 213px;
    padding-left: 10px;
    @include break-max($breakpoint-xs) {
      width: 140px;
      padding-left: 20px;
    }
  }

  .zl__build-line {
    margin-bottom: 10px;
    width: 1px;
    height: 140px;
    @include break-max($breakpoint-md) {
      height: 104px;
    }
    @include break-max($breakpoint-sm) {
      height: 62px;
    }
    @include break-max($breakpoint-xs) {
      height: 56px;
    }
  }
}

.zl__build-item_3 {
  right: 45px;
  top: 90px;

  @include break-max($breakpoint-md) {
    right: 95px;
    top: 130px;
  }

  @include break-max($breakpoint-sm) {
    right: 10px;
    top: 60px;
  }

  @include break-max($breakpoint-xs) {
    right: 30px;
    top: 20px;
  }


  .zl__build-counter {
    order: -1;
    @include break-max($breakpoint-xs) {
      order: 1;
    }
  }

  .zl__build-pic {
    order: 1;
    @include break-max($breakpoint-xs) {
      order: -1;
    }
  }

  
  .zl__build-box + .zl__build-text {
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  .zl__build-line {
    margin-right: 10px;
    @include break-max($breakpoint-md) {
      width: 122px;
    }
    @include break-max($breakpoint-sm) {
      width: 90px;
    }
    @include break-max($breakpoint-xs) {
      width: 1px;
      height: 90px;
      margin-right: 0;
    }
  }

  .zl__build-text {
    width: 213px;
    text-align: right;
    margin-left: auto;
    @include break-max($breakpoint-xs) {
      width: 150px;
      text-align: left;
      padding-left: 20px;
    }
  }
}

.zl__build-item_4 {
  bottom: 0;
  right: 350px;
  @include break-max($breakpoint-md) {
    right: 270px;
  }

  @include break-max($breakpoint-sm) {
    right: 120px;
    bottom: 20px;
  }
  
  @include break-max($breakpoint-xs) {
    right: 10px;
    bottom: 40px;
  }
  

  .zl__build-counter {
    order: -1;
  }

  .zl__build-pic {
    order: 1;
  }

  .zl__build-box {
    flex-direction: column;
  }

  .zl__build-text {
    width: 271px;
    padding-left: 40px;
    @include break-max($breakpoint-xs) {
      width: 140px;
      padding-left: 20px;
    }
  }

  .zl__build-line {
    margin-bottom: 10px;
    width: 1px;
    height: 81px;
    @include break-max($breakpoint-md) {
      height: 48px;
    }
    @include break-max($breakpoint-sm) {
      height: 15px;
    }
    @include break-max($breakpoint-xs) {
      height: 20px;
    }
  }

}

.zl__tradein_bg {
  background: url('../../assets/images/zl/bg-2.jpg') no-repeat;
  background-position: right -80%;
  @include break-max($breakpoint-sm) {
    background: none;
  }
}

.zl__tradein-title {
  &_width {
    @include break-max($breakpoint-sm) {
      width: 286px;
    }
  }
}

.zl__tradein-sub {
  margin-top: 35px;
  width: 845px;
  max-width: 100%;
  @include break-max($breakpoint-md) {
    width: 620px;
    margin-top: 25px;
  }
  @include break-max($breakpoint-sm) {
    width: 424px;
  }
}

.zl__tradein-item {
  position: relative;
  width: 285px;
  @include break-max($breakpoint-sm) {
    width: 250px;
  }
  @include break-max($breakpoint-xs) {
   // width: 100%;
   margin: 0 auto;
  }
}

.zl__tradein-item {
  &::after {
    content: '';
    display: flex;
    position: absolute;
    top: 110px;
    right: -50px;
    width: 42px;
    height: 15px;
    background: url('../../assets/images/zl/icons/arrow.svg') no-repeat top right;
    @include break-max($breakpoint-xs) {
      display: none !important;
     }
  }
}

.zl__tradein-item_1 {
  @include break-max($breakpoint-sm) {
    order: 1;
  }
}

.zl__tradein-item_2 {
  @include break-max($breakpoint-sm) {
    order: 2;
    &::after {
      display: none;
    }
  }
}

.zl__tradein-item_3 {
  &::after {
    display: none;
  }
  @include break-max($breakpoint-sm) {
    order: 4;
  }
}

.zl__tradein-item_4 {
  order: 2;
  &::after {
    display: none;
  }
  @include break-max($breakpoint-sm) {
    order: 3;
    &::after {
      display: block;
      background: url('../../assets/images/zl/icons/arrow-2.svg') no-repeat top right;
    }
  }
}

.zl__tradein-item_5 {
  order: 1;
  &::after {
    background: url('../../assets/images/zl/icons/arrow-2.svg') no-repeat top right;
  }
  @include break-max($breakpoint-sm) {
    order: 5;
    &::after {
      background: url('../../assets/images/zl/icons/arrow.svg') no-repeat top right;
    }
  }
}

.zl__tradein-item_6 {
  &::after {
    background: url('../../assets/images/zl/icons/arrow-2.svg') no-repeat top right;
  }
  @include break-max($breakpoint-sm) {
    order: 6;
    &::after {
      display: none;
    }
  }
}

.zl__tradein-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.zl__tradein-list {
  padding-right: 100px;
  margin-top: 50px;
  padding-bottom: 40px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, 285px);
  grid-gap: 50px 0; /* 50 pixels of space added between rows and 30 pixels added between columns  */

  @include break-max($breakpoint-md) {
    padding-right: 30px;
  }

  
  @include break-max($breakpoint-sm) {
    grid-gap: 50px 140px;
    justify-content: start;
    padding-right: 0;
    grid-template-columns: repeat(2, 240px);
  }
  
  @include break-max($breakpoint-xs) {
    position: relative;
    .swiper-container {
      padding-bottom: 30px;
    }
  }
  
  &_with-5 {
    .zl__tradein-item_5::after {
      @include break-max($breakpoint-sm) {
        display: none;
      }
    }
  }

  @include break-max($breakpoint-xs) {
    display: block;
  }
}

.zl__tradein-counter {
  position: absolute;
  top: 100px;
  transform: translateX(-50%);
}

.zl__tradein-img {
  margin-bottom: 30px;
  @include break-max($breakpoint-xs) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.zl__about-left {
  width: 625px;
  flex-shrink: 0;
  background: none;
  padding: 90px 0;

  @include break-max($breakpoint-md) {
    width: 500px;
    padding: 80px 0;
  }

  @include break-max($breakpoint-sm) {
    width: 100%;
    padding: 40px 60px 20px;
  }
  
  @include break-max($breakpoint-xs) {
    padding: 0;
  }

  .zl__houses-content {
    width: 435px;
    @include break-max($breakpoint-md) {
      width: 370px;
    }
    @include break-max($breakpoint-sm) {
      width: 100%;
    }
    
  }
}

.zl__about-box {
  background: url('../../assets/images/zl/gradient-2.png') no-repeat;
  background-size: cover;
  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }
}

.zl__about-list {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include break-max($breakpoint-sm) {
    flex-wrap: nowrap;
  }
  @include break-max($breakpoint-xs) {
    flex-wrap: wrap;
  }
}



.zl__about-item {
  width: 200px;
  &:nth-child(1), &:nth-child(2) {
    margin-bottom: 50px;
    @include break-max($breakpoint-xs) {
      margin-bottom: 25px;
    }
  }

  @include break-max($breakpoint-md) {
    width: 170px;
  }
  @include break-max($breakpoint-sm) {
    width: 140px;
  }
  @include break-max($breakpoint-xs) {
    width: 160px;
  }
}

.zl__about-value {
  display: block;
  margin-bottom: 10px;

  font-weight: 900;
}

.zl__about-anchors {
  display: flex;
  align-items: center;
  margin-top: 60px;
  @include break-max($breakpoint-sm) {
    margin-top: 0;
    padding: 30px 0;
    justify-content: center;
  }
  @include break-max($breakpoint-xs) {
    padding: 25px 0;
    flex-direction: column;
  }
}

.zl__about-btn {
  width: 270px;
}


.zl__about-right {
  position: relative;
  color: $color-white;
  display: flex;
  flex-grow: 1;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $color-brand-opaque-15;
  }

  @include break-max($breakpoint-sm) {
    width: 100%;
    padding: 40px 60px 40px;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
    padding: 40px 20px 40px;
  }
}

.zl__about-checklist {
  position: relative;
  display: flex;
  margin: auto;
  width: 610px;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  @include break-max($breakpoint-md) {
    width: 500px;
  }
  @include break-max($breakpoint-sm) {
    width: 100%;
  }
}

.zl__about-checkitem {
  width: 270px;
  @include break-max($breakpoint-md) {
    width: 230px;
  }

  @include break-max($breakpoint-sm) {
    width: 290px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
  }
  
  &:nth-child(1), &:nth-child(2) {
    margin-bottom: 40px;
  }
  @include break-max($breakpoint-xs) {
    &:nth-child(n) {
      margin-bottom: 30px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.zl__about-h4 {
  position: relative;
  padding-top: 70px;
  margin-bottom: 20px;
  @include break-max($breakpoint-xs) {
    padding-top: 55px;
  }
  &::before {
    content: '';
    width: 38px;
    height: 39px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: url('../../assets/images/zl/icons/check.svg') no-repeat;
  }
}

.zl__manager {
  margin-top: 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;

  @include break-max($breakpoint-md) {
    margin-top: 80px;
  }
  @include break-max($breakpoint-sm) {
    margin-top: 60px;
  }
  @include break-max($breakpoint-xs) {
    margin-top: 40px;
  }
}

.zl__manager-text {
  text-align: center;
}

.zl__manager-avatar {
  margin-bottom: 30px;
  @include break-max($breakpoint-sm) {
    width: 120px;
  }
}

.zl__manager-name {
  margin-top: 55px;
  @include break-max($breakpoint-md) {
    margin-top: 35px;
  }
}

.zl__manager-position {
  margin-top: 5px;
  color: $color-brand-3;
  @include break-max($breakpoint-xs) {

    width: 232px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.zl__tradein-item_empty {
  &::after {
    display: none;
  }
  @include break-max($breakpoint-sm) {
    order: 9;
  }
  @include break-max($breakpoint-xs) {
    display: none;
  }
}


.zl__team {
  display: flex;
  background: $color-brand-opaque-8;
  @include break-max($breakpoint-sm) {
    flex-direction: column;
    position: relative;
  }
}

.zl__team-slider {
  width: 1035px;
  max-width: 100%;
  @include break-max($breakpoint-md) {
    width: 900px;
  }
  @include break-max($breakpoint-sm) {
    width: 100%;
  }
}

.zl__team-nav {
  margin-top: 50px;
  display: flex;
  @include break-max($breakpoint-md) {
    margin-right: 0;
    margin-top: 30px;
  }

  @include break-max($breakpoint-sm) {
    margin-top: 0;
  }

  .prev-slide {
    margin-right: 20px;
    padding: 0;
    @include break-max($breakpoint-md) {
      margin-right: 15px;
    }
    @include break-max($breakpoint-sm) {
      width: 55px;
      display: none;
    }
  
  }

  .next-slide {
    padding: 0;

    @include break-max($breakpoint-sm) {
      width: 55px;
      position: absolute;
      z-index: 2;
      bottom: 270px;
      right: 210px;
    }

    @include break-max($breakpoint-xs) {
      bottom: auto;
      right: 15px;
      top: 125px;
    }

  }
}

.zl__houses-sup {
  @include break-max($breakpoint-md) {
    margin-top: auto;
  }
}

.zl__team-block {
  flex-grow: 1;
  padding-left: 125px;
  padding-top: 80px;
  padding-right: 80px;
  width: 375px;
  max-width: 100%;
  @include break-max($breakpoint-md) {
    width: 300px;
    padding-left: 100px;
  }
  @include break-max($breakpoint-sm) {
    width: 100%;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 0;
    padding-right: 0;
  }
  @include break-max($breakpoint-xs) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.zl__team-item {
  position: relative;
  color: $color-white;

  &_with-gradient {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(0, #000, transparent);
    }
  }

  img {
    @include break-max($breakpoint-xs) {
      width: 100%;
      max-height: 550px;
      object-fit: cover;
    }
  }
}

.zl__team-img {
  width: 345px;
  height: 500px;
  object-fit: cover;
  @include break-max($breakpoint-md) {
    width: 300px;
    height: 435px;
  }
  @include break-max($breakpoint-sm) {
    width: 240px;
    height: 347px;
  }
  @include break-max($breakpoint-xs) {
    width: 384px;
    height: 550px;
  }
}

.zl__team-content {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0 35px 35px;
  z-index: 1;
  @include break-max($breakpoint-sm) {
    padding: 0 20px 20px;
  }
}

.zl__team-name {
  display: block;
  margin-bottom: 5px;
}

.zl__exp {
  display: inline-flex;
  align-items: center;
  margin-top: 15px;
  height: 28px;
  padding: 0 10px;
  background: $green;
  border-radius: 4px;
}

.zl__houses_diplomas {
  margin-top: 85px;
  background: none;
  @include break-max($breakpoint-xs) {
    margin-top: 40px;
    position: relative;
  }
  .zl__houses-box {
    background: none;
  }

  .next-slide {
    display: inline-flex;
    position: static;
    margin-top: 35px;
    @include break-max($breakpoint-xs) {
      position: absolute;
      right: 15px;
      bottom: 270px;
      z-index: 2;
      width: 55px;
      height: 55px;
      padding: 0;
    }

  }

  .zl__houses-slider {
    @include break-max($breakpoint-xs) {
      order: 1;
      margin-top: 35px;
    }
  }
}

.zl__houses-slider {
  width: 720px;
  max-width: 100%;
  @include break-max($breakpoint-md) {
    width: 600px;
  }

  @include break-max($breakpoint-sm) {
    width: 364px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
  }

  .next-slide {
    position: absolute;
    top: 35px;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    background: none;
    cursor: pointer;

    @include break-max($breakpoint-sm) {
      transform: none;
      left: auto;
      right: 25px;
      width: 55px;
      height: 55px;
    }
  }
}

.zl__houses-slider_with-love {

  .next-slide {
    left: auto;
    right: 35px;
    transform: none;
  }

}

.zl__houses-right {
  width: 750px;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  .zl__block-box {
    margin: auto 70px;
    @include break-max($breakpoint-sm) {
      width: 325px;
      margin-left: auto;
      margin-right: 0;
    }
  }
}

.zl__placeholder {
  width: 100%;
  height: 425px;
  background: $color-brand-opaque-8;
}

.zl__reviews-title {
  margin-bottom: 60px;
  @include break-max($breakpoint-sm) {
    margin-bottom: 30px;
  }
}


.zl__footer {
  display: flex;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
  
  p.zl__footer-item, p.zl__footer-item + p.zl__footer-item {
    margin-top: 30px;
  }

  a {
    color: inherit;
    transition: all 0.3s;
    
    &:hover, &:focus {
      color: $green-hover;
    }
    
    &:active {
      color: $green-active;
    }
  }
}

.zl__footer-block {
  width: 50%;
  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.zl__footer-contacts {
  padding: 120px 0 110px;
  background: url('../../assets/images/zl/gradient.png') no-repeat;
  @include break-max($breakpoint-sm) {
    padding: 0;
    display: flex;
  }
}


.zl__h4 {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  @include break-max($breakpoint-sm) {
    font-size: 14px;
    line-height: 18px;
  }
}

.zl__map {
  position: relative;

  iframe {
    border: none;
    @include break-max($breakpoint-sm) {
      height: 400px;
    }
    @include break-max($breakpoint-xs) {
      height: 220px;
    }
  }

  @include break-max($breakpoint-xs) {
    order: -1;
  }
}

.zl__map-img {
  width: 100%;
  height: 539px;
  object-fit: cover;
}

.zl__map-pin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.zl__about-title {
  @include break-max($breakpoint-sm) {
    text-align: center;
    width: 480px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.zl__about-socials {
  display: flex;
  margin-left: 40px;
  @include break-max($breakpoint-xs) {
    margin-left: 0;
    margin-top: 25px;
  }
}

.zl__social {
  position: relative;
  display: inline-flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  &:first-of-type {
    margin-right: 20px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($green, 0.2);
    border-radius: 50%;
  }
  img {
    position: relative;
  }
}

.modal_zl {
  .modal__close-outer {
    background: $green;
    &:hover, &:focus {
      background: $green-hover;
    }
    
    &:active {
      background: $green-active;
    }
  
  }
  .modal__inner {
    border-radius: 20px;
    padding-top: 70px;
    padding-bottom: 80px;
  }
  .modal__shadow {
    opacity: 0.8;
  }
}

.zl-form__item + .zl-form__item {
  margin-top: 10px;
}

.zl-form {
  
  .checkbox__box {
    order: 0;
  }

  .checkbox__box + .checkbox__label {
    padding-left: 14px;
  }
}

.zl-form .checkbox {
  margin-top: 20px;
  align-items: flex-start;
}

.zl-form__submit {
  background: $green;
  &:hover, &:focus {
    background: $green-hover;
  }
  
  &:active {
    background: $green-active;
  }

}

.zl-form__link {
  color: $green;
  &:hover, &:focus {
    color: $green-hover;
  }
  
  &:active {
    color: $green-active;
  }

}

.zl-form__submit {
  display: block;
  width: 220px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.zl-form__title {
  font-weight: 900;
  margin-bottom: 40px;
  @include break-max($breakpoint-xs) {
    display: none;
  }
}
