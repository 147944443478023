.create-company {
  width: 860px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;

  &__title {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__top {
    display: flex;
    gap: 20px;
  }

  &__top-left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 460px;
  }

  &__top-right {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: calc(100% - 480px)
  }

  &__block {
    border: 1px solid #DFE1E9;
    padding: 20px;
    border-radius: 20px;
    position: relative;
  }

  &__block_add-requisites {
    border-color: #EF372E;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .create-company__block-title {
      margin-bottom: 0;
    }
  }

  &__block_dark {
    background-color: #f6f7f8;
    border-color: #f6f7f8
  }

  &__block-title {
    font-weight: 700;
    color: #2C2E3E;
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__block-titlehint {
    cursor: pointer;
    height: 24px;
  }

  &__edit-requisites {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    gap: 5px;
    color: #8790E5;
    display: flex;
    align-items: center;
    transition-duration: .3s;

    &:hover {
      color: #7b84d7;

      svg {
        path {
          stroke: #7b84d7;
        }
      }
    }
  }

  &__add {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__add-btn {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #F6F7F8;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: .3s;

    &:hover {
      background-color: #e9eaec;
    }
  }

  &__add-btn_image {
    height: auto;
  }

  &__manager {
    display: flex;
    gap: 10px;
  }

  &__manager-img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__manager-name {
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__manager-seconodinfo {
    display: flex;
    gap: 10px;
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__status-block {
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    padding-left: 10px;
    margin-bottom: 20px;
  }

  &__status-title {
    font-weight: bold;
    margin-bottom: 3px;
  }

  &__status-text {
    color: #777A89;
    font-size: 12px;
  }

  &__status-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &__status-info {
    z-index: 1;
  }

  &__status-chart {
    z-index: 1;
  }

  &__status-stat {

  }

  &__status-topstat {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #DFE1E9;

    span {
      color: #777A89;
      font-style: italic;
    }
  }

  &__status-botstat {
    padding-top: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    span {
      font-weight: bold;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }



  .requisites {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lk-form__row-content {
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
  }


  .requisites__text-block {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    flex-direction: row;
    gap: 0;
  }

  .requisites__info {
    max-width: 100%;
    flex-direction: column;
    width: 50%;
  }

  .requisites__info:last-child {
    border-left: 1px solid #DFE1E9;
    padding-left: 10px;
    margin-left: -5px;
  }

  .requisites__info-title {
    color: #8F95AC;
  }

  .requisites__info-text p {
    font-weight: normal;
    color: #2C2E3E;
  }

  .requisites__info-text {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;
  }

  &__action-btns {
    display: flex;
    gap: 20px;
    align-items: flex-end;
    width: 100%;
    justify-content: flex-end;
  }

  &__upload-image {
    border-radius: 50%;
    width: 64px;
    height: 64px;
    object-fit: cover;
    min-width: 64px;
  }

  &__remove-image {
    margin-top: 5px;
    color: #8790E5;
    display: inline-block;
    margin-left: 4px;
    cursor: pointer;
  }

  &__over-upload {

  }

  &__editrequisites-btn {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    span {
      color: #8790E5;
    }
  }

}

.create-company.create-company_edited {
  .cabinet-input {
    input {
      box-shadow: inset 0 0 0 1px rgba(143, 149, 172, .2);
      background-color: white;
    }
  }
  .cabinet-textarea {
    box-shadow: inset 0 0 0 1px rgba(143, 149, 172, .2);
    background-color: white;
  }
}

.edit-requisites {
  .requisites .lk-form__row-content {
    padding-top: 0;
  }
  .requisites {
    margin-top: 0;
  }
  .requisites .tabs {
    margin-top: 0;
  }
  .lk-form__row._bg-gray {
    background-color: white;
  }

  .input_type_form .input__control {
    background-color: #F6F7F8;
    box-shadow: none;
  }

}

.cabinet-input {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  input {
    width: 100%;
    background-color: #F6F7F8;
    border-radius: 5px;
    height: 50px;
    padding-left: 12px;
    padding-right: 12px;
    font-family: "Formular", sans-serif;

    &::placeholder {
      color: #777A89;
    }
  }

  &__hint {
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
    transition-duration: .3s;
  }

  &__hint-text {
    color: #EF372E;

    &:hover {
      color: #de342b;
    }
  }

  &__hint_text {
   top: 15px;
  }

  &__add-new {
    display: flex;
    gap: 10px;
    color: #8F95AC;
    align-items: center;
    cursor: pointer;
    transition-duration: .3s;

    &:hover {
      color: #777A89;
    }
  }

  &__overinp {
    position: relative;
  }

  &__remove-inp {
    position: absolute;
    right: 5px;
    top: 4px;
    cursor: pointer;
  }
}

.cabinet-textarea {
  min-height: 130px;
  padding: 15px;
  border-radius: 5px;
  background-color: #F6F7F8;
  width: 100%;
  font-family: "Formular", sans-serif;
  border: none;
  outline: none;
  resize: vertical;
}





.project-theme {
  display: flex;
  gap: 10px;
  align-items: center;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &--active {
      .project-theme__colors {
      }

      .project-theme__radio-custom {
        background: #6b7280;
        position: relative;

        &:before {
          content: '';
          width: 14px;
          height: 14px;
          border: 3px solid white;
          border-radius: 50%;
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  &__colors {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.2s;
  }

  &__color {
    width: 50px;
    height: 50px;

    &--left {
    }
  }

  &__radio {
    display: none;
  }

  &__radio-custom {
    margin-top: 8px;
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    border-radius: 50%;
    background: #fff;
    transition: background 0.2s;
  }
}














