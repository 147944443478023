@import "../../styles/variables";
@import "../../styles/mixins";

.hot-news {
  padding-bottom: 80px;

  .news__btn {
    display: inline-flex;
  }

  .news__title {
    text-align: center;
    padding-left: 18px;
    padding-right: 18px;

    @include break-max($breakpoint-xsm) {
      padding-top: 35px;
    }
  }

  .news__wrapper {
    display: flex;
    align-items: flex-start;
    position: relative;
  }

  .news__container {
    width: 700px;
    display: flex;
    flex-direction: column;

    &_smena {
      width: 730px;
    }
  }

  .news__share + .news__container {
    position: relative;
    right: 16px;
  }

  .news__share {
    position: sticky;
    top: 60%;
  }

  .news__promo-img {
    margin: 60px 0;

    @include break-max($breakpoint-sm) {
      margin: 30px 0;
    }
  }

  .news__text {
    line-height: 1.5;
  }

  .news__text + .news__text {
    margin-top: 20px;
    &.news__big-margin {
      margin-top: 40px;
    }
  }

  .news__text + .news__subtitle {
    margin-top: 20px;
    &.news__big-margin {
      margin-top: 40px;
    }
  }

  .news__list + .news__text {
    margin-top: 20px;
    &.news__big-margin {
      margin-top: 40px;
    }
  }



  .news__subtitle {
    margin-bottom: 20px;
  }

  .news__btn {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    &_left {
      margin-left: 0;
    }
    &_mt-0 {
      margin-top: 0;
    }
  }

  .h5 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
  }
}


.share {
  display: inline-flex;
}

.share__item {
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.share__anchor {

  width: 32px;
  height: 32px;

  svg {
    fill: $color-brand-3;
    width: 18px;
    height: 18px;
  }
}
