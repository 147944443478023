.flats__card {
  $c: &;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  padding: 24px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: box-shadow 0$transition-default;
  height: 100%;
  justify-content: space-between;

  &:hover {
    box-shadow: 0px 2px 15px $color-brand-opaque-50;
  }

  @include break-max($breakpoint-sm) {
    align-items: center;
    flex-direction: row;
    padding: 16px 24px;
  }

  @include break-max($break-xs) {
    flex-direction: column;
  }

  &_compact {
    padding: 10px;
    flex-direction: row;

    @include break-max($break-xs) {
      flex-direction: column;
    }

    &:hover {
      box-shadow: none;
    }

    #{$c}-image {
      padding: 10px 0;
      margin: auto 5px auto 0;
      width: 160px;
      height: 190px;
      flex-shrink: 0;
    }

    #{$c}-info {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .card {
      &__place {
        max-width: initial;
      }

      &__dates {
        text-align: left;
      }
    }
  }

  .card {

    &__fav {

      width: 36px;
      height: 36px;
      border-radius: 50%;
      justify-content: center;
      .icon {
        font-size: 18px;
      }
    }

    &__row {
      margin-top: 20px;
    }

    &__mt-10 {
      margin-top: 10px;
    }

    &__mt-5 {
      margin-top: 5px;
    }

    &__title {
      font-size: 14px;
      line-height: 17px;
    }

    &__routes {
      margin-top: 10px;
    }

    &__place {
      max-width: 100%;
    }
  }

  & .favorites__link {
    width: 18px;
    height: 20px;
    display: block;
  }
  &-top {
    display: flex;
    justify-content: space-between;
  }
  &-image {
    position: relative;
    padding: 20px;
    text-align: center;
    height: 270px;
    box-sizing: content-box;

    @include break-max($breakpoint-sm) {
      // width: 500px;
      width: 290px;
      height: auto;
      border-right: 1px solid $color-brand-opaque-10;
    }

    @include break-max($break-xs) {
      width: 100%;
    }

    @include break-max($breakpoint-sm) {
      border-right: none;
    }

    // @include break-max($breakpoint-sm) {
    //   width: 290px;
    // }

    & img {
      height: 100%;
      width: 100%;
      max-height: 260px;
      object-fit: contain;
    }
  }
  &-info {
    padding: 12px 0;

    @include break-max($breakpoint-sm) {
      padding-left: 12px;
      flex-grow: 1;
    }

    @include break-max($breakpoint-sm) {
      padding-left: 0;
    }
  }
  &-price {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
  }
  &-features {
    padding-top: 20px;
    display: flex;
    margin: -4px -12px;
    flex-wrap: wrap;

    @include break-max($break-xs) {
      margin: 0;
    }
  }
  &-feature {
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(33.3333% - 24px);
    margin: 4px 12px;

    @include break-max($breakpoint-md) {
      margin: 4px 8px;
      flex-basis: calc(33.3333% - 9px)
    }

    @include break-max($break-xs) {
      margin-left: 0;
    }

    &_title {
      color: $color-brand-3;
      flex-shrink: 0;
      margin-right: 10px;
    }
    &_value {
      font-weight: bold;
      flex-shrink: 0;
    }
  }
}

.flat-card__col {
  @include grid-size(4);
  display: flex;
  margin-bottom: #{$grid-gutter};

  @include break-max($breakpoint-md) {
    @include grid-size(4, $grid-gutter-md);
    margin-bottom: #{$grid-gutter-sm};
  }

  @include break-max($breakpoint-sm) {
    @include grid-size(12, $grid-gutter-sm);
    margin-bottom: #{$grid-gutter-sm};
  }

  & .flats__card {
    width: 100%;
  }
}
