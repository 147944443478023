.orders {
  padding-bottom: 25px;

  .label-item {
    @include break-max($breakpoint-md) {
      white-space: nowrap;
    }

    @include break-max($breakpoint-sm) {
      white-space: normal;
    }
  }


  .is-hidden-md {
    @include break-max($breakpoint-md) {
      display: none;
    }
  }

  .is-hidden-sm {
    @include break-max($breakpoint-sm) {
      display: none;
    }
  }

  &__header {
    position: relative;
    display: flex;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  &__switcher {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &__filter {
    padding: 20px;
    background: $color-brand-opaque-8;
    border-radius: 5px;
    display: flex;
    position: relative;

    @include break-max($breakpoint-sm) {
      margin-left: -20px;
      margin-right: -20px;
    }

    &-grid {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
    }

    &-col {
      flex: 1 1 150px;

      & + & {
        margin-left: 12px;
      }

      & .form__item {
        margin-bottom: 0;
        margin-left: 0;
      }

      &_2x {
        flex: 2 1 300px;
      }
    }

    &-button {
      margin-left: 30px;
      border-radius: 5px;
      padding: 16px 24px;
      border: 1px solid $color-border;

      @include break-max($break-md) {
        padding: 10px;
        border: 0;
      }
    }

    &-mobile {
      display: none;
      justify-content: center;
      margin-bottom: 40px;

      @include break-max($breakpoint-sm) {
        display: flex;
      }
    }

    &-search {
      flex-basis: 25%;
      .input_type_search {
        .input__control {
          height: 50px;
          border-radius: 4px;
          background-color: $color-white;
          color: $color-brand-1;
        }
      }
    }
  }

  .index-search__filter {

    &-applied {}

    &-inc {
      padding-left: 0;
      border: none;

      &:after {
        display: none;
      }
    }
  }

  &__content {
    margin-top: 25px;
  }

  &__table {
    $c: &;

    &-client {
      font-weight: bold;

      @include break-max($breakpoint-md) {
        display: inline-block;
        width: 120px;
      }
    }

    &-link {
      color: $color-brand-2;
      text-decoration: none;
      transition: color 0.15s ease;

      .icon {
        display: inline-block;
        stroke: $color-red;
        opacity: 0;
        width: 12px;
        height: 12px;
        transition: opacity 0.15s ease;
      }

      &:hover {
        color: $color-red;

        .icon {
          opacity: 1;
          stroke: $color-red;
        }
      }

      @include break-max($breakpoint-sm) {
        .icon {
          opacity: 1;
          stroke: $color-brand-3;
        }
      }
    }

    &-object {
      min-width: 130px;
      margin-bottom: 10px;

      @include break-max($breakpoint-sm) {
        width: 130px;
      }
    }

    &-label {
      color: $color-brand-3;
      text-decoration: none;
      transition: color 0.15s ease;

      &:hover {
        color: $color-red;
      }
    }

    &-payment {
      @include break-max($breakpoint-md) {
        width: 130px;
      }
    }


    & tbody tr.disabled {
      color: $color-brand-opaque-30;
      cursor: default;

      #{$c}-link,
      #{$c}-label {
        color: inherit;
        pointer-events: none;
      }
    }
  }

  .label-item {
    @include break-min($break-lg) {
      white-space: nowrap;
    }
  }
}

.table {
  width: 100%;
  & th.nowrap,
  & td.nowrap {
    white-space: nowrap;
  }
}

.orders__content.for-agents {
  @include break-max($break-xs) {
    margin: 0 10px;

    .flats__row-table .ant-table-cell {
      &:first-child {
        padding-left: 10px;
      }
    }

    .flats__row-table-expanded-column .lk-presentation__clients .flats__row-table-expanded-title {
      width: 130px;
    }

    .select.select_type_table .custom-select .custom-select__selected {
      padding: 0;
    }

    .icon.icon_external-link {
      display: none;
    }

    .lk-presentation__clients .select_type_table .custom-select {
      min-width: 120px;
    }

    .custom-select__option {
      padding: 2px 4px;
    }

    .orders__table-link.orders__table-client {
      white-space: normal;
    }

    .label-item {
      text-align: center;
    }
  }
}

.no-header-profile {
  padding-top: 35px;
  @include break-max($breakpoint-xs) {
    padding-top: 0;
  }
}
