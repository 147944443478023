.client__value {
  word-break: break-word;
}
.custom-select-high{
  width: 195px!important;
}

.pan-table.for-clients .custom-select__selected .label-item{
  padding-left: 0;
}
.option-small-padding{
  padding: 8px 8px;
}

.clients__download {
  cursor: pointer;
}

.clients__crm-data {
  position: relative;
}

.clients__crm-data .mini-filter .form {
  background-color: transparent;
  padding: 0;
}

.clients__crm-data .mini-filter .form .form__drop-btn {
  background-color: #f6f7f8;
}

.clients__crm-data .mini-filter {
  margin-bottom: 0;
}

.clients__crm-data .clients__filter-col_search .input_type_search .input__control {
  background-color: #f6f7f8;
}

.clients__crm-data .mini-filter .form  .clients__filter-col {
  margin-right: 20px;
}
.clients__crm-data .mini-filter .form  .clients__filter-col:last-child {
  margin-right: 0px;
}

.clients__crm-data .flats__row-table table {
  padding-left: 0;
  padding-right: 0;
}

.clients__crm-data .ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 5px 0 0 5px;
}
.clients__crm-data .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0 5px 5px 0;
}

.clients__crm-data .ant-table-container table > tbody > tr td:first-child {
  border-radius: 5px 0 0 5px;
}
.clients__crm-data .ant-table-container table > tbody > tr td:last-child {
  border-radius: 0 5px 5px 0;
}

.clients__crm-data .mini-filter .form {
  margin-left: 0;
  margin-right: 0;
  width: calc(100% - 600px);
}

.clients__crm-data .clients__filter-col_search {
  flex: 1 1 270px;
}

@media screen and (max-width: 1599px) {
  .clients__crm-data .mini-filter .form {
    width: calc(100% - 350px);
  }
}

@media screen and (max-width: 1279px) {
  .clients__crm-data .mini-filter .form {
    width: calc(100% - 250px);
  }
  .clients__crm-data .clients__filter-col_search {
    flex: 1 1 210px;
  }
}

.button_addClientFromCrm {
  position: absolute;
  top: 0;
  right: 0;
}

.table__controls.table__controls_fromCrm {
  justify-content: flex-start;
  margin-bottom: 15px;
  margin-top: 30px;
}

.table__controls.table__controls_fromCrm .button_view_control:first-child {
  margin-left: 0;
  padding-left: 0;
}
.table__controls.table__controls_fromCrm .button_view_control {
  height: auto;
  color: #8f95ac;
}

.table__controls.table__controls_fromCrm .button_view_control:hover {
  box-shadow: none;
  color: #ff2d23;
}

.mini-filter__grid.mini-filter__grid_fromCrmPresentation {
  justify-content: flex-start;
}

.mini-filter__grid.mini-filter__grid_fromCrmPresentation .clients__filter-col {
  min-width: 170px;
  flex: 0;
}

.add-client-redesign {
  display: flex;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 100px;
  padding-right: 100px;
  justify-content: center;
}

.add-client-redesign__left {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 340px;
  min-width: 340px;
}

.add-client-redesign__part {
  border: 1px solid #DFE1E9;
  border-radius: 20px;
  padding: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.add-client-redesign__right {
  width: 340px;
  min-width: 340px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-client-redesign .custom-select__selected {
  background-color: #F6F7F8;
  box-shadow: none;
}

.add-client-redesign .input_type_form .input__control {
  background-color: #F6F7F8;
  box-shadow: none;
}


.add-client-redesign .textarea__control {
  background-color: #F6F7F8;
  box-shadow: none;
}

.add-client-redesign .input_type_upload-btn {
  background-color: #f4f4f7;
  width: 300px;
  color: #8F95AC;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}


.add-client-redesign .lk-form__row_files {
  flex-direction: column;
}

.add-client-redesign .lk-form__row_files .file-up {
  margin-top: 0;
}

.add-client-redesign .lk-form__files {
  display: flex;
}

.add-client-redesign .lk-form__files-hint {
  display: flex;
  gap: 10px;
  color: #8F95AC;
}


.add-client-redesign .lk-form__files-hint svg {
  flex-shrink: 0;
}
