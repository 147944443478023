.banners {
  margin-top: auto;

  @include break-max($breakpoint-xs) {
    margin-top: 0;
  }

  &__grid {
    display: flex;
    flex-wrap: nowrap;
    @include grid-size(10);
    @include grid-shift(1);

    border-radius: 5px;
    border: 1px solid $color-brand-opaque-20;

    @include break-max($breakpoint-md) {
      @include grid-size(12);
      @include grid-shift(0);
    }

    @include break-max($breakpoint-xs) {
      flex-direction: column;
      margin: 0;
      padding: 0 10px;
      width: 100%;
      border: none;
    }

    &-col {
      flex-grow: 1;
      min-width: 32%;

      & + & {
        margin-left: 13px;

        @include break-max($breakpoint-xs) {
          margin-left: 0;
          margin-top: 15px;
        }
      }

      &:last-of-type .banner-card::after {
        display: none;
      }

      @include break-max($breakpoint-xs) {
        min-width: 100%;
        border: 1px solid $color-brand-opaque-20;
        border-radius: 5px;
      }

      @media (max-width: $breakpoint-sm) {
        display: flex;
        flex-direction: column;
        a {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      }
    }
  }

  .banner-card {
    position: relative;
    display: flex;
    align-items: center;

    padding: 15px 30px;
    border-radius: 5px;
    color: $color-brand-1;
    cursor: pointer;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 8px;
      bottom: 8px;
      width: 1px;
      background: $color-brand-opaque-20;

      @include break-max($breakpoint-xs) {
        display: none;
      }
    }

    @include break-max($breakpoint-sm) {
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
      padding: 15px 20px;
    }

    @include break-max($breakpoint-xs) {
      flex-direction: row;
      padding: 0 18px 0;
    }

    &__description {
      @include break-max($breakpoint-xs) {
        font-size: 14px;
        line-height: 20px;
        padding-right: 0;

      }
    }

    &__name {
      font-weight: bold;
      margin-bottom: 7px;

      @include break-max($breakpoint-xs) {

      }

      &_new {
        display: flex;
        position: relative;
        &::after {
          content: 'New';

          position: absolute;
          right: -25px;
          @media (max-width: 1330px) {
            right: -35px;
          }

          @media (max-width: $breakpoint-sm) {
            right: 0;
          }

          display: flex;
          justify-content: center;
          align-items: center;
          width: 42px;
          height: 28px;

          background: $color-red;
          border-radius: 5px;

          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          color: $color-white;

          @include break-max($breakpoint-xs) {
            font-size: 12px;
            height: 24px;
            line-height: 18px;
            top: -3px;
            left: 150px;
            width: 37px;
          }
        }
      }
    }

    &__icon {
      transition: opacity $transition-default;
      @include break-max($breakpoint-xs) {
        margin-right: 20px;
      }
      .icon {
        width: 75px;
        height: 85px;
        padding-top: 15px;
        @include break-max($breakpoint-xs) {
          padding-bottom: 10px;
        }
      }
    }

    &__external-arrow {

      opacity: 0;
      width: 10px;
      height: 11px;
      transition: all $transition-default;
      margin-left: 20px;
      .icon {
        width: 10px;
        height: 11px;
        fill: none;
        stroke: $color-red;
      }
    }


    @include break-max($breakpoint-sm) {
      padding-bottom: 20px;


      &__icon {
        order: -1;
      }
    }

    @include break-max($breakpoint-xs) {
      padding-bottom: 0;
      align-items: center;
      &__description {
        padding-right: 0;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }


  .banner-card__content {
    margin-left: 20px;
    @include break-max($breakpoint-sm) {
      margin-left: 0;
    }
    @include break-max($breakpoint-xs) {
      margin-left: 0;
      width: 100%;
      z-index: 1;
    }
  }

  .banner-card__text {
    display: flex;
  }

  .banner-card__description {
    line-height: 18px;
  }

  .banner-card:hover .banner-card__external-arrow {
    opacity: 1;
  }

}

.home_dark .banners .banner-card {
  color: $color-white;
}
