.icon-plus {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    &__icon {
        flex-shrink: 0;
    }

    &__block {
        flex: 1;
    }

    &_align {

      &_vertical {
        flex-direction: column;
      }
    }

    &_distrib {

      &_center {
        justify-content: center;
      }
    }

    &_align {

      &_center {
        align-items: center;
      }

      &_top {
        align-items: flex-start;
      }
    }
}