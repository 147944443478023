.files {

  &__header {
    padding-top: 60px;

    @include break-max($break-xs) {
      padding: 20px 10px 15px;
    }
  }

  &__breadcrumbs {
    display: flex;
    margin-top: 20px;

    @include break-max($break-xs) {
      flex-wrap: wrap;
      margin-top: 15px;
    }
  }

  &__breadcrumb {
    color: $color-brand-3;

    &-link, &-delimiter {
      color: inherit;
    }

    &-delimiter {
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;
      cursor: default;
    }
  }

  &__body {
    position: relative;
    margin-top: 40px;
    padding-bottom: 135px;
    @include grid-shift(2);
    @include break-max($breakpoint-sm) {
      @include grid-shift-left(2);
      margin-right: 0;
    }

    @include break-max($break-xs) {
      margin: 0;
    }


    .search {
      position: relative;

      &.with-back {
        @include break-max($break-xs) {
          margin-left: 70px;
        }
      }

      &-result {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 15px;
        box-shadow: 0 0 0 1px $color-brand-opaque-20;
        border-radius: 0 0 4px 4px;
        background-color: $color-white;
        z-index: 10;

        &.is-active {
          display: block;
        }

        &__list {

          & .document__row {
            padding: 15px 20px;

            &:hover {

              & .document__arrow {
                display: flex;
              }
            }
          }

          & .files__item {
            cursor: pointer;

            &:hover {
              background-color: $color-brand-opaque-8;
            }

            &-control {
              display: none;
            }
          }

        }

        &__more {
          display: inline-block;
          margin: 15px 20px;
        }
      }
    }
  }

  &__return {
    position: absolute;
    z-index: 1;

    .button {
      padding: 17px 27px;

      @include break-max($break-xs) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .icon {
        stroke: $color-brand-2;
      }

      &:hover {
        .icon {
          stroke: $color-brand-3;
        }
      }
    }
  }

  &__list {
    margin-top: 20px;
  }
}

.document {

  &__row {
    position: relative;
    display: flex;
    align-items: center;
    padding: 25px 0;
    min-height: 60px;
    margin-left: -6px;
    margin-right: -6px;
    border-bottom: 1px solid $color-brand-opaque-10;
    cursor: pointer;
    transition: background $transition-default;

    &:hover {
      background-color: $color-brand-opaque-8;
    }

    @include break-max($breakpoint-xs) {
      align-items: flex-start;
    }

    @include break-max($break-xs) {
      margin: 0;
      padding: 20px 0;
    }
  }

  &__col {
    flex: 1 1 auto;
    padding: 0 6px;

    &_icon {
      flex-basis: 28px;
      flex-grow: 0;
    }

    &_name {
      flex-grow: 0;
      flex-basis: 320px;
    }

    &_info {
      flex-basis: 160px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-right: 40px;

      @include  break-max($breakpoint-sm) {
        flex-direction: column;
      }
    }

    &_controls {
      flex-grow: 0;
      display: flex;
    }
  }

  &__arrow {
    display: none;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 12px;
    height: 12px;
    align-items: center;
    justify-content: center;

    .icon {
      width: 12px;
      height: 4px;
      stroke: $color-brand-2;
      transform: rotate(-90deg);
    }
  }

  &__icon {

    .icon {
      width: 16px;
      height: 16px;
    }
  }
}

.folder {
  &__name {
    font-weight: bold;
  }

  &__files-amount {
    color: $color-brand-3;
  }
}

.files__item {
  cursor: default;

  &:hover {
    background-color: transparent;
  }

  &-size {
    color: $color-brand-3;
    margin-right: 25px;
  }

  &-control {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: background $transition-default;

    &:hover {
      background-color: $color-brand-opaque-10;
    }

    .icon {
      width: 22px;
      height: 20px;
    }
  }

  &-download {
    margin-left: 20px;
  }

  &-link-container {
    position: absolute;
    left: 9999px;
    opacity: 0;
  }
}

.wrapper.for-files {
  @include break-max($break-xs) {
    margin: 0 10px;
  }
}

.files__list {
  @include break-max($break-xs) {
    .document__row.files__item {
      margin: 0;
      padding: 20px 0;
    }
  }
}

.document__mobile-wrapper {
  flex-grow: 1;
  .document__col.document__col_info {
    display: flex;
    flex-direction: row;
  }
}

.document__col {
  @include break-max($break-xs) {
    padding: 0;
  }
}

.document__col_info {
  @include break-max($break-xs) {
    flex-basis: auto;
    justify-content: flex-start;
    margin-right: 0;
    margin-top: 5px;
  }
}

.document__col_controls {
  @include break-max($break-xs) {
    flex-direction: row-reverse;

    .files__item-download {
      margin-left: 0;
      margin-right: 14px;

      .icon {
        width: 16px;
      }
    }

    .files__item-control {
      background: $color-brand-opaque-10;
    }

    .clip__tooltip .popover__inner {
      width: 200px;
    }
  }
}

.document__col_icon {
  @include break-max($break-xs) {
    flex-shrink: 0;
  }
}
