@import '../../styles/variables';
@import '../../styles/mixins';

.body-map {
  .back-top {
    display: none;
  }

  header.header {
    display: none;
  }

  footer.footer {
    display: none;
  }

  aside.panel {
    display: none;
  }
  .notification {
    display: none;
  }
}

// для старых страниц
.sf_inner-public + .section {
  @include break-max($breakpoint-xs) {
    margin-top: 60px;
  }
}

.sf-mobile {
  display: flex;
  flex-direction: column;

  .sf__filter-btns {
    margin-top: auto;
  }

  .button__mobile-filters {
    font-weight: bold;
    color: $color-brand-1;
  }

  .mobile-filters__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .sf__filter-block {
    padding: 0 10px;
    flex-grow: 1;
    padding-bottom: 25px;
  }

  .sf__filter-btn {
    background-color: var(--colorButton, $color-red);
    color: var(--colorButtonText, $color-white);
    &:hover {
      background-color: var(--colorHover, $color-red);
    }
    &:disabled {
      opacity: 0.8;
    }
  }
  .sf__clear-filter-btn {
    &:hover {
      color: var(--colorHover, $color-red);
    }
  }
}

.sf {

  .swiper-pan .swiper-container:not(.swiper-thumbs) {
    border-radius: 0;
  }

  .flats__row {
   border-radius: 20px;
  }

  .table2 .flats__row.is-active {
    margin-bottom: 15px;
  }

  .css-1ry5b51 {
    display: none;
  }
  .index-search__show-booked {
    margin-bottom: 0;
  }

  .table2:first-of-type {
    padding-top: 0;
  }
  .complex__title-text {
    margin-left: 35px;
  }
  .toggler:not(.opened)::before {
    background-color: var(--colorHeaderText, $color-brand-1);
    box-shadow: 0 7px 0 0 var(--colorHeaderText, $color-brand-1),
      0 14px 0 0 var(--colorHeaderText, $color-brand-1);
  }

  .button_view_ghost.is-active {
    background-color: var(--colorButton, $color-red);
  }

  .map-circle-text {
    color: var(--colorButtonText, $color-white);
  }

  #myMap,
  #dist-map-container {
    & [class*='-hint'] {
      background-color: var(--colorButton, $color-red);
      color: var(--colorButtonText, $color-white);
      &::after {
        width: 0;
        height: 0;
        border-top: 13px solid var(--colorButton, $color-red);
        border-left: 13px solid transparent;
        background-image: none;
      }
    }
  }

  .simplebar-scrollbar:before {
    background-color: var(--colorButton, $color-red);
  }

  .sf__header {
    background-color: var(--colorHeader, $gray-light);
    color: var(--colorHeaderText, $color-brand-1);
  }

  .sf__header-item a {
    color: var(--colorHeaderText, $color-brand-1);
    &:hover {
      color: var(--colorHover, $color-red);
    }
  }

  .mobile-slide-menu {
    color: $color-brand-1;

    a {
      color: $color-brand-1;
      &:hover {
        color: $color-red;
      }
    }
  }

  .sf__filter-btns svg path {
    fill: var(--colorButton, $color-red);
  }

  .sf__filter-btn {
    background-color: var(--colorButton, $color-red);
    color: var(--colorButtonText, $color-white);
    &:hover {
      background-color: var(--colorHover, $color-red);
    }
    &:disabled {
      opacity: 0.8;
    }
  }

  .sf__clear-filter-btn {
    &:hover {
      color: var(--colorHover, $color-red);
    }
  }

  &.wrapper {
    @include break-max($breakpoint-md) {
      padding: 0 20px;
    }

    @include break-max($breakpoint-xs) {
      padding: 0 10px;
    }
  }

  .ymaps-2-1-79-map-copyrights-promo,
  .ymaps-2-1-79-copyright__wrap {
    display: none !important;
  }

  .ymaps-2-1-79-user-selection-none {
    box-shadow: none;
  }

  .ymaps_maps-button {
    box-shadow: none;
  }

  &--apt-modal {
    .sf__elems {
      display: flex;
      gap: 0;

      .sf__elem-btn {
        width: auto;
        margin-right: 25px;

        @include break-max($breakpoint-xsm) {
          margin-right: 15px;
        }

        &:last-child {
          margin-right: 0;

          @include break-max($breakpoint-xsm) {
            margin-left: -15px;
          }
        }
      }
    }
  }
}

.sf .sf-slider--with-border .swiper-container:not(.swiper-thumbs) {
  border-radius: 20px;
}

.sf-card {
  color: inherit;
}

.sf__h1 {
  margin-top: 40px;

  @include break-max($breakpoint-md) {
    margin-top: 30px;
  }

  @include break-max($breakpoint-sm) {
    margin-top: 28px;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 80px;
  }
}

.sf__h1 + .sf__row {
  @include break-max($breakpoint-xs) {
    margin-top: 15px;
  }
}

.sf__header {
  margin-top: 30px;
  display: flex;
  align-items: center;
  background-color: $gray-light;
  color: $color-brand-1;
  border-radius: 20px;
  padding: 20px;
  padding-right: 60px;

  @include break-max($breakpoint-xs) {
    margin-top: 0;
    padding: 10px 20px;
    height: 50px;
    position: fixed;
    z-index: 2;
    top: 14px;
    left: 10px;
    right: 10px;
  }
}

.sf__header-manager {
  margin-left: 20px;
  margin-right: auto;
  @include break-max($breakpoint-xsm) {
    max-width: 230px;
  }
  @include break-max($breakpoint-xs) {
    margin-left: 12px;
    max-width: 100%;
  }
}

.sf__header-item {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;

  a {
    color: $color-brand-1;
    transition: all 0.3s;
  }
}

.sf__header-info {
  @include break-max($breakpoint-xs) {
    margin-left: 35px;
    margin-right: 35px;
    padding-bottom: 15px;
    border-bottom: 1px solid $color-brand-opaque-20;
  }

  & + & {
    margin-left: 100px;
    @include break-max($breakpoint-xsm) {
      margin-left: 70px;
      a {
        max-width: 160px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    @include break-max($breakpoint-xs) {
      margin-left: 35px;
      margin-top: 17px;
      border-bottom: none;
    }
  }
}

.sf__logo {
  display: flex;
  height: 40px;
  flex-shrink: 0;

  img {
    height: auto;
    max-height: 100%;
  }

  @include break-max($breakpoint-sm) {
    height: 28px;
  }
}

.sf__tags {
  margin-top: 0;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}

.sf__tags + .sf__filter-btn,
.sf__filter + .sf__filter-btn {
  @include break-max($breakpoint-sm) {
    margin-top: 15px;
  }
}

.sf__tag {
  flex-shrink: 0;
  font-size: 13px;
  padding: 5px 10px;
  background-color: $gray-light;
  border-radius: 20px;
  color: $color-brand-3;
  margin-right: 9px;

  &:last-of-type {
    margin-right: 0;
  }

  span {
    color: $color-brand-2;
  }

  &_clear {
    background-color: $color-white;
  }
}
.sf__tag-filter {
  margin-right: 0;
}

.sf__tag-exclude {
  margin-right: 0;
  background-color: #FFE6E5;
}
.sf__tag-close {
  margin-left: 10px;
  cursor: pointer;
  background-color: transparent;
}

.sf__row {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include break-max($breakpoint-xs) {
    .sf__btn {
      background-color: $gray-light;
      color: $color-brand-2;

      &:hover {
        background-color: $gray-light-3;
      }
    }
  }
}

.sf__row.sf__row_adaptive {
  @include break-max($breakpoint-sm) {
    margin-top: 20px;
  }
}

.sf__header + .sf__row {
  @include break-max($breakpoint-xs) {
    margin-top: 85px;
  }
}

.sf__sort {
  display: flex;
  align-items: center;
  font-size: 14px;
  svg {
    margin-right: 10px;
  }
}

.sf__cards-list {
  margin-top: 20px;
  margin-bottom: 50px;

  display: grid;
  grid-template-columns: repeat(3, 470px);
  justify-content: space-between;
  row-gap: 40px;
  gap: 40px;

  @include break-max($breakpoint-md) {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 36px;
    gap: 20px;
  }

  @include break-max($breakpoint-xsm) {
    grid-template-columns: repeat(2, 2fr);
    gap: 20px;
  }

  @include break-max($breakpoint-sm) {
    row-gap: 20px;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 20px;
    grid-template-columns: 100%;
    row-gap: 20px;
  }
}

.sf__cards-list_dubai-complex-list {
  margin-top: 15px;

  @include break-max($breakpoint-sm) {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  @include break-max($breakpoint-xsm) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include break-max($breakpoint-xs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.sf__cards-list_flats {
  grid-template-columns: repeat(4, 345px);
  @include break-max($breakpoint-md) {
    grid-template-columns: repeat(4, 295px);
    justify-content: space-between;
  }
  @include break-max($breakpoint-sm) {
    grid-template-columns: repeat(3, 232px);
    column-gap: 15px;
    justify-content: center;
  }

  @include break-max($breakpoint-xs) {
    grid-template-columns: 100%;
  }
}

.sf__cards-list_flats-dubai {
  grid-template-columns: repeat(4, 345px);
  display: grid;
  margin-top: 10px;
  margin-bottom: 10px;
  @include break-max($breakpoint-md) {
    grid-template-columns: repeat(4, 295px);
    justify-content: space-between;
  }
  @include break-max($breakpoint-sm) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: 20px;
  }

  @include break-max($breakpoint-xsm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include break-max($breakpoint-xs) {
    grid-template-columns: 100%;
  }
}
.sf__filter_dubai ~ .sf__tags {
  @include break-max($breakpoint-sm) {
    margin-top: -30px;
    margin-bottom: 0;
  }
}


.sf__filter {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 30px;
  margin-bottom: 25px;
  gap: 20px;
  @include break-max($breakpoint-md) {
    margin-top: 30px;
    gap: 12px;
  }

  @include break-max($breakpoint-sm) {
    margin-top: 0;
    grid-template-columns: repeat(3, 232px);
    gap: 18px 16px;
    justify-content: center;
  }
}

.sf__filter.sf__filter_dubai {
  margin-top: 0;
  grid-template-columns: repeat(5, 1fr);

  @include break-max($breakpoint-sm) {
    grid-template-columns: repeat(3, 1fr);
    margin-top: -30px;

    .sf__filter-item {
      width: 100%;
    }
  }
}

.filter__max.filter__max_second {
  @include break-max($breakpoint-md) {
    grid-template-columns: repeat(6, 1fr);
  }
  @include break-max($breakpoint-sm) {
    grid-template-columns: repeat(5, 1fr);
    .sf__filter-item {
      width: auto;
    }
  }
}

.filter__max {
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 20px;
  @include break-max($breakpoint-sm) {
    grid-template-columns: repeat(3, 232px);
    margin-top: 30px;
    .form__item_2x{
      flex-grow: 1;
      background-color: #fff;
    }
  }
  .white-button {
    color: #2C2E3E;
    background-color: #fff;
    font-weight: 700;

    &:hover {
      background-color: $gray-light-3;
      box-shadow: none;
    }
  }
  &.flex {
    display: flex;
  }
  &.flex ~ div .sf__filter.filter__max.form {
    //margin-bottom: 0;
    //margin-top: 0;
    //display: none;
  }
}

.filter__max.filter__max_six {
  grid-template-columns: repeat(6, 1fr);
}
.filter__max.filter__max_seven {
  grid-template-columns: repeat(7, 1fr);
}

.filter__max_six-toggles {
  display: grid;
  gap: 20px;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  grid-column: 1 / -1;
  @media screen and (max-width: 1599px) {
    gap: 12px;
  }
}

.filter__max.filter__max_five {
  grid-template-columns: repeat(5, 1fr);
}

.filter__max.filter__max_multi {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}


.filter__max.filter__max_multi > div:nth-child(1) {
  grid-column: 1;
  grid-row: 1 / span 2;
}



.tabs + .sf__filter {
  margin-top: 0;
}

.sf__filter-item {
  flex-grow: 1;
  flex-basis: auto;

  background-color: $gray-light;
  margin-right: 0;
  border-radius: 5px;
  height: 50px;
  max-height: 50px;
  margin-bottom: 0;
  margin-left: 0;

  @include break-max($breakpoint-sm) {
    width: 232px;
    margin-right: 0;
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &.form__item_2x {
    grid-column: span 2;
    @include break-max($breakpoint-sm) {
      width: auto;
    }
  }
}

.sf__filter-item_full {
  grid-column: -1 / 1;
}

.sf__filter_on-map {
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 0;
  gap: 0;
  height: 100%;
  padding-bottom: 15px;

  .sf__filter-item {
    width: 100%;
    margin-bottom: 16px;
    margin-right: 0;
    margin-left: 0;
    flex-basis: auto;

    @include break-max($breakpoint-md) {
      width: 100%;
    }

    @include break-max($breakpoint-sm) {
      width: 100%;
    }
  }
}

.sf__filter-btn {
  margin-left: 20px;
  min-width: 220px;

  @include break-max($breakpoint-sm) {
    display: block;
    width: 232px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
}

.sf__filter-btn.sf__filter-btn_dubai {
  @include break-max($breakpoint-sm) {
    margin-left: 16px;
    width: 170px;
    min-width: 170px;
  }
}

.sf__filter-item .form__drop-btn {
  background-color: $gray-light;
  &:hover {
    background-color: $gray-light-3;
    box-shadow: none;
  }
}

.sf__toggler {
  @include break-max($breakpoint-xs) {
    margin-top: 23px;
  }
}

.sf__side-menu.mobile-only {
  top: -14px;
}

.sf__side-menu.mobile-only.opened {
  @include break-max($breakpoint-xs) {
    right: -10px;
  }
}

.sf__map-block {
  position: absolute;
  left: 30px;
  top: 20%;
  bottom: 0;
  width: 325px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px 0 0;
  background-color: $color-white;
  border-radius: 20px 20px 0 0;
  transition: transform 0.3s;

  &.hidden {
    transform: translateX(-110%);
  }

  @include break-max($breakpoint-md) {
    top: 10px;
  }

  @include break-max($breakpoint-sm) {
    width: 320px;
    top: 10%;
  }
  @include break-max($breakpoint-xs) {
    width: 90%;
    left: 0;
    &.hidden {
      transform: translateX(-100%);
    }
  }
}

.sf__map-block_dubai {
  top: 30px;
  bottom: 30px;
  border-radius: 20px;
  box-shadow: 0px 2px 10px rgba(44, 46, 62, 0.2);
}

.sf__map-list {
  width: 100%;
  height: calc(100% - 70px);

  .status-circle__tooltip {
    left: auto;
    right: 20px;
    &::before {
      left: auto;
      right: -8px;
      border-left: none;
      border-right: 10px solid transparent;
    }
  }
}

.sf__map-scroll {
  height: 100%;
  padding: 0 20px;

  .sf-card {
    margin-bottom: 24px;
  }

  &_filter {
    // @include break-max($breakpoint-md) {
    //   height: calc(100% - 178px);
    // }

    @include break-max($breakpoint-xs) {
      height: 600px;
    }
  }
}

.sf__map-btn {
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.sf__filter-block {
  width: 100%;
  padding: 0 20px;
}

.sf__bar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: $gray-light;
  margin-bottom: 60px;
  border-radius: 20px;

  @include break-max($breakpoint-md) {
    margin-bottom: 40px;
  }

  @include break-max($breakpoint-sm) {
    margin-bottom: 20px;
  }
}

.sf__bar-closer {
  position: absolute;
  right: 28px;

  svg {
    stroke: $color-brand-2;
    transition: all 0.3s;
  }

  &:hover svg {
    stroke: $color-brand-3;
  }
}

.sf_map {
  position: relative;
  height: 100vh;
  padding-top: 30px;

  .form__drop-content {
    min-width: auto;
    padding: 1px 1px 15px;
    max-height: 280px;
    // max-height: 200px;

    .filter__col {
      margin-right: 10px;
    }

    .checkbox-group {
      padding-right: 0;
    }
  }

  .form__drop.is-showing .form__drop-btn {
    box-shadow: none;
  }

  .dropdown.is-showing .dropdown__block {
    position: static;
    margin-top: 10px;
  }

  .form__drop .dropdown__block {
    box-shadow: none;
  }

  // .simplebar-mask {
  //   overflow: auto;
  // }

  .simplebar-content-wrapper {
    overflow: auto !important;
    @include break-max($breakpoint-md) {
      overflow: hidden scroll !important;
    }
  }
}

.sf__map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // z-index: -1;
}

.sf__metro-list {
  margin-top: auto;
}

.sf__metro-station {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.sf__metro-circle {
  display: inline-flex;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-top: 1.5px;
  margin-right: 10px;
  flex-shrink: 0;
}


.sf__metro-time {
  display: flex;

  svg {
    margin-top: 1px;
    margin-right: 5px;
  }
}

.sf__metro {
  display: flex;
  align-items: center;
  color: $color-brand-3;
  gap: 5px;
  flex-wrap: wrap;
  & + & {
    margin-top: 5px;
  }
}

.sf__commission {
  display: inline-flex;
  align-items: center;
  padding: 2px 10px;
  border-radius: 20px;
  color: $gray-dark-2;
  background-color: $gray-light;
  margin-left: 10px;

  svg {
    margin-right: 2px;
  }

  @include break-max($breakpoint-xs) {
    margin-left: 0;
  }
}

.sf__detail-header {
  margin-top: 28px;
  margin-bottom: 35px;

  @include break-max($breakpoint-sm) {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  @include break-max($breakpoint-xs) {
    position: relative;
    //margin-top: 80px;
    margin-top: 25px;
    text-align: center;

    .sf__btn {
      position: absolute;
      top: 0;
      right: 25px;
    }
  }
}

.sf__detail-title {
  @include break-max($breakpoint-xs) {
    padding: 0 50px;
    padding: 0;
    margin-bottom: 15px;
  }
}

.sf__detail-head {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  @include break-max($breakpoint-xs) {
    margin-bottom: 10px;
    flex-direction: column;
    align-items: start;
  }
}

.sf__detail-descr {
  margin-right: 28px;
  color: $color-brand-3;
}

.sf__detail-descr_center {
  margin-left: 28px;
  color: $color-brand-2;
}

.sf__detail-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  // .sf__btn {
  //   display: none;
  // } ?
}

.sf__btn_no-bg {
  color: $color-red;
  background-color: $color-white;
  font-weight: 400;
  padding: 0;
  &:hover {
    background-color: $color-white;
  }
  svg {
    margin-right: 5px;
    fill: currentColor;
  }
}

.sf__detail-metro {
  display: grid;
  grid-template-columns: repeat(6, max-content);
  gap: 15px 30px;

  @include break-max($breakpoint-sm) {
    grid-template-columns: repeat(2, max-content);
  }

  @include break-max($breakpoint-xs) {
    grid-template-columns: max-content;
    gap: 10px;
  }
}

.sf-slider {

  &--with-border {

    .swiper-container:not(.swiper-thumbs) {
      border: 1px solid $gray-dark;

      .sf-slide {

        img {
          object-fit: contain;
          padding: 30px;

        }
      }
    }

    .swiper-wrapper {
      align-items: center;
    }

    .apt-modal__map ~ .swiper-container:not(.swiper-thumbs) {
      border: none;
    }

    .apt-modal__map ~ .swiper-thumbs-block .swiper-slide img {
      border: none;
     }

  }

  img {
    max-height: 490px;
    height: 490px;
  }

  .swiper-container:not(.swiper-thumbs) .sf-slide_main img {
    border-radius: 20px;
  }

  .swiper-thumbs-block img {
    height: 100%;
    max-height: 100%;
  }
}

.sf-slider-btn {
  display: flex;
  align-items: center;
  background: $gray-light;
  padding: 13px 20px;
  border-radius: 5px;
  transition: background-color 0.15s ease-in-out;
  &:hover,
  &:focus {
    background: $gray-dark;
  }
  svg {
    margin-right: 8px;
  }
}

.sf__detail-row {
  position: relative;
  display: grid;
  grid-template-columns: 970px 470px;
  justify-content: space-between;
  align-items: start;
  column-gap: 30px;

  & + & {
    margin-top: 25px;
    @include break-max($breakpoint-sm) {
      margin-top: 0;
    }

  }

  @include break-max($breakpoint-md) {
    grid-template-columns: 820px 1fr;
  }

  @include break-max($breakpoint-sm) {
    grid-template-columns: 100%;
    gap: 30px;
  }

  &:last-child {
    margin-bottom: 30px;
  }

  &_full {
    grid-template-columns: 100%;
    @include break-max($breakpoint-md) {
      grid-template-columns: 100%;
    }

    @include break-max($breakpoint-sm) {
      grid-template-columns: 100%;
    }

    .tab-list {
      display: inline-flex;
    }
  }

  &--common-modal {
    display: grid;
    grid-template-columns: 820px 1fr;
    grid-column-gap: 20px;
    column-gap: 20px;

    &:last-child {
      @include break-max($breakpoint-xsm) {
        margin-bottom: 0;
        gap: 30px;
      }
    }

    @include break-max($breakpoint-sm) {
      grid-template-columns: 820px 1fr;
    }

    @include break-max($layout-breakpoint-1200) {
      grid-template-columns: 750px 1fr;
    }

    @include break-max($layout-breakpoint-1150) {
      grid-template-columns: 700px 1fr;
    }

    @include break-max($layout-breakpoint-1100) {
      grid-template-columns: 650px 1fr;
    }

    @include break-max($layout-breakpoint-1050) {
      grid-template-columns: 600px 1fr;
    }

    @include break-max($breakpoint-xsm) {
      grid-template-columns: 100%;
    }

    @include break-max($breakpoint-xs) {
      grid-template-columns: 100%;
    }

    .sf__info-header {

      @include break-max($breakpoint-md) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

.sf__detail-row--common-page {
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-template-columns: 980px 470px;

  @include break-max($breakpoint-md) {
    grid-template-columns: 820px 1fr;
  }

  @include break-max($breakpoint-sm) {
    grid-template-columns: 820px 1fr;
  }

  @include break-max($layout-breakpoint-1200) {
    grid-template-columns: 750px 1fr;
  }

  @include break-max($layout-breakpoint-1150) {
    grid-template-columns: 700px 1fr;
  }

  @include break-max($layout-breakpoint-1100) {
    grid-template-columns: 650px 1fr;
  }
  @include break-max($breakpoint-xsm) {
    grid-template-columns: 100%;
  }
}

.sf__block {
  padding: 30px;
  border-radius: 20px;
  background-color: $gray-light;
  position: relative;
}

.sf__info {
  margin-bottom: 30px;
  @include break-max($breakpoint-xs) {
    background: none;
    padding: 0;
  }
}

.sf__block-overhint {
  display: inline-block;
  margin-left: 5px;
  position: relative;
}

.sf__block-hint {
  box-shadow: 0px 2px 20px rgba(143, 149, 172, 0.1);
  position: absolute;
  background-color: $color-white;
  border-radius: 20px;
  padding: 30px;
  left: 0;
  right: 0;
  z-index: 1234;
  opacity: 0;
  pointer-events: none;
  transition-duration: .3s;
}
.sf__block-hint_active {
  opacity: 1;
}

.sf__info-header {
  display: grid;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 26px;
  padding-bottom: 26px;
  border-bottom: 1px solid $color-brand-opaque-20;
  grid-template-columns: 1fr 1fr;

  .sf__header-col:nth-child(2) {
    justify-self: end;
    text-align: right;
  }
  .sf__header-col:nth-child(3) {
    justify-self: start;
    text-align: left;
  }
  .sf__header-col:nth-child(4) {
    justify-self: end;
    text-align: right;
  }

  @include break-max($breakpoint-md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
  }

  @include break-max($breakpoint-xs) {
    grid-template-columns: 1fr;
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
  }
}

.sf__info-header.sf__info-header_with-hint .sf__header-col:last-child svg {
  display: none;
}

.sf__info-header.sf__info-header_mobile {
  grid-template-columns: 1fr 1fr;
  margin-top: 16px;
}

.sf__header-col {
  @include break-max($breakpoint-xs) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-brand-opaque-20;
  }
}

.sf__header-col_mobile {
  display: block;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.sf__header-col:last-child {
  text-align: right;
  @include break-max($breakpoint-xs) {
    text-align: left;
  }
}

.sf__header-col:last-child:first-child {
  text-align: left;
  @include break-max($breakpoint-xs) {
    text-align: left;
  }
}

.sf__price {
  margin-bottom: 4px;
  @include break-max($breakpoint-xs) {
    font-size: 14px;
  }
}

.sf__descr {
  color: $color-brand-3;
  @include break-max($breakpoint-xs) {
    order: -1;
    font-size: 14px;
  }
}

.sf__info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }

  @include break-max($breakpoint-xs) {
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-brand-opaque-20;
  }
}

.sf__info-key {
  color: $color-brand-3;
}

.sf__info-value {
  max-width: 270px;
  text-align: right;
  font-weight: bold;

  @include break-max($breakpoint-md) {
    max-width: 220px;
  }

  .sf__info-description {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition-duration: .3s;

    svg {
      margin-top: -17px;
      position: absolute;
      margin-left: -27px;
    }
    span {
      border-radius: 10px;
      padding: 20px;
      background-color: $color-white;
      position: absolute;
      box-shadow: 0px 2px 20px rgba(143, 149, 172, 0.1);
      right: 36px;
      top: -34px;
      width: max-content;
      opacity: 0;
      pointer-events: none;
      font-weight: normal;
    }

    &:hover {
      span {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

}

.sf__manager {
  background-color: $color-white;
  border: 1px solid $color-brand-opaque-20;
  @include break-max($breakpoint-xs) {
    margin-bottom: 30px;
  }
}

.sf__manager-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  @include break-max($breakpoint-md) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.sf__manager-pic {
  width: 88px;
  height: 88px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: $gray-light;
  border-radius: 50%;
  margin-right: 20px;

  img {
    width: 52px;
    height: 28px;
  }

  @include break-max($breakpoint-md) {
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.sf__manager-name {
  margin-bottom: 4px;
}

.sf__manager-contacts {
  display: flex;
  justify-content: space-between;

  @include break-max($breakpoint-md) {
    flex-direction: column;
  }
}

.sf__manager-col {
  @include break-max($breakpoint-md) {
    &:first-of-type {
      margin-bottom: 10px;
    }
  }
}

.sf__manager-key {
  color: $color-brand-3;
  margin-bottom: 5px;
}

.sf__manager_modal {
  display: flex;

  & + & {
    margin-top: 20px;
  }

  .sf__manager-header {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .sf__manager-col {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 0;
  }

  .sf__manager-pos {
    color: $gray-dark-2;
  }

  .sf__manager-pic {
    width: 72px;
    height: 72px;
    margin-bottom: 0;
  }

  .sf__manager-pic img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
  }

  .sf__manager-social {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $gray-light;
    margin-left: 20px;
  }
}

.sf__promo {
  cursor: pointer;
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.sf__promo-icon {
  width: 44px;
  height: 44px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 50%;
  margin-right: 23px;

  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.sf__promo-timing {
  margin-top: 4px;
  display: flex;
  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }
  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.sf__mortgage-timing {
  @include break-max($breakpoint-xs) {
    display: block;
    margin-bottom: 20px;
  }
}

.sf__promo-key {
  color: $color-brand-3;
  margin-right: 8px;
  @include break-max($breakpoint-sm) {
    margin-right: 0;
    margin-bottom: 2px;
  }
}

.sf__promo-link {
  margin-left: auto;
  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.sf__detail-filter-block {
  margin-bottom: 40px;
}

.sf__detail-h3 {
  margin-bottom: 26px;
}

.sf__detail-table-block {
  margin-bottom: 40px;

  // удалить
  height: 225px;
  background-color: $color-brand-3;
}

.sf__h3 {
  margin-bottom: 20px;
}

.sf__about {
  padding-right: 30px;
  margin-bottom: 40px;

  @include break-max($breakpoint-sm) {
    padding-right: 0;
  }
}

.sf__about-text {
  margin-bottom: 20px;
  p {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.sf__advantages {
  display: grid;
  grid-template-columns: repeat(3, 252px);
  gap: 20px 40px;

  @include break-max($breakpoint-md) {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px 20px;
  }

  @include break-max($breakpoint-xs) {
    grid-template-columns: 1fr;
  }
}

.sf__advantage {
  display: flex;
  &::before {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 20px;
    margin-top: 6px;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: $color-red;
  }
}

.sf__mortgage {
  margin-bottom: 0;
  align-items: flex-start;
  @include break-max($breakpoint-sm) {
    margin-bottom: 40px;
  }
}

.sf__mortgage-body {
  @include break-max($breakpoint-sm) {
    display: flex;
    width: 100%;
    align-items: center;
  }
  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.sf__mortgage-content {
  @include break-max($breakpoint-sm) {
    width: 290px;
    flex-shrink: 0;
  }
}

.sf__mortgage-btn {
  margin-top: 20px;

  @include break-max($breakpoint-sm) {
    margin-top: 0;
    margin-left: auto;
  }
}

.sf__mortgage-text {
  color: $color-brand-3;
  margin-top: 10px;
}

.sf__promo-list {
  display: grid;
  grid-template-columns: repeat(2, 470px);
  justify-content: space-between;
  row-gap: 30px;

  @include break-max($breakpoint-md) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 22px;
  }

  @include break-max($breakpoint-xs) {
    display: flex;
    overflow-x: auto;
  }
}

.sf__promo_card {
  margin-top: 0;
  color: inherit;
  align-items: flex-start;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: $gray-light-3;
  }

  .sf__promo-icon {
    width: auto;
    height: auto;
    border: none;
    margin-right: 20px;
  }

  @include break-max($breakpoint-xs) {
    min-width: 310px;
  }
}

.sf__section {
  margin-bottom: 40px;
  @include break-max($breakpoint-sm) {
    margin-bottom: 25px;
  }
}

.sf__section_single-tab .tab-list {
  display: none;
}

.sf__sticky {
  position: sticky;
  top: 70px;

  @include break-max($breakpoint-sm) {
    display: none;
  }
}

.sf__video-list {
  display: block;
}

.sf__video-card {
  cursor: pointer;
}

.sf__video-image {
  position: relative;
}

.sf__video-image img {
  border-radius: 20px;
  height: 270px;
  width: 100%;
  object-fit: cover;
}

.sf__timing {
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: inline-flex;
  min-width: 54px;
  line-height: 24px;
  justify-content: center;
  background-color: $gray-light;
  border-radius: 20px;
  color: $gray-dark-2;
}

.sf__video-title {
  margin-top: 20px;
}

.sf__video-type {
  margin-top: 20px;
  display: inline-flex;
  padding: 5px 10px;
  background-color: $blue-20;
  color: $blue;
  border-radius: 20px;

  &_webinar {
    background-color: $green-20;
    color: $green;
  }
}

.sf__modal-btn {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px;
  background-color: $gray-light;
  margin-bottom: 20px;
  svg:first-child {
    margin-right: 14px;
  }

  svg:last-child {
    margin-left: auto;
  }
}

.sf-mobile-modal {
  .mobile-filters__content {
    padding-top: 0;
  }
}

.sf__filter-btn {
  margin-top: auto;
  margin-bottom: 16px;
}

.sf__clear-filter-btn {
  display: block;
  text-align: center;
  color: $color-brand-3;
}

.sf__tags .sf__clear-filter-btn {
  text-align: left;
  font-size: 13px;
}

.sf_map {
  .sf__filter-block {
    padding: 0;
    height: 100%;
  }
}

.sf__map-top {
  position: absolute;
  right: 30px;

  @include break-max($breakpoint-xs) {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

.sf__map-toggler {
  position: absolute;
  top: 25px;
  right: 0;
  transform: translate(100%, 0);
  background-color: $color-white;
  transition: all 0.3s;
  border-radius: 0 4px 4px 0;
  display: flex;
  padding: 5px;
  svg {
    transform: rotate(0);
  }
  &:hover {
    background-color: $gray-light-3;
  }
  &.active svg {
    transform: rotate(-180deg);
  }
}

.sf__filter-btns {
  display: flex;
  align-items: center;

  @include break-max($breakpoint-xs) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    justify-content: center;
  }

  .btn {
    padding: 14px 30px;
    height: 50px;
    &:only-child {
      grid-column: -1 / 1;
    }
  }

  .sf__filter-btn {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.filter_item_temp {
  margin: 0;
}
.cardlist_temp {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
}

.sf__work-company {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  padding-right: 15px;
  height: 1.2em;
  white-space: nowrap;
}

.sf-iframe {
  width: 100%;
  max-width: 100%;
  height: 490px;
  border-radius: 20px;

  @include break-max($breakpoint-sm) {
    height: 365px;
  }
}

.sf__header-flex {
  display: flex;
  justify-content: space-between;
}

.sf__elems {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  gap: 30px;
  margin-top: 15px;
  margin-bottom: 35px;
}

.sf__elem-btn {
  display: flex;
  align-items: center;
  width: 200px;

  color: $color-brand-2;
  font-size: inherit;

  svg {
    margin-right: 5px;
  }
}

.sf__contacts-header {
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
}

.sf__schedule {
  margin-top: 10px;
  display: flex;
  padding-left: 33px;
  color: $gray-dark-2;
}

.sf__sch-row:last-child {
  text-align: right;
  flex-grow: 1;
}

.sf__sch-key {
  margin-bottom: 2px;
}

.sf__manager-block {
  margin-top: 30px;

  .h5-l {
    margin-bottom: 20px;
  }
}

.sf__manager-cb {
  margin-top: 30px;
}

.sf__audio {
  margin-bottom: 15px;
}

.sf__audio-header {
  width: 370px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  .h6 {
    margin: 0;
  }
}

.sf__audio-link {
  display: flex;
  align-items: center;
  svg {
    margin-left: 10px;
  }
}

.sf__audio-body audio {
  width: 520px;
  max-width: 100%;
}

.sf__video-header {
  margin-top: 0;
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;

  .sf__video-type {
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 25px;
  }
}

.sf__video-btns {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.sf__video-btn {
  &:first-child {
    margin-right: 20px;
  }
}

.sf__actions-flex {
  display: flex;
  justify-content: space-between;
  width: 330px;
  max-width: 100%;
  margin-bottom: 30px;

  .sf__video-item + .sf__video-item {
    margin-top: 0;
    padding-left: 15px;
  }

  .sf__video-item {
    max-width: 200px;
  }
}

.sf__actions-title {
  margin-top: 10px;
  margin-bottom: 20px;
}

.sf__video-key {
  color: $gray-dark-2;
  margin-bottom: 3px;
}

.sf__video-item + .sf__video-item {
  margin-top: 15px;
}

.sf__video-descr {
  margin-top: 15px;

  ul, ol {
    margin-left: 15px;
  }
}

.sf__contacts-modal {
  .new-alert {
    margin-top: 25px;
  }
}

.sf__files-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-dark;
  padding: 13px 0;

  &:first-of-type {
    padding-top: 0;
  }

  a {
    display: inline-flex;
    gap: 10px;
    span {
      padding-top: 6px;
    }
  }
}

.sf__files-actions {
  margin-left: auto;
  display: flex;
  align-items: baseline;
  gap: 20px;

  svg {
    stroke: $color-brand-2;
    transition: all 0.3s;
    &:hover {
      stroke: $color-red;
    }
  }
}

.sf__notfound {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  svg {
    margin-right: 20px;
    min-width: 50px;
  }

  .h6-l {
    margin-bottom: 5px;
  }
}


.panmodal iframe + .sf__video-content {
  margin-top: 30px;
}

.sf-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.sf__italic-text {
  color: $gray-dark-2;
  font-size: 13px;
  font-style: italic;
  margin-bottom: 20px;
  font-weight: 300;
}

.smartfilter-wrapper {
  padding: 16px 20px;
  background-color: #F6F7F8;
  border-radius: 20px;
  .form__item {
    margin: 0;
    border-radius: 5px;
  }
  .form__item_2x {
    grid-column: 1/4;
  }
}

.smartfilter-wrapper.smartfilter-wrapper_dubai.filter__max {

  .form__item_2x {
    grid-column: 1/3;
  }

}

.smartfilter-wrapper.smartfilter-wrapper_new-suburban {
  background-color: #e3f0f7;
}

.smartfilter-wrapper.smartfilter-wrapper_new-suburban .sf__filter-item .form__drop-btn {
  background-color: white;
}

.show_second_filter .sf__filter-item .form__drop-btn {
  background-color: white;
}


.villages-save-filter {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition-duration: .3s;

  span {
    margin-left: 4px;
    color: #777A89;
  }

  &:hover {

    span {
      color: $color-red-bottom-border;
    }

    svg path {
      stroke: $color-red-bottom-border;
    }

    svg line {
      stroke: $color-red-bottom-border;
    }
  }
}



.sf__card-units {
  display: flex;
  align-items: center;
  color: $gray-light-2;
  font-size: 13px;
  margin-bottom: 15px;
  svg {
    margin-right: 10px;
    margin-top: 1px;
  }
  svg.red circle {
    stroke: $color-red-bottom-border;
  }
  svg.red line {
    stroke: $color-red-bottom-border;
  }
  svg.yellow circle {
    stroke: #FFA100;
  }
  svg.yellow line {
    stroke: #FFA100;
  }
  svg.green circle {
    stroke: #2CBA2E;
  }
  svg.green line {
    stroke: #2CBA2E;
  }

}

.dubai-complex-filter {
  @include break-max($breakpoint-xs) {
    height: 100%;

    .sf__filter-block {
      height: 100%;

      .sf__filter_on-map {
        padding-bottom: 0;

        .sf__filter-btns {
          .sf__filter-btn {
            min-width: 100%;
            height: 50px;
          }
        }
      }

      .sf__filter-item {
        flex-grow: 0;
      }
    }
  }
}

.index-search__show-booked_fst {
  position: absolute;
  margin-top: 0;
}

.index-search__show-booked_second {
  position: absolute;
  margin-top: 0;
  margin-left: 115px;
}
.form__row.form__row_tapfilters {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 30px;

  @include break-max($breakpoint-sm) {
    bottom: -40px;
  }

}
.sf {
  .primary-map-filter {
    .form__row {
      margin-bottom: 20px;
      flex-direction: column;
      align-items: flex-start;
    }

    .index-search__show-booked {
      position: relative;
      margin-left: 0;
      margin-top: 10px;
    }

    .index-search__show-booked.index-search__show-booked_fst {
      margin-left: 5px;
      margin-top: 0px;
    }

    .index-search__show-booked.index-search__show-booked_second {
      margin-left: 5px;
      margin-top: 15px;
    }
  }
}


.smartfilter-wrapper_primary {

  @include break-max($breakpoint-sm) {
    grid-template-columns: repeat(5, 1fr);
    position: relative;

    .form__item_2x {
      grid-column: 1/3;
    }
    .sf__filter-item {
      width: 100%;
    }
  }

  @include break-max($breakpoint-xsm) {
    .form__item_2x {
      grid-column: 1/4;
    }
  }


}
.smartfilter-wrapper_primary-bottom {
  @include break-max($breakpoint-sm) {
    .form__row {
      grid-column: 1;
      margin-top: 10px;
      margin-bottom: 15px;
    }

    .checkbox__label {
      white-space: nowrap;
    }
  }
}

.quick-icon {
  position: absolute;
  top: 20px;
  right: 20px;
}

.sf-card:nth-child(3n+1) {
  .quick-card {
    margin-left: 0;

    @include break-max($breakpoint-xsm) {

    }
  }
}
.sf-card:nth-child(3n+2) {
  @include break-max($breakpoint-sm) {
    .quick-card {
      margin-left: 0;
      left: -100px;

      @include break-max($breakpoint-xsm) {
        margin-left: 0;
        left: auto;
      }
    }
  }
}
.sf-card:nth-child(3n) {
  .quick-card {
    margin-left: 0;
    right: 0;

    @include break-max($breakpoint-xsm) {
      right: auto;
    }
  }
}

.quick-card {
  cursor: default;
  position: absolute;
  background-color: white;
  padding: 30px 25px;
  border-radius: 20px;
  border: 1px solid #DFE1E9;
  box-sizing: border-box;
  width: 850px;
  margin-top: 55px;
  margin-left: -190px;
  z-index: 2;
  display: flex;
  gap: 20px;

  .sf__card {
    width: 100%;
  }

  @include break-max($breakpoint-md) {
    width: 740px;
    margin-left: -170px;
  }

  @include break-max($breakpoint-sm) {
    width: 650px;
    padding: 20px;
  }

  @include break-max($breakpoint-xsm) {
    width: 100%;
    flex-direction: column;
  }

  &__left {
    width: 295px;

    @include break-max($breakpoint-sm) {
      width: 240px;
    }

    @include break-max($breakpoint-xsm) {
      width: 100%;
    }

    .swiper-pan img:not(.pswp__img) {
      height: 222px;
      max-height: none;

      @include break-max($breakpoint-sm) {
        height: 170px;
      }
    }

    .swiper-button-prev {
      display: none;
    }

    .swiper-button-next {
      display: none;
    }

    .btn.swiper-zoom {
     display: none;
    }

    .swiper-thumbs-block {
       .swiper-wrapper {
         .swiper-slide {
           width: 91px;
           height: 64px;

           @include break-max($breakpoint-sm) {
             width: 70px;
             height: 50px;
           }
           img {
             height: 64px;
             border-radius: 10px;

             @include break-max($breakpoint-sm) {
               height: 50px;
             }
           }
         }

         .swiper-slide.swiper-slide-thumb-active img {
           border: none;

         }
       }
    }

  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1;
    @include break-max($breakpoint-sm) {
      top: 15px;
      right: 15px;
    }

    @include break-max($breakpoint-xsm) {
      display: none;
    }

    path {
      transition-duration: .3s;
    }

    &:hover {
      path {
        fill: #f3f3f5;
      }
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 3px;
  }

  &__address {
    font-weight: 500;
    margin-bottom: 3px;
  }

  &__builder {
    font-weight: 500;
    color: #8F95AC;
  }

  &__info-list {
    display: flex;
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 30px;

    @include break-max($breakpoint-sm) {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    li {

      p:first-child {
        font-weight: 500;
        padding-bottom: 6px;
        border-bottom: 1px solid;
      }
      p:last-child {
        padding-top: 5px;
      }
    }
  }

  &__second-info {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include break-max($breakpoint-sm) {
      gap: 5px;
    }

    li {
      display: flex;
      gap: 20px;

      p:first-child {
        color: #777A89;
        min-width: 180px;
      }
    }
  }

  &__btn {
    width: 225px;
    margin-top: 20px;
  }
}
.quick-card.quick-card_skeleton {
  padding: 0;
  border: none;
}

.filter-title {
  display: flex;
  font-weight: 700;
  font-size: 16px;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
  svg {
    margin-right: 15px;
  }
}



@include break-max($breakpoint-md) {

}

@include break-max($breakpoint-xsm) {

}

.disabled-filter-row {
  pointer-events: none;
  opacity: 0.25;
}