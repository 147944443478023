.slider {
  border-radius: 5px;
  border: 1px solid rgba(#979797, 0.3);
  padding: 20px 0;
  position: relative;

  &__nav {
    position: absolute;
    right: 220px;
    top: -30px;
    background: #fff;
    z-index: 10;
    padding: 0 10px;

    @include break-max($breakpoint-sm) {
      right: 90px;
      top: -22.5px;
    }

    &-wrap {
      @include unlist();
      display: flex;
      height: 60px;
      width: 100px;
      border-radius: 30px;
      border: 1px solid $color-brand-opaque-30;
      overflow: hidden;
      margin: 0;
      @include break-max($breakpoint-sm) {
        height: 45px;
      }
    }

    &-item {
      width: 50%;
      display: flex;
      outline: none;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      transition: background $transition-default;

      &:hover {
        background-color: $color-brand-opaque-20;
      }
      &:active {
        background-color: $color-brand-opaque-30;
      }

      & .icon {
        width: 6px;
        height: 11px;
      }

      &.prev .icon {
        margin-right: -10px;
      }

      &.next .icon {
        margin-left: -10px;
      }
    }
  }

  &__item {
    border-right: 1px solid $color-brand-opaque-30;
    @include break-max(600px) {
      border-right: none;
    }
  }

  &__image {
    height: 35px;
    margin-bottom: 30px;

    & img {
      display: block;
      max-width: 100%;
      max-height: 35px;
    }
  }

  &__conditions {
    @include grid();
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 18px;
  }

  &__condition {
    @include grid-col();
    @include grid-size(8);

    @include break-max($breakpoint-md) {
      @include grid-size(7, $grid-gutter-md);
    }

    @include break-max($breakpoint-sm) {
      @include grid-size(7, $grid-gutter-sm);
    }
  }

  &__value {
    @include grid-col();
    @include grid-size(4);
    font-weight: bold;
    color: $color-red;

    @include break-max($breakpoint-md) {
      @include grid-size(5, $grid-gutter-md);
    }

    @include break-max($breakpoint-sm) {
      @include grid-size(5, $grid-gutter-sm);
    }
  }

  &__more {
    color: $color-brand-3;
    margin-top: 20px;
    & .icon_more {
      margin-left: 6px;
      fill: none;
      stroke: $color-brand-3;
      width: 10px;
      height: 10px;
    }
  }

  &__link {
    @include link();
    color: $color-brand-3;
  }
}
.assignations-top__slider.slider .slider__item.tns-slide-active.slider__item-one {
  @include break-max($break-xs) {
    margin-right: 0;

    .flats__card.flats__card_compact {
      width: calc(100% - 10px)
    }
  }
}
