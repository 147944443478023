.radio-group {
  $c: &;

  &__list {
    &_dir {
      &_horizontal {
        display: flex;
        align-items: flex-start;

        #{$c}__item {
          flex: 0 1 auto;
          margin-right: 30px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      &_vertical {
        display: block;

        #{$c}__item {
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    &_dist {
      &_between {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &_type {
    &_form {
      #{$c}__label {
        color: #8f95ac;
        line-height: 25px;
        margin-bottom: 10px;
        white-space: nowrap;
      }
    }
  }

  &__label {
    display: inline-block;
  }
}
