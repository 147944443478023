
.forced-visible {
  opacity: 1!important;
  transform: translateX(0)!important;
  pointer-events: initial!important;
}
.info__popup:hover {
  opacity: 1!important;
  transform: translateX(0)!important;
  pointer-events: initial!important;
}
.mb-50{
  margin-bottom: 50px;
  min-height: 300px;
}
.mb-25{
  margin-bottom: 25px;
  min-height: 300px;
}
.decrease-child-margin .presentation__info-col {
    margin-bottom: 10px;
}
.small-margin-block img{
  margin-top: 30px
}