@import '../../../styles/variables';
@import '../../../styles/mixins';

.common-info {
  background-color: $gray-light;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 20px;
  margin-bottom: 30px;
 }



.color-status {
  padding: 4px 11px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: -4px;
  margin-top: -4px;

  &--completed {
    background-color: $deal-completed-bg-status;
    color: $green;
  }

  &--inwork {
    background-color: $deal-inwork-bg-status;
    color: $orange;
  }

  &--booking {
    background-color: $deal-booked-bg-status;
    color: $deal-booked-color-status;
  }

  &--waiting {
    background-color: $gray-light-3;
    color: $gray-dark-2;
  }
}

.common-info-list-item {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  align-items: center;

  &:first-child {
    margin-top: 0px;
  }

  &__value {
    color: inherit;
    @include h6();
  }

  &__label {
    color: $gray-dark-2;
  }
}
