@import "../../styles/variables";
@import "../../styles/mixins";

.footer {
  position: relative;
  background-color: $color-brand-2;
  color: $color-white;
  padding: 0;
  z-index: 0;

}


  .footer__inner {
    display: flex;
    justify-content: space-between;
    min-height: 80px;

    @include break-max($breakpoint-sm) {
      flex-wrap: wrap;
    }
    @include break-max($breakpoint-xs) {
      display: grid;
      grid-template-columns: min-content 1fr;
      padding: 0 10px;
    }
  }

  .footer__block {
    display: flex;
    align-items: center;
  }

  .footer__company {
    display: flex;
    @include break-max($breakpoint-xs) {
      order: 1;
      color: $color-brand-3;
      padding: 15px 0;
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }

  .footer__copy {
    display: flex;
    max-width: 200px;
    align-items: center;
    margin-left: 18px;
    padding-right: 15px;
    @include break-max($breakpoint-md) {
      //max-width: 200px;
    }
    @include break-max($breakpoint-xs) {
      max-width: 100%;
    }
  }


  .footer__social {
    height: 80px;
    display: flex;
    align-items: center;
    // border-left: 1px solid $color-brand-opaque-40;
    // border-right: 1px solid $color-brand-opaque-40;
    // padding-right: 64px;
    // padding-left: 64px;
    padding-right: 25px;
    padding-left: 25px;
    @include break-max($breakpoint-xs) {
      width: 100%;
      height: 65px;
      order: -1;
    }
  }

  .footer__social-list {
    @include unlist();
    display: flex;
    align-items: center;
  }

  .footer__social-item {
    margin-right: 32px;
    @include break-max($breakpoint-xs) {
      margin-right: 25px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .footer__social-link {

    .icon {
      fill: $color-brand-3;
      transition: fill $transition-default;
    }

    &:hover .icon {
      fill: $color-red;
    }
  }

  .footer__contacts {

  }

  .footer__logo {
    display: block;
    width: 72px;
    height: 39px;
    svg {
      fill: $color-white;
    }

  }

  .footer__anchors {
    display: flex;

    @include break-max($breakpoint-sm) {
      width: 100%;
      justify-content: center;
      padding: 5px 0;
      border-top: 1px solid $color-brand-opaque-40;
    }

    @include break-max($breakpoint-xs) {
      padding: 15px 0;
      flex-direction: column;
      border-bottom: 1px solid $color-brand-opaque-40;
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }

  .footer__anchor {
    display: flex;
    align-items: center;
    color: $color-brand-3;
    text-decoration: none;
    font-size: 13px;
    transition: color $transition-default;

    &:hover {
      color: $color-white;
    }

    @include break-max($breakpoint-xs) {
      font-size: 15px;
      margin-bottom: 10px;
    }

    &:not(:first-child) {
      padding-left: 20px;
      position: relative;
      &::before {
        content: '';
        width: 1px;
        height: 35px;
        background: $color-brand-opaque-40;
        margin-right: 20px;
      }

      @include break-max($breakpoint-xs) {
        padding-left: 0;
        position: static;
        &::before {
          display: none;
        }
      }
    }

  }

  .footer__app {
    // border-left: 1px solid $color-brand-opaque-40;
    // // border-right: 1px solid $color-brand-opaque-40;
    // padding-left: 45px;
    // // padding-right: 64px;
  }

  .footer__app-list {
    display: flex;

  }

  .footer__app-item {
   

    & + & {
      margin-left: 18px;
    }
  }
