.theme-switcher__input {
  background-color: transparent !important;
}

.theme-switcher__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.theme-switcher__icon svg {
  width: 100%;
  height: 100%;
}