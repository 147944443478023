@import "../../../styles/variables";
@import "../../../styles/mixins";

.status-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  .status-circle__tooltip {
    display: none;
    position: absolute;
    z-index: 101;

    top: 2px;
    left: 20px;

    padding: 6px 15px 8px;
    border-radius: 4px;

    line-height: 1;
    color: $color-white;

    white-space: nowrap;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -8px;

      width: 0; 
      height: 0;
      
      border-top: 10px solid;
      border-left: 10px solid transparent;
    }
    
  }

  &_positive, &_green {
    background: $green;
    .status-circle__tooltip {
      background: $green;
      &::before {
        border-top-color: $green;
      }
    }
  }

  &_negative, &_red {
    background: $color-red;
    .status-circle__tooltip {
      background: $color-red;
      &::before {
        border-top-color: $color-red;
      }
      
    }
  }

  &_yellow {
    background-color: $yellow;
    .status-circle__tooltip {
      background: $yellow;
      &::before {
        border-top-color: $yellow;
      }
      
    }
  }

  &_gray {
    background-color: $color-brand-3;
    .status-circle__tooltip {
      background-color: $color-brand-3;
      &::before {
        background-color: $color-brand-3;
      }
      
    }
  }

  &:hover .status-circle__tooltip {
    display: flex;

  }
}