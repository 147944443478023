@import '../../../styles/variables';
@import '../../../styles/mixins';

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  font-family: 'Formular', sans-serif;
  white-space: nowrap;
  appearance: none;

  padding: 16px 30px;
  max-height: 50px;
  border-radius: 5px;
  transition: all $transition-default;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: $color-brand-3;
    pointer-events: none;
  }

  .icon,
  svg {
    margin-right: 10px;
  }

  @include break-max($breakpoint-xs) {
    padding: 14px 30px;
  }
}

.btn_primary {
  background-color: $color-red;
  color: #fff;

  &:hover,
  &:active {
    background-color: $color-red-hover;
  }

  &:disabled {
    color: $color-white;
    background-color: $color-brand-opaque-30;
  }
}

.btn_primary_gray {
  background-color: $gray-light-2;
  color: #fff;

  &:hover,
  &:active {
    background-color: $gray-dark-2;
  }

  &:disabled {
    color: $color-white;
    background-color: $color-brand-opaque-30;
  }
}

.btn_line {
  background-color: $color-white;
  color: $color-brand-2;
  box-shadow: inset 0 0 0 1px $color-border;

  &:hover {
    box-shadow: none;
    background-color: $gray-light-3;
  }
}

.btn_secondary {
  background-color: $gray-light;
  color: $color-brand-2;

  &:hover {
    background-color: $gray-light-3;
  }

  &:disabled {
    color: $color-white;
    background-color: $color-brand-opaque-30;
  }
}

.btn_full {
  width: 100%;

  & + & {
    margin-top: 14px;
  }
}

.btn_small {
  padding: 14px 30px;
  @include break-max($breakpoint-xs) {
    padding: 9px 14px;
  }
}

.btn_clean {
  padding: 0;
  svg {
    margin: 0;
  }
}

.for-mobile-app-popup {
  min-width: 180px;

  &:disabled {
    background-color: $gray-light;
    color: $gray-dark;
  }
}
