@import "../../styles/variables";
@import "../../styles/mixins";

.autopan {
  @include break-max($breakpoint-xs) {
    margin-left: 10px;
    margin-right: 10px;
    overflow-x: hidden;
  }
}

.autopan-sec1 {
  min-height: 600px;
  border-radius: 60px;
  background-color: #FF2D23;
  background-image: url("../../assets/images/autopan/autopan-header.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 70px 90px;

  @include break-max($breakpoint-md) {
    border-radius: 40px;
    min-height: 540px;
  }

  @include break-max(1120px) {
    border-radius: 30px;
  }

  @include break-max($breakpoint-sm) {
    padding: 40px 60px;
  }

  @include break-max($breakpoint-xsm) {
    border-radius: 20px;
    height: 440px;
    min-height: 440px;
  }

  @include break-max($breakpoint-xs) {
    border-radius: 15px;
    background-position: right bottom;
    padding: 30px 35px;
    height: 330px;
    min-height: 330px;
    margin-top: 10px;
  }

  @include break-max(500px) {
    background-position: right -150px bottom;
    margin-top: 5px;
    margin-bottom: 0;
  }

  &__intensive {
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: white;

    @include break-max($breakpoint-xs) {
      font-size: 12px;
      text-align: center;
      margin-bottom: 30px;
    }
  }

  &__leveltext {
    font-size: 18px;
    margin-bottom: 80px;
    color: white;

    @include break-max($breakpoint-xs) {
      font-size: 12px;
      text-align: center;
      margin-bottom: 0;
    }

    &:before {
      content: '';
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: white;
      margin-right: 10px;
      display: inline-block;

      @include break-max($breakpoint-xs) {
        width: 10px;
        height: 10px;
      }
    }
  }

  &__title {
    color: white;
    font-size: 80px;
    font-weight: bold;
    margin-bottom: 30px;

    @include break-max($breakpoint-md) {
      font-size: 64px;
    }

    @include break-max($breakpoint-xs) {
      font-size: 36px;
      text-align: center;
      margin-bottom: 15px;
    }
  }

  &__secondtitle {
    font-size: 24px;
    font-weight: 500;
    color: white;

    @include break-max($breakpoint-md) {
      font-size: 20px;
    }

    @include break-max($breakpoint-xs) {
      font-size: 12px;
      text-align: center;
      margin-bottom: 70px;
      font-weight: bold;
    }

    br {
      @include break-max($breakpoint-xs) {
        display: none;
      }
    }
  }

  &__btns {
    margin-top: 60px;
    display: flex;
    gap: 30px;

    @include break-max($breakpoint-xsm) {
      margin-top: 20px;
    }

    button {
      min-width: 220px;
      height: 50px;
    }
  }
}

.autopan-sec2 {
  padding: 60px;
  border-radius: 60px;
  background-color: #FF2D23;
  background-image: url("../../assets/images/autopan/autopan-sec2bg.png");
  background-position: right;


  @include break-max($breakpoint-md) {
    border-radius: 40px;
  }
  @include break-max($breakpoint-sm) {
    border-radius: 30px;
  }
  @include break-max(1120px) {
    border-radius: 20px;
  }
  @include break-max($breakpoint-xsm) {
    border-radius: 15px;
  }

  @include break-max($breakpoint-xs) {
    padding: 30px;
    margin-top: 5px;
  }

  @include break-max(500px) {

  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @include break-max($breakpoint-xs) {
      gap: 10px;
    }

    li {
      flex-basis: calc(33.3333% - 20px);
      box-sizing: border-box;
      flex-grow: 1;
      padding: 30px;
      border-radius: 20px;
      background-color: #E3F0F7;

      @include break-max($breakpoint-sm) {
        flex-basis: calc(50% - 20px);

        br {
          display: none;
        }
      }

      @include break-max($breakpoint-xs) {
        flex-basis: 100%;
        padding: 25px;
      }

    }
  }

  &__list-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;

    @include break-max($breakpoint-sm) {
      font-size: 18px;
    }

    @include break-max($breakpoint-xsm) {
      font-size: 14px;
    }
  }

  &__list-text {
    font-size: 18px;

    @include break-max($breakpoint-sm) {
      font-size: 14px;
    }
  }

}

.autopan-sec3.panschool-sec3 {
  margin-bottom: 10px;

  @include break-max(950px) {
    margin-bottom: 5px;
  }

  .autopan-sec3__right-title {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: 40px;
    @include break-max(950px) {
      gap: 20px;
      margin-bottom: 20px;
    }
  }

  .autopan-sec3__right-name {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 5px;
    white-space: nowrap;

    @include break-max($breakpoint-sm) {
      font-size: 18px;
    }
  }

  .autopan-sec3__right-text {
    font-size: 18px;

    @include break-max($breakpoint-sm) {
      font-size: 14px;
    }
  }
  .autopan-sec3__right-text.autopan-sec3__right-text_post {
    font-size: 16px;
    white-space: nowrap;

    @include break-max($breakpoint-sm) {
      font-size: 14px;
    }

    @include break-max(950px) {
      white-space: normal;
    }
  }

  .panschool-sec3__lady {
    width: 166px;
    height: 166px;
    border-radius: 50%;
  }

  .panschool-sec3__right {
    @include break-max($breakpoint-xs) {
      padding: 30px;
    }
  }


  .panschool-sec3__left {
    background-image: url("../../assets/images/autopan/autopan-sec3-bg.png");
    background-size: cover;
  }

  .autopan-sec3__right-col1 {
    text-align: left;

    .autopan-sec3__right-name {
      @include break-max(950px) {
        white-space: normal;
      }
    }
  }

  .autopan-sec3__right-col2 {
    @include break-max(950px) {
      text-align: left;
    }
  }
}

.autopan-sec4 {
  padding: 70px 90px;
  border-radius: 60px;
  background-color: #E3F0F7;
  top: 10px;

  @include break-max($breakpoint-md) {
    border-radius: 40px;
  }
  @include break-max($breakpoint-sm) {
    border-radius: 30px;
  }
  @include break-max(1120px) {
    border-radius: 20px;
  }
  @include break-max($breakpoint-xsm) {
    border-radius: 15px;
    padding-left: 130px;
    padding-right: 130px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include break-max($breakpoint-xs) {
    padding: 30px;
  }

  &__title {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;

    @include break-max($breakpoint-sm) {
      font-size: 30px;
    }

    @include break-max($breakpoint-xs) {
      font-size: 22px;
      margin-bottom: 20px;
    }
  }

  &__list {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;

    @include break-max($breakpoint-xsm) {
      flex-wrap: wrap;
      gap: 20px;
    }

    @include break-max($breakpoint-xs) {
      gap: 10px;
      margin-bottom: 10px;
    }

      li {
      list-style-type: none;
      width: 33.3333%;
      background-color: #ED2324;
      border-radius: 20px;
      padding: 20px;
      position: relative;
      padding-top: 80px;

      @include break-max($breakpoint-xsm) {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: calc(50% - 10px);
      }

      @include break-max($breakpoint-xs) {
        flex-basis: 100%;
        padding-top: 20px;
      }

    }

    li.autopan-sec4__list-lastitem {
      background-color: white;
      display: flex;
      align-items: center;
      padding-top: 20px;

      @include break-max($breakpoint-xs) {
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        p {
          font-size: 14px;
        }
      }
    }
  }

  &__girls {
    position: absolute;
    bottom: 0;
  }

  &__list-logo {
    background-color: white;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;

    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  &__list-title {
    font-size: 24px;
    color: white;
    margin-bottom: 20px;
    font-weight: 500;

    @include break-max($breakpoint-sm) {
      font-size: 18px;
    }

    @include break-max($breakpoint-xs) {
      font-size: 14px;
      padding-left: 110px;
      margin-bottom: 10px;
    }
  }

  &__list-text {
    font-size: 18px;
    color: white;

    @include break-max($breakpoint-sm) {
      font-size: 14px;
    }

    @include break-max($breakpoint-xs) {
      font-size: 12px;
      padding-left: 110px;

      br {
        display: none;
      }
    }
  }

  .panschool-sec4__bottom-left {
    background-color: white;
    color: black;
    font-weight: 400;
    font-size: 18px;

    @include break-max($breakpoint-sm) {
      font-size: 16px;
    }
  }
}

.autopan-sec5 {
  padding: 70px 90px;
  border-radius: 60px;
  background-color: #E3F0F7;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  position: relative;

  @include break-max($breakpoint-md) {
    border-radius: 40px;
  }
  @include break-max($breakpoint-sm) {
    border-radius: 30px;
  }
  @include break-max(1120px) {
    border-radius: 20px;
  }
  @include break-max($breakpoint-xsm) {
    border-radius: 15px;
    padding-left: 130px;
    padding-right: 130px;
    padding-top: 40px;
    padding-bottom: 110px;
  }

  @include break-max($breakpoint-xs) {
    padding: 30px;
    flex-direction: column;
    padding-bottom: 90px;
    margin-top: 5px;
    margin-bottom: 0;
  }

  &__left {
    height: 370px;
    min-width: 590px;
    background-image: url("../../assets/images/autopan/autopan-sec5-img.jpg");
    background-size: cover;
    border-radius: 30px;
    display: flex;
    padding: 30px 40px;
    flex-direction: column;
    justify-content: flex-end;

    @include break-max($breakpoint-md) {
      min-width: 405px;
    }

    @include break-max($breakpoint-sm) {
      min-width: 320px;
      width: 320px;
    }
    @include break-max($breakpoint-xsm) {
      height: 280px;
    }
    @include break-max($breakpoint-xs) {
      order: 1;
      width: 100%;
      height: 210px;

    }
  }

  &__title {
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;

    @include break-max($breakpoint-sm) {
      font-size: 18px;
    }

    @include break-max($breakpoint-xs) {
      margin-bottom: 0;
    }
  }

  &__right {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-grow: 1;
  }

  &__parzl {
    border-radius: 30px;
    background-color: #EF372E;
    width: 50%;
    padding: 20px 40px;
    border: 1px solid #E3F0F7;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: white;
    text-align: center;
    position: relative;

    @include break-max($breakpoint-sm) {
      font-size: 14px;
    }

    @include break-max($breakpoint-xsm) {
      width: 100%;
      height: 140px;
      font-size: 12px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 30px;
      padding-left: 30px;
    }

  }

  &__parzl:nth-child(1):before {
    content: '';
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #EF372E;
    border-left: 1px solid #E3F0F7;
    position: absolute;
    left: -40px;
    z-index: 1;

    border-bottom: 2px solid #E3F0F7;
    border-right: 2px solid #E3F0F7;
    border-left: 2px solid #EF372E;
    border-top: 2px solid #EF372E;
    transform: rotate(135deg);

    @include break-max($breakpoint-sm) {
      width: 60px;
      height: 60px;
      left: -30px;
    }
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }
  &__parzl:nth-child(1):after {
    content: '';
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #EF372E;
    position: absolute;
    left: calc(50% - 40px);
    bottom: -40px;
    z-index: 1;

    border-bottom: 2px solid #E3F0F7;
    border-right: 2px solid #E3F0F7;
    border-left: 2px solid #EF372E;
    border-top: 2px solid #EF372E;
    transform: rotate(45deg);

    @include break-max($breakpoint-sm) {
      width: 60px;
      height: 60px;
      bottom: -30px;
      left: calc(50% - 30px);
    }
  }

  &__parzl:nth-child(2):before {
    content: '';
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #EF372E;
    border-left: 1px solid #E3F0F7;
    position: absolute;
    left: -40px;
    z-index: 1;

    border-bottom: 2px solid #E3F0F7;
    border-right: 2px solid #E3F0F7;
    border-left: 2px solid #EF372E;
    border-top: 2px solid #EF372E;
    transform: rotate(135deg);

    @include break-max($breakpoint-sm) {
      width: 60px;
      height: 60px;
      left: -30px;
    }
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }
  &__parzl:nth-child(2):after {
    content: '';
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #EF372E;
    position: absolute;
    left: calc(50% - 40px);
    bottom: -40px;
    z-index: 1;

    border-bottom: 2px solid #E3F0F7;
    border-right: 2px solid #E3F0F7;
    border-left: 2px solid #EF372E;
    border-top: 2px solid #EF372E;
    transform: rotate(45deg);

    @include break-max($breakpoint-sm) {
      width: 60px;
      height: 60px;
      bottom: -30px;
      left: calc(50% - 30px);
    }

  }

  &__parzl:nth-child(3):before {
    content: '';
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #EF372E;
    border-left: 1px solid #E3F0F7;
    position: absolute;
    left: -40px;
    z-index: 1;

    border-bottom: 2px solid #E3F0F7;
    border-right: 2px solid #E3F0F7;
    border-left: 2px solid #EF372E;
    border-top: 2px solid #EF372E;
    transform: rotate(135deg);

    @include break-max($breakpoint-xsm) {
      display: none;
    }
  }
  &__parzl:nth-child(3):after {
    content: '';
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #EF372E;
    position: absolute;
    top: calc(50% - 40px);
    right: -40px;
    z-index: 1;

    border-bottom: 2px solid #E3F0F7;
    border-right: 2px solid #E3F0F7;
    border-left: 2px solid #EF372E;
    border-top: 2px solid #EF372E;
    transform: rotate(315deg);

    @include break-max($breakpoint-xsm) {
      display: none;
    }

    @include break-max($breakpoint-xs) {
      display: block;
      width: 60px;
      height: 60px;
      top: auto;
      bottom: -30px;
      left: calc(50% - 30px);
      right: auto;
      transform: rotate(45deg);
    }
  }

  &__parzl:nth-child(4){
    @include break-max($breakpoint-xsm) {
      margin-top: -140px;
      margin-left: -315px;
      width: 320px;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
      margin-left: 0;
      margin-top: 0;
    }
  }

  &__parzl:nth-child(4):after {
    @include break-max($breakpoint-xsm) {
      content: '';
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #EF372E;
      position: absolute;
      top: calc(50% - 30px);
      right: -30px;
      z-index: 1;

      border-bottom: 2px solid #E3F0F7;
      border-right: 2px solid #E3F0F7;
      border-left: 2px solid #EF372E;
      border-top: 2px solid #EF372E;
      transform: rotate(315deg);
    }

    @include break-max($breakpoint-xs) {
      top: auto;
      transform: rotate(45deg);
      bottom: -30px;
      right: auto;
    }
  }

 &__mobile-btn {
   position: absolute;
   bottom: 40px;
   left: 0;
   right: 0;
   text-align: center;
   padding-left: 140px;
   padding-right: 140px;

   @include break-max($breakpoint-xs) {
     padding-left: 30px;
     padding-right: 30px;
     bottom: 30px;
   }

   button {
     width: 100%;
   }
 }

}
.autopan-sec6.panschool-sec6 {
  min-height: auto;
  @include break-max($breakpoint-xs) {
    min-height: 550px;
    margin-bottom: 5px;
  }
}
.autopan-sec7 {
  background-color: #EF372E;
  border-radius: 60px;
  color: white;
  padding: 60px 90px;
  background-image: url("../../assets/images/autopan/autopan-sec7-bg.png");
  margin-bottom: 10px;
  background-position: -170px 0;


  @include break-max($breakpoint-md) {
    border-radius: 40px;
  }
  @include break-max($breakpoint-sm) {
    border-radius: 30px;
  }
  @include break-max(1120px) {
    border-radius: 20px;
  }
  @include break-max($breakpoint-xsm) {
    border-radius: 15px;
    padding: 40px 30px;
  }

  @include break-max($breakpoint-xs) {
    padding: 30px;
  }

  .autopan-sec1__title {
    @include break-max($breakpoint-md) {
      line-height: 64px;
      font-size: 64px;
    }
    @include break-max($breakpoint-sm) {
      line-height: 40px;
      font-size: 40px;
    }

    @include break-max($breakpoint-xsm) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  .sor__prog-list_autopan .sor__prog-item:nth-child(odd) {
    background-color: transparent;
  }

  .sor__prog-list_autopan .sor__prog-header .h4 {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 26px;

    @include break-max($breakpoint-xsm) {
      font-size: 18px;
      line-height: 20px;
    }
  }

  .sor__prog-list_autopan .sor__prog-header {
    //padding-bottom: 0;
  }

  .sor__prog-list_autopan .sor__prog-item {
    border-bottom: 1px solid white;
    border-radius: 0;
  }

  .sor__prog-list_autopan .sor__prog-text {
    border-top: none;

    @include break-max($breakpoint-xsm) {
      font-size: 14px;
    }
  }

  .sor__prog-list_autopan .sor__accordion-btn {
    background-color: white;
  }

  .sor__accordion-btn .icon {
    stroke: #EF372E;
  }

  .sor__prog-list_autopan .sor__prog-item {
    padding-left: 0;
    padding-right: 0;
  }

  .sor__prog-list.sor__prog-list_autopan {
    max-width: 900px;
    margin-top: 50px;
    padding-bottom: 0;

    @include break-max($breakpoint-xsm) {
      margin-top: 0;
    }
  }
}













