@import "../../styles/variables";
@import "../../styles/mixins";

.recently-card {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;

  &__image {
    border: 1px solid $gray-dark;
    border-radius: 20px;
    height: 185px;
    object-fit: cover;
    margin-bottom: 18px;
  }

  &__price {
    @include h5-L();
  }
  &__type {
    color: $gray-dark-2;
    margin-top: 4px;
  }
  &__builder {
    color: $gray-dark-2;
    margin-top: 4px;
  }
}
