@import "../../styles/variables";
@import "../../styles/mixins";
.botbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  min-height: 120px;
  margin-top: 40px;
  border-radius: 5px;
  background: $color-brand-2;
  color: $color-white;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;

  @include break-max($breakpoint-xsm) {
    font-size: 14px;
    line-height: 18px;
  }

  @include break-max($break-xs) {
    flex-direction: column;
    padding: 15px 20px;
  }
}

.botbar__text {
  width: 287px;
  max-width: 100%;

  &_lg {
    width: 450px;
  }

  @include break-max($breakpoint-xsm) {
    padding-right: 25px;
  }
}

.botbar__img {
  align-self: flex-end;

  @include break-max($breakpoint-xsm) {
    display: none;
  }
}

.botbar__btn {
  padding: 22px 26px;

  @include break-max($break-xs) {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
