.contacts {
  &__title {
    margin-bottom: 30px;

    @include break-max($break-xs) {
      margin: 0 10px 20px;
    }
  }

  &__header {
    margin: 60px 0 20px;

    @include break-max($break-xs) {
      margin: 20px 0;
    }
  }

  .wrapper {
    overflow: hidden;
  }

  &__content {
    margin-bottom: 60px;
  }

  &__department {

    @include break-max($break-xs) {
      border-bottom: 1px solid $color-brand-opaque-20;
      margin: 0 10px 30px;

      &:last-child {
        border-bottom: none;
      }
    }

    &-title {
      margin-bottom: 50px;
    }

    &-contact {
      @include break-max($breakpoint-sm) {
        max-width: 200px;
        margin: 0 auto;
      }

      @include break-max($break-xs) {
        margin: 0;
        max-width: none;
      }
    }
  }

  &__grid {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-left: -65px;
    margin-right: -65px;

    @include break-max($breakpoint-md) {
      margin-left: -40px;
      margin-right: -40px;
    }

    @include break-max($breakpoint-sm) {
      margin-left: -30px;
      margin-right: -30px;
    }

    @include break-max($break-xs) {
      margin-left: 0px;
      margin-right: 0px;
    }

    &-col {
      max-width: 25%;
      flex-basis: 25%;
      padding: 0 65px;
      margin-bottom: 95px;

      @include break-max($breakpoint-md) {
        padding: 0 40px;
        margin-bottom: 80px;
      }

      @include break-max($breakpoint-sm) {
        max-width: 33.3333%;
        flex-basis: 33.3333%;
        padding: 0 30px;
        margin-bottom: 50px;
      }
    }
  }

}

.contact {

  @include break-max($break-xs) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  &__photo {
    margin-bottom: 25px;
    border-radius: 4px;
    overflow: hidden;

    @include break-max($break-xs) {
      height: 240px;
      width: 205px;
    }

    & img {
      width: 100%;
      height: 310px;

      @include break-max($breakpoint-md) {
        height: 265px;
      }

      @include break-max($breakpoint-sm) {
        height: 230px;
      }

      // @include break-max($break-xs) {
      //   height: 80px;
      //   width: 80px;
      // }
    }
  }

  &__post {
    margin: 8px 0 15px;
    color: $color-brand-3;
  }

  &__developers {
    display: inline-block;
    margin-bottom: 15px;
    color: $purple;

    .label__icon {
      margin-right: 10px;
    }

    &-item {
      margin: 9px 0;

      & a {
        color: $purple;
      }
    }

    &-block {
      max-height: 250px;
    }

    .popover__inner {
      width: 320px;
      background-color: transparent;

      @include break-max($breakpoint-sm) {
        width: 235px;
      }
    }

    .popover__block {
      border-radius: 4px;
      background-color: $color-white;
    }
  }

  &__contacts {
    margin-bottom: 15px;

    &-item {
      margin: 5px 0;
      & a {
        color: $color-brand-1;
      }
    }
  }

  &__socials {
    display: flex;

    @include break-max($break-xs) {
      justify-content: center;
    }

    &-item {
      margin-left: 15px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.contact__details {
  @include break-max($break-xs) {
    max-width: 205px;
  }
}

.attendats__row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  & + & {
    padding-top: 30px;
    border-top: 1px solid $color-brand-opaque-20;
  }

  @include break-max($breakpoint-sm) {
    justify-content: flex-start;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.attendats__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 575px;
  max-width: 100%;

  @include break-max($breakpoint-md) {
    width: 550px;
  }
  @include break-max($breakpoint-sm) {
    width: 50%;
    &:first-child {
      margin-right: 100px;
    }
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
    margin: 0 auto;
    &:first-child {
      padding-bottom: 30px;
    }
    & + & {
      padding-top: 30px;
      border-top: 1px solid $color-brand-opaque-20;
    }
  }
}

.attendats__block {
  display: flex;
  align-items: center;
  @include break-max($breakpoint-sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.attendats__label {
  display: inline-flex;
  padding: 6px 22px;
  margin-bottom: 20px;
  background: $color-red;
  border-radius: 100px;
  color: $color-white;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 0.3px;
  text-transform: uppercase;

  @include break-max($breakpoint-xs) {
    margin-left: auto;
    margin-right: auto;
  }

  @include break-max($break-xs) {
    margin-left: 0;
    margin-right: 0;
  }
}

.attendats__photo {
  width: 80px;
  height: 80px;
  margin-right: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.attendats__person {
  width: 180px;

  @include break-max($break-xs) {
    text-align: left;
  }
}

.attendats__content {
  display: flex;
  width: 100%;
  @include break-max($breakpoint-xs) {
    justify-content: center;
  }
}

.attendats__contacts {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-left: 15px;
  margin-right: 60px;
  flex-shrink: 0;

  a + a {
    margin-top: 5px;
  }

  @include break-max($breakpoint-md) {
    margin-left: 35px;
    margin-right: 35px;
  }
  @include break-max($breakpoint-sm) {
    margin: 0;
    margin-top: 5px;
    margin-bottom: 15px;
  }
}

.attendats__socials {
  margin-left: auto;
  @include break-max($breakpoint-sm) {
    margin-left: 0;
  }
}

.attendats__position {
  margin-bottom: 0;
}

.attendats__contacts-icon {
  width: 32px;
  height: 32px;
  .icon {
    width: 1.2em;
    height: 1.2em;
  }
}

.contact__developers-block.for-popup {
  .simplebar-track.simplebar-vertical {
    right: -30px;
  }
}
