.contacts-list {
  display: grid;
  grid-template-columns: 345px 345px 1fr;
  gap: 30px;

  margin-bottom: 60px;

  @include break-max($breakpoint-md) {
    grid-template-columns: 282px 282px 1fr;
    gap: 22px;

    margin-bottom: 50px;
  }

  @include break-max($breakpoint-sm) {
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    margin-bottom: 40px;
  }

  @include break-max($breakpoint-xs) {
    grid-template-columns: 1fr;
    gap: 12px;

    margin-bottom: 40px;
  }
}

.contact {
  & > * + * {
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  background: $color-brand-opaque-8;
  border-radius: 8px;
  padding: 40px;

  @include break-max($breakpoint-md) {
    padding: 35px 40px;
  }

  @include break-max($breakpoint-sm) {
    padding: 32px 62px;
    &:last-of-type {
      grid-column: 1 / span 2;
    }
  }

  @include break-max($breakpoint-xs) {
    padding: 18px 42px 22px;
    &:last-of-type {
      grid-column: unset;
    }
  }
}

.contact__icon {
  margin-bottom: 35px;

  @include break-max($breakpoint-sm) {
    margin-bottom: 25px;
  }

  @include break-max($breakpoint-xs) {
    margin-bottom: 18px;

    height: 20px;
  }
}

.contact:nth-child(2) .contact__icon {
  @include break-max($breakpoint-xs) {
    height: 16px;
  }
}

.contact__type {
  margin-bottom: 10px;
  color: $color-brand-3;
}


.contact__overlay {
  display: none;
  background-color: $color-white;
  padding: 4px 8px;
  position: absolute;
  z-index: 1;
}

.contact__anchor {
  position: relative;
  max-width: 100%;
  font-size: 20px;
  line-height: 24px;
  transition: all 0.3s;

  @include break-max($breakpoint-sm) {
    font-size: 18px;
    line-height: 22px;
  }

  &:hover .contact__overlay {
    display: block;
  }

}


.contact__anchor + .contact__anchor {
  margin-top: 4px;
}

.contacts-socials {
  margin-bottom: 75px;
  @include break-max($breakpoint-sm) {
    margin-bottom: 40px;
  }
}

.contacts-socials__header {
  display: flex;
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 3px solid;
  color: $color-brand-3;
  @include break-max($breakpoint-md) {
    font-size: 16px;
    line-height: 24px;
  }
}

.contacts-socials__icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;

  @include break-max($breakpoint-xs) {
    width: 35px;
    height: 35px;
  }

  svg {
    width: 23px;
    height: 23px;
    fill: $color-white;

    @include break-max($breakpoint-xs) {
      width: 16px;
      height: 16px;
    }
  }
}

.contacts-socials__list {
  display: grid;
  gap: 30px;
  grid-template-columns: 345px 345px 1fr;

  @include break-max($breakpoint-md) {
    grid-template-columns: 282px 282px 1fr;
    gap: 22px;
  }

  @include break-max($breakpoint-sm) {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  @include break-max($breakpoint-xs) {
    grid-template-columns: 1fr;
    gap: 12px;
  }
}

.contacts-socials__item {
  display: flex;
  align-items: center;
  transition: all 0.3s;
}

.contacts-map {
  width: 100%;
  height: 390px;
  margin-bottom: 90px;

  @include break-max($breakpoint-md) {
    margin-bottom: 75px;
  }

  @include break-max($breakpoint-sm) {
    margin-bottom: 50px;
  }

  @include break-max($breakpoint-xs) {
    height: 300px;
  }

  ////delete
  background: $color-brand-3;
}