@import "../../styles/variables";
@import "../../styles/mixins";

.fbooking__container {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 105px;

  @include break-max($breakpoint-xsm) {
    flex-direction: column;
  }

  @include break-max($breakpoint-xsm) {
    padding: 0 10px 30px;
  }
}

.fbooking__title {
  width: 705px;
  max-width: 100%;
  text-align: center;
  margin: 55px auto 50px;
  @include break-max($breakpoint-xsm) {
    margin: 30px auto 40px;
  }
  @include break-max($breakpoint-xs) {
    margin: 10px auto 15px;
    font-size: 18px;
    line-height: 22px;
    border-bottom: 1px solid $color-brand-opaque-20;
    padding-bottom: 10px;
  }
}

.fbooking__formbox {
  width: 590px;
  max-width: 100%;
  @include break-max($breakpoint-xsm) {
    width: 100%;
  }
}
.fbooking__object {
  //position: sticky;
  top: 100px;
  width: 348px;
  margin-left: 55px;
  max-width: 100%;
  padding: 30px;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 4px;

  @include break-max($breakpoint-xsm) {
    display: flex;
    order: -1;
    width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }

  @include break-max($breakpoint-xs) {
    flex-wrap: nowrap;
    flex-direction: column;
    padding: 10px 0;
    margin-top: 20px;
    margin-bottom: 0;
    order: 1;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
}

.fbooking__object-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-brand-opaque-20;
  padding-bottom: 30px;

  @include break-max($breakpoint-xsm) {
    width: 50%;
    justify-content: flex-start;
    border: none;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    padding: 5px 22px 0;
  }
}

.fbooking__object-img {
  width: 112px;
  @include break-max($breakpoint-xsm) {
    margin-right: 40px;
  }
  @include break-max($breakpoint-xs) {
    width: 78px;
    margin-right: 15px;
  }
}

.fbooking__info {
  padding-top: 22px;
  padding-bottom: 35px;
  border-bottom: 1px solid $color-brand-opaque-20;

  @include break-max($breakpoint-xsm) {
    width: 100%;
    order: 2;
    display: flex;
    flex-wrap: wrap;
    border-bottom: none;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 20px;
    border-top: 1px solid $color-brand-opaque-20;
    order: 0;
    padding-bottom: 15px;
  }
}

.fbooking__info_form {
  .fbooking__info-row {
    gap: 15px;
    @include break-max($breakpoint-xsm) {
      width: 100%;
      flex-wrap: wrap;
      gap: 15px;
    }
    @include break-max($breakpoint-xs) {
      padding: 0 15px;
      gap: 0;
    }
  }

  .fbooking__info-col {
    @include break-max($breakpoint-xs) {
      flex-grow: 0;
      margin: 0;
      min-width: 50%;
      padding: 0 10px;

      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 15px;
      }
    }
  }

}

.fbooking__info-grid {
  display: flex;
  flex-wrap: wrap;

  .fbooking__info-col {
    flex-grow: 0;
    flex-basis: 50%;
    width: 50%;
    padding: 0 10px;
    margin-top: 25px;
    &:nth-child(1), &:nth-child(2) {
      margin-top: 0;
    }
  }

  @include break-max($breakpoint-xsm) {
    flex-wrap: nowrap;
    margin-top: 10px;
    .fbooking__info-col {
      margin-top: 0;
      width: auto;
      flex-basis: auto;
      padding: 0;
    }
  }

  @include break-max($breakpoint-xs) {
    flex-wrap: wrap;
    margin-top: 0;
    padding: 0 15px;
    .fbooking__info-col {
      flex-basis: 50%;
      width: 50%;
      padding: 0 10px;
      margin-top: 25px;
      &:nth-child(1), &:nth-child(2) {
        margin-top: 0;
      }
    }
  }

}

.fbooking__info_border_none {
  border: none;
  @include break-max($breakpoint-xs) {
    border: 1px solid $color-brand-opaque-20;
    margin-bottom: 20px;
    margin-top: 0;
  }
}

.fbooking__info-row {
  display: flex;
  margin-bottom: 25px;
  &:last-of-type {
    margin-bottom: 0;
  }

  @include break-max($breakpoint-xsm) {
    width: calc(33% - 16px);
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.fbooking__info-col {
  flex: 1 1;
  @include break-max($breakpoint-xsm) {
    margin-left: 16px;
    margin-right: 16px;
  }

}

.fbooking__info-value {
  color: $color-brand-3;
  margin-bottom: 5px;
}

.fbooking__info-desc {
  font-size: 16px;
  font-weight: bold;
}

.fbooking__manager {
  padding-top: 25px;
  @include break-max($breakpoint-xsm) {
    width: 50%;
  }

  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
  }

  .manager {
    @include break-max($breakpoint-xs) {
      padding: 0 25px 10px;
    }
  }

  .manager-avatar_wrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .manager-title {
    margin-top: 0;
    font-size: 16px;
    line-height: 20px;
    @include break-max($breakpoint-xs) {
      font-size: 15px;
      line-height: 18px;
    }
  }

  .manager-contacts {
    margin-top: 10px;
  }

  .manager-contacts_item {
    margin-bottom: 5px;
  }


  @include break-max($breakpoint-xs) {
    width: 100%;

    .manager-contacts_list {
      flex-direction: column;
    }

    .manager-avatar {
      display: none;
    }

    .manager-content {
      display: flex;
      margin-top: 0;
    }

    .manager-contacts {
      margin-top: 0;
      width: 80%;
      padding-left: 25px;
    }
  }

}


.fbooking__progress {

}

.fbooking__progress-percents {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.fbooking__progress-percent {
  color: $color-brand-3;
}

.fbooking__progress-bar {
  position: relative;
  height: 8px;
  background: $color-brand-opaque-20;
  border-radius: 5px;
}

.fbooking__progress-filled {
  position: absolute;
  z-index: 2;
  width: 40%;
  height: 100%;
  background: $green;
  border-radius: 5px;
  transition: all 0.3s;
}

.fbooking__progress-prospective {
  position: absolute;
  z-index: 1;
  width: 70%;
  height: 100%;
  background: $green-20;
  border-radius: 5px;
  transition: all 0.3s;
}

.fbooking__tooltip {
  display: inline-flex;
  background: $green;
  color: $color-white;
  border-radius: 4px;
  padding: 10px;

}

.fbooking__progress-tooltip {
  position: absolute;
  top: 25px;
  right: 8%;
  width: max-content;
  transition: all 0.3s;

  &_negative-coord {
    transform: translateX(40%);
    @include break-max($breakpoint-xs) {
      transform: translateX(65%);
    }
  }
  svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -8px;
  }

}

.fbooking__form {
  margin-top: 90px;
}

.fbooking__form-row {
  display: flex;
  margin-bottom: 20px;
  @include break-max($breakpoint-xs) {
    flex-direction: column;

    .fbooking__form-col + .fbooking__form-col {
      margin-left: 0;
      margin-top: 20px;
    }
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  &_space-between {
    justify-content: space-between;

    .fbooking__form-col {
      display: flex;
    }
    .button {
      margin-left: auto;

      @include break-max($breakpoint-xs) {
        width: 100%;
      }
    }
  }

  .modal-form__gist {
    margin-top: 20px;
  }

  .custom-select-label {
    margin-bottom: 4px;
    display: block;
  }

  .custom-select .fbooking__tooltip {
    margin-left: 40px;
    padding: 7px 10px;
  }

  .form__item {
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }
}

.fbooking__form-col {
  flex: 1 1;

  max-width: 100%;

  & + & {
    margin-left: 20px;
  }

  .input {
    width: 100%;
  }

  .textarea__control {
    height: 100px;
  }

  .form__drop-name {
    display: flex;
    justify-content: flex-start;
    .fbooking__tooltip {
      margin-left: 15px;
      padding: 7px 10px;
    }
  }

  .add-client {
    margin-bottom: 15px;
  }
}

.fbooking__files {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed $color-brand-opaque-20;
  border-radius: 4px;
  width: 100%;
  height: 110px;

  color: $color-brand-3;
  text-align: center;

  cursor: pointer;

  svg {
    margin-bottom: 5px;
  }
}

.fbooking__progress {
  @include break-max($breakpoint-xs) {
    position: sticky;
    top: 0;
    background: $color-white;
    z-index: 22;
  }
}

.fbooking__showmore {
  display: flex;
  justify-content: center;
  border-top: 1px solid $color-brand-opaque-20;
}

.fbooking__showmore-btn {
  background: none;
  font-size: 15px;
  margin-left: auto;
  margin-right: auto;
  display: none;
  @include break-max($breakpoint-xs) {
    display: inline-block;
  }
  //
  // .icon {
  //   fill: none;
  // }
}

.fbooking .form__drop-content {
  @include break-max($breakpoint-xs) {
    width: 100%;
    max-width: 100%;
    .mobile-filters__title {
      display: none;
    }
  }
}

.fb-success-wrapper {
  @include break-max($breakpoint-xs) {
    padding-top: 70px;
  }
}

.fb-success {
  width: 820px;
  max-width: 100%;

  @include break-max($breakpoint-md) {
    width: 70%;
  }

  @include break-max($breakpoint-sm) {
    width: 80%;
  }

  svg {
    max-width: 100%;

    @include break-max($breakpoint-xs) {
      max-height: 150px;
    }
  }
}

.fb-success__title {
  margin-top: 70px;
  padding-left: 15px;
  padding-right: 15px;

  @include break-max($breakpoint-sm) {
    margin-top: 20px;
  }
}

.fb-success__btns {
  margin-top: 45px;
  width: 520px;
  display: flex;
  max-width: 100%;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.fb-success__btn {
  width: 254px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:last-child {
    margin-left: auto;
  }
  svg {
    margin-right: 12px;
    max-width: 100%;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    &:last-child {
      margin-left: 0;
      margin-top: 15px;
    }
  }
}
.booking-client-select.custom-select.is-showing {
  box-shadow: 0 2px 10px rgba(143, 149, 172, 0.2)!important;

  .custom-select__options {
    box-shadow: 0 2px 10px rgba(143, 149, 172, 0.2)!important;
  }
}
