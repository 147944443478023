.dropdown {
  $c: &;
  position: relative;
  z-index: 10;
  height: 100%;

  &.is-showing {
    z-index: 20;

    #{$c}__block {
      display: block;
    }

    .side-menu {
      // right: 0;
      transform: translateX(0);
    }
  }

  &__block {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: none;

    &_align {

      &_right {
        left: auto!important;
        right: 0!important;
        transform: translateX(0)!important;

        & .profile-drop:before {
          left: auto!important;
          right: 90px;
        }
      }

      &_top {
        top: -600%;
      }
    }
  }

  &__btn {
    transition: background-color $transition-default;
  }
}

.dropdown__block_payment {
  @include break-max($breakpoint-xs) {

    .form__drop-content {
      min-width: 285px;
      max-width: 285px;
    }
    .mobile-filters__title {
      display: none;
    }

    .checkbox {
      align-items: flex-start;
    }
  }
}