#myMap, #dist-map-container {
  width: 100%;

  & [class*="-ground-pane"] {
    // filter: brightness(1.06) grayscale(100%)
  }

  & [class*="-hint"] {
    border-radius: 5px;
    background-color: $color-red;
    height: 38px;
    display: flex;
    border: 0;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0 14px;
    box-shadow: none;

    &:after {
      content: '';
      width: 13px;
      height: 10px;
      position: absolute;
      top: 0;
      left: 5px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDBsMTMgMTBWMEgweiIgZmlsbD0iI0ZGMkQyMyIvPjwvc3ZnPg==);
      background-size: contain;
      background-repeat: no-repeat;

    }
  }

  & [class*="-hint__text"] {
    color: $color-white;
    font-size: 14px;
    font-weight: 700;
  }
}

#myMap {
  height: 900px;

  @include break-max($breakpoint-xs) {
    height: 100%;
  }
}

.js-show-list {
  display: none;
}

.map {
  position: relative;
  display: flex;
  width: 100%;

  @include break-max($break-xs) {
    flex-direction: column;
    flex-wrap: nowrap;
    position: absolute;
    top: 50px;
  }

  &__container {
    width: calc(100% - #{$fields} - 376px);
    margin-left: auto;

    @include break-max($breakpoint-md) {
      width: calc(100% - #{$fields-md} - 376px);
    }

    @include break-max($breakpoint-sm) {
      width: calc(100% - #{$fields-sm} - 376px);
    }

    @include break-max($breakpoint-xs) {
      order: 1;
      height: 100%;
      position: fixed;
      width: 100%;
      z-index: 10;
    }
  }

  &__list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    @include break-max($breakpoint-xs) {
      max-height: none;
      margin-top: 300px;
      position: relative;
      order: 2;
      width: 100%;
      z-index: 11;

      transform: translateY(80%);
      transition: transform 1s ease-in-out;

      &.is-visible {
        transform: translateY(0);
      }
    }
  }
}

.map__list.map__list_suburban + div.map__container {
  width: 100%;
}
