.lk-events {

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @include break-max($break-xs) {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 0;
      padding: 20px 10px 30px;

      .lk-events__all {
        margin-left: 0;
        margin-top: 10px;
        width: 100%;
      }
    }
  }

  &__show-calendar {
    color: $color-brand-3;
    margin-left: 60px;
    margin-top: 10px;
    cursor: pointer;

    & .icon {
      font-size: 8px;
      stroke: $color-brand-3;
      transform: rotate(-90deg);
    }

    &:hover {
      color: $color-red;

      & .icon {
        stroke: $color-red;
      }
    }
  }

  &__all {
    margin-left: auto;
  }

  &__content {
    padding-bottom: 50px;
  }

  &__name {
    width: 35%;
    min-width: 230px;
    font-weight: bold;
    transition: color $transition-default;

    &:hover {
      color: $color-red;

      & .icon {
        stroke: $color-red;
      }
    }
  }

  &__date {
    display: flex;
    align-items: center;
  }

  &__time {
    padding: 6px 12px;
    background-color: $color-brand-opaque-10;
    border-radius: 100px;
    margin-left: 30px;

    @include break-max($breakpoint-sm) {
      margin-left: 10px;
    }
  }

  &__status {
    display: flex;

    .label-item {
      white-space: nowrap;
    }

    & .icon_comment {
      fill: $color-brand-opaque-40;
    }

    &-info {
      display: inline-block;
      margin-left: 10px;
      background-color: transparent !important;
      width: auto;
      height: auto;
    }
  }

  &__eventCancel {

    &-label {
      color: $color-brand-3;
      margin-right: 30px;
    }

    &-name {
      font-weight: bold;
      margin-bottom: 15px;
    }

    &-reason {

      & textarea {
        height: 120px;
      }
    }
  }

  &__control {
    display: flex;
    justify-content: flex-end;
  }
}
