@import "../../../styles/variables";
@import "../../../styles/mixins";
// Accordeon

.accordeon {

  .accordeon__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .accordeon__btn {
    display: flex;
    font-size: 18px;
    stroke: $color-white;
    fill: transparent;
    transition: transform 0.3s;
    cursor: pointer;

    .icon_more {
      display: inline-block;
      width: 1em;
      height: 1em;
      vertical-align: middle;
      margin: 0;
    }

  }


  .accordeon__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease;

    p + p {
      margin-top: 15px;
    }
  }

  &.opened {

    .accordeon__btn {
      transform: rotate(180deg);
    }
    .accordeon__content {
      transition: max-height 0.6s ease-in-out;
      max-height: 1500px;
    }
  }
}
