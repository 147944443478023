&.aa {
  .aa__placing {
    margin-top: 20px;
  }

  .aa__flex {
    display: flex;
    align-items: center;
  }

  .aa__images {
    margin-top: 20px;
    img + img {
      margin-left: 15px;
    }
  }

  .aa__mortgage-checkbox {
    margin-top: auto;
    margin-bottom: 14px;
    .checkbox.checkbox_view_invert {
      @include break-max($breakpoint-xs) {
        justify-content: start;
        flex-direction: row;
      }
    }
  }

  .aa__info-popup {
    margin-left: 10px;
  }

  .admin__header {
    @include break-max($breakpoint-xs) {
      justify-content: start;
    }
  }

  .form__item  {
    @include break-max($breakpoint-xs) {
      width: 100%;
      flex-basis: 100%;
    }
  }

  .mobile-filters__title {
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  .mobile-filters__submit {
    @include break-max($breakpoint-xs) {
      width: 262px;
    }
  }

  .admin__showpromo-anchor {
    @include break-max($breakpoint-xs) {
      margin-top: 15px;
    }
  }

  .file-up__del {
    flex-shrink: 0;
  }
  
  .modal-form__item_145 {
    @include break-max($breakpoint-sm) {
      flex-basis: 100px;
      .custom-select {
        min-width: 100px;
        width: 100px;
      }
    }

    @include break-max($breakpoint-xs) {
      flex-basis: 100%;
      .custom-select {
        min-width: 100%;
        width: 100%;
      }
    }
  }

  .modal-form__item + .modal-form__item {
    @include break-max($breakpoint-sm) {
      margin-left: 15px;
    }
    @include break-max($breakpoint-xs) {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  .admin__h1 {
    @include break-max($breakpoint-xs) {
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
}

.aa__box {
  display: flex;
  position: relative;
  align-items: flex-start;
  width: 100%;
  margin-top: 35px;
  margin-bottom: 35px;
  @include break-max($breakpoint-xs) {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.aa__back {
  position: sticky;
  top: 100px;
  @include break-max($breakpoint-md) {
    font-size: 0;
    .icon {
      margin-right: 0;
    }
  }
  @include break-max($breakpoint-xs) {
    position: static;
    padding: 15px;
    height: 30px;

    .icon {
      font-size: 14px;
    }
  }
}

.aa__content {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 1220px;
  max-width: 100%;
  margin-left: 100px;
  padding: 50px;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 5px;

  @include break-max($breakpoint-md) {
    padding: 40px;
    margin-left: 30px;
  }

  @include break-max($breakpoint-sm) {
    padding: 25px;
    margin-left: 0;
  }

  
  @include break-max($breakpoint-xs) {
    border: none;
    flex-direction: column;
    padding: 20px 0;
  }

}

.aa__content-left {
  width: 670px;
  max-width: 100%;
  @include break-max($breakpoint-sm) {
    width: 460px;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-top: 10px;
    border: 1px solid $color-brand-opaque-20;
    order: 1;
  }
}

.aa__content-right {
  width: 300px;
  max-width: 100%;
  margin-left: auto;
  position: sticky;
  top: 100px;
  @include break-max($breakpoint-sm) {
    width: 180px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 10px;
    background: $color-brand-opaque-8;
  }
}

.aa__block {
  margin-bottom: 35px;
  &:last-of-type {
    margin-bottom: 0;
  }
  @include break-max($breakpoint-xs) {
    padding: 20px 15px;
    margin-bottom: 0;
  }
}

.aa__title {
  margin-bottom: 10px;
}

.aa__switcher {
  min-height: 50px;
  justify-content: flex-start;

    @include break-max($breakpoint-sm) {
      flex-wrap: wrap;
    }

    @include break-max($breakpoint-xs) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;

    }

  .switcher__btn {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    min-width: 100px;
    font-size: 14px;
    border: 1px solid $color-brand-opaque-20;
    margin-left: 30px;
    &:first-of-type {
      margin-left: 0;
    }

    &.active {
      border-color: transparent;
    }

    @include break-max($breakpoint-sm) {
      height: 50px;
      width: 140px;
      margin-left: 10px;
      
      &:first-of-type {
        margin-left: 0;
      }

      &:nth-child(4) {
        margin-left: 0;
      }
      &:nth-child(n+4) {
        margin-top: 10px;
      }
    }

    @include break-max($breakpoint-xs) {
      &:nth-child(n) {
        width: 100%;
        height: 44px;
        margin: 0;
      }
    }
  }
}



.aa__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-brand-3;
  transition: background-color 0.15s ease-in-out;
  color: $color-white;
  border-radius: 4px;

  &:hover {
    background: $color-brand-2;
  }

  &:active {
    background: $color-brand-1;
  }

  &_map {
    width: 95px;
    height: 50px;
    margin-top: auto;
    margin-left: 30px;
    
    @include break-max($breakpoint-sm) {
      margin-left: 15px;
      width: 100px;
    }
    @include break-max($breakpoint-xs) {
      margin-left: 0;
      margin-top: 10px;
      width: 100%;
    }
  }

}

.aa__info {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
}

.aa__info-icon {
  width: 25px;
  height: 25px;
  background-color: $color-brand-opaque-10;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  border-radius: 50%;
  flex-shrink: 0;
  svg {
    fill: $color-brand-3;
  }
}
