@import "../../styles/variables";
@import "../../styles/mixins";

.pan-comfort {

  &__sendbtn {
    margin-bottom: 10px;
    max-width: 220px;

    @include break-max($breakpoint-xs) {
      max-width: 100%;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .autopan-sec1 {
    background-image: url("../../assets/images/comfort/comfort-bg1.png");
  }

  .autopan-sec1__leveltext {

    @include break-max($breakpoint-xsm) {
      margin-bottom: 20px;
    }
  }

  .autopan-sec1__title {
    margin-top: 200px;

    @include break-max($breakpoint-md) {
      margin-top: inherit;
    }

  }

  .pan-comfort__sec2 {
    padding: 70px 90px;
    border-radius: 60px;
    background-color: #E3F0F7;
    top: 10px;

    @include break-max($breakpoint-md) {
      border-radius: 40px;
    }
    @include break-max($breakpoint-sm) {
      border-radius: 30px;
    }
    @include break-max(1120px) {
      border-radius: 20px;
    }
    @include break-max($breakpoint-xsm) {
      border-radius: 15px;
      padding-top: 40px;
      padding-bottom: 40px;
    }

    @include break-max($breakpoint-xs) {
      padding: 30px;
    }

    .pan-comfort__sec2-list {
      display: flex;
      gap: 20px;

      @include break-max($breakpoint-md) {
        gap: 15px;
      }

      @include break-max(950px) {
        flex-direction: column;
        gap: 10px;
      }

      li {
        width: calc(100% / 3);
        border-radius: 20px;
        background-size: cover;
        height: 285px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;

        @include break-max($breakpoint-md) {
          height: 205px;
        }
        @include break-max($breakpoint-xsm) {
          height: 130px;
        }

        @include break-max(950px) {
          width: 100%;
        }

      }

      li:nth-child(1) {
        background-image: url("../../assets/images/comfort/comfort-listbg1.png");
        background-position: center;
        transform: rotate(-5deg);
        margin-top: 17px;

        @include break-max($breakpoint-md) {
          margin-top: 15px;
        }

        @include break-max($breakpoint-sm) {
          margin-top: 10px;
        }

        @include break-max(950px) {
          transform: none;
          margin-top: 0;
        }

      }
      li:nth-child(2) {
        background-image: url("../../assets/images/comfort/comfort-listbg2.png");
        background-color: #EF372E;
      }
      li:nth-child(3) {
        background-image: url("../../assets/images/comfort/comfort-listbg3.png");
        transform: rotate(5deg);
        margin-top: 17px;

        @include break-max($breakpoint-md) {
          margin-top: 15px;
        }

        @include break-max($breakpoint-sm) {
          margin-top: 10px;
        }

        @include break-max($breakpoint-xsm) {
          margin-top: 8px;
        }

        @include break-max(950px) {
          transform: none;
          margin-top: 0;
        }
      }

      .pan-comfort__sec2-list-title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 5px;
        text-align: center;

        @include break-max($breakpoint-md) {
          font-size: 18px;
        }

        @include break-max($breakpoint-xsm) {
          br {
            display: none;
          }
        }
      }
      .pan-comfort__sec2-list-text {
        font-size: 18px;
        text-align: center;

        @include break-max($breakpoint-md) {
          font-size: 14px;
        }

        @include break-max($breakpoint-xsm) {
          br {
            display: none;
          }
        }
      }
    }
  }

  .pan-comfort__sec3 {

    border-radius: 60px;
    padding: 60px 90px;
    margin-top: 10px;
    margin-bottom: 5px;
    position: relative;
    background-color: #EF372E;
    background-image: url("../../assets/images/comfort/comfort-mapbg.png");
    background-size: cover;

    @include break-max($breakpoint-md) {
      padding: 60px 80px;
      border-radius: 40px;
    }

    @include break-max($breakpoint-sm) {
      border-radius: 30px;
    }

    @include break-max($breakpoint-xsm) {
      padding: 30px 45px;
      border-radius: 15px;
    }

    @include break-max(950px) {
      margin-top: 5px;
    }

    @include break-max($breakpoint-xs) {
      padding-left: 10px;
      padding-right: 10px;
    }

    .panschool-sec1__maintitle {
      color: white;
      text-align: center;
    }

  }

  &__map {
    min-height: 1000px;
    border-radius: 40px;
    margin-top: 50px;
    overflow: hidden;

    @include break-max($breakpoint-sm) {
      margin-top: 30px;
    }
    @include break-max($breakpoint-xsm) {

    }

    @include break-max($breakpoint-xs) {
      margin-top: 15px;
    }

    @include break-max($breakpoint-md) {
      min-height: 700px;
    }

    @include break-max($breakpoint-xs) {
      min-height: 350px;
    }

    [class*="ymaps-2"][class*="-ground-pane"] {
      //filter: hue-rotate(180deg);
    }
    .pan-comfort__map-map {
      width: 100%;
      height: 1000px;

      @include break-max($breakpoint-md) {
        height: 700px;
      }

      @include break-max($breakpoint-xs) {
        height: 350px;
      }


    }
  }

  .panschool-sec6 {
    min-height: auto;

    .panschool-sec6__form-row:first-child .hp__form-item {
      width: calc(50% - 5px);
    }

    .panschool-sec6__form-row:first-child .custom-select__option {
      padding: 16px;
    }

    .panschool-sec6__form-row {
      display: flex;
      gap: 10px;
      align-items: flex-end;

      .hp__form-item {
        margin-top: 0!important;
        flex-grow: 1;
      }
      .view-info__dates {
        min-width: 300px;
        margin: 20px auto;
        gap: 10px;
        //background-color: white;
      }

      .input_type_form .input__label {
        line-height: normal;
        margin-bottom: 5px;
      }

      .input_type_form .input__control {
        box-shadow: none;
      }

      .view-info__dates .input__control {
        background-color: white;
      }
    }

    .sor__form-box {
      width: auto;
      max-width: 1000px;
    }

    .sor__form-footer {
      width: auto;
      max-width: 1000px;
      margin-top: 0;
    }

    @include break-max(950px) {

      .sor__form-box {
        gap: 20px;
      }

      .panschool-sec6__form-row:first-child .hp__form-item {
        width: 100%;
      }

      .panschool-sec6__form-row {
        flex-direction: column;
        gap: 20px;
        display: flex;
        max-width: 100%;
        margin-bottom: 20px;

        .hp__form-item {
          width: 100%;
        }
        .sor__form-item {
          margin-top: 0px;
          margin-bottom: 0;
        }

        .view-info__dates {
          margin-top: 0;
          width: 100%;
          margin-bottom: 0;
        }
      }


    }

  }


}


.pan-comfort__popup {
  width: 620px;
  min-height: 270px;
  border-radius: 30px;
  background-color: #EF372E;
  color: white;
  padding: 25px;
  margin-left: -291px;
  background-image: url("../../assets/images/comfort/comfort-popupbg.png");
  margin-top: 110px;
  position: relative;
  display: flex;
  gap: 20px;

  &::after {
    content: '';
    position: absolute;
    border: 20px solid transparent;
    border-bottom: 20px solid #EF372E;
    top: -38px;
    left: calc(50% - 20px);
  }



  @include break-max($breakpoint-sm) {
    .pan-comfort__marker-text {
      display: none;
    }
  }

  @include break-max($breakpoint-xsm) {
    width: 400px;
    margin-left: -181px;
    min-height: auto;
    gap: 10px;
  }

  @include break-max($breakpoint-xs) {
    width: 340px;
    margin-left: -151px;
    padding: 25px 15px;
  }

  .btn.btn_secondary.h6 {
    @include break-max($breakpoint-xs) {
      width: 125px;
    }
  }

  .pan-comfort__popup-metro {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 15px;

    @include break-max($breakpoint-xsm) {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  .pan-comfort__popup-name {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;

    @include break-max($breakpoint-xsm) {
      font-size: 16px;
    }
  }
  .pan-comfort__popup-address {
    font-size: 16px;
    margin-bottom: 15px;

    @include break-max($breakpoint-xsm) {
      font-size: 14px;
    }
  }
  .pan-comfort__popup-time {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 30px;

    @include break-max($breakpoint-xsm) {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
  .pan-comfort__popup-icons {
    display: flex;
    gap: 12px;
    @include break-max($breakpoint-xsm) {
      gap: 8px;
    }
  }
  .pan-comfort__popup-img {
    height: 165px;
    width: 280px;
    background-size: cover;

    @include break-max($breakpoint-xsm) {
      width: 170px;
      height: 100px;
    }

    @include break-max($breakpoint-xs) {
      width: 125px;
      border-radius: 5px;
    }
  }
  .pan-comfort__popup-img.pan-comfort__popup-img_1 {
    background-image: url("../../assets/images/comfort/comfort-room1.png");
  }
  .pan-comfort__popup-img.pan-comfort__popup-img_2 {
    background-image: url("../../assets/images/comfort/comfort-room2.png");
  }
  .pan-comfort__popup-img.pan-comfort__popup-img_3 {
    background-image: url("../../assets/images/comfort/comfort-room3.png");
  }
  .pan-comfort__popup-img.pan-comfort__popup-img_4 {
    background-image: url("../../assets/images/comfort/comfort-room4.png");
  }
  .pan-comfort__popup-img.pan-comfort__popup-img_5 {
    background-image: url("../../assets/images/comfort/comfort-room5.png");
  }
  .pan-comfort__popup-img.pan-comfort__popup-img_6 {
    background-image: url("../../assets/images/comfort/comfort-room6.png");
  }
  .pan-comfort__popup-img.pan-comfort__popup-img_7 {
    background-image: url("../../assets/images/comfort/comfort-room7.png");
  }
  .pan-comfort__popup-btn {
    margin-top: 10px;
  }
}

@include break-max($breakpoint-md) {

}

@include break-max($breakpoint-sm) {

}
@include break-max($breakpoint-xsm) {

}

@include break-max($breakpoint-xs) {

}

