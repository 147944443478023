@import "../../../styles/variables";
@import "../../../styles/mixins";

.social {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  background: rgba(143, 149, 172, 0.2);
  border-radius: 50%;
  transition: all 0.3s;

  &_ig {
    background-image: $color-ig;
  }
  &_vk {
    background: $color-vk;
  }
  &_fb {
    background: $color-fb;
  }
  &_tg {
    background: $color-tg;
  }
  &_wa {
    background: $color-wa;
  }
  &_viber {
    background: $color-viber;
  }
  &_mail {
    background: $color-brand-3;
  }

  .icon {
    fill: $color-white;
    width: 16px;
    height: 16px;
  }
}
