.events {

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 60px 0 40px;

    @include break-max($breakpoint-md) {
      align-items: initial;
      flex-direction: column;
    }
  }

  &__grid {
    @include grid();
    justify-content: space-between;

    &-col {
      @include grid-col-p();
    }
  }

  &__list {
    @include grid-size-p(7);
  }

  &__day {

    & + & {
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid $color-brand-opaque-20;

      @include break-max($breakpoint-xs) {
        border-top: none;
        padding-top: 0;
      }
    }

    &-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 30px;

      &.today {
        color: $color-red;
      }
    }
  }

  &__event {
    display: flex;
    align-items: flex-start;
    margin-bottom: 35px;

    &-time {
      padding: 7px 15px;
      background-color: $color-brand-3;
      color: $color-white;
      margin-right: 20px;
      border-radius: 100px;
    }

    &-name {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
    }

    &-info {
      display: flex;
      flex-wrap: wrap;
    }

    &-label {
      color: $color-brand-3;
      margin-right: 30px;
      margin-top: 20px;
    }
  }

  &__aside {
    @include grid-size-p(4);

    &_sticky {
      position: sticky;
      top: 280px;
      margin-bottom: 20px;
    }
  }

  &__main {
    width: 280px;
    margin-left: auto;
    padding: 55px 30px;
    background-color: $color-brand-2;
    border-radius: 5px;

    @include break-max($breakpoint-sm) {
      width: 230px;
    }

    &-date {
      color: $color-red;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    &-time {
      color: $color-brand-3;
      margin-bottom: 15px;
    }

    &-name {
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      color: $color-white;
      margin-bottom: 40px;

      @include break-max($breakpoint-sm) {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }

    &-places {
      color: $color-brand-3;
      margin-bottom: 30px;
    }
  }

  &__secondary {
    border: 1px solid $color-brand-opaque-20;
    border-radius: 5px;
    margin-top: 20px;
    padding: 35px;
    width: 345px;
    margin-left: auto;


    @include break-max($breakpoint-sm) {
      padding: 20px;
      width: 230px;
    }

    &-name {
      margin-bottom: 10px;
    }

    &-info {
      margin-bottom: 20px;
      color: $color-brand-3;
    }
  }
  

  &__reports {
    margin-top: 75px;
    margin-bottom: 75px;

    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px;

      @include  break-max($breakpoint-md) {
        margin-bottom: 30px;
      }
    }

    .reports {
      &__item {
        position: relative;
        cursor: pointer;

        .gallery__item {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
        }

        &-image {
          border-radius: 4px;
          overflow: hidden;
          margin-bottom: 30px;

          @include  break-max($breakpoint-md) {
            margin-bottom: 15px;
          }

          & img {
            width: 100%;
            height: 260px;
            object-fit: cover;

            @include  break-max($breakpoint-md) {
              height: 205px;
            }

            @include  break-max($breakpoint-sm) {
              height: 170px;
            }
          }
        }

        &-name {
          font-weight: bold;
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 10px;

          @include  break-max($breakpoint-md) {
            font-size: 16px;
            line-height: 20px;
          }
        }

        &-date {
          color: $color-brand-3;
        }

        &:hover {

          .reports__item-name {
            color: $color-red;
          }
        }
      }
    }
  }

}

.calendar {

  &__nav {
    display: flex;
    max-width: 100%;
    overflow: hidden;

    @include break-max($breakpoint-md) {
      margin-top: 20px;
    }

    &-list {
      flex-grow: 1;
      display: flex;

      @include break-max($breakpoint-sm) {
        overflow: auto;
        padding-bottom: 5px;
      }
    }

    &-month {
      padding: 7px 15px;
      border-radius: 100px;
      cursor: pointer;

      @include  break-max($breakpoint-sm) {
        padding: 7px 12px;
      }

      &:hover {
        background-color: $color-brand-opaque-10;
      }

      &.active {
        background-color: $color-brand-3;
        border-radius: 0;
        color: $color-white;

        &_left {
          background-color: $color-brand-3;
          border-radius: 100px 0 0 100px;
          color: $color-white;
        }
        &_right {
          background-color: $color-brand-3;
          border-radius: 0 100px 100px 0;
          color: $color-white;
        }
      }
    }

    &-controls {
      flex: 0 0 auto;
      margin-left: 10px;

      .tabs__navs {
        margin-right: 0;
      }
    }
  }

  &__slider {

  }

  &__month {
    height: 420px;
    border-radius: 5px;
    background-color: $color-brand-opaque-8;
  }

  &__designations {
    &-list {
      display: flex;
      margin-top: 40px;
      margin-bottom: 80px;

      @include break-max($breakpoint-xs) {
        background: $color-brand-opaque-8;
        border-radius: 5px;
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 0;
        padding: 18px 30px 20px;
      }
    }

    &-item {
      position: relative;
      padding-left: 26px;
      color: $color-brand-3;

      @include break-max($breakpoint-xs) {
        font-size: 15px;
      }

      @include break-max($breakpoint-xxs) {
        font-size: 14px;
      }

      &+& {
        margin-left: 75px;

        @include break-max($breakpoint-sm) {
          margin-left: 25px;
        }

        @include break-max($breakpoint-xs) {
          margin-left: 0px;
          margin-top: 7px;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto 0;
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $color-brand-opaque-40;
      }

      &:nth-child(2) {
        &:before {
          background-color: $purple;
        }
      }

      &:nth-child(3) {
        &:before {
          background-color: $color-red;
        }
      }
    }
  }
}

.subscribe {

  &-bg {
    background-image: url("data:image/svg+xml,%3Csvg width='167' height='157' viewBox='0 0 167 157' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M50.5524 190.532L221.407 134.095C226.153 132.527 227.755 127.781 226.51 123.977L188.534 7.92922C186.977 3.17318 182.247 1.57373 178.45 2.8279L7.59572 59.2654C3.79896 60.5195 1.24732 65.5785 2.49244 69.3834L40.4686 185.431C42.025 190.187 46.7556 191.786 50.5524 190.532Z' fill='%238F95AC' fill-opacity='0.08'/%3E%3Cpath d='M119.823 106.516L191.662 14.2742L191.039 12.3718C188.86 5.71337 181.297 1.88739 174.653 4.08218L12.3415 57.6978C1.0086 61.1935 1.86969 67.481 4.04865 74.1395L4.67121 76.0419L119.823 106.516Z' fill='%238F95AC' fill-opacity='0.15'/%3E%3C/svg%3E%0A");
    background-position: right bottom;
    background-repeat: no-repeat;

    &.modal__inner {
      padding-left: 75px;
      padding-right: 75px;
    }

    & .modal__hint {
      padding: 0 100px;
    }
  }

  &__socials {
    display: flex;
    justify-content: center;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: $color-brand-opaque-10;
      cursor: pointer;
      transition: background-color $transition-default;

      & + & {
        margin-left: 20px;
      }

      & .icon {
        fill: $color-brand-opaque-70;
        transition: fill $transition-default;
      }

      &:hover {
        background-color: $color-red;

        & .icon {
          fill: $color-white;
        }
      }
    }
  }
}

.events__calendar {
  @include break-max($breakpoint-xs) {
    margin: 0 10px;

    .tns-outer {
      position: relative;
    }

    .tns-nav {
      height: 27px;
      position: absolute;
      top: -29px;
      width: 100%;

      button {
        background: none;
        height: 100%;
        outline: none;
        transition: all 0.3s ease 0s, border 0s;
        width: 33.3%;
      }

      .tns-nav-active {
        border-bottom: 2px solid $color-red;
      }
    }

    #tns2 > .tns-item {
      padding: 0;
    }

    #tns3-iw,
    #tns2-iw {
      margin: 0;
    }

     .tns-slider.tns-carousel {
       min-height: 320px;
       height: auto;
     }
  }
}

div.calendar__month {
  @include break-max($breakpoint-xs) {
    background: none;
    height: auto;
    padding: 25px 30px 0;

    &:hover {
      background: none;
    }
  }
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  @include break-max($breakpoint-xs) {
    font-size: 18px;
  }
}

.events__grid {
  @include break-max($breakpoint-xs) {
    flex-direction: column;
    margin-top: 25px;
  }
}

.events__aside {
  @include break-max($breakpoint-xs) {
    padding: 0 10px;
    width: 100%;
  }
}

.events__day-list {
  @include break-max($breakpoint-xs) {
    border-bottom: 1px solid $color-brand-opaque-20;
    margin-bottom: 24px;
    padding-bottom: 25px;
  }
}

.events__event {
  @include break-max($breakpoint-xs) {
    margin-bottom: 0;
  }
}

.events__day-title {
  @include break-max($breakpoint-xs) {
    font-size: 15px;
    margin-bottom: 20px;
  }

  @include break-max($breakpoint-xxs) {
    font-size: 14px;
  }
}

.events__event-name {
  @include break-max($breakpoint-xs) {
    font-size: 15px;
  }

  @include break-max($breakpoint-xxs) {
    font-size: 14px;
  }
}

.events__event-label {
  @include break-max($breakpoint-xs) {
    margin: 8px 0 0 0;
  }
}

.events__secondary {
  @include break-max($breakpoint-xs) {
    min-height: 145px;
    margin: 0;
    // overflow: hidden;
    padding: 20px 30px;
    position: relative;
    width: 100%;

    & + & {
      margin-top: 20px;
    }

    .icon_mail-bg {
      bottom: -9px;
      height: 105px;
      width: 150px;
      position: absolute;
      right: -9px;
    }

    .pdf-icon {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

.events__secondary-info {
  @include break-max($breakpoint-xs) {
    color: $color-brand-3;
    font-size: 15px;
    margin-bottom: 10px;
  }

  @include break-max($breakpoint-xxs) {
    font-size: 13px;
  }
}

.events__header {
  @include break-max($breakpoint-xs) {
    margin: 20px 0 15px;
  }
}

.events__secondary-name {
  @include break-max($breakpoint-xs) {
    font-size: 15px;
    margin-bottom: 15px;
  }

  @include break-max($breakpoint-xxs) {
    font-size: 14px;
  }
}

div.react-calendar__navigation {
  @include break-max($breakpoint-xs) {
    height: auto;
    margin-bottom: 15px;
  }
}

.events__list {
  @include break-max($breakpoint-xs) {
    padding: 0 10px;
    width: 100%;
  }
}

.events__reports {
  @include break-max($breakpoint-xs) {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 50px;

    .reports__item {
      box-shadow: 0px 4px 20px $color-brand-opaque-10;
      min-height: 292px;
      width: 90%;
    }

    .tns-inner {
      margin-right: -10px !important;
      overflow: hidden;
      padding-right: 10px;
      margin-left: -10px !important;
      padding-left: 10px;
    }

    .tns-item.tns-slide-active + .tns-item {
      margin-left: -20px;
    }

    .tns-ovh {
      overflow: visible;
    }

    .reports__item-name {
      padding: 0 10px;
    }

    .reports__item-date {
      padding: 0 10px 20px;
    }
  }
}

.calendar__mobile-nav-controls {
  border-bottom: 1px solid $color-brand-opaque-20;
  display: flex;
  justify-content: space-between;
  padding-bottom: 9px;
}

.calendar__mobile-nav-item {
  text-align: center;
  width: 33.3%;
}

div.event__title {
  @include break-max($breakpoint-xs) {
    margin: 20px 0 28px;
    padding: 0 10px;
    width: 100%;
  }
}

div.event__info {
  @include break-max($breakpoint-xs) {
    margin: 0px 10px 25px;
    padding: 24px 26px;

    .event__info-col {
      padding: 0;
      margin-bottom: 18px;
    }

    .event__label {
      margin-top: 5px;
    }
  }
}

.event__speaker {
  @include break-max($breakpoint-xs) {
    align-items: center;
  }
}

div.event__details {
  @include break-max($breakpoint-xs) {
    order: 3;
    margin: 35px 0 0 0;
    padding: 0 10px;
    width: 100%;

    b {
      display: block;
      margin-bottom: 20px;
    }

    ul {
      padding-left: 20px;
    }

    li {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 10px;
    }
  }
}

div.event__registration {
  @include break-max($breakpoint-xs) {
    border: 1px solid $color-brand-opaque-20;
    border-radius: 5px;
    margin: 0 10px;
    padding: 30px 24px 25px;
    width: 100%;
  }
}

.event__registration-availability {
  @include break-max($breakpoint-xs) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

div.event__registration-label {
  @include break-max($breakpoint-xs) {
    align-items: center;
    display: flex;
    margin-top: 0;
    margin-right: 55px;
    text-align: left;
    width: 46%;
  }
}

.h1.event__registration-places {
  @include break-max($breakpoint-xs) {
    font-size: 48px;
    line-height: 55px;
  }
}

div.event__registration-date {
  @include break-max($breakpoint-xs) {
    border-bottom: none;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    h4 {
      text-align: left;
      white-space: nowrap;
    }
  }
}

div.event__registration-btns {
  @include break-max($breakpoint-xs) {
    margin-top: 5px;
    width: 100%;

    .button.button_view_default {
      height: 44px;
      padding: 0;
      width: 100%;
    }

    .mobile-filters__show-all .button {
      align-items: center;
      justify-content: center;
      display: flex;
      width: calc(100% - 20px);
    }

    .custom-select__option {
      text-align: left;
    }
  }
}

div.event__details-text {
  @include break-max($breakpoint-xs) {
    overflow: hidden;
    padding-bottom: 0;
  }
}

div.event__grid {
  @include break-max($breakpoint-xs) {
    padding-bottom: 65px;
  }
}

div.event__registration-done {
  @include break-max($breakpoint-xs) {
    align-items: center;
    display: flex;
    margin-bottom: 0;
    text-align: left;
  }

  @include break-max($breakpoint-xxs) {
    font-size: 12px;
  }
}

div.event__registration-cancel {
  @include break-max($breakpoint-xs) {
    .button {
      padding-left: 20px;
      padding-right: 20px;

      @include break-max($breakpoint-xxs) {
        font-size: 12px;
      }
    }
  }
}

div.event__records {
  @include break-max($breakpoint-xs) {
    align-items: flex-start;
    flex-direction: column;

    .label_with-icon_postfix .label__icon {
      margin-left: 10px;
    }
  }
}

.events__agent-name {
  @include break-max($breakpoint-xs) {
    display: block;
    overflow: hidden;
    max-width: 140px;
    text-overflow: ellipsis;
  }

  @include break-max($breakpoint-xxs) {
    max-width: 100px;
  }
}


.events__secondary-img {
  display: block;
  margin-left: auto;
  margin-right: auto;

  margin-top: -180px;
}

.events__secondary_socials {
  margin-top: 180px;
  background: url('../../../assets/images/events/event-bg.svg') no-repeat bottom -15px right 0;

  .h4 {
    width: 75%;
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }
}

.events__socials {
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(2, 60px);
  gap: 10px;

}