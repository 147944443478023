@import "../../styles/variables";
@import "../../styles/mixins";

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotating-reverse {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes rotating-reverse {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
/* bottom bounce*/
@-webkit-keyframes bounceBottom {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
  60% {
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
}
@-moz-keyframes bounceBottom {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
  60% {
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
}
@keyframes bounceBottom {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
  60% {
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
}
/* /bottom bounce*/

.rotating {
  -webkit-animation: rotating 16s linear infinite;
  -moz-animation: rotating 16s linear infinite;
  -ms-animation: rotating 16s linear infinite;
  -o-animation: rotating 16s linear infinite;
  animation: rotating 16s linear infinite;
}

.rotating-reverse {
  -webkit-animation: rotating-reverse 16s linear infinite;
  -moz-animation: rotating-reverse 16s linear infinite;
  -ms-animation: rotating-reverse 16s linear infinite;
  -o-animation: rotating-reverse 16s linear infinite;
  animation: rotating-reverse 16s linear infinite;
}

.ten-years-label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  margin-left: 10px;
  margin-top: 10px;

  background: $color-red;
  color: $color-white;
  border-radius: 3px;

  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;

  &_placeholder {
    display: inline;
    position: relative;
    top: -7px;
  }
}

.ten__placeholder {
  max-width: 690px;
  margin: 0 auto;
  text-align: center;
  flex-direction: column;

  .button {
    display: inline-flex;
    margin-bottom: 100px;
  }
}

.ten__placeholder-img {
  display: block;
  margin: 30px auto;
}

.ten__rating-title {
  width: 100%;
  justify-content: center;

  &_placeholder {
    flex-direction: column;
    max-width: 690px;
    text-align: center;
    margin: 0 auto;
  }
}

.ten__score {
  display: flex;
  justify-content: center;
  max-width: 720px;
  padding: 10px 15px 15px;
  margin: 0 auto 45px;

  border: 1px solid $color-brand-opaque-20;
  border-radius: 4px;
}

.ten__table {
  max-width: 970px;
  margin: 0 auto 45px;
}

.ten__table tr {
  cursor: default;
}

.ten__table th:nth-child(1){ // разные стили в фигме у th, такое вот быстрое решение
  text-align: center;
}
.ten__table th:nth-child(4){
  text-align: center;
}
.ten__score-icon {
  width: 28px;
  height: 28px;
  background: $color-brand-1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-right: 5px;
  margin-left: 28px;
  .icon {
    width: 15px;
    stroke: #fff;
  }
}

.ten__score-change {
  display: flex;
  align-items: center;
  margin-left: 28px;
  &.plus {
    color: $green;
    .ten__score-icon {
      transform: rotate(-90deg);
      background: $green;
    }
  }
  .ten__score-count {
    width: 50px;
    color: black;
    text-align: right;
  }
  &.minus {
    color: $color-red;
    .ten__score-icon {
      transform: rotate(90deg);
      background: $color-red;
    }
  }

}

.ten__score-key {
  padding-right: 15px;
}

.ten__score-value {
  padding-right: 25px;
}


.ten {
  .track-anchor {
    position: relative;
    top: -100px;
  }
  .big-text {
    font-weight: 300;
  }

  .slider-nav {
    margin-right: 25px;
  }

  .slider-nav__wrapper {
    position: relative;
    display: flex;
    width: 100px;
    height: 60px;
    border-radius: 30px;
    border: 1px solid $color-brand-opaque-30;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 30px;
      background: $color-brand-opaque-20;
    }
  }

  .slider-nav__item {
    width: 50%;
    display: flex;
    outline: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: all .15s ease-in-out;

    &:hover {
      background-color: $color-brand-opaque-20;
    }

    .icon {
      width: 6px;
      height: 11px;
      }
    }
  }

.ten__container {
  width: 1320px;
  padding: 0 20px;
  max-width: 100%;
  margin: 0 auto;

  @include break-max($breakpoint-md) {
    width: 1070px;
  }
}

.ten__dark {
  color: $color-white;
  background: linear-gradient(157.13deg, #1E2030 26.3%, #0B0D18 68.17%);
}

.ten__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ten__first-screen {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 134px);
}

.ten__promo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  background: url('../../assets/images/ten/ten.svg') no-repeat top center;
  background-size: contain;
}

.ten__h1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 120px;
  line-height: 130px;
  font-weight: bold;
  text-transform: uppercase;
}

.ten__h1-highlighted {
  padding: 0 30px;
  background: $color-red;
  border-radius: 8px;
}

.ten__h1-content-text {
  margin-right: 60px;
  padding-left: 30px;
}

.ten__h1-content {
  display: flex;
}

.ten__mouse {
  position: absolute;
  left: 0;
  bottom: 55px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  &_static {
    position: static;
    padding-bottom: 15px;
    cursor: pointer;
  }
}

.ten__mouse-arrow {
  -webkit-animation: bounceBottom 2s infinite ease-in-out;
  animation: bounceBottom 2s infinite ease-in-out;

  .icon {
    width: 12px;
    stroke: $color-white;
    transform: rotate(90deg);
  }
}

.ten__mouse_black .icon {
  stroke: $color-brand-2;
}

.ten__mouse-text {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 18px;
  color: $color-brand-2;
}

.ten__promo-slogan {

}

.ten__start {
  padding: 170px 0;
  background: url('../../assets/images/ten/one-group.svg') no-repeat right;
}

.ten__start-block {
  max-width: 500px;
}

.ten__title {
  font-size: 42px;
  line-height: 45px;
  font-weight: 300;
  text-transform: uppercase;
}

.ten__start-title {
  margin-bottom: 45px;
}

.ten__start-text {
  margin-bottom: 45px;
  font-weight: 300;
}

.ten__start-btn {
  padding: 22px 126px;
}

.ten__info {
  padding-top: 110px;
  padding-bottom: 200px;
  background: url('../../assets/images/ten/waves.svg') no-repeat;
  background-position: bottom 0 left 10%;
}

.ten__info-title {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 840px;
  margin: 0 auto 90px;
  text-align: center;

  &::after {
    content: '';
    width: 66px;
    height: 2px;
    margin-top: 70px;
    background: $color-red;
  }
}

.ten__info-content {
  display: flex;
}

.ten__info-block {
  display: flex;
  flex-direction: column;
  max-width: 830px;
  padding-right: 75px;
  background: url('../../assets/images/ten/half-separator.svg') no-repeat top right;

  &_socials {
    width: 830px;
    max-width: 100%;
  }
}

.ten__info-text {
  margin-bottom: 30px;

  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  color: $color-brand-3;

  b {
    color: $color-white;
  }
}

.ten__info-descr {
  font-size: 26px;
  line-height: 35px;
  font-weight: 300;
  color: $color-brand-3;

  b {
    color: $color-white;
    font-weight: 300;
  }

  &:first-of-type {
    margin-bottom: 25px;
  }

  &_socials {
    max-width: 590px;
    display: flex;
    align-items: center;
  }

  @include break-max($breakpoint-md) {
    font-size: 24px;
    line-height: 32px;
  }
}

.ten__info-aside {
  width: 230px;
  margin-left: 45px;
  @include break-max($breakpoint-md) {
    margin-left: 24px;
  }
}

.ten__info-counter {
  display: block;
  margin-bottom: 40px;
  font-weight: bold;
  font-size: 52px;
  line-height: 45px;

  @include break-max($breakpoint-md) {
    font-size: 42px;
    margin-bottom: 20px;
  }
}


.ten__info-sign {
  font-weight: 300;
  font-size: 21px;
  line-height: 30px;
  color: $color-brand-3;
}

.ten__path {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-bottom: 100px;
  overflow: hidden;

  .wrapper {
    display: flex;
    width: 574px;
    margin: 0;
    margin-top: 60px;
    padding-top: 100px;
    padding-left: 190px;
    background: $color-white url('../../assets/images/ten/waves-sm.svg') no-repeat top center;
;

    @include break-max($breakpoint-md) {
      width: 415px;
      padding-left: 60px;
    }
  }
}

.ten__path-container {
  display: flex;
  width: 9856px;
  height: 650px;
  background: url('../../assets/images/ten/path.svg') no-repeat;
  background-position-x: 520px;
  background-position-y: center;
  transition: all 0.3s ease;

  @include break-max($breakpoint-md) {
    background-size: contain;
  }

  &_2010 {
    background-position-x: 520px;
    @include break-max($breakpoint-md) {
      background-position-x: 320px;
    }
  }

  &_2011 {
    background-position-x: -480px;
    @include break-max($breakpoint-md) {
      background-position-x: -480px;
    }
  }

  &_2012 {
    background-position-x: -1380px;
    @include break-max($breakpoint-md) {
      background-position-x: -1260px;
    }
  }

  &_2013 {
    background-position-x: -2280px;
    @include break-max($breakpoint-md) {
      background-position-x: -1980px;
    }
  }

  &_2014 {
    background-position-x: -3020px;
    @include break-max($breakpoint-md) {
      background-position-x: -2680px;
    }
  }

  &_2015 {
    background-position-x: -3880px;
    @include break-max($breakpoint-md) {
      background-position-x: -3380px;
    }
  }

  &_2016 {
    background-position-x: -4730px;
    @include break-max($breakpoint-md) {
      background-position-x: -4130px;
    }
  }

  &_2017 {
    background-position-x: -5680px;
    @include break-max($breakpoint-md) {
      background-position-x: -4880px;

    }
  }

  &_2018 {
    background-position-x: -6450px;
    @include break-max($breakpoint-md) {
      background-position-x: -5580px;

    }
  }

  &_2019 {
    background-position-x: -7250px;
    @include break-max($breakpoint-md) {
      background-position-x: -6250px;
    }
  }

  &_2020 {
    background-position-x: -8190px;
    @include break-max($breakpoint-md) {
      background-position-x: -6990px;
    }
  }
}


.ten__path-slogan {
  position: absolute;
  right: 15%;
  top: 10%;
  @include break-max($breakpoint-md) {
    top: 5%;
    right: 5%;
  }
}

.ten__path-block {
  display: flex;
  flex-direction: column;
  width: 0;
  max-width: 100%;

  opacity: 0;

  &.active {
  width: 320px;
  transition: opacity 800ms ease-in-out;
  opacity: 1;
  }
}

.ten__path-year {
  margin-bottom: 20px;

  font-size: 100px;
  line-height: 100px;
  font-weight: bold;
  color: $color-red;
}

.ten__path-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  margin-bottom: 60px;

  background: $color-brand-opaque-8;
  border-radius: 50%;

  font-size: 32px;
  color: $color-brand-2;

  transition: all 0.3s;

  &:hover {
    color: $color-white;
    background: $color-brand-2;
  }
}

.ten__path-title {
  margin-bottom: 30px;

}

.ten__path-rail {
  position: relative;
  margin-top: 0;
  margin-bottom: 40px;
  padding-top: 15px;
  padding-bottom: 30px;
}

.ten__path-dots {
  display: flex;
  justify-content: space-between;
  padding-left: 140px;
  @include break-max($breakpoint-md) {
    padding-left: 90px;
  }
}

.ten__path-dot {
  cursor: pointer;
  padding: 6px 18px;
  border-radius: 100px;
  transform: rotate(-45deg);
  font-size: 14px;
  line-height: 18px;
  color: $color-brand-3;
  background: transparent;
  transition: all 0.3s;

  &.active {
    background: $color-brand-2;
    color: $color-white;
  }
}

.ten__path-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background: $color-brand-2;
  transition: all 0.3s ease;
}

.ten__box {
  width: 1220px;
  max-width: 100%;
  margin: 0 auto 30px;

  box-shadow: 0px 2px 70px rgba(143, 149, 172, 0.14);
  border-radius: 5px;
}

.ten__box-header {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 220px;

  padding: 0 125px;

  background-image: url('../../assets/images/ten/bg-header.svg');
  background-color: $color-brand-2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -130px;
  &_start {
    position: relative;
    z-index: 2;
    background: transparent;
  }
}

.ten__box-header-wrapper {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 280px;
    left: 0;
    bottom: 0;
    background-color: $color-brand-2;
    z-index: 1;
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 345px;
    left: 0;
    bottom: 0;
    background-image: url('../../assets/images/ten/blocks-and-car.svg');

    z-index: 1;
  }
}

.ten__box-title {
  position: relative;
  z-index: 2;
  max-width: 430px;
  font-size: 35px;
  line-height: 45px;
  font-weight: 300;
  text-transform: uppercase;

  color: $color-white;
}

.ten__box-header_top {
  height: 280px;
  .ten__box-title {
    max-width: 510px;
  }
}

.ten__box-header_resale {
  // background-image: url('../../assets/images/ten/resale.svg');
  // background-position: left;
  // background-size: 83%;

  .ten__box-title {
    max-width: 660px;
  }
}

.ten__box-header_education {
  // background-image: url('../../assets/images/ten/education.svg');
  // background-position: -200px -330px;
  .ten__box-title {
    max-width: 510px;
  }
}

.ten__box-header_track {
  // background-image: url('../../assets/images/ten/track.svg');
  // background-position: 0px 60px;
  .ten__box-title {
    max-width: 450px;
  }
}

.ten__box-bg-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 280px;
}

.ten__box-header_top .ten__box-bg-img {
  right: 95px;
}

.ten__box-subtitle {
  margin-top: 20px;
}

.ten__box-body {
  display: flex;
  flex-direction: column;
  padding: 45px 0 55px;
}

.ten__box-row {
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 85px;

  & + & {
    margin-top: 35px;
  }
}

.ten__box-content {
  display: flex;
  padding: 0 85px 70px;

  &_no-pb {
    padding-bottom: 0;
  }

  .ten__box-row {
    padding: 0;
  }
  .ten__box-counter {
    font-size: 60px;
  }
  .ten__box-item {
    padding-top: 5px;
  }

  &_pr-sm {
    padding-right: 25px;
  }
}

.ten__box-col {
  width: 50%;
  margin-right: 80px;
  &:last-of-type {
    margin-right: 0;
  }
}

.ten__box-info {
  display: flex;
  margin-top: 20px;

  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  color: $color-brand-3;


  .info {
    margin-right: 15px;
    flex-shrink: 0;
  }
}

.ten__gray {
  color: $color-brand-3;
}

.ten__box-sign {
  margin-bottom: 15px;
}

.ten__box-subtitle {
  font-size: 21px;
  line-height: 30px;
  font-weight: 300;
  color: $color-brand-3;
}

.ten__box-counter {
  margin-right: 25px;
  font-size: 70px;
  line-height: 1;
  font-weight: 900;
  color: $color-brand-opaque-10;
}

.ten__box-inforow {
  display: flex;

  .ten__box-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 320px;
    margin-right: 180px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}


.ten__box-action {
  max-width: 590px;
  font-size: 26px;
  line-height: 32px;
  padding-right: 70px;
  b {
    font-weight: bold;
  }
  & + & {
    margin-top: 5px;
  }
}

.ten__box-graytext {
  margin-top: 10px;
}

.ten__label {
  display: inline-flex;
  padding: 8px 28px;
  background: $color-brand-opaque-10;
  border-radius: 100px;
  color: $color-brand-3;
  font-weight: 400;

  &_bold {
    font-weight: 700;
    color: $color-brand-2;
  }

  &_blue {
    font-weight: 700;
    background: $blue;
    color: $color-white;
  }

  &_or {
    margin: 20px 0 !important;
    padding: 8px 48px;
  }
}

.ten__red {
  color: $color-red;
}

.ten__underline {
  border-bottom: 1px solid $color-red;
}

.ten__box-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  font-weight: 300;
}

.ten__box-action + .ten__label {
  margin-top: 15px;
}

.ten__slider {
  display: flex;
  width: 950px;
}

.ten__slider-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 170px;
  height: 100%;
  margin-right: 85px;
  &:last-of-type {
    margin-right: 0;
  }
}

.ten__slider-title {

  margin-top: 10px;
  font-weight: 700;
}

.ten__slider-imgbox {

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 196px;
  margin-top: 20px;

  background: $color-brand-opaque-10;
  border-radius: 12px;
}

.ten__button {
  display: inline-flex;
  margin: 45px auto 0;
}

.ten__inside-button {
  display: inline-flex;
  margin: 25px auto 50px;

}

.ten__accordion {

}

.ten__accordion-header {
  position: relative;
  cursor: pointer;
}

.ten__accordion-btn {
  position: absolute;
  right: 125px;
  top: 50%;
  width: 60px;
  height: 60px;

  transform: translateY(-50%);
  border-radius: 50%;
  background: #474A61;

  transition: transform 0.3s;
  cursor: pointer;

  &:hover {
    background: transparentize(#474A61,.2);
  }

  .icon {
    font-size: 18px;
    stroke: $color-white;
    fill: transparent;
  }

  &.opened {
    transform: translateY(-50%) rotate(180deg);
  }
}

.ten__accordion-panel {
  padding-top: 15px;
  max-height: 3000px;
  transition: all 0.2s ease-out;
  &.hidden {
    max-height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }
}

.ten__box-subheader {
  display: flex;
  align-items: center;

  padding: 20px 60px;
  margin: 0 30px 30px;

  background: $color-brand-opaque-8;
  border-radius: 5px;

  &_pr-sm {
    padding-right: 10px;
  }

  .separator {
    width: 1px;
    height: 57px;
    margin: 0 35px;
    background: $color-brand-opaque-30;
    transform: rotate(25deg);
  }

  b {
    flex-shrink: 0;
    font-weight: 700;
  }

  p {
    color: $color-brand-3;
  }
}

.ten__box-half {
  display: flex;
  flex-direction: column;
  &:first-child {
    flex-shrink: 0;
    padding-right: 35px;
    background: url('../../assets/images/ten/half-separator.svg') no-repeat top right;
  }
  &:last-child {
    padding-left: 90px;
    justify-content: center;
  }
  &:last-child.ten__box-half_wide {
    padding-left: 35px;
    .ten__box-prize {
      width: 100%;
    }
  }

  &_50 {
    width: 50%;
    &:first-child {
      padding-right: 0;
      background: none;
    }
    &:last-child {
      padding-left: 0;
    }

    .ten__box-action {
      padding-right: 25px;
    }
  }

  &_ticket {
    &:last-child {
      padding-left: 60px;
    }
    .ten__box-bonus {
      width: 232px;
    }
  }
}

.ten__box-prize {
  width: 140px;
  margin-bottom: 35px;
  &:first-of-type {
    margin-top: 20px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }

  .ten__label {
    margin-bottom: 10px;
  }

}

.ten__box-bonus {
  display: flex;
  flex-direction: column;
  width: 185px;
  margin: auto;
}

.ten__old-price {
  text-decoration: line-through;
  color: $blue;
}

.ten__current-price {
  font-size: 42px;
  line-height: 45px;
  font-weight: bold;
  margin-bottom: 5px;
}

.ten__sub-price {
  margin-top: 15px;
  font-size: 21px;
  line-height: 24px;
  font-weight: 300;
  color: $color-brand-3;
}

.ten__sub-price + .ten__label {
  margin-top: 15px;
}

.ten__books {
  width: 310px;
  margin-top: 10px;

  li {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  span {
    color: $color-brand-3;
  }
}

.ten__info_socisals {
  background-image: url('../../assets/images/ten/waves-reverse.svg'),
  linear-gradient(113.49deg, #1E2030 45.95%, #0B0D18 82.94%);
  background-position: top 0 left 10%;

  .ten__info-title {
    color: $color-white;
  }

  .ten__info-counter {
    font-size: 35px;
    color: $color-white;
    text-transform: uppercase;
  }

  .ten__info-aside {
    width: 330px;
  }
}

.ten__social {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-right: 45px;

  background: $color-brand-opaque-20;
  border-radius: 50%;
  transition: all 0.3s;

  &:hover {
    background: $color-red;
    .icon {
      fill: $color-white;
    }
  }

  &:active {
    opacity: 0.8;
  }

  &_sm {
    width: 46px;
    height: 46px;
    margin-right: 30px;

  }

  .icon {
    fill: $color-brand-3;
    width: 2em;
    height: 1.2em;
    transition: all 0.3s;
  }
}

.ten__messengers {
  display: flex;
  li {
    &:last-child .ten__social {
      margin-right: 0;
    }
  }
  .icon {
    width: 22px;
    height: 22px;
  }
}

.ten__register {
  position: fixed;
  left: 0;
  bottom: 0;

  width: 100%;

  z-index: 9;

  background: $color-white;
  box-shadow: 0px 2px 70px rgba(143, 149, 172, 0.14);

  .big-text {
    display: flex;
    font-weight: 700;
    margin-bottom: 5px;
  }

  p {
    font-weight: 300;
  }
}

.ten__register-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 130px;
}

.ten__register-info {
  width: 440px;
  max-width: 100%;
}

.ten__register-btn {
  padding: 22px 88px;
}

.ten__text {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: $color-brand-3;
  &_mt-15 {
    margin-top: 15px;
  }

  .link {
    color: inherit;
  }
}

.ten__box-add {
  max-width: 590px;
  margin-top: 25px;

  font-size: 21px;
  line-height: 24px;
  font-weight: 300;
  color: $color-brand-3;
}

.ten__box-blurwrapper {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}

.ten__box-blurtext {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.ten__box-blur {
  filter: blur(5px);
}
