.background {
  position: absolute;
  width: 100%;
  height: 100%;
}

.background__left {
  position: absolute;
  width: 10%;
  top: 0;
  left: 0;
}

.background__right_white {
  transform: rotate(-20deg);
  left: 70%;
  position: absolute;
  top: -10%;
}

.background__right_mobile_green {
  height: 890px;
  width: 1010px;
  position: absolute;
  right: -130px;
  top: -295px;
  z-index: 1;
}
.background__right_green {
  @include break-max($breakpoint-xs) {
    position: relative;
    z-index: 1;
  }
}

.background__right {
  position: absolute;
  width: 50%;
  top: -100px;
  right: 0;
  @include break-max($breakpoint-md) {
    top: 0;
  }
  @include break-max($breakpoint-xs) {
    width: 60%;
  }
}

.background__fill-main {
  fill: $color-1;
}

.background__fill-black {
  fill: $color-2;
}

.background_sphere {
  overflow: hidden;
  max-height: calc(100vh - 50px);
  // z-index: 1;

  .background__right {
    width: 65%;
    right: -50px;
    z-index: 1;
    @include break-max($breakpoint-md) {
      top: -100px;
      right: -100px;
    }
    @include break-max($breakpoint-sm) {
      width: 80%;
    }
    @include break-max($breakpoint-xsm) {
      width: 100%;
    }
  }

  .background__gradient {
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  & ~ .promo {
    background: $color-1;
    margin-bottom: 100px;
    @include break-max($breakpoint-md) {
      margin-bottom: 60px;
    }
    @include break-max($breakpoint-xsm) {
      margin-bottom: 50px;
    }

    .promo__label,
    .promo__text,
    .promo__sign {
      color: $color-white;
    }

    .promo__btn {
      background: $color-2;
      &:hover {
        background: rgba($color-2, 0.9);
      }

      &:active {
        background: rgba($color-2, 0.8);
      }
    }

  }

}


.background_oval {

  @include break-max($breakpoint-xs) {
    overflow: hidden;
  }

  .background__left {
    z-index: 2;
  }

  .background__right {
    top: 0;
    width: 70%;
    background: url('../../../assets/images/constructor/bg-oval.svg') no-repeat top right;
    background-size: contain;
    @include break-max($breakpoint-md) {
      width: 75%;
    }

    @include break-max($breakpoint-xsm) {
      width: 90%;
    }

    @include break-max($breakpoint-xs) {
      top: -50px;
      width: 180%;
    }
    @include break-max($breakpoint-xxs) {
      top: 0;
    }
  }

}


.background__paint {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: 55%;
  height: calc(100vh - 60px);

  @include break-max($breakpoint-md) {
    height: 741px;
  }

  @include break-max($breakpoint-sm) {
    height: 520px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    height: 280px;
  }

  &::after {
    content: '';
    flex-grow: 1;
    display: flex;
  }

}

.background__paint-text {
  margin-right: 35px;
  
  position: relative;
  top: -45px;

  @include break-max($breakpoint-xs) {
    margin-right: 25px;
    position: relative;
    top: -15px;
    margin-left: 25px;
  }

  svg {
    width: 100%;
    height: calc(100% + 45px);
    @include break-max($breakpoint-xs) {
      height: calc(100% + 15px);
    }
  }

}


.background__pyramid {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - 60px);
  overflow: hidden;


  @include break-max($breakpoint-md) {
    height: 741px;
  }

  @include break-max($breakpoint-sm) {
    height: 520px;
  }

  @include break-max($breakpoint-xs) {
    // width: 100%;
    // height: calc(100vh - 20px);
  }


  &::after {
    content: '';
    display: flex;
    width: 40%;

    @include break-max($breakpoint-xs) {
      display: none;
    }
  }
}

.background__pyramid .left-bg {
  position: relative;
  width: 60%;
  height: 100%;

  @include break-max($breakpoint-xs) {
    width: 100%;
    background-color: none !important;
  }
}

.background__pyramid .left-bg svg {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.background__pyramid .pyramid {
  position: absolute;
  min-height: 100%;
  z-index: 1;
}