.lk-presentations {

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__table {
    padding-bottom: 80px;
  }

  &__info {
    width: 35%;

    @include break-max($breakpoint-sm) {
      width: initial;
    }
  }

  &__clients {
    display: flex;
    align-items: center;

    &-attach {
      display: flex;
      align-items: center;

      .icon_plus {
        font-size: 9px;
        margin-right: 5px;
      }

      .fav-attach {
        opacity: 1;
        visibility: visible;
        white-space: normal;
      }

      &_new {
        margin-left: 10px;
        color: $color-brand-3;
      }

      
      .popover__block {
        right: calc(100% + 40px);
        
        &_align {
          &_right {
            right: inherit;
            left: calc(100% + 30px);
          }
        }
      }
    }
  }

  &__client {
    padding: 5px 0;
    margin-right: 30px;
  }

  &__control {
    display: flex;
    justify-content: flex-end;
  }
}

.table__controls {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;

  & .button {
    margin-left: 12px;
  }

  & .icon {
    font-size: 20px;
  }
}