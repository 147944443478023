.card {
  background: transparent;
  &::after {
    display: none;
  }

  .breadcrumbs {
    margin-bottom: 0;
    margin-left: 30px;
    border: none;

    & ~ .complex__block-row {
      padding-top: 0;
    }
  }

  .card__image {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .complex__info-item-value {
    color: $color-brand-2;
  }

  .complex__block-col.text__col {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    justify-content: flex-start;
  }

  .complex__metro-station {
    color: $color-brand-3;
  }

  .complex__slider-magnifier {
    background: $color-white;
  }

  .complex__block .complex__images-nav {
    color: $color-white;
  }

  .complex__block iframe {
    @include break-min($break-lg) {
      min-width: 550px;
    }
  }

  .complex__slider-controls li {
    background: $color-white;
    box-shadow: 0px 10px 40px rgba(148, 134, 204, 0.15);
  }

  .complex__slider-controls li:hover .icon {
    stroke: $color-brand-2;
  }

  .show__map {
    background: url('../../../assets/images/constructor/map.png') no-repeat center;
    background-size: cover;
    border: 6px solid $color-white;
    box-shadow: 0px 10px 40px rgba(148, 134, 204, 0.15);
  }

  .show__map svg {
    fill: $color-1;
  }

  .card__routes {
    margin-top: 20px;
    @include break-max($breakpoint-sm) {
      margin-top: 10px;
    }
  }

  .card__routes + .card__routes {
    margin-top: 0;
  }

  @include break-max($breakpoint-xs) {
    .complex__address {
      margin-bottom: 0;
      padding-top: 0;
    }

    .complex__block {
      margin-left: -10px;
      margin-right: -10px;
    }

    .complex__bottom {
      width: calc(100% - 10px);
    }
  }
}
