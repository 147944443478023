@import "../../styles/variables";
@import "../../styles/mixins";


.future {

  .no-wrap {
    white-space: nowrap;
  }

  .wrapper__center_start {
    @include break-max($breakpoint-sm) {
      width: 100%;
    }
  }

  .big-text {
    font-weight: 300;
  }

  .icon_logo {
    fill: $color-white;
    width: 72px;
    margin-right: 25px;
    @include break-max($breakpoint-xs) {
      margin-left: 10px;
      margin-right: auto;
    }
  }

  .future__header,
  .future__footer {
    background: $color-brand-2;
    color: $color-white;
  }

  .future__header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2;
  }

  .future__footer {
    @include break-max($breakpoint-xs) {
      padding: 25px 0;
    }
  }

  .future__footer .future__header-box {
    @include break-max($breakpoint-xs) {
      height: auto;
      flex-wrap: wrap;
    }
  }

  .future__footer .icon_logo {
    @include break-max($breakpoint-xs) {
      height: 33px;
    }
  }

  .future__callback {
    margin-left: auto;
    background: $color-brand-3;
    border-radius: 0;
    transition: background 0.3s;
    &:hover {
      background: $color-red;
    }
    &:active {
      background: $color-brand-opaque-20;
    }

    @include break-max($breakpoint-xs) {
      height: 100%;
      font-size: 0;
      order: 1;
      display: flex;
      align-items: center;
    }

    .icon {
      display: none;
      @include break-max($breakpoint-xs) {
        display: block;
        font-size: 19px;
        fill: $color-white;

      }

    }
  }

  .future__anchor {
    margin-left: 40px;
    font-family: inherit;
    color: $color-white;
    font-size: 16px;
    transition: color 0.3s;
    &:hover {
      color: $color-red;
    }
    &:active {
      color: $color-brand-3;
    }
  }

  .future__footer-text {
    @include break-max($breakpoint-sm) {
      max-width: 260px;
    }
    @include break-max($breakpoint-xsm) {
      max-width: 205px;
    }
  }

  .future__footer-phone {
    font-size: 14px;
    font-weight: bold;
    @include break-max($breakpoint-xsm) {
      margin-left: 0;
    }
    @include break-max($breakpoint-xs) {
      margin-top: 10px;
    }
  }

  .future__footer-separators {
    display: flex;
    margin-left: auto;
    @include break-max($breakpoint-xs) {
      position: relative;
      flex-basis: 100%;
      margin-left: 85px;
      flex-direction: column;
      margin-top: 20px;
      padding-left: 15px;

      &::before {
        position: absolute;
        left: 0;
        content: '';
        width: 1px;
        height: 78px;
        background: $color-brand-opaque-50;
        margin-right: 15px;
      }
    }
  }

  .future__footer-separator {
    display: flex;
    align-items: center;
    &::before {
      content: '';
      width: 1px;
      height: 26px;
      background: $color-brand-opaque-50;
      margin-right: 35px;
      @include break-max($breakpoint-xs) {
        display: none;
      }
    }

    @include break-max($breakpoint-xs) {
      margin-left: 0;
    }
  }

  .footer__address {
    margin-left: 30px;
    @include break-max($breakpoint-xsm) {
      max-width: 215px;
    }
    @include break-max($breakpoint-xs) {
      margin-left: 0;
      margin-top: 15px;
    }
  }

  .future__header-box {
    display: flex;
    align-items: center;
    height: 60px;
  }

  .future__h1 {
    width: 375px;
    font-size: 16px;
    line-height: 18px;
    @include break-max($breakpoint-xsm) {
      font-size: 14px;
      line-height: 16px;
    }
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  .future__h2 {
    padding-top: 80px;
    padding-bottom: 55px;
    text-align: center;

    @include break-max($breakpoint-xsm) {
      padding-top: 50px;
      padding-bottom: 25px;
    }
    @include break-max($breakpoint-xs) {
      padding-top: 35px;
    }
  }

  .future__header-phone {
    @include break-max($breakpoint-xsm) {
      display: none;
    }
    @include break-max($breakpoint-xs) {
      display: block;
      margin-left: 0;
    }
  }

  .promo {
    padding-top: 60px;
    background: $color-brand-1;
    color: $color-white;
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  .promo__container {
    position: relative;
    display: flex;
    align-items: center;
    height: calc(100vh - 60px);
    @include break-max($breakpoint-sm) {
      max-height: 575px;
    }
    @include break-max($breakpoint-xsm) {
      max-height: none;
    }

    @include break-max($breakpoint-xs) {
      height: auto;
      padding-top: 40px;
      padding-bottom: 30px;
    }
  }

  .promo__box {
    width: 570px;
    max-width: 100%;
    @include break-max($breakpoint-sm) {
      width: 440px;
    }
    @include break-max($breakpoint-xsm) {
      width: 578px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .promo__image {
    position: absolute;
    right: 0;
    top: 17%;
    @include break-max($breakpoint-md) {
      max-width: 70vh;
      right: 5%;
    }
    @include break-max($breakpoint-sm) {
      max-width: 474px;
      top: 40px;
    }
    @include break-max($breakpoint-xsm) {
      max-width: 420px;
      top: 240px;
      right: auto;
      left: 50%;
      transform: translateX(-45%);
    }

    @include break-max($breakpoint-xs) {
      max-width: 300px;
    }

  }

  .promo__box-title {
    margin-bottom: 35px;
    @include break-max($breakpoint-xs) {
      margin-bottom: 25px;
    }
  }

  .promo__box-text {
    margin-bottom: 55px;
    @include break-max($breakpoint-xsm) {
      margin-bottom: 350px;
      padding-bottom: 20px;
      background: $color-brand-1;
    }

    @include break-max($breakpoint-xs) {
      margin-bottom: 260px;
    }

  }

  .promo__form {
    display: flex;
    height: 60px;
    background: $color-brand-2;
    @include break-max($breakpoint-xsm) {
      height: 50px;
    }

    @include break-max($breakpoint-xs) {
      flex-direction: column;
      height: auto;
      background: none;
    }
  }


  .promo__input {
    flex-grow: 1;
    font-size: 14px;
    padding: 0 25px;
    background: $color-brand-2;
    color: $color-brand-3;
    height: 100%;

    @include break-max($breakpoint-xs) {
      height: 50px;
      flex-grow: 0;
      margin-bottom: 10px;
    }
  }

  .promo__submit {
    border-radius: 5px;
    display: flex;
    align-items: center;

    @include break-max($breakpoint-xs) {
      height: 50px;
    }
  }

  .benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .benefits__list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    margin-bottom: 30px;
  }

  .benefits__item {
    display: flex;
    flex-direction: column;
    flex-basis: 49%;

    padding: 45px 60px;
    padding-right: 45px;
    line-height: 1.4;
    border: 1px solid $color-brand-opaque-20;
    border-radius: 5px;
    // &:nth-child(odd) {
    //   border-right: 1px solid $color-brand-opaque-20;
    //   border-top: 1px solid $color-brand-opaque-20;
    // }
    //
    // &:nth-child(even) {
    //   border-top: 1px solid $color-brand-opaque-20;
    // }
    //
    // &:nth-child(1) {
    //   border-top: none;
    // }


    // &:nth-child(2) {
    //   border-top: none;
    //   @include break-max($breakpoint-xsm) {
    //     border-top: 1px solid $color-brand-opaque-20;
    //   }
    // }

    @include break-max($breakpoint-xsm) {
      flex-basis: 100%;
      padding: 30px 60px;

      // &:nth-child(n) {
      //   border-top: 1px solid $color-brand-opaque-20;
      //   border-right: none;
      // }
      //
      // &:nth-child(1) {
      //   border-top: none;
      // }
    }

    @include break-max($breakpoint-xsm) {
      padding: 30px;
    }
  }

  .benefits table {
    width: 100%;
  }

  .benefits table, .benefits td {
    border: none;
  }

  .benefits__date {
    color: $color-brand-3;
    margin-bottom: 20px;
  }

  .benefits__title {
    margin-bottom: 25px;
    line-height: 30px;
  }

  .benefits__ul,
  .benefits ul,
  .benefits ol {
    margin-top: 15px;
    margin-bottom: 15px;
    li {
      margin-bottom: 15px;
      padding-left: 2px;
      line-height: 1.4;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .benefits ul li {
    position: relative;
    display: flex;
    padding-left: 20px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 6px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $color-red;
    }
  }

  .future__section {
    padding-bottom: 65px;
  }

  .benefits__btn {
    margin-top: 30px;
  }

  .tariff {
    background: $color-brand-opaque-8;
    @include break-max($breakpoint-xs) {
      padding-top: 60px;
    }
  }


  .custom-table__th,
  .custom-table__td {
    flex-basis: 30%;
  }

  .custom-table__th:first-of-type,
  .custom-table__td:first-of-type {
    flex-basis: 40%;
    @include break-max($breakpoint-xsm) {
      flex-basis: 36%;
    }
  }

  .custom-table__th:nth-of-type(2),
  .custom-table__td:nth-of-type(2) {
    @include break-max($breakpoint-xsm) {
      flex-basis: 25%;
    }
    @include break-max($breakpoint-xs) {
      flex-basis: 40%;
      background: $color-white;
      padding: 15px;
    }
  }

  .custom-table__th:last-of-type,
  .custom-table__td:last-of-type {
    @include break-max($breakpoint-xs) {
      flex-grow: 1;
      background: $color-white;
      padding: 15px;
    }
  }

  .custom-table__tr {
    padding: 5px 0;

    @include break-max($breakpoint-xs) {
      flex-wrap: wrap;
      padding-bottom: 0;
    }
  }

  .custom-table__td {
    @include break-max($breakpoint-xsm) {
      display: flex;
      flex-direction: column;
    }

    &:first-of-type {
      @include break-max($breakpoint-xs) {
        flex-basis: 100%;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
      }
    }
  }

  .custom-table__card {
    @include break-max($breakpoint-xs) {
      background: transparent;
      box-shadow: 0px 2px 20px rgba(143, 149, 172, 0.12);
      border-radius: 4px;
    }
  }

  .custom-table__head,
  .custom-table__item {
    padding: 0 55px;

    @include break-max($breakpoint-xsm) {
      padding: 0 22px;
    }

    @include break-max($breakpoint-xs) {
      font-size: 15px;
      padding: 0;
    }
  }

  .motivation__builder-name {
    max-width: 245px;
  }

  .label__text {
    max-width: 325px;
    line-height: 21px;
  }

  .tariff__cash {
    display: flex;
    align-items: center;
    font-weight: normal;
    margin-top: 8px;
    img {
      margin-right: 5px;
    }
  }

  .custom-table__head {
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  .tariff__mobile-header {
    display: none;
    @include break-max($breakpoint-xs) {
      display: inline-flex;
      color: $color-brand-3;
      margin-bottom: 5px;
    }
  }

  .info {
    margin-left: 15px;
  }

  .future__section-header {
    position: relative;

    @include break-max($breakpoint-xsm) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
    }

    @include break-max($breakpoint-xs) {
      margin-bottom: 15px;
    }


    .switch {
      position: absolute;
      top: 90px;
      right: 60px;
      @include break-max($breakpoint-md) {
        top: 85px;
      }
      @include break-max($breakpoint-xsm) {
        position: static;
      }
    }
  }

  .benefits__btn {
    margin: 0 auto;
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  .furute__flex-wrapper {
    display: flex;
    flex-direction: column;
  }

  .tariff__table_stripped {
    margin-bottom: 85px;
    padding-bottom: 0;
    box-shadow: 0px 2px 20px rgba(143, 149, 172, 0.12);

    .custom-table__body .custom-table__item:nth-child(even) {
      background: $color-brand-opaque-8;

      @include break-max($breakpoint-xs) {
        .custom-table__td {
          background: $color-brand-opaque-8;
        }
      }

    }


    .custom-table__th:nth-of-type(n),
    .custom-table__td:nth-of-type(n) {
      @include break-max($breakpoint-xsm) {
        flex-basis: 40%;
      }
      @include break-max($breakpoint-xs) {
        flex-basis: 100%;
        display: flex;
        flex-direction: row;
      }
    }

    .custom-table__th:last-of-type,
    .custom-table__td:last-of-type {
      @include break-max($breakpoint-xsm) {
        flex-basis: 20%;
      }
      @include break-max($breakpoint-xs) {
        flex-basis: 100%;
      }
    }


    .tariff__mobile-header {
      margin-bottom: 0;
      margin-right: 5px;
      flex-basis: 35%;
    }

  }

  .motivation__builder-name_events {
    max-width: 350px;
    font-size: 16px;
    line-height: 20px;
    @include break-max($breakpoint-xsm) {
      font-size: 14px;
      line-height: 18px;
    }
    @include break-max($breakpoint-xs) {
      max-width: 100%;
      font-size: 15px;
    }
  }

  .tariff__date {
    display: flex;
    align-items: center;
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  .tariff__day {
    margin-right: 25px;
    @include break-max($breakpoint-xs) {
      margin-right: 10px;
    }
  }

  .tariff__time {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    background: $color-brand-opaque-10;
    border-radius: 100px;
  }

  .manager {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $color-brand-opaque-10;
    border-radius: 4px;

    margin-bottom: 190px;

    @include break-max($breakpoint-md) {
      margin-bottom: 70px;
    }

    @include break-max($breakpoint-xsm) {
      margin-bottom: 35px;
    }
  }

  .manager__title {
    margin-top: -18px;
    padding: 0 40px;
    background: $color-white;
    @include break-max($breakpoint-sm) {
      margin-top: -15px;
    }

    @include break-max($breakpoint-xs) {
      padding: 0 20px;
      margin-top: 25px;
    }
  }

  .manager__container {
    width: 100%;
    padding: 35px 120px;
    @include break-max($breakpoint-sm) {
      padding: 25px 60px;
    }
    @include break-max($breakpoint-xs) {
      padding: 25px 35px;
    }
  }

  .manager__block {
    display: flex;
    align-items: center;
    @include break-max($breakpoint-xs) {
      width: 100%;
      min-height: 85px;
    }
  }

  .contact__photo {
    margin-right: 15px;
    margin-bottom: 0;
  }

  .contact {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
    .contact__photo {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      flex-shrink: 0;
      @include break-max($breakpoint-xsm) {
        width: 108px;
        height: 108px;
      }
      @include break-max($breakpoint-xs) {
        position: absolute;
        left: 0;
        width: 85px;
        height: 85px;
      }
      img {
        height: 100%;
      }
    }
  }

  .manager__info {
    @include break-max($breakpoint-xs) {
      text-align: left;
      padding-left: 100px;
    }
  }

  .contact__contacts {
    margin-bottom: 0;
  }

  .contact__contacts-block {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 100px;
    @include break-max($breakpoint-sm) {
      margin-left: 60px;
    }

    @include break-max($breakpoint-xsm) {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 30px;
      flex-shrink: 0;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
      margin-left: 0;
      padding-left: 8px;
    }
  }

  .contact__contacts-item {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 12px;
      width: 16px;
      fill: $color-brand-3;
      transition: all 0.3s;
      @include break-max($breakpoint-xsm) {
        margin-right: 6px;
      }
    }

    a:hover .icon {
      fill: $color-red;
    }

  }

  .contact__socials {
    @include break-max($breakpoint-xsm) {
      margin-top: 10px;
    }
  }

  .contact__socials .button {
    width: 32px;
    height: 32px;
  }

  .panel__tooltip::after {
    display: none;
  }

  .panel__social {
    position: static;
    display: block;
  }
  .panel__link .icon {
    stroke: $color-white;
    fill: transparent;
    &:hover {
      stroke: $color-red;
      fill: transparent;
    }
  }

  .panel__social .panel__link .icon {
    fill: $color-white;
    stroke: transparent;
    &:hover {
      stroke: transparent;
      fill: $color-red;
    }
  }

  .motivation__table {
    @include break-max($breakpoint-xs) {
      margin: 0;
    }
  }
}
