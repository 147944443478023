.admin__header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @include break-max($breakpoint-sm) {
    flex-wrap: wrap;
    align-items: center;
  }
}

.admin__header-info {
  display: flex;
  align-items: center;
  margin-top: 40px;
  justify-content: space-between;
  width: 100%;
  @include break-max($breakpoint-xs) {
    margin-top: 10px;
    flex-direction: column;
  }
}
.admin__header-wrapper{
  width: 100%;
}
.admin__tabs {
  display: flex;

  user-select: none;
  .admin__tabs-tab {
    min-width: fit-content;
    color: #2C2E3E;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 14px;
    border: 1px solid #8F95AC;
    cursor: pointer;
    &.active{
      background: #8F95AC;
      color: white;
    }
    &.disabled {
      opacity: 0.3;
    }

    @include break-max($breakpoint-sm) {
      padding: 11px 47px;
      margin: 0 10px;
      &:first-of-type {
        margin-left: 0;
      }
    }

    @include break-max($breakpoint-xs) {
      margin: 0;
      padding: 8px 10px;
    }
  }

  @include break-max($breakpoint-sm) {
    margin-top: 15px;
    order: 3;
    width: 100%;
    justify-content: flex-start;
  }

  @include break-max($breakpoint-xs) {
    justify-content: space-between;
  }

  & > div {
    @include break-max($breakpoint-xs) {
      flex-grow: 1;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
      .admin__tabs-tab {
        width: 100%;
      }
    }
  }
}
.admin__section {
  margin-top: 40px;
  padding-bottom: 60px;
  border-bottom: 1px solid $color-brand-opaque-20;

  @include break-max($breakpoint-xs) {
    margin-top: 25px;
    padding-bottom: 55px;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &_no-border {
    padding-bottom: 0;
    border: none;
  }
  &:last-of-type {
    border: none;
  }

  &_md {
    max-width: 970px;
    @include break-max($breakpoint-sm) {
      max-width: 100%;
    }
  }

  &_sm {
    max-width: 780px;
    @include break-max($breakpoint-sm) {
      max-width: 100%;
    }
  }
  &_xsm {
    max-width: 640px;
    @include break-max($breakpoint-sm) {
      max-width: 100%;
    }
  }

  &_pb-20 {
    padding-bottom: 20px;
    @include break-max($breakpoint-xs) {
      padding-bottom: 5px;
    }
  }

  &_pb-0 {
    padding-bottom: 0;
  }
}


.admin {
  input[type="radio"] {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
  }
  textarea {
    resize: vertical;
  }
}

.admin__h1 {
  flex-shrink: 0;
  margin-right: 55px;
  @include break-max($breakpoint-xsm) {
    margin-right: 35px;
  }

  @include break-max($breakpoint-xsm) {
    margin-right: 0;
    flex-basis: auto !important;
  }

}

.admin__title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .info {
    margin-left: 15px;
  }

  .switch {
    margin-right: 15px;
  }

  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
  }

  &_with-info {
    margin-bottom: 8px;
    .admin__label {
      margin-bottom: 0;
    }
  }

  &_mb-40 {
    margin-bottom: 40px;
  }
}

.admin__h6 {
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 21px;
  font-weight: bold;

  @include break-max($breakpoint-xs) {
    font-size: 15px;
    line-height: 20px;
  }
}

.admin__header-btn {
  display: flex;
  margin-left: 25px;

  @include break-max($breakpoint-xsm) {
    margin-top: 0;
  }
  @include break-max($breakpoint-xs) {
    margin-left: 0;
  }
}

.admin__showpromo-anchor {
  color: $color-brand-3;
  background: rgba(143, 149, 172, 0.08);
  display: flex;
  border-radius: 5px;
  padding: 14px 24px;
  flex-wrap: nowrap;
  min-width: fit-content;
  @include break-max($breakpoint-xs) {
    padding: 12px 14px;
  }

}

.admin__anchor {
  transition: all 0.3s;
  color: $color-brand-3;
  &:hover {
    color: $color-brand-2;
  }
  &:active {
    color: $color-brand-1;
  }

  &_red {
    color: $color-red;

    &:hover {
      color: $color-red-hover;
    }

    &:active {
      color: $color-red-active;
    }
  }

  &_mt-5 {
    display: inline-flex;
    margin-top: 5px;
  }
}
.admin__anchor-wrapper{
  display: flex;
  align-items: center;
  .admin__anchor {
    margin-left: 10px;
  }

  @include break-max($breakpoint-sm) {
    &:only-of-type {
      margin-bottom: 15px;
    }
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-bottom: 20px;
  }
}
.admin__views {
  margin-left: 15px;
  display: flex;
  align-items: center;
  width: fit-content;
  height: 28px;
  padding: 0 10px;
  background: rgba(143, 149, 172, 0.1);
  border-radius: 100px;
  fill: rgba(143, 149, 172, 0.4);
  user-select: none;
  span{
    margin-left: 5px;
  }
}
.admin__list-tabs {
  width: 100%;
  border: 1px solid rgba(143, 149, 172, 0.2);
  border-radius: 5px;
  border-top: none;
  @include break-max($breakpoint-xs) {
    border: none;
  }
}

.admin__list-container {
  display: flex;
  &_sm {
    width: 490px;
    max-width: 100%;
  }
  &_lg {
    width: 752px;
    max-width: 100%;
    @include break-max($breakpoint-sm) {
      width: 452px;
    }
  }
  @include break-max($breakpoint-xs) {
    border-bottom: 1px solid $color-brand-opaque-20;
    overflow-x: scroll;
  }
}

.admin__list-tab {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border-bottom: 1px solid rgba(143, 149, 172, 0.2);
  border-top: 1px solid rgba(143, 149, 172, 0.2);
  border-right:  1px solid rgba(143, 149, 172, 0.2);
  cursor: pointer;
  user-select: none;

  @include break-max($breakpoint-xs) {
    margin-right: 25px;
    font-weight: 700;
    flex-shrink: 0;
    border: none;
  }

  &.active {
    background: #8F95AC;
    color: white;
    @include break-max($breakpoint-xs) {
      color: inherit;
      background: $color-white;
      box-shadow: inset 0 -2px $color-red;
    }
  }
  &:last-child {
    border-right: none;
    border-top-right-radius: 5px;
  }
  &:first-child {
    border-right: none;
    border-top-left-radius: 5px;
  }
  .dot {
    background-color: #FF2D23;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin-left: 10px;
    text-align: center;
    line-height: 25px;
    color: white;
  }
}

.admin__list-box {
  padding: 0 35px 35px;
  @include break-max($breakpoint-xs) {
    padding: 0;
  }

  &.hidden {
    display: none;
  }
}


.admin__icon-btn {
  align-items: center;
  transition: all 0.3s;
  &:hover {
    color: $color-red;
  }
  &:active {
    color: $color-brand-3;
  }

  &_with-hover {
    .admin__icon {
      &:hover {
        background: $color-brand-opaque-30;
      }
      &:active {
        background: $color-brand-opaque-20;
      }
    }
  }

  &_gray {
    color: $color-brand-3;
    font-size: 14px;
    .admin__icon {
      margin-right: 10px;
    }
    &:hover {
      .admin__icon {
        background: $color-brand-opaque-30;
      }
    }
    &:active {
      .admin__icon {
        background: $color-brand-opaque-20;
      }
    }
  }

}

.admin__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background: $color-brand-opaque-10;
  transition: all 0.3s;
  &_delete {
    margin-right: 12px;
  }
  &_sm {
    width: 28px;
    height: 28px;
  }
  &_square {
    width: 50px;
    height: 50px;
    border-radius: 0;
  }
  &_eye {
    fill: $color-brand-opaque-40;
  }
  &.without-border {
    width: auto;
    height: auto;
    border: none;
  }
  .icon {
    width: 15px;
    height: 20px;
  }
  .icon_cross {
    width: 11px;
    height: 8px;
  }
  .icon_plus {
    width: 8px;
    height: 8px;
  }
}

.admin__upload-text {
  margin-right: 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  width: auto;
  display: inline-block;
  vertical-align: text-bottom;


  @include break-max($breakpoint-md) {
    max-width: 120px;
  }
}

.admin__boxes {
  margin-left: auto;

  @include break-max($breakpoint-sm) {
    margin-left: 25px;
    max-width: 100%;
  }
  @include break-max($breakpoint-xs) {
    margin-left: 0;
  }
}

.admin__box {
  display: flex;
  width: 1220px;
  max-width: 100%;
  margin-top: 60px;
  padding: 50px 50px;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 5px;
  @include break-max($breakpoint-md) {
    padding: 45px 45px;
    margin-top: 40px;
    width: 980px;
  }
  @include break-max($breakpoint-sm) {
    padding: 25px 30px;
    margin-top: 30px;
    width: 100%;
    

  }
  @include break-max($breakpoint-xs) {
    padding: 20px 15px 25px;
    margin-top: 15px;
    width: 100%;
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.admin__h3 {
  margin-bottom: 30px;

  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
  }
}

.admin__label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: $color-brand-3;

  .info {
    margin-left: 12px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 15px;
    line-height: 20px;
  }
}

.admin__input,
.admin__textarea {
  font-family: inherit;
  font-size: 14px;
  padding: 16.5px 15px;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 4px;
}

.admin__row {
  display: flex;
  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }
}

.admin__input-flex {
  display: flex;

  .admin__input {
    flex-grow: 1;
  }
}

.admin__map-btn {
  font-family: inherit;
  margin-left: 15px;
  background: $color-brand-3;
  color: $color-white;
  padding: 16px 20px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.admin__halfbox {
  width: 50%;
  @include break-max($breakpoint-sm) {
    width: 100%;
  }

  &:first-child {
    padding-right: 60px;
    border-right: 1px solid $color-brand-opaque-20;

    @include break-max($breakpoint-md) {
      padding-right: 30px;
    }

    @include break-max($breakpoint-sm) {
      padding-right: 0;
      border-right: none;
    }
    
  }

  &:last-child {
    padding-left: 60px;

    @include break-max($breakpoint-md) {
      padding-left: 30px;
    }

    @include break-max($breakpoint-xs) {
      padding-left: 0;
    }
  }

  &_single:nth-child(n) {
    padding-left: 0;
    padding-right: 0;

    margin-top: 35px;

    flex-direction: column;

    .admin__input-block:first-child {
      margin-bottom: 15px;
    }
  }
}

.admin__input-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
  }

  &_sm {
    max-width: 780px;
    @include break-max($breakpoint-sm) {
      max-width: 100%;
    }
  }

  &_xs {
    max-width: 345px;
    @include break-max($breakpoint-sm) {
      max-width: 100%;
    }
  }

  &:last-child,
  &:last-of-type {
    margin-bottom: 0;
  }

  &_socials {
    position: relative;
    flex-direction: row;
    overflow: hidden;

    border: 1px solid $color-brand-opaque-20;
    height: 52px;
    border-radius: 4px;
    .admin__input {
      padding-right: 55px;
      width: 100%;
      border: none;
      border-radius: 0;
    }
    .admin__icon-btn {
      transition: all 0.9s;
      position: absolute;
      right: -200%;
    }

    &:hover .admin__icon-btn,
    .admin__input:focus + .admin__icon-btn {
      right: 0;
    }
  }
}

.admin__upload-block {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  padding: 18px 35px;
  background: $color-brand-opaque-8;
  border-radius: 5px;

  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }

  &_inside {
    padding: 0;
    padding-top: 12px;
    margin-bottom: 0;
    background: none;
    border-radius: 0;
    .admin__upload-img {
      margin-right: 10px;
    }
  }
}

.admin__upload {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @include break-max($breakpoint-md) {
    flex-direction: column;
  }

  @include break-max($breakpoint-sm) {
    flex-direction: column;
    align-items: center;
  }
}

.admin__upload-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 25px;
  border: 5px solid $color-white;
  border-radius: 50%;

  @include break-max($breakpoint-sm) {
    margin-right: 0;
    margin-bottom: 10px;
  }

  &_no-border {
    border: none;
  }
}

.admin__upload-label {
  background: none;
  font-size: 14px;
  color: $color-brand-3;

  @include break-max($breakpoint-sm) {
    margin-bottom: 15px;
  }
}

.admin__socials-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @include break-max($breakpoint-xsm) {
    flex-direction: column;
  }

  li {
    // width: 258px;
    margin-bottom: 10px;
    width: 100%;
    &:nth-child(even) {
      margin-left: 0;
    }
    // &:nth-child(even) {
    //   margin-left: auto;
    // }

    // @include break-max($breakpoint-md) {
    //   width: 219px;
    // }
    // @include break-max($breakpoint-sm) {
    //   width: 195px;
    // }
    // @include break-max($breakpoint-xsm) {
    //   width: 100%;
    //   &:nth-child(even) {
    //     margin-left: 0;
    //   }
    // }
  }
}

.constructor__box {
  @include break-max($breakpoint-sm) {
    width: 100%;
  }
}

.admin__socials-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  background: #4D7198;
  flex-shrink: 0;

  &_ig {
    background-image: $color-ig;
  }
  &_vk {
    background: $color-vk;
  }
  &_fb {
    background: $color-fb;
  }
  &_tg {
    background: $color-tg;
  }
  &_wa {
    background: $color-wa;
  }
  &_vb {
    background: $color-viber;
  }

  .icon {
    width: 23px;
    height: 23px;
    fill: $color-white;
  }
}

.admin__socials-add {
  display: flex;
  align-items: center;
  height: 100%;

  @include break-max($breakpoint-xs) {
    margin-top: 5px;

    .admin__icon-btn_gray {
      font-size: 15px;
      line-height: 20px;
    }
  }
}

.admin__color-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  @include break-max($breakpoint-xs) {
    flex-wrap: wrap;
  }

  .admin__icon-btn {
    @include break-max($breakpoint-xsm) {
      display: none;
    }
  }
}

.admin__color-item {
  display: flex;
}

.admin__color-bar {
  width: 48px;
  height: 48px;
  margin-right: 10px;

  box-shadow: 0px 10px 40px rgba(148, 134, 204, 0.15);

  border-radius: 4px;
  background: rgb(98,184,231);

  cursor: pointer;

  @include break-max($breakpoint-md) {
    width: 45px;
    height: 45px;
  }


  &_green {
    background: linear-gradient(135deg, $c-green 50%, $c-black-1 50%);
  }

  &_blue {
    background: linear-gradient(135deg, $c-blue 50%, $c-black-2 50%);
  }

  &_yellow {
    background: linear-gradient(135deg, $c-yellow 50%, $c-black-3 50%);
  }

  &_red {
    background: linear-gradient(135deg, $c-red 50%, $c-black-4 50%);
  }

  &_gray {
    background: linear-gradient(135deg, $c-gray 50%, $c-black-5 50%);
  }

  &_asphalt {
    background: linear-gradient(135deg, $c-asphalt 50%, $c-black-6 50%);
  }

  &_purple {
    background: linear-gradient(135deg, $c-purple 50%, $c-black-7 50%);
  }

  &_burgundy {
    background: linear-gradient(135deg, $c-burgundy 50%, $c-black-8 50%);
  }

  &_nightsky {
    background: linear-gradient(135deg, $c-nightsky 50%, $c-black-9 50%);
  }

  &_turquoise {
    background: linear-gradient(135deg, $c-turquoise 50%, $c-black-10 50%);
  }

  &_light {
    background: $color-white;
    border: 1px solid $color-brand-opaque-20;
  }
  &_dark {
    background: $color-brand-1;
  }
}

.admin__radio-box {
  position: relative;
  display: flex;
  align-items: center;
  .admin__radio-marker {
    display: flex;
    width: 18px;
    height: 18px;
    margin-right: 12px;
    border-radius: 50%;
    background: $color-white;
    border: 1px solid $color-brand-opaque-30;
  }

}

.admin__radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}


.admin__radio:checked ~ .admin__radio-marker::before {
  content: '';
  width: 10px;
  height: 10px;
  margin: auto;
  border-radius: 50%;
  background: $color-brand-3;
}

.admin__theme-list {
  display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 25px;

  @include break-max($breakpoint-sm) {
    grid-template-columns: 1fr;
  }

  @include break-max($breakpoint-xs) {
    align-items: flex-start;
  }
}

.admin__theme-item {
  margin-right: 28px;
  &:last-child {
    margin-right: 0;
  }

  @include break-max($breakpoint-xs) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-bottom: 25px;
    margin-right: 0;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.admin__theme-block {
  position: relative;
  width: 353px;
  display: flex;
  max-width: 100%;
  margin-bottom: 15px;
  box-shadow: 0px 10px 40px rgba(148, 134, 204, 0.15);
  border-radius: 5px;

  &_dark {
    background: #151622;
  }

  @include break-max($breakpoint-md) {
    width: 287px;
  }

  @include break-max($breakpoint-sm) {
    width: 263px;
  }


  @include break-max($breakpoint-xs) {
    width: 262px;
    height: 153px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.admin__theme-manager {
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin__theme-avatar {
  width: 175px;
  height: 175px;
  border-radius: 50%;
  object-fit: cover;
  background: $color-white;

  @include break-max($breakpoint-md) {
    width: 140px;
    height: 140px;
  }

  @include break-max($breakpoint-sm) {
    width: 120px;
    height: 120px;
  }

  @include break-max($breakpoint-xsm) {
    width: 74px;
    height: 74px;
  }

  @include break-max($breakpoint-xs) {
    width: 94px;
    height: 94px;
  }

  &_border_square {
    border-radius: 16px;
  }
}

.admin__theme-info {
  margin-top: -20px;
  padding: 8px 10px;
  text-align: center;

  .admin__theme-name {
    margin-bottom: 5px;
    color: $color-white;
    font-size: 14px;
    font-weight: bold;
    @include break-max($breakpoint-md) {
      font-size: 12px;
    }
    @include break-max($breakpoint-xsm) {
      font-size: 8px;
    }
  }

  .admin__theme-position {
    color: $color-white;
    font-size: 10px;
    @include break-max($breakpoint-md) {
      font-size: 8px;
    }
    @include break-max($breakpoint-xsm) {
      font-size: 5px;
    }
  }

  &_bg-white {
    background: $color-white;
    box-shadow: 0px 10px 40px rgba(148, 134, 204, 0.15);
    border-radius: 10px;
    .admin__theme-name {
      color: $color-brand-2;
    }
    .admin__theme-position {
      color: $color-brand-3;
    }
  }
}

.admin__theme-label {
  display: flex;
  justify-content: center;
  width: 120px;
  height: 50px;
  align-items: center;
  margin-right: 25px;

  font-size: 14px;
  color: $color-brand-2;

  border-radius: 5px;
  //background: $color-brand-opaque-10;
  transition: background 0.3s;

  @include break-max($breakpoint-xs) {
    height: 40px;
  }

  &:hover {
    background: $color-brand-opaque-30;
  }
  &:active {
    background: $color-brand-opaque-20;
  }
}

.admin__radio:checked + .admin__theme-label {
  background: $color-brand-3;
  color: $color-white;
}

.admin__theme-controls {
  display: flex;
  align-items: center;
  @include break-max($breakpoint-sm) {
    justify-content: flex-start;
    margin-bottom: 25px;
  }
}

.admin__input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  &_mt {
    margin-top: 30px;
  }

  &_flex_column {
    flex-direction: column;
  }
  

  @include break-max($breakpoint-sm) {
    flex-direction: column;
    margin-bottom: 0;
  }

  .admin__input-block {
    width: 48%;
    margin-bottom: 0;

    @include break-max($breakpoint-sm) {
      width: 100%;
      margin-bottom: 15px;
      &_with-anchor {
        margin-bottom: 0;
      }
    }


      &_lg {
        width: 665px;
        max-width: 100%;
      }

  }

  &_flex-end {
    .admin__input-block {
      justify-content: flex-end;
    }
  }
}


.admin__input-row_sm_column {
    @include break-max($breakpoint-sm) {
      flex-direction: column;
      .admin__input-block {
        width: 100%;
        margin-bottom: 15px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

.admin__cities-row{
  justify-content: flex-start;
  .admin__radio-box + .admin__radio-box{
    margin-left: 20px;
    @include break-max($breakpoint-sm) {
      margin-top: 15px;
      margin-left: 0;
    }
  }


}
.admin__checkboxes {
  display: flex;
  margin-top: 30px;

  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }
}

.admin__checkboxes-column {
  width: 50%;
  padding-right: 20px;

  @include break-max($breakpoint-sm) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }

  &:last-child {
    padding-right: 0;
    @include break-max($breakpoint-sm) {
      margin-bottom: 0;
    }
  }

  .checkbox {
    margin-bottom: 15px;
    align-items: flex-start;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .checkbox__label {
    padding-right: 15px;
  }
}

.admin__add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  margin-top: 30px;

  background: $color-white;
  color: $color-brand-2;
  border: 2px solid $color-red;
  border-radius: 100px;

  font-size: 14px;
  font-weight: bold;
  line-height: 1;

  transition: all 0.3s;

  &:hover, &:focus {
    background: $color-red;
    color: $color-white;
  }

  &:active {
    border-color: $color-red-hover;
    background: $color-red-hover;
  }
}

.admin__socials {
  margin-top: 10px;

  @include break-max($breakpoint-xs) {
    flex-wrap: wrap;
    justify-content: flex-start;

    li {
      margin-bottom: 10px;
    }
  }

}

.admin__socials .admin__social-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $color-brand-opaque-50;
  cursor: pointer;

  .icon {
    fill: $color-white;
  }
}

.admin__social-input {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.admin__social-input:checked + .admin__social-label {
  &_ig {
    background-image: $color-ig;
  }
  &_vk {
    background: $color-vk;
  }
  &_fb {
    background: $color-fb;
  }
  &_tg {
    background: $color-tg;
  }
  &_wa {
    background: $color-wa;
  }
  &_vb {
    background: $color-viber;
  }
}

.admin__settings-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.admin__status {
  margin-left: 35px;
  border-radius: 4px;
  padding: 6px 14px;
  &:nth-of-type(2) {
    margin-left: 10px; //for test
  }
  @include break-max($breakpoint-xs) {
    margin-top: 10px;
    margin-left: 0;
    &:nth-of-type(2) {
      margin-top: 10px;
      margin-left: 0; //for test
    }
  }
}

.admin__status_published {
  color: $green;
  background: transparentize($green, 0.85);
}

.admin__status_not-published {
  color: $color-red;
  background: transparentize($color-red, 0.85);
}

.admin__last-update-date {
  margin-left: 35px;
  color: $color-brand-3;

  @include break-max($breakpoint-xsm) {
    margin-left: 15px;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 15px;
    margin-left: 0;
  }
}

.admin__domen-input {
  display: flex;
  align-items: baseline;
  input {
    width: 100%;
    margin: 0 8px;
    @include break-max($breakpoint-xs) {
      margin: 0;
    }
  }

  span {
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }
}

.admin__submit {
  margin-top: 30px;

  @include break-max($breakpoint-xs) {
    border-radius: 50em;
    width: 100%;
  }
}

.admin__domen-result {
  margin-top: 5px;
  padding-left: 60px;
  @include break-max($breakpoint-xsm) {
    padding-left: 0;
  }
}

.admin__domen-result_enabled {
  color: $green;
}

.admin__domen-result_disabled {
  color: $color-red;
}

.admin__edit-btn {
  display: flex;
  width: 28px;
  height: 28px;
  margin-left: auto;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s;
  background: $color-brand-opaque-10 url('../../../assets/images/constructor/edit.svg') no-repeat center;
  flex-shrink: 0;
  &:hover, &:focus {
    background-color: $color-brand-opaque-30;
  }
  &:active {
    background-color: $color-brand-opaque-20;
  }
}

.admin__menu {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  background: $color-white;
  z-index: 10;
  box-shadow: 0px 10px 40px rgba(148, 134, 204, 0.15);
  visibility: visible;
  opacity: 1;
  transition: all 0.3s;

  @include break-max($breakpoint-xs) {
    display: block;
    top: 0;
  }

  @include break-max($breakpoint-sm) {
    top: 70px;
  }

  @include break-max($breakpoint-xs) {
    height: 50px;

    &.is-mobile {
      top: 0px;
    }

    .constructor__container {
      overflow: visible !important;
    }
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.admin__menu {
  .constructor__container {
    @include break-max($breakpoint-xs) {
      overflow: scroll;
    }
  }
}

.admin__form {
  width: 100%;
}

.admin__menu-btns {
  @include break-max($breakpoint-md) {
    display: flex;

    .icon__wrap {
      align-items: center;
      background: $color-brand-opaque-10;
      border-radius: 50em;
      justify-content: center;
      display: flex;
      margin-right: 10px;
      height: 32px;
      width: 32px;
    }

    .admin__menu-btn_transparent {
      align-items: center;
      background: none;
      border: none;
      color: $color-brand-3;
      display: flex;
      font-weight: 300;
      margin-right: 30px;
      justify-content: space-between;
      padding: 0;
      height: 50px;

      &:hover {
        background: none;
        color: inherit;
      }
    }
  }

  @include break-max($breakpoint-md) {
    .admin__menu-btn_transparent {
      margin-right: 25px;
    }
  }
}


.admin__menu.for-mobile {
  background: linear-gradient(rgba($color-white, 0), rgba($color-white, 0.5) 10%, $color-white 40%);
  height: 70px;
  bottom: 0;
  top: auto;
  padding: 12px 10px;

  .admin__menu-btn_transparent {
    background: $color-white;
  }

  .admin__menu-btns {
    display: flex;
    justify-content: space-between;
  }

  .admin__menu-btn {
    border-radius: 50em;
  }

  .button {
    border-radius: 5px;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
    width: 100%;
  }
}

.admin__menu-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;

  @include break-max($breakpoint-xs) {
    height: 50px;
    overflow-x: scroll;
  }
}

.admin__menu-list {
  display: flex;

  @include break-max($breakpoint-xs) {
    height: 100%;
  }
}

.admin__menu-item {
  margin-right: 70px;
  &:last-child {
    margin-right: 0;
  }

  @include break-max($breakpoint-md) {
    margin-right: 65px;
  }

  @include break-max($breakpoint-sm) {
    margin-right: 30px;
  }

  @include break-max($breakpoint-xs) {
    align-items: center;
    display: flex;
    margin-right: 30px;
    height: 100%;

    &:last-child {
      margin-right: 30px;
    }

    &.is-active {
      box-shadow: inset 0 -2px 0 0 $color-red;
    }
  }
}

.admin__menu-anchor {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 0.3px;
  transition: all 0.3s;
  cursor: pointer;

  svg {
    transition: stroke 0.3s;
  }

  &:hover {
    color: $color-red;
    svg {
      stroke: $color-red;
    }
  }
  &:active {
    color: transparentize($color-red, 0.15);
    svg {
      stroke: transparentize($color-red, 0.15);
    }
  }

  @include break-max($breakpoint-sm) {
    font-size: 11px;
    line-height: 16px;
    white-space: nowrap;
  }
}

.admin__menu-btn {
  padding: 16px 32px;
  margin-right: 10px;
  border: 1px solid transparent;
  &:last-child {
    margin-right: 0;
  }
  &_transparent {
    display: inline-flex;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    color: $color-brand-3;
    border: 1px solid $color-brand-opaque-20;
    border-radius: 5px;
    transition: all 0.3s;
    &:hover {
      background: $color-brand-3;
      color: $color-white;
    }
    &:active {
      background: transparentize($color-brand-3, 0.15);
    }
  }
}

.admin__remove-review {
  display: inline-flex;
  color: $color-red;
  margin: 15px 0;
  &:hover {
    color: rgba($color-red, 0.8);
  }
}

.admin__color-choice {
  @include break-max($breakpoint-xs) {
    margin-top: 20px;
  }
}
.admin__h5 {
  @include break-max($breakpoint-xs) {
    font-size: 15px;
    line-height: 20px;
  }
}
.admin__reviews + .admin__reviews {
  @include break-max($breakpoint-xs) {
    padding-bottom: 30px;
  }
}

.admin__theme-choice {
  @include break-max($breakpoint-xs) {
    padding-bottom: 5px;
  }
}

.admin__menu-list-wrap {
  @include break-max($breakpoint-xsm) {
    transform: translateX(-110%);
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
    background: #fff;

    &.is-active {
      left: 0;
      transform: translateX(0);
      top: 70px;
    }

    .admin__menu-list {
      display: flex;
      flex-direction: column;
    }

    .admin__menu-item {
      align-items: center;
      padding: 15px 20px;
    }
  }

  @include break-max($breakpoint-xs) {
    height: 100%;
    position: static;
    transform: none;

    .admin__menu-list {
      flex-direction: row;
    }

    .admin__menu-item {
      padding: 0;
    }
  }
}

.admin__menu-list-shadow {
  display: none;

  @include break-max($breakpoint-xsm) {
    display: block;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(143, 149, 172, 0.7);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.15s ease-in-out;

    &.is-active {
      opacity: 1;
      pointer-events: all;
    }
  }

  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.admin__menu-list-burger {
  display: none;

  @include break-max($breakpoint-xsm) {
    cursor: pointer;
    display: block;

    .icon {
      stroke: $color-brand-2;
      font-size: 19px;
    }
  }


  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.admin__header-btns {
  display: flex;
  @include break-max($breakpoint-xs) {
    width: 100%;
    flex-wrap: wrap;
    gap: 15px;
  }
}

.admin__input-notice {
  font-size: 14px;
  line-height: 17px;
  color: $color-brand-3;
  margin-top: 12px;
}


.landings-dropdown{
  width: 220px;

  .custom-select__selected{
    background-color: transparent;
    transition: padding 0.3s;
  }
}
.flats__row-table-expanded-title{
  padding-right: 5px;
}

.landings-dropdown:not(.is-showing) .custom-select__selected {
  padding-left: 0;
}

.orders__content.for-agents {
  @include break-max($breakpoint-xs) {
    margin-top: 20px;
  }
}


.domen {
  position: relative;
  width: 640px;
  max-width: 100%;

  &.hide {
    display: none;
  }

  .text {
    font-size: 14px;
    line-height: 18px;
  }

  .admin__input {
    width: 425px;
    max-width: 100%;
  }

  .link_color_brand-3 {
    color: $color-brand-3;
    &:hover, &:focus {
      color: $color-red;
    }
    &:active {
      color: $color-red-active;
    }
  }
}

.domens {
  border: none;

  .admin__list-tab {
    border: 1px solid $color-brand-opaque-20;
    margin-bottom: -1px;
    &:not(.active) {
      color: $color-brand-3;
    }
    &:first-of-type {
      border-radius: 4px 0px 0px 0px;
    }
    &:nth-of-type(2) {
      border-radius: 0px 4px 0px 0px;
    }
    @include break-max($breakpoint-xs) {
      border: none;
    }
  }

  .admin__list-container_lg .admin__list-tab {
    width: 252px;
    &:nth-of-type(2) {
      border-radius: 0px 0px 0px 0px;
    }
    &:nth-of-type(3) {
      border-radius: 0px 4px 0px 0px;
    }

    @include break-max($breakpoint-xs) {
      width: 150px;
      border: none;
    }
  }
}

.domens__btns {
  margin-top: 30px;
  display: flex;
  @include break-max($breakpoint-xs) {
    flex-direction: column;
    align-items: center;
  }

  .button {
    margin: 0;
    width: auto;
    min-width: 196px;
    font-weight: normal;
    display: flex;
    text-align: center;
    justify-content: center;
    @include break-max($breakpoint-xs) {
      width: 280px;
    }
  }
  .button:last-child {
    margin-left: 15px;
    @include break-max($breakpoint-xs) {
      margin-left: 0;
      margin-top: 15px;
    }
  }
}

.domens .admin__list-box {
  border: 1px solid $color-brand-opaque-20;
  border-radius: 5px;
  @include break-max($breakpoint-xs) {
    border: none;
  }
}

.domen__title {
  margin-top: 25px;
  margin-bottom: 20px;
}

.domen__close-btn {
  position: absolute;
  right: 15px;
  top: 15px;
}

.domen__text {
  margin-top: 20px;
}

.domen__table {
  width: 100%;
  margin-top: 18px;
  font-size: 14px;
  line-height: 18px;

  border-radius: 5px;
  border-spacing: 0;

  &_sm {
    width: 720px;
    max-width: 100%;
    @include break-max($breakpoint-sm) {
      width: 100%;
    }
  }
  tr:first-child {
    height: 45px;
    vertical-align: middle;
    background: $color-brand-3;
    color: $color-white;
  }

  tr {
    height: 62px;
  }

  th {
    font-weight: normal;
    text-align: left;
  }

  th:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  th:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  th, td {
    padding: 0 25px;
  }

  td {
    background: $color-brand-opaque-8;
  }

  tr:not(:first-child) td {
    border-top: 1px solid $color-brand-opaque-20;

    &:first-child {
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-bottom-right-radius: 5px;
    }
  }

  td.bold {
    font-weight: bold;
  }

  @include break-max($breakpoint-xs) {
    display: flex;
    align-items: center;
    flex-direction: column;
    tr {
      width: 100%;
      display: flex;
      align-items: center;
    }

    td, th {
      padding: 0 10px;
      height: 100%;
      display: flex;
      align-items: center;
      flex-grow: 1;
      min-width: 170px;

      &:nth-child(2) {
        min-width: 140px;
      }
    }
  }

}

.domen__sign {
  display: flex;
  margin-top: 20px;

  &_center {
    align-items: center;
  }

  .info {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    margin-right: 10px;
    background: $color-white;
    border: 1px solid $color-red;
    .icon {
      fill: $color-red;
    }
  }

  p {
    padding-top: 4px;
    font-size: 14px;
    line-height: 18px;
  }

  .link {
    margin-left: 5px;
  }
}

.domen__save-btn {
  display: block;
  width: 196px;
  text-align: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.domen-toggler {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 35px;
  background: $color-brand-opaque-8;
  border-radius: 4px;
  border-bottom: none;
  margin-top: 20px;
  margin-bottom: 40px;

  &.hide {
    display: none;
  }
}

.domen-toggler__title {
  margin-right: 38px;
  font-weight: bold;
}

.domen-toggler__btn {
  width: 238px;
  font-weight: normal;
  justify-content: center;
  text-align: center;
}

.admin__input-block .form__item {
  margin: 0;
  height: 52px;
  width: 100%;
}

.admin__input-block .form__drop-btn {
  margin: 0;
  font-family: inherit;
  font-size: 14px;
  padding: 16.5px 15px;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 4px;

  &:hover {
    box-shadow: none;
  }
}

.admin__input-block .form__drop .dropdown__block {
  width: 100%;
}

.admin__input-block .mobile-filters__title {
  @include break-max($breakpoint-xs) {
    display: none;
  }
}


.admin__aside {
  margin-top: 15px;
  width: 220px;
  position: sticky;
  z-index: 2; // was 3
  top: 100px;


  @include break-max($breakpoint-md) {
    width: 180px;
  }

  @include break-max($breakpoint-sm) {
    width: 150px;
  }

  @include break-max($breakpoint-xs) {
    display: none;
  }

  .collapse {
    width: 100%;
    
  }

  .collapse__title {
    font-size: 12px;
    padding-left: 0;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: 0.3px;
    transition: all 0.3s;
    cursor: pointer;
    
    svg {
      transition: stroke 0.3s;
    }
  
    &:hover, & + .collapse__content a:hover {
      color: $color-red;
      svg {
        stroke: $color-red;
      }
    }
    &:active, & + .collapse__content a:active {
      color: transparentize($color-red, 0.15);
      svg {
        stroke: transparentize($color-red, 0.15);
      }
    }
  
    @include break-max($breakpoint-sm) {
      font-size: 11px;
      line-height: 16px;
      white-space: nowrap;
    }
  }

  .collapse__content {
    padding: 25px 0 0;

    ul {
      padding-left: 20px;
    }

    li:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  .collapse__content a {
      transition: all 0.3s;
  }
}

.admin__nav-item {
  margin-bottom: 10px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
}

.admin__nav-item_with-border-bot {
  border-bottom: 1px solid $color-brand-opaque-20;
  margin-bottom: 25px;
}

.admin__nav-item_disabled {
  cursor: default;
  color: $color-brand-3;
  .collapse__title {
    cursor: default;
  }
  .collapse__title:hover , .collapse__title:hover svg {
    color: $color-brand-3;
    stroke: none;
  }
  .info {
    font-weight: normal !important;
    text-transform: none;
    font-size: 14px;
    line-height: 18px;
  }
}

.admin__aside-btns {

  .button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.admin__banner {
  width: 100%;
  height: 50px;
  justify-content: center;
  background: $gray-light;
  align-items: center;
  display: flex;
  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.admin__popover {
  .popover__block {
    position: fixed;
    right: 15px;
    top: 85%;

    @include break-max($breakpoint-md) {
      top: 75%;
    }
  }
  .clip__tooltip .popover__inner {
    width: 360px;
    max-width: 100%;
    background-color: $gray-light;
    display: flex;
    align-items: center;
  }
  
  .clip__tooltip-text {
    color: $color-brand-1;
  }

  .popover__flex {
    display: flex;
    align-items: flex-start;
    img {
      margin-top: 5px;
      margin-right: 25px;
    }

    &_center {
      align-items: center;
      img {
        margin-top: 0;
      }
    }

  }

  .admin__menu-btn {
    height: 36px;
    margin-top: 15px;
  }

  &_dark {
    .popover__block {
      top: 65%;
    }
    .clip__tooltip .popover__inner {
      .clip__tooltip-text {
        color: $color-white;
      }
      background: $color-brand-1 url('../../../assets/images/constructor/darkmode.svg') no-repeat center;
    }
  }
}