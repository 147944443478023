@import "../../styles/variables";
@import "../../styles/mixins";

.motivation-filter-grig {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;

  &__item {
    flex: 1;
    margin-right: 20px;

    .form__drop-content {
      min-width: 230px;
    }

    .form__item {
      margin-left: 0;
    }
  }
  &__item:first-child {
    flex: 0 0 400px
  }
  &__item:first-child {
    background-color: transparent;
  }
  &__item:last-child {
    background-color: transparent;


  }
}

.item {

}

@media (max-width: 768px) {
  .motivation-filter-grig {
    justify-content: flex-start;

    &__item {
      flex: 0 0 calc(33.333% - 20px);
    }

    &__item:last-child {
      display: none;
    }
  }

}

@media (max-width: 400px) {
  .motivation-filter-grig__item {
    flex: 0 0 calc(50% - 20px);
  }
}
.motivation__pretable-filters {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.motivation__dwn {
  //display: flex;
  cursor: pointer;
  //justify-content: flex-end;
}
.motivation__dwn a {
  display: flex;
  align-items: center;
  transition-duration: .3s;
  color: black;
  white-space: nowrap;
}
.motivation__dwn a svg {
  margin-right: 5px;
  flex-shrink: 0;
}

.motivation__dwn a:hover {
  color: #FF2D23;

}
.motivation__dwn a svg path {
  transition-duration: .3s;
}
.motivation__dwn a:hover svg path {
  stroke: #FF2D23;
}
.mini-filter__col_small {
  width: 90px;
}

.motivation-table {
  margin-bottom: 100px;
  .ant-table-container table > thead > tr:first-child {

  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    //width: 120px;
    border-radius: 5px 0 0 5px;
  }
  .ant-table-container table > thead > tr th {
    padding-top: 11px;
    padding-bottom: 12px;
    //min-width: 200px;
  }
  .ant-table-container table > thead > tr th:nth-child(2) {
    //min-width: 250px;
  }
  .ant-table-container table > thead > tr th:nth-child(4) {
    //min-width: 150px;
  }

  .ant-table-container table > thead > tr th {
    //width: calc((100% - 220px) / 5);
    //padding: 0;
    width: 16.666%;
  }
  .ant-table-container table > thead > tr:first-child th {
    border-right: 1px solid #565865;
  }

  .ant-table-container table > thead > tr th:first-child {
    //width: 220px;
  }



  .ant-table-container table > thead > tr:first-child th:last-child {
    border-right: none;
    border-radius: 0 5px 5px 0;
  }

  .ant-table-tbody > tr > td {
    vertical-align: top;
    padding: 0;
  }

  .ant-table-tbody > tr > td:nth-child(3) {
    //max-width: 300px;
  }
  .ant-table-tbody > tr > td:nth-child(4) {
    //max-width: 300px;
  }

  &__builder-title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__builder-link {
    color: #8790E5;
    transition-duration: .3s;
    cursor: pointer;
  }

  &__builder-link:hover {
    color: #727bcb;
  }

  &__object {
    padding-top: 4px;
    padding-bottom: 4px;
    display: block;
    transition-duration: .3s;
    color: black;
  }

  &__object:first-child {
    padding-top: 0;
  }

  &__object:hover {
    color: #727bcb;
  }

  &__reward {
    font-weight: bold;
  }

  &__reward:first-child {
    padding-top: 0;
  }

  &__complex-cell {
    border-bottom: 1px solid #DFE1E9;
    display: flex;

    &:last-child {
      border-bottom: none;
    }
  }

  &__block-title {
    font-weight: bold;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;

    a {
      color: black;
      //transition-duration: .3s;
      &:hover {
        border-bottom: 1px solid;
      }
    }

  }

  &__comment-cell {
    word-break: break-word;
  }

  &__td {
    width: calc((100% + 20%) / 6);
    vertical-align: top;

    div {
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 10px;
      padding-right: 10px;
      border-bottom: 1px solid #DFE1E9;
    }
    div:last-child {
      border-bottom: none;
    }

    div:nth-of-type(odd) {
    }
    div:nth-of-type(even) {
    }
  }

  &__fst-cell {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__twoRow-cell {
    border-bottom: 1px solid #DFE1E9;
    vertical-align: top;

    table:last-child {
      td {
        border-bottom: none;
      }
    }
  }
  &__twoRow-row:last-child .motivation-table__twoRow-cell {
    border-bottom: none;
  }

  &__td-second {
    width: calc(100% / 4);
    vertical-align: top;
    border-bottom: 1px solid #DFE1E9;
    padding-top: 10px;
    padding-bottom: 10px;

    padding-left: 10px;
    padding-right: 10px;
  }
}

.motivation-filters {
  display: inline-flex;
  gap: 20px;
  margin-bottom: 20px;

  .form__item {
    //min-width: 180px;
    min-width: auto;
  }


  .rdrCalendarWrapper.rdrDateRangeWrapper {
    padding-bottom: 35px;
  }

  .rdrDateDisplayItem input {
    pointer-events: none;
    color: black;
  }

  .form__drop-name {
    max-width: 100%;
    padding-right: 12px;

    span {
      margin-left: 5px;
    }
  }
}
.motivation-date-inp .input__control {
  cursor: pointer;
  background-color: #f6f7f8;
  transition-duration: .3s;
  caret-color: transparent;
  box-shadow: none;

  &:hover {
    background-color: #ECEDF0;
  }
  &:focus {
    outline: none;
  }


}

.motivation-date-inp {
  position: relative;
  .section-search__icon {
    right: 7px;
    top: 11px;
    transition-duration: .3s;
    border-radius: 50%;
    &:hover {
      background-color: #ECEDF0;
    }
  }

  input.input__control {
    padding: 0;
    background: transparent;
  }
  div.input__control {
    .icon {
      width: 9px;
      stroke: #1e2030;
      fill: none;
      transform: rotate(0);
      position: absolute;
      top: 18px;
      right: 16px;
      transition: transform 0.15s ease-in-out;
      pointer-events: none;
    }
  }
  div.input__control.react-datepicker-ignore-onclickoutside {
    .icon {
      transform: rotate(180deg);
    }
  }
}


.motivation__terms-interaction {
  max-width: 830px;
  margin: 0 auto;

  li {
    list-style-type: none;
  }

  br {
    display: none;
  }
  h3 {
    text-align: center;
    font-size: 21px;
    font-weight: 400;
    margin-bottom: 25px;
  }

  table {
    border-collapse: collapse;
    margin-bottom: 80px;

    thead {
      th {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        padding-bottom: 10px;
      }
    }
    tbody {
      border: 1px solid #ccc;
      tr {
        td {
          padding-top: 15px;
          padding-bottom: 12px;
          vertical-align: top;
          padding-left: 12px;
          padding-right: 12px;
        }

        td:first-child {
          min-width: 300px;
        }

       td:nth-last-child(1):not(last-child):first-child {
         padding-top: 0;
       }


        td:nth-last-child(2) {
          font-weight: 500;
          padding-right: 50px;
          background-color: #F4F4F4;
          padding-left: 12px;
        }
      }
    }
  }
}

.sf__filter-item.disabled{
  filter: opacity(0.5);
  pointer-events: none;
}