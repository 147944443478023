.fill-1 {
  fill: $color-1;
}

.stroke-1 {
  stroke: $color-1;
}

.color-1 {
  color: $color-1;
}

.background-1 {
  background-color: $color-1;
}

.lp__phone {
  @include break-max($breakpoint-xs) {
    background: $color-1;
  }
}

.lp__phone-svg {
  fill: $color-1;
  @include break-max($breakpoint-xs) {
    fill: $color-white;
  }
}

.about__features {
  background: $color-1;
}

.about__features-list {
  background: $color-1;
}

.background__fill-main {
  fill: $color-1;
}

.background__fill-black {
  fill: $color-2;
}

.banner {
  background: $color-2;
}

.banner__img {
  path {
    fill: $color-1;
    stroke: $color-1;
  }
}

.card {
  .show__map svg {
    fill: $color-1;
  }
}

.banks {
  .banks__slider-value {
    color: $color-1;
  }
}

.consultation_dark {
  background: $color-2;
}

.infra {
  .checkbox__marker {
    background: $color-1;
  }
}

.promo__label {
  color: $color-1;
}

.promo__position {
  color: $color-1;
}

.property {

  .card__dates {
    color: $color-1;
  }

  .map-card__dates {
    color: $color-1;
  }

  .button_view_outline-with-icon-invert {
    color: $color-1;
    &:hover {
      color: $color-1;
    }

    .icon {
      fill: $color-1;
    }
  }

  .simplebar-scrollbar:before {
    background: $color-1;
  }

  .button_view_default {
    background: $color-1;
  }

  .property__btn:not(.property__btn_active) {
    background: $color-bg-light;
    color: $color-brand-2;
    // &:hover {
    //   color: $color-1;
    // }
  }
}

.types-tabs__subtabs {
  .property__btn:not(.property__btn_active) {
    color: $color-white;

    &:hover {
      background: rgba($color-3, 0.2);
      color: $color-white;
    }
  }
}

.block__title_underline {
  &::after {
    background: $color-1;
  }
}

.link {
  color: $color-white;
  &:hover {
    color: $color-1;
  }

  &:active {
    color: rgba($color-1, 0.5);
  }

}

.constructor__header {
  background: $color-2;
}

.inner__list-item::before {
  background: $color-2;
}

.constructor__socials {
  .icon {
    fill: $color-white;
    &:hover {
      fill: rgba($color-white, 0.9);
    }

    &:active {
      fill: rgba($color-white, 0.8);
    }

  }
}

.constructor__btn {
  background: $color-1;

  &:hover {
    background: darken($color-1, 10%);
  }

  &:active {
    background: darken($color-1, 20%);
  }

  &_transparent {
    background: transparent;
    color: $color-2;
    border: 1px solid $color-1;

    &:hover {
      background: $color-1;
    }
  }
}

.inner-form__submit {
  background: $color-2;
  color: $color-white;

  &:hover {
    background: darken($color-2, 10%);
  }

  &:active {
    background: darken($color-2, 20%);
  }
}

.footer {
  background: $color-2;
}

.footer__socials a {
  background: transparentize($color-1, 0.8);
  &:hover {
    background: transparentize($color-1, 0.9);
  }
}

.footer__toprealtors {
  border-top: 1px solid rgba($color-1, 0.2);
}

.header__list {
  a {
    &:hover {
      color: $color-1;
    }
  }
}

.header__phone {
  color: $color-white;
  &:hover {
    color: $color-1;
  }
}

.header__callback-btn {
  background: $color-1;
  &:hover {
    background: darken($color-1, 10%);
  }

  &:active {
    background: darken($color-1, 20%);
  }

  &_mob {
    @include break-max($breakpoint-xs) {
      background: $color-1;
    }
  }

}

.calculator-section {
  .button_view_default {
    @extend .constructor__btn;
  }
}

.header__nav {
  @include break-max($breakpoint-sm) {
    background: $color-3;
  }

  @include break-max($breakpoint-xs) {
    background: $color-white;
  }
}

.header__nav-toggler {
  background: $color-3;
}

.background_sphere {
  & ~ .promo {
    background: $color-1;

    .promo__btn {
      background: $color-2;

        &:hover {
          background: rgba($color-2, 0.9);
        }

        &:active {
          background: rgba($color-2, 0.8);
        }
    }
  }
}

// .background__pyramid {
//   & ~ .promo {
//     background: $color-1;

//     // .promo__btn {
//     //   background: $color-2;

//     //     &:hover {
//     //       background: rgba($color-2, 0.9);
//     //     }

//     //     &:active {
//     //       background: rgba($color-2, 0.8);
//     //     }
//     // }
//   }
// }

.constructor__socials_banner {
  .icon {
    fill: $color-white;
    &:hover,
    &:active {
      fill: $color-white;
    }
  }
}

.property {
  .label-item {
    color: $color-1;
  }

  .button_view_ghost,
  .button_view_outline-with-icon-invert {
    .icon {
      fill: $color-1;
    }
  }
}

.types-tabs {
  border: 1px solid $color-1;
}

.types-tabs__background {
  background: $color-1;
}

.types-tabs__item {
  &:hover {
    background: rgba($color-1, 0.2);
  }

  &:hover:not(.is-active) {
    .types-tabs__name {
      color: $color-1;
      transition: none;
    }

    path {
      fill: rgba($color-1, 0.2);
      stroke: $color-1;
      transition: none;
    }
  }

  &.is-active {
    &:active {
      .types-tabs__name {
        color: $color-1;
      }
    }

    path {
      fill: $color-1;
      stroke: $color-white;
    }
  }
}

.property__btn.constructor__btn.property__btn_active,
.typical__label {
  background: $color-1;
}

.typical__btn {
  background: none;
  border: 1px solid $color-1;

  &:hover {
    background: $color-1;
    color: $color-white;
  }
}

.lid {
  
}

.lid__fill-svg {
  fill: $color-1;
}

.consultation__map-icon {
  stroke: $color-1;
}

.consultation__map-info {
  background: $color-2;
}

.consultation__form .link {
  color: $color-1;
}

.constructor__social-btn_site {
  background: $color-2;
}

.video .simplebar-scrollbar:before {
  background: $color-1;
}

.swiper-pagination-bullet-active {
  background-color: $color-1;
}

.property .cards__item.cards__item_highlight {
  border: 2px solid $color-1;

  .obj-card__title::before {
    background-color: $color-1;
  }
}

.co__manager-btn,
.co__video-panel {
  background: $color-1;

  &:hover {
    background: darken($color-1, 10%);
  }

  &:active {
    background: darken($color-1, 20%);
  }
}

.co__manager {
  border: 1px solid $color-1;
}

.co__video-circle {
  fill: $color-1; 
 }

 .co__online-icon {
  fill: $color-1;
 }

 .mortgage__icon {
  fill: $color-1;
 }

 .co__on-map {
  color: $color-1;
  &:hover {
    color: darken($color-1, 10%);
  }

  &:active {
    color: darken($color-1, 20%);
  }
 }

 .co__on-map-icon {
  fill: $color-1;
 }

 #myMap, #dist-map-container {
  & [class*="-hint"] {
    background-color: $color-1;
    &::after {
      width: 0; 
      height: 0;
      border-top: 13px solid $color-1;
      border-left: 13px solid transparent;
      background-image: none;
    }
  }
 }

 .inner-form {
   background: $color-1;
 }

 .inner__header, .inner__section {
  border-color: $color-1;
 }

 .breadcrumbs a[href] {
  color: $color-1;
  &:hover {
    color: rgba($color-1, 0.9);
  }

  &:active {
    color: rgba($color-1, 0.8);
  }
 }

 .inner__main-color-bg {
   background-color: $color-1;
 }

 .video__h5 {
  color: $color-2;
 }

 .contact__icon {
   stroke: $color-1;
 }

 .contact__anchor[href] {
  &:hover, &:focus {
    color: $color-1;
  }

  &:active {
    color: darken($color-1, 10%);
  }
}

.contacts-socials__header {
  border-color: $color-1;
 }

 .contacts-socials__icon {
   background-color: $color-1;
 }

 
.contacts-socials__item {
  &:hover, &:focus {
    color: $color-1;
  }

  &:active {
    color: darken($color-1, 10%);
  }
}

.background__paint::after {
  background: $color-1;
}

.promo__paint-bg svg {
  fill: $color-2;
}

.background__pyramid svg.pyramid {
  fill: rgba($color-1, 0.7);
}

.background__pyramid::after {
  background: $color-1;
}

.background__pyramid .left-bg {
  background: $color-2;
}

.slider__input .noUi-handle {
  background-color: $color-1;
}

.stroke-color {
  stroke: $color-2;
}


.header__top-menu-drop {
  background-color: $color-2;
}

.header__socials {
  background-color: $color-2;
}

.lp__agreement {
  color: $color-1;
}

.lp .simplebar-scrollbar:before {
  background-color: $color-1;
}


// dark mode



.header__socials {
  background-color: $c-darkmode;
}

&.constructor_darkMode {

  background: $c-darkmode;
  color: $color-white;

  .header__top-menu-drop {
    background-color: $c-darkmode;
  }

  .banks__slider-nav {
    background: $c-darkmode;
    .icon-dark_stroke {
      stroke: $color-white;
    }
  }

  .lid {
    background: $color-brand-opaque-8;
  }

  .services__item-inner,
  .advantages__item-inner {
    @include break-max($breakpoint-xs) {
      background: $color-brand-opaque-8;
    }
  }

  .constructor__socials .icon {
    fill: $color-white;
  }

  .card .show__map {
    border-color: $color-1;
  }

  .banner {
    background-color: $color-brand-opaque-8;
  }

  .footer {
    border-top: 1px solid $color-brand-opaque-8;
  }

  .property .section_view_gray,
  .types-tabs,
  .card__body,
  .block,
  .reviews__cloud,
  .section_view_white,
  .cards__map-cards,
  .constructor__header,
  .background__pyramid .left-bg,
  .footer {
    background: $c-darkmode;
  }

  .types-tabs__item path {
    stroke: $color-white;
  }

  .property .property__btn:not(.property__btn_active) {
    background: $color-brand-opaque-10 !important;
    color: $color-white !important;
  }

  .promo__text,
  .card,
  .card__body,
  .constructor__btn_transparent,
  .consultation__form input,
  .consultation__form input::placeholder,
  .typical__btn,
  .card .complex__info-item-value,
  .video__h5 {
    color: $color-white;
  }

  .constructor__btn_transparent:not(:hover),
  .typical__btn:not(:hover) {
    color: $color-white !important;
  }

  .reviews__cloud::after {
    border-top-color: $c-darkmode;
  }

  .property .cards__item {
    border-color: $color-brand-opaque-30;
  }
  
  .block.tns-item {
    border: 1px solid $color-brand-opaque-30;
  }

  .calculator__item-wrapper {
    background: transparent;
    color: $color-white;
    border: 1px solid $color-brand-opaque-30;

    input {
      background: transparent;
      color: $color-white;
    }
  }

  .slider-nav__item {
    background: lighten($c-darkmode, 10%);
    svg {
      stroke: $color-white;
    }
  }

  .toggle-color {
    fill: $color-white;
  }

  .property .button_view_outline-with-icon-invert {
    background: $c-darkmode !important;
    color: $color-white !important;

    .icon {
      fill: $color-white !important;
    }
  }
  .background__fill-black {
    fill: $color-3;
  }

  .promo__label {
    color: $color-white;
  }

  .promo__sign {
    color: $color-white;
  }


}