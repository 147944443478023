@import "../../styles/variables";
@import "../../styles/mixins";

.school {

  .wrapper__center_start {

    @include break-max($breakpoint-sm) {
      width: 980px;
    }

    @include break-max($breakpoint-xsm) {
      width: 730px;
    }
  }

  .text {
    line-height: 21px;
  }

  .button {
    display: inline-flex;
    padding-left: 30px;
    padding-right: 30px;
  }

  .big-text {
    font-weight: 300;
    line-height: 30px;
    @include break-max($breakpoint-md) {
      line-height: 21px;
    }
  }

  .school__section {
    padding-top: 130px;
    @include break-max($breakpoint-md) {
      padding-top: 70px;
    }
    @include break-max($breakpoint-xsm) {
      padding-top: 60px;
    }
    @include break-max($breakpoint-xs) {
      padding-top: 50px;
    }
  }

  .h5 {
    font-size: 16px;
    line-height: 20px;
    text-transform: none;

    @include break-max($breakpoint-sm) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .red-pill {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 7px 32px;

    background-color: $color-red;
    border-radius: 100px;

    color: $color-white;
    text-transform: uppercase;
  }

  .counter {
    @extend .red-pill;
    padding-left: 23px;
    padding-right: 23px;
    font-size: 12px;
    font-weight: 700;
  }

  .promo {
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 134px);

    background: $color-brand-1;
    color: $color-white;

    padding-top: 90px;

    @include break-max($breakpoint-md) {
      padding-top: 60px;
    }

    @include break-max($breakpoint-sm) {
      padding-top: 40px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: rgba(30, 32, 48, 0.9);
      transition: all 0.3s;
      opacity: 0;
      @include break-max($breakpoint-sm) {
        display: none;
      }
    }

    &.promo_blackout::after {
      opacity: 1;
    }

  }

  .promo__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 210px;
    @include break-max($breakpoint-sm) {
      padding-bottom: 0;
    }
    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }

  }

  .promo__box {
    display: flex;
    flex-direction: column;
    width: 555px;
    max-width: 100%;
  }

  .promo__logo {
    display: flex;
    margin-bottom: 30px;
  }

  .promo__label {
    margin-right: 30px;
  }

  .promo__title {
    margin-bottom: 30px;
  }

  .promo__sub-title {
    margin-bottom: 25px;
  }

  .promo__image {
    @include break-max($breakpoint-sm) {
      width: 340px;
    }
  }

  .tariff {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 2;

    @include break-max($breakpoint-sm) {
      position: static;
      transform: none;
    }
  }

  .tariff__list {
    display: flex;

    border-radius: 5px 5px 0px 0px;

    @include break-max($breakpoint-sm) {
      flex-direction: column;
    }
  }

  .tariff__item {
    position: relative;
    width: 33.3%;
    background: $color-brand-2;
    padding-left: 80px;
    padding-top: 55px;
    padding-bottom: 60px;
    padding-right: 50px;

    &:nth-child(1) {
      border-right: 1px solid $color-brand-1;
    }
    &:nth-child(2) {
      border-left: 1px solid $color-brand-1;
      border-right: 1px solid $color-brand-1;
      margin: 0 -1px;
    }
    &:nth-child(3) {
      border-left: 1px solid $color-brand-1;
    }

    transform: translateY(390px);
    transition: 0.3s ease-in;
    &:hover {
      transform: translateY(0);
    }

    @include break-max($breakpoint-md) {
      padding-left: 50px;
      padding-right: 26px;
      transform: translateY(430px);
      padding-bottom: 75px;
    }

    @include break-max(1330px) {
      transform: translateY(460px);
    }

    @include break-max($breakpoint-sm) {
      width: 100%;
      padding-top: 45px;
      padding-bottom: 60px;
      margin-top: 25px;
      margin-bottom: 25px;
      transform: translateY(0);
      &:nth-child(n) {
        border: none;
        margin-left: 0;
        margin-right: 0;
      }

      // &::after {
      //   content: '';
      //   position: absolute;
      //   top: 40px;
      //   right: 30px;
      //   width: 30px;
      //   height: 30px;
      //   background: url('../../assets/images/school/arrow-link.svg') no-repeat center;
      // }
    }


  }

  .tariff__title {
    padding-bottom: 15px;
  }

  .tariff__label {
    display: inline-flex;
    margin-bottom: 20px;
    padding: 7px 20px;

    background: $color-brand-3;
    border-radius: 100px;

    color: $color-white;
    font-size: 12px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }

  .tariff__btn {
    position: absolute;
    bottom: 60px;


    @include break-max($breakpoint-sm) {
      bottom: 30px;
    }
  }

  .tariff__info {
    display: flex;
    padding-bottom: 35px;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }

  .tariff__detail {
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 22px;
      height: 22px;
      margin-right: 8px;
      background: url('../../assets/images/school/clock.svg') no-repeat center;
    }
  }

  .tariff__detail_price {
    margin-right: 45px;
    &::before {
      background-image: url('../../assets/images/school/money.svg');
    }

    @include break-max($breakpoint-md) {
      margin-right: 15px;
    }

  }
  .tariff__ul {
    line-height: 21px;

    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;

      &::before {
        content: '';
        display: inline-flex;
        flex-shrink: 0;
        width: 5px;
        height: 5px;
        margin-top: 8px;
        margin-right: 25px;
        border-radius: 50%;
        background: $color-brand-3;
      }


      @include break-max($breakpoint-md) {
        margin-bottom: 15px;
      }
    }
  }

  .tariff__descr {
    padding-top: 30px;
    padding-bottom: 50px;
    border-top: 1px solid $color-brand-opaque-40;
  }

  .tariff__cashback {
    margin-bottom: 30px;
    padding: 15px 90px 15px 30px;
    border: 1px solid $color-brand-opaque-20;;
    border-radius: 5px;
    color: $color-red;
    font-weight: 700;

    @include break-max($breakpoint-md) {
      padding-right: 60px;
    }

    @include break-max($breakpoint-sm) {
      display: inline-flex;
    }

  }

  .program {
    position: relative;
    z-index: 2;
    background: $color-white url('../../assets/images/school/ps.svg') no-repeat;
    background-position: right -20px;

    @include break-max($breakpoint-md) {
      background-size: 300px;
    }

    @include break-max($breakpoint-sm) {
      background-image: none;
    }
  }

  .program__box {
    width: 870px;
    max-width: 100%;
  }

  .program__counter {
    margin-bottom: 20px;
  }

  .program__title {
    margin-bottom: 25px;
  }

  .program__descr {
    margin-bottom: 35px;
    color: $color-brand-3;
  }

  .program__list {
    display: flex;
    justify-content: space-between;

    @include break-max($breakpoint-xsm) {
      flex-wrap: wrap;
    }

    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
  }

  .program__item {
    flex-basis: 240px;
    &:last-child {
      flex-basis: 280px;
    }

    @include break-max($breakpoint-sm) {
      flex-basis: 220px;
      &:last-child {
        flex-basis: 230px;
      }
    }

    @include break-max($breakpoint-xsm) {
      flex-basis: 45%;
      margin-bottom: 40px;
      &:nth-child(n+3) {
        margin-bottom: 0;
      }
      &:last-child {
        flex-basis: 45%;
      }
    }

    @include break-max($breakpoint-xs) {
      flex-basis: 100%;
      margin-bottom: 30px;
      &:nth-child(n) {
        margin-bottom: 30px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .program__subtitle {
    margin-bottom: 35px;
    @include break-max($breakpoint-md) {
      margin-bottom: 20px;
    }
  }

  .program__ul {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        width: 14px;
        height: 8px;
        margin-right: 12px;
        background: url('../../assets/images/school/arrow.svg') no-repeat center;
      }
    }
  }

  .sign {
    display: flex;
    align-items: center;
    height: 220px;
    margin-top: 130px;
    padding-top: 0;
    padding-left: 100px;
    padding-right: 125px;

    border-radius: 5px;
    background: $color-brand-3 url('../../assets/images/school/plane.svg') no-repeat right;
    color: $color-white;

    @include break-max($breakpoint-md) {
      height: 190px;
      margin-top: 80px;
    }

    @include break-max($breakpoint-sm) {
      height: 170px;
      background-size: contain;
    }

    @include break-max($breakpoint-xsm) {
      padding-left: 60px;
    }

    @include break-max($breakpoint-xs) {
      background-image: url('../../assets/images/school/plane-mob.svg');
      background-position: bottom;
      flex-direction: column;
      height: 240px;
      text-align: center;
      padding: 10px 25px;
    }

  }

  .sign__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    @include break-max($breakpoint-xs) {
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
    }
  }

  .sign__descr {
    width: 500px;
    max-width: 100%;
    &_lg {
      width: 635px;
    }

    @include break-max($breakpoint-sm) {
      width: 260px;
      &_lg {
        width: 310px;
      }
    }

  }

  .sign__title {
    margin-bottom: 10px;
  }

  .sign_insta {
    background: $color-red url('../../assets/images/school/phone.svg') no-repeat right;
    background-size: contain;
    margin-bottom: 130px;
    @include break-max($breakpoint-sm) {
      margin-bottom: 90px;
    }

    @include break-max($breakpoint-xsm) {
      margin-top: 45px;
      margin-bottom: 45px;
    }

    @include break-max($breakpoint-xs) {
      background-image: url('../../assets/images/school/phone-mob.svg');
      background-position: bottom;
    }
  }

  .sign_materials {
    padding-left: 65px;
    background: $color-brand-3 url('../../assets/images/school/book.svg') no-repeat right;
    background-size: contain;
    margin-bottom: 130px;
    @include break-max($breakpoint-sm) {
      margin-bottom: 90px;
    }

    @include break-max($breakpoint-xsm) {
      margin-top: 45px;
      margin-bottom: 45px;
    }

    @include break-max($breakpoint-xs) {
      padding-left: 25px;
      background-image: url('../../assets/images/school/book-mob.svg');
      background-position: bottom;
    }
  }

  .whom {

  }

  .whom__title {
    margin-bottom: 55px;
  }

  .whom__list {
    display: flex;
    justify-content: space-between;
    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
  }

  .whom__img {
    align-self: center;
  }

  .block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 380px;
    max-width: 100%;
    padding: 35px 60px;
    box-shadow: 0px 10px 50px rgba(143, 149, 172, 0.15);
    border-radius: 5px;
    background: $color-white;

    @include break-max($breakpoint-md) {
      width: 340px;
    }
    @include break-max($breakpoint-sm) {
      width: 300px;
      padding: 25px 40px;
    }

    @include break-max($breakpoint-xsm) {
      width: 228px;
      padding: 25px 25px;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
      margin-bottom: 15px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .block__title {
    margin-bottom: 15px;
  }

  .block__img {
    margin-bottom: 20px;
  }

  .block__descr {
    margin-bottom: 15px;
    color: $color-brand-3;
  }

  .know {
    padding-bottom: 50px;
    background: $color-brand-1;
    color: $color-white;
  }

  .know__row {
    display: flex;
    padding-top: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid $color-brand-opaque-20;

    &:first-of-type {
      padding-top: 0;
    }
    &:last-of-type {
      border-bottom: none;
    }

    @include break-max($breakpoint-xs) {
      flex-direction: column;
      border-bottom: none;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .know__item {
    width: 360px;
    flex-shrink: 0;
    margin-right: 100px;
    &:last-child {
      margin-right: 0;
    }

    @include break-max($breakpoint-md) {
      width: 320px;
      margin-right: 90px;
    }

    @include break-max($breakpoint-sm) {
      margin-right: 15px;
    }

    @include break-max($breakpoint-xsm) {
      width: 240px;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
      padding-bottom: 30px;
      border-bottom: 1px solid $color-brand-opaque-20;
    }

  }

  .know__title {
    margin-bottom: 50px;
  }

  .know__subtitle {
    margin-bottom: 20px;
  }

  .know__counter {
    margin-bottom: 15px;
  }

  .know__list {
    display: flex;
    align-items: center;
    margin-top: 15px;

    li {
      display: flex;
      align-items: center;
      &::after {
        content: '';
        width: 28px;
        height: 28px;
        margin: 4px 12px 0;
        background: $color-brand-2 url('../../assets/images/school/arrow-small.svg') no-repeat center;;
        color: $color-white;
        border-radius: 50%;
      }

      &:last-of-type::after {
        display: none;
      }
    }

    @include break-max($breakpoint-xsm) {
      flex-wrap: wrap;
    }
  }

  .advantages {
    background: url('../../assets/images/school/ps.svg') no-repeat;
    background-position: left 115px;

    @include break-max($breakpoint-md) {
      background-size: 300px;
    }

    @include break-max($breakpoint-xs) {
      background-image: none;
    }
  }

  .advantages__list {
    display: flex;
    justify-content: space-between;

    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
  }

  .advantages__title {
    @extend .whom__title;
  }

  .tariff__price {
    display: flex;
    align-items: center;
  }

  .tariff__old-price {
    margin-right: 15px;
    color: $color-brand-3;
    text-decoration: line-through;
  }

  .tariff__new-price {
    display: inline-flex;
    align-items: center;
    padding: 2px 12px 2px 2px;
    background: $color-red;
    border-radius: 100px;

    svg {
      width: 22px;
      height: 22px;
      margin-right: 8px;
      stroke: $color-white;
    }
  }

}
