@import "../../styles/variables";
@import "../../styles/mixins";

.legal {
  color: $color-brand-1;

  .input__control {
    background-color: $color-white;
  }

  .sor__sup {
    color: $color-red;
  }

  .button_view_default {
    background-color: $color-red;
    &:hover, &:focus {
      background-color: $color-red-hover;
    }
    &:active {
      background-color: $color-red-active;
    }
  }

  .rie__top-item::before {
    background-color: $color-red;
  }

  .button_view_outline-with-icon-invert {
    color: $color-red;
    box-shadow: inset 0 0 0 1px $color-red;
    &:hover, &:focus {
      background-color: $color-red-hover;
      box-shadow: inset 0 0 0 1px $color-red-hover;
    }
    &:active {
      background-color: $color-red-active;
      box-shadow: inset 0 0 0 1px $color-red-active;
    }
  }

  .sor__faq-section {
    background: $color-brand-2;
  }

  .sor__faq-title {
    color: $color-red;
  }
  
  .sor__faq-descr span {
    display: inline;
    margin-top: 0;
  }

  .rie__about-img-left {
    left: -55px;
    margin-bottom: 15px;
    @include break-max($breakpoint-xs) {
      left: 0;
    }
  }

  .rie__learn-title {
    height: auto;
  }

  .rie__learn-item:last-of-type {
    margin-bottom: 0;
  }

  .rie__how-badge {
    background-color: $color-red;
  }

  .rie__how-item ul {
    font-size: 21px;
    line-height: 30px;
    margin-left: 15px;

    li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .rie__banner {
    background: $color-brand-2;
  }

  .rie__banner-title {
    margin-bottom: 0;
    @include break-max($breakpoint-xs) {
      margin: 15px 0;
    }
  }

  .rie__banner-text {
    color: $color-brand-3;
    margin-top: 5px;
  }

  .rie__banner-box {
    width: 755px;
    max-width: 100%;
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  .sor__promo-btn {
    position: relative;
  }

  .rie__banner-content {
    align-items: center;
    @include break-max($breakpoint-xs) {
      align-items: flex-start;
    }
  }

  .rie__banner-container {
    @include break-max($breakpoint-xs) {
      align-items: flex-end;
      height: 420px;
      padding-bottom: 25px;
    }

  }

  .sor__form .link {
    color: $color-red;
    &:hover, &:focus {
      color: $color-red-hover;
    }
    &:active {
      color: $color-red-active;
    }
  }

  .rie__promo-svg-1 {
    @include break-max($breakpoint-xs) {
      right: 220px;
    }
  }


}

.preview__header_bg + .section .legal {
  padding-top: 85px;
  background-color: $color-brand-opaque-8;
  @include break-max($breakpoint-sm) {
    padding-top: 60px;
  }
}