.lp {
  display: flex;
  min-height: 100vh;
  // overflow: hidden;

  @include break-max($breakpoint-sm) {
    height: auto;
    min-height: 100vh;
    overflow: auto;
    flex-direction: column;
  }
}

.lp__header {
  padding-top: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid $color-brand-opaque-20;

  @include break-max($breakpoint-md) {
    padding-top: 40px;
  }

  @include break-max($breakpoint-sm) {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: none;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.lp__name {
  padding-right: 15px;
}

.lp__phone {
  display: flex;
  align-items: center;

  @include break-max($breakpoint-xs) {
    font-size: 0;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  svg {
    margin-right: 10px;
    @include break-max($breakpoint-xs) {
      margin-right: 0;
      fill: $color-white;
    }
  
  }
}

.lp__main {
  margin: auto;
  width: 490px;
  max-width: 100%;

  @include break-max($breakpoint-md) {
    // width: 385px;
  }

  @include break-max($breakpoint-sm) {
    padding-top: 50px;
    width: 100%;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 25px;
  }

  .simplebar-track.simplebar-vertical {
    background-color: none;
  }
}

.lp__title {
  margin-bottom: 25px;
  @include break-max($breakpoint-md) {
    margin-bottom: 15px;
  }
}

.lp__text {
  color: $color-brand-3;
  margin-bottom: 30px;
}

.lp__highlighted {
  display: block;
}

.lp__svg {
  display: flex;
  width: fit-content;
  min-width: fit-content;


  @include break-max($breakpoint-sm) {
    width: 100%;
  }
  
  svg {
    width: fit-content;
    max-width: 100%;
    height: 100vh;
    @include break-max($breakpoint-sm) {
      height: auto;
      width: 100%;
    }
  }
}

.lp__content {
  display: flex;
  flex-direction: column;
  width: 720px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 25px;

  @include break-max($breakpoint-md) {
   // width: 535px;
  }

  @include break-max($breakpoint-sm) {
    padding-bottom: 75px;
  }

  @include break-max($breakpoint-xs) {
    padding: 0 15px;
    padding-bottom: 35px;
  }
}

.lp__content_success {
  width: 100%;
  max-width: 1470px;
  display: flex;
  flex-direction: column;
}


.lp__checkbox {
  width: 100%;
  margin-top: 25px;

  @include break-max($breakpoint-xs) {
    align-items: flex-start;
    margin-top: 15px;
  }


  .checkbox__label {
    @include break-max($breakpoint-xs) {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.lp__input .input__control {
  border-radius: 8px;
  
  box-shadow: none;
  background: rgba($color-white, 0.1);
}

.lp__input .input__control::placeholder {
  
}

.lp__checkbox-box {
  width: 32px;
  height: 32px;
  box-shadow: none;
  background-color: #4545C2;

  .checkbox__marker {
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      display: none;
    }
    .checkbox__marker-icon {
      position: static;
    }
  }
}


.link.lp__agreement {
  
  
  &:hover {
    
  }

  &:active {
    
  }
 }

 .lp__checkbox-box .checkbox__marker-icon {
  fill: $color-white;
}

.lp__btn {
  width: 220px;
  min-width: fit-content;
  padding: 17px 35px;
  margin-top: 0;

  @include break-max($breakpoint-xs) {
    width: 100%;
    min-width: 100%;
  }
}

.lp__success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.lp__success-title {
  width: 240px;
  margin: 0 auto 15px;
  text-align: center;
}

.lp__success-btn {
  width: 280px;
  max-width: 100%;
}

.lp__success-text {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 15px;
}

.lp__input-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  row-gap: 25px;

  .admin__input-block {
    margin-bottom: 0;
  }

  @include break-max($breakpoint-xs) {
    grid-template-columns: 1fr;
  }
}

.lp__info {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.lp__info-block {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  width: 220px;
  padding: 0 15px;
  margin-left: 15px;
  font-size: 13px;
  line-height: 16px;

  border-radius: 8px;
  background-color: $color-brand-opaque-10;
  color: $color-brand-3;

  @include break-max($breakpoint-xs) {
    margin-top: 15px;
    margin-left: 0;
    width: 100%;
    height: 48px;
  }
}

.lp__info-icon {
  position: absolute;
  top: 0;
  right: -9px;

  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}