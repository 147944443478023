$color-dark: #1e2030;

.icon-dark_stroke {
  stroke: #2c2e3e;
}
.icon-white_stroke {
  stroke: #ffffff;
}

#anchor {
  &-flats,
  &-about,
  &-infrastructure,
  &-news,
  &-calc,
  &-others {
    position: absolute;
    opacity: 0;
    z-index: -1;
    top: -150px;
  }
}

.section_view_dark {
  background-color: $color-dark;
  color: $color-white;
}

.show__map {
  margin-left: 40px;
  display: flex;
  .icon_map-pin {
    height: 17px;
    margin-right: 10px;
  }
}

.complex {
  .section {
    position: relative;
  }

  &__collapse {
    padding: 30px 10px;

    .banks__slider {
      @include break-max($break-xs) {
        margin-top: -15px;
        padding-top: 0;
      }
    }

    &-icon {
      display: flex;
      justify-content: center;
      margin-left: -10px;
      width: 45px;
    }

    & .complex__slider-info {
      bottom: -60px;
    }

    & .banks__slider {
      margin-bottom: 60px;
    }

    & .banks__slider-inner {
      margin-left: 0;
    }

    & .banks__slider-item.tns-slide-active {
      margin-right: -20px;

      &:last-child {
        margin-right: 0;

        & .banks__slider-inner {
          width: 100%;
        }
      }
    }
  }

  &__routes {
    @include unlist();
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: auto;
    color: $color-brand-3;
  }

  &__routes-item {
    margin-right: 30px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__metro-color {
    width: 6px;
    height: 6px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 6px;
    margin-right: 10px;

    &_1 {
      background-color: $color-metro-1;
    }

    &_2 {
      background-color: $color-metro-2;
    }

    &_3 {
      background-color: $color-metro-3;
    }

    &_4 {
      background-color: $color-metro-4;
    }

    &_5 {
      background-color: $color-metro-5;
    }
  }

  &__metro-station {
    display: inline-block;
    margin-right: 10px;
  }

  &__route-type {
    & .icon {
      fill: $color-brand-3;
    }

    & .icon_auto {
      font-size: 12px;
      margin-right: 10px;
    }

    & .icon_pedestrian {
      font-size: 16px;
      margin-right: 5px;
    }
  }

  .filter {
    &__header {
      display: flex;
      justify-content: space-between;

      .is-hidden-desktop {
        @include break-min($break-md) {
          display: none;
        }
      }

      @include break-max($break-xs) {
        justify-content: flex-end;
      }
    }

    &__manager {
      display: flex;
      align-items: center;

      @include break-max($break-xs) {
        justify-content: space-between;
        margin: 0 10px;
        width: 100%;

        &-content {
          padding-left: 10px;
        }
      }

      &-photo {
        width: 85px;
        height: 85px;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $color-brand-opaque-10;
        margin-right: 15px;
      }

      &-name {
        font-size: 18px;
        line-height: 22px;
        font-weight: bold;
      }

      &-label {
        color: $color-brand-3;
        margin-bottom: 13px;

        @include break-max($break-xs) {
          margin-top: 5px;
        }
      }

      &-call {
        display: flex;
        align-items: center;
        @include link();
        .icon {
          fill: $color-red;
          margin-right: 10px;
        }
      }

      &-hint {
        margin-left: 20px;
      }
    }
  }
}

.complex__title {
  font-size: 48px;
  font-weight: 600;
  line-height: 55px;
  @include break-max($breakpoint-md) {
    font-size: 42px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 36px;
    line-height: 42px;
  }

  @include break-max($break-xs) {
    font-size: 24px;
    line-height: 26px;
  }

  @include break-max($break-xxs - 1px) {
    font-size: 21px;
    line-height: 24px;
  }
}

.complex__section-chart {
  .form__item {
    flex-basis: 220px;
    @include break-max($breakpoint-xs) {
      flex-grow: 1;
    }
  }

  .complex__about-col {
    width: 100%;
    @include break-max($breakpoint-xs) {
      margin: 0;
    }
  }
  .form__item {
    margin-left: 10px;
    margin-right: 10px;
    @include break-max($breakpoint-xs) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .form__item:last-of-type {
    margin-left: 45px;
    @include break-max($breakpoint-xsm) {
      margin-left: auto;
    }
    @include break-max($breakpoint-xs) {
      margin: 0;
    }
  }
  // .button {
  //   width: 250px;
  //   @include break-max($breakpoint-sm) {
  //     width: auto;
  //   }
  //   @include break-max($breakpoint-xs) {
  //     width: 100%;
  //   }
  // }
  .chart__block {
    margin-top: 10px;
    @include break-max($breakpoint-xs) {
      margin-top: 0;
    }
  }
}

$color-grey-background: rgba($color-brand-3, 0.08);

.section.grey {
  background: $color-grey-background;

  @include break-max($break-xs) {
    background: none;
  }
}

.section__complex-results {
  position: relative;
  margin-top: 60px;

  @include break-max($break-xs) {
    margin-top: 25px;
  }
}
.button_favorite,
.button_favorite:hover {
  background-color: transparent;
}
.icon_favorite {
  width: 18px;
  height: 18px;
  fill: none;
  stroke: $color-brand-3;
}

.complex__table {

  &-status {
    display: flex;
    align-items: center;
    line-height: 26px;

    .info {
      margin-left: 10px;

      &:hover .alarm-button,
      &.alarm-button.active {
        background-color: $color-red;
      }
    }

    .alarm-button {
      padding: 0;
      width: 25px;
      height: 25px;
      background-color: $color-brand-3;

      .icon_alarm {
        width: 13px;
        height: 16px;
      }
    }

    &_alarm {
      width: 240px;
      padding: 32px 34px;
      white-space: initial;

      & .button_view_default {
        display: block;
        padding: 6px 14px;
        font-weight: normal;
        margin-top: 10px;
        cursor: pointer;
      }
    }
  }

  &-more {
    text-align: center;
    padding-bottom: 20px;

    &_button {
      display: inline-block;
      color: $color-brand-3;
      cursor: pointer;
      transition: color $transition-default;

      .icon {
        width: 10px;
        stroke: $color-brand-3;
        transition: stroke $transition-default;
      }

      &:hover {
        color: $color-brand-2;

        .icon {
          stroke: $color-brand-2;
        }
      }
    }
  }
}

.complex__appartments {
  padding: 54px 0 80px;

  @include break-max($break-xs) {
    padding: 0;
  }

  &-tab {
    display: none;

    @include break-max($break-xs) {
      .complex__views {
        display: none;
      }
    }

    &.active {
      display: block;
    }
  }

  &.view-cards {
    .complex__appartments {
      &-cards {
        display: flex;
      }
    }

    .flats__row-table {
      display: none !important;
    }
  }

  &.view-table {
    .flats__row {
      &.is-active {
        border-radius: 5px 5px 0 0;
      }

      &-results {
        display: none !important;
        padding: 0;
      }
    }
  }

  &-row {
    margin-bottom: 25px;
  }

  &-cards {
    display: flex;
    @include grid();
    margin-top: -15px;
    margin-bottom: -15px;
  }

  &-card {
    @include grid-col();
    @include grid-size(4);
    margin-top: 15px;
    margin-bottom: 15px;

    @include break-max($breakpoint-md) {
      @include grid-size(4, $grid-gutter-md);
    }

    @include break-max($breakpoint-sm) {
      @include grid-size(12, $grid-gutter-sm);
    }

    @include break-max($break-xs) {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }
}
.view__icon {
  width: 19px;
  height: 19px;
  fill: $color-brand-3;
  stroke: $color-brand-3;
  fill-opacity: 0.2;
  stroke-opacity: 0.2;
}
.complex__appartments-navigation {
  display: flex;
  align-items: center;

  @include break-max($break-xs) {
    overflow: hidden;
    margin-bottom: 8px;
    padding-top: 10px;
  }

  & .complex__tabs {
    display: block;
    flex: 1 1 auto;
    overflow: hidden;

    @include break-max($break-xs) {
      overflow: visible;

      .tns-slider {
        margin-left: -5px;
        width: 100% !important;
      }
    }
  }

  & .complex__tabs-item {
    @include break-max($break-xs) {
      display: flex;
      flex-direction: column;
      min-height: 60px;
      text-align: center;
    }
  }
  & .complex__move-nav {
    display: flex;
    flex: 0 0 20%;
    max-width: 300px;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 30px;

    @include break-max($break-xs) {
      padding-left: 0;

      & .tabs__navs-item {
        border: 1px solid $color-brand-opaque-30;
        border-radius: 16px;
        height: 32px;
        top: 32px;
        position: absolute;
        width: 32px;

        &.prev {
          left: 0;
        }

        &.next {
          right: 0;
        }
      }
    }
  }
  & .complex__views {
    display: flex;
    justify-content: flex-end;
    flex: 1;

    @include break-max($break-xs) {
      display: none;
    }
  }
  & .complex__view {
    @include unlist();
    display: flex;
    align-items: center;
    height: 30px;

    &-item {
      margin-right: 20px;
      cursor: pointer;

      &:last-of-type {
        margin-right: 0;
      }
      &:hover {
        & .view__icon {
          fill: $color-brand-3;
          stroke: $color-brand-3;
          fill-opacity: 1;
          stroke-opacity: 1;
        }
      }
      &.active {
        & .view__icon {
          fill: $color-brand-2;
          stroke: $color-brand-2;
          fill-opacity: 1;
          stroke-opacity: 1;
        }
      }
    }
  }
}

.flats__row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: $color-white;
  border-radius: 5px;
  padding: 20px 30px;
  cursor: pointer;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  &-count {
    @include break-max($break-xs) {
      align-items: center;
      background-color: $color-brand-opaque-12;
      border-radius: 100px;
      color: $color-brand-3;
      font-size: 14px;
      font-weight: 400;
      flex: 0;
      line-height: 25px;
      display: flex;
      justify-content: center;
      height: 30px;
      padding: 0 13px;
    }
  }

  &.is-active {
    & .button_view_round {
      transform: rotate(180deg);
    }

    @include break-max($break-xs) {
      & ~ .complex__views {
        bottom: 20px;
        display: flex;
        justify-content: space-between;
        position: sticky;
        z-index: 6;

        .presentation-filters-btn {
          margin: 0;
        }

        .complex__view {
          width: calc(50% - 6px);
        }

        & .complex__view-item {
          align-items: center;
          background: $color-white;
          box-shadow: 0px 4px 20px $color-brand-opaque-30;
          border-radius: 22px;
          display: flex;
          justify-content: center;
          height: 44px;
          padding: 0 20px;

          svg {
            stroke: $color-brand-2;
            margin-right: 8px;
          }

          &.active {
            display: none;
          }
        }
      }
    }
  }

  &:hover {
    .button_view_round {
      background-color: $color-brand-3;
    }
  }

  @include break-max($breakpoint-md) {
    padding: 20px 8px;
  }

  @include break-max($break-xs) {
    padding: 18px 14px 18px 10px;
  }

  &-results {
    display: none;
    padding-top: 20px;
    &.is-active {
      display: block;
      padding-bottom: 35px;
    }
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  &-table {
    background-color: $color-white;
    border-radius: 0 0 5px 5px;
    height: 0;
    visibility: hidden;
    transform: scaleY(0);
    transform-origin: top;
    backface-visibility: hidden;
    .ant-table-tbody, .ant-table-thead {
      transform: scaleY(0);
    }
    &.is-active {
      .ant-table-tbody, .ant-table-thead {
        transform: scaleY(1);
        transform-origin: top;
        transition: transform 0.2s;
      }
      height: auto;
      visibility: visible;
      transform: scaleY(1);
    }

    &-sign {
      align-items: flex-start;
      color: $color-brand-3;
      font-size: 16px;
      font-family: Formular;
      line-height: 24px;
    }

    & table {
      width: 100%;
      padding: 0 20px 20px;

      @include break-max($breakpoint-md) {
        padding: 0 15px;
      }

      @include break-max($breakpoint-sm) {
        padding-left: 0;
        padding-right: 0;
      }
    }



    @include break-max($break-xs) {
      &-expanded {
        display: flex;
        font-size: 15px;
        line-height: 20px;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;

        &-column {
          display: flex;
          flex-direction: column;

          & > div {
            margin-bottom: 3px;
          }
        }

        &-title {
          color: $color-brand-3;
        }
      }

      & .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded,
      & .ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
        background: $color-brand-opaque-10;
        border-radius: 14px;
        border: none;
        height: 29px;
        width: 29px;

        &::before {
          top: 14px;
          left: 10px;
          right: 10px;
        }

        &::after {
          top: 10px;
          left: 14px;
          bottom: 10px;
        }
      }

      & .ant-table-row-expand-icon {
        color: $color-brand-3;

        &:hover {
          color: inherit;
        }
      }

      & .ant-table-container table > thead > tr:first-child th:first-child {
        border-radius: 5px 0 0 5px;
      }

      & .ant-table-container table > thead > tr:first-child th:last-child {
        border-radius: 0 5px 5px 0;
      }

      & .ant-table-cell.ant-table-row-expand-icon-cell {
        padding-left: 7px;
        padding-right: 8px;
      }

      & .ant-table-thead > tr > th {
        padding-top: 9px;
        padding-bottom: 11px;
      }

      & .ant-table-cell {
        padding: 15px 15px 15px 0;
        //white-space: nowrap;
      }

      & .ant-table-row.ant-table-row-level-0:first-child td,
      & .ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table-cell {
        border-top: none;
      }
    }

    @include break-max($break-xxs) {
      & .ant-table-cell {
        padding-right: 5px;
      }
    }
  }



  &-col {
    margin: 0 12px;
    flex: 1;

    @include break-max($breakpoint-sm) {
      &:first-child {
        flex: 0.5;
      }
    }

    @include break-max($break-xs) {
      flex: 0;
      margin: 0;

      &:first-child {
        min-width: 64px;
      }
    }
  }

  &-concat {
    flex: 5;
    display: flex;
    @include break-max($breakpoint-sm) {
      flex-direction: column;
      flex: 2;
    }

    @include break-max($break-xs) {
      color: $color-brand-3;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      margin-left: 24px;
    }
  }

  &-rooms {
    font-size: 25px;
    line-height: 28px;

    @include break-max($breakpoint-md) {
      font-size: 20px;
      line-height: 24px;
    }

    @include break-max($break-xs) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &-price {
    flex: 2;
  }

  .nowrap {
    white-space: nowrap;
  }

  &-action {
    flex: 0 0 auto;
    & .button_view_round {
      background-color: $color-brand-2;
      transition: all 0.5s ease;
      transform: rotate(0deg);

      .icon {
        stroke: $color-white;
      }

      @include break-max($break-xs) {
        height: 30px;
        width: 30px;
        margin-left: 6px;

        .icon {
          margin-top: 2px;
          width: 8px;
        }
      }
    }
  }

  &-info {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: #ff2d23;
  }
}

.complex__tabs {
  display: flex;

  @include break-max($break-xs) {
    & .tns-ovh {
      overflow: visible;
    }
  }

  &-row {
    display: flex;
  }
  &-item {
    padding: 10px 30px;
    border-radius: 5px 5px 0 0;
    color: #8f95ac;
    cursor: pointer;
    border: 1px solid transparent;
    border-bottom: none;
    transition: border 0$transition-default;

    &:hover {
      border-color: $color-brand-opaque-30;
    }

    &.active {
      border-color: transparent;
    }
    &.active + & {
      border-left-color: transparent;
    }

    &.tns-item.active,
    &.tns-item.tns-slide-active {
      @include break-max($breakpoint-md) {
        align-items: center;
        color: $color-brand-3;
        display: flex;
        padding-left: 15px;
        padding-right: 15px;
        flex-direction: column;
        align-items: flex-start;
      }

      @include break-max($break-xs) {
        background: none;
      }
    }

    &.active {
      background: $color-grey-background;
      & .complex__tabs-value {
        color: #2c2e3e;
      }
    }

    &.tns-item.active.tns-slide-active {
      @include break-max($break-xs) {
        background: none;
        display: flex;
        justify-content: center;
        margin-left: -10px;
        min-height: 60px;
        padding: 0 40px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &-value {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;

    @include break-max($breakpoint-md) {
      font-size: 16px;
    }

    @include break-max($break-xs) {
      font-size: 21px;
      line-height: 24px;
    }

    @include break-max($wrapper-xsm) {
      font-size: 16px;
      line-height: 21px;
    }
  }
  &-desc {
    font-size: 14px;
    line-height: 18px;

    @include break-max($break-xs) {
      font-size: 15px;
      line-height: 20px;
      margin-top: 5px;
    }
  }
}

.tabs__navs {
  @include unlist();
  display: flex;
  margin-right: 45px;

  @include break-max($break-xs) {
    margin-right: 0;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    width: 34px;
    height: 30px;
    border: 1px solid rgba($color-brand-3, 0.3);
    cursor: pointer;
    transition: all 0$transition-default;

    .icon-dark_stroke {
      stroke: $color-brand-3;
    }

    &:hover {
      background-color: $color-brand-opaque-20;
    }
    &:active {
      background-color: $color-brand-opaque-30;
    }

    .icon-dark_stroke {
      width: 10px;
      height: 10px;
    }

    &.prev {
      border-radius: 20px 0 0 20px;
      border-right: hidden;
    }

    &.next {
      border-radius: 0 20px 20px 0;
      border-left: hidden;
    }
  }
}

.complex__wrapper {
  width: calc(100% / 12 * 10);
  margin: 0 auto;
  @include break-max($break-sm) {
    width: 100%;
  }
}

.complex__about {
  @include grid();
  justify-content: space-between;
  &-col {
    @include grid-col();
  }
  &-preview {
    @include grid-size(5);
    padding-right: 80px;
    font-size: 25px;
    line-height: 32px;
    font-weight: 300;
    @include break-max($breakpoint-md) {
      @include grid-size(6, $grid-gutter-md);
      font-size: 20px;
      line-height: 32px;
      padding-right: 30px;
    }

    @include break-max($break-md) {
      padding-right: 0;
    }
  }
  &-text {
    @include grid-size(7);
    font-size: 14px;
    line-height: 21px;

    @include break-max($breakpoint-md) {
      @include grid-size(6, $grid-gutter-md);
    }

    p {
      margin-bottom: 25px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &-features {
    margin-top: 50px;
    padding: 20px 50px;
    background: #2c2e3e;
    color: #fff;
    border-radius: 4px;

    & ul {
      display: flex;
      flex-wrap: wrap;
      list-style: disc;

      @include break-max($breakpoint-sm) {
        justify-content: space-between;
      }
    }

    &.assignation-features {
      margin-top: 25px;
    }
  }
  &-feature {
    flex-basis: calc(30% - 30px);
    margin: 5px 30px 5px 0;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;

    @include break-max($breakpoint-sm) {
      flex-basis: 26%;
      margin-right: 0;
    }
  }
}

.section.navigation_section {
  height: 57px;
}
.section__block {
  position: relative;
  padding-bottom: 140px;

  @include break-max($break-xs) {
    padding-bottom: 10px;

    & .complex__block-col.info__col {
      align-items: flex-start;
      flex-direction: column;
    }

    & .info__col-top {
      padding: 0 10px;
    }

    & ul.complex__badges {
      margin-top: 8px;
      order: 2;
    }

    & .complex__address {
      margin-bottom: 0;
      padding-top: 30px;
    }

    & .complex__price {
      border: none;
      width: 50%;
    }

    & .complex__border {
      border-bottom: 1px solid $color-brand-opaque-20;
      display: flex;
      justify-content: space-between;
    }

    & .complex__info-item-desc {
      margin-top: 5px;
      white-space: nowrap;
    }

  }
}
.navigation__block {
  z-index: 20;
  position: absolute;
  margin-top: 40px;
  width: 100%;
  -webkit-transition: top 0.5s ease-out;
  -moz-transition: top 0.5s ease-out;
  -o-transition: top 0.5s ease-out;
  transition: top 0.5s ease-out;
  &-inner {
    border-bottom: 1px solid $color-brand-opaque-30;
    padding: 12px 0;
  }

  .is-hidden-sm {
    @include break-max($breakpoint-sm) {
      display: none;
    }
  }
  .is-hidden-desktop {
    @include break-min($breakpoint-sm) {
      display: none;
    }
  }

  &.nav_fixed {
    position: fixed;
    background: #fff;
    margin-top: 0;
    box-shadow: 0 2px 20px 0 $color-brand-opaque-10;
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      0.5s both;
    animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both;
    & .navigation__block-inner {
      border: none;
    }
    & .complex__navigation {
      &-item,
      &-more {
        color: $color-brand-2;

        & .form__drop-btn .icon {
          stroke: $color-brand-2;
        }
      }
    }
  }
  & .complex__navigation {
    @include unlist();
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include break-max($breakpoint-sm) {
      justify-content: space-between;
    }

    &-item {
      display: inline-block;
      padding: 8px 20px;
      border-radius: 15px;
      text-decoration: none;

      & + & {
        margin-left: 25px;
        @include break-max($breakpoint-md) {
          margin-left: 20px;
        }
      }

      &_basis_0 {
        flex-basis: 0;
      }

      &:hover {
        background: rgba(#8f95ac, 0.2);
      }
      & a {
        color: inherit;
        font-size: 12px;
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;
      }
      &.active {
        background: #ff2d23;
        color: $color-white;
      }
    }

    &-list {
      & .complex__navigation-item {
        & a {
          color: $color-brand-2;
        }
      }
    }

    &-more {
      color: $color-white;

      .form__item {
        height: auto;
        background-color: transparent;
        margin: 0;
      }

      .form__drop-btn {
        border-radius: 4px;
        padding: 5px 10px;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: inherit;
        text-transform: uppercase;
        font-weight: 600;
        background: $color-brand-opaque-30;
        & .icon {
          margin-right: 0;
          margin-left: 4px;
          width: 7px;
          height: 7px;
          stroke: $color-white;
        }
      }
    }
  }
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(44px);
    transform: translateY(44px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(44px);
    transform: translateY(44px);
  }
}

.complex__flats {
  cursor: pointer;
  &-row {
    cursor: pointer;
    transition: all 0.5s ease;
    border-radius: 4px;
    &:hover {
      background: $color-brand-opaque-10;
    }
    &_inner {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      margin: 0 20px;
      border-bottom: 0.5px solid $color-brand-opaque-30;
    }
  }
  &-header {
    background: #2c2e3e;
    color: $color-white;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    border-radius: 3px;
  }
}

.section__calc {
  background-color: $color-brand-2;

  .block__title {
    margin-bottom: 45px;

    @include break-max($breakpoint-sm) {
      margin-bottom: 25px;
    }
  }

  .mortgage__calc {
    padding: 24px 40px 24px 25px;
    background-color: $color-brand-1;
    border-radius: 5px;
    margin-bottom: 0;

    &-grid {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;

      & > * {
        margin: 0;
        width: auto;

        &:first-child {
          margin-right: 20px;
        }
      }
    }

    &-form {
      @include break-min($break-lg) {
        padding-right: 50px;
      }
    }

    &-result {
      &_info {
        @include break-min($break-lg) {
          white-space: nowrap;
        }
      }
    }
  }

  .calc__manager {
    @include break-min($break-md) {
      padding-left: 40px;
    }

    @include break-max($breakpoint-sm) {
      margin-top: 40px;
      padding: 0 35px;
      @include grid-size(12, $grid-gutter-sm);
    }

    &-description {
      color: $color-brand-3;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 20px;
    }

    &-card {
      display: flex;
      color: $color-white;
    }

    &-photo {
      width: 125px;
      height: 125px;
      border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      margin-right: 30px;
    }

    &-name {
      margin-top: 20px;
      font-size: 25px;
      line-height: 28px;
    }

    &-label {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 20px;
      color: $color-brand-3;
    }

    &-contacts {
      display: flex;
      flex-direction: column;

      @include break-max($breakpoint-sm) {
        flex-direction: row;

        a + a {
          margin-left: 35px;
        }
      }

      a {
        display: inline-block;
        color: inherit;

        &:hover {
          color: $color-red;
        }
      }
    }
  }
}

.icon-dark_stroke {
  stroke: #2c2e3e;
}
.icon-white_stroke {
  stroke: #ffffff;
}

.block__title {
  &.with-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .link {
      font-size: 14px;
      line-height: 21px;
      white-space: nowrap;
    }
  }
  &-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &.with-button {
    display: flex;
    align-items: center;
    & button {
      margin-left: 60px;
    }

    @include break-max($breakpoint-sm) {
      flex-direction: column;
      align-items: flex-start;
      & button {
        margin-left: 0;
        margin-top: 15px;
        padding-top: 12px;
        padding-bottom: 11px;
      }
    }

    @include break-max($break-xs) {
      &.for-mobile {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;

        a, .button {
          width: 100%;
        }
      }
    }
  }
  &-navs {
    @include unlist();
    display: flex;
    height: 60px;
    width: 100px;
    border-radius: 30px;
    border: 1px solid $color-brand-opaque-30;
    overflow: hidden;
    margin: 0;
    &_item {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      cursor: pointer;
      transition: background 0$transition-default;

      &:hover {
        background-color: $color-brand-opaque-20;
      }
      &:active {
        background-color: $color-brand-opaque-30;
      }

      & .icon {
        width: 6px;
        height: 11px;
      }
      &.prev .icon {
        margin-right: -10px;
      }
      &.next .icon {
        margin-left: -10px;
      }
    }
  }
}

.complex__section-filter {
  margin-top: 44px;

  @include break-max($break-xs) {
    margin-top: 30px;

    & .filter__header {
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 30px;

      & .button_view_outline-with-icon {
        padding-left: 17px;
        padding-right: 20px;
        margin-top: 0;
      }
    }

    & .filter__manager-photo {
      height: 90px;
      flex-shrink: 0;
      width: 90px;
    }

    & .filter__title {
      flex-basis: 100%;
      margin-bottom: 20px;
      padding: 0 10px;
    }

    & .filter__manager {
      border: 1px solid $color-brand-opaque-20;
      border-radius: 4px;
      display: flex;
      flex-basis: 100%;
      margin: 0 10px;
      padding: 28px 30px;
      width: calc(100% - 20px);
    }

    & .filter__manager-label {
      margin-bottom: 0;
    }

    & .filter__manager-name {
      max-width: 95%;
      position: relative;
    }

    & .info {
      position: absolute;
      right: -17px;
      top: 0;
    }

    & .filter__manager-content {
      padding-left: 0;
      width: 100%;
    }

    & .complex__manager-contacts_item .link-hover {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.complex__manager-contacts_item {
  @include break-max($break-xs) {
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.filter__manager-call {
  margin-bottom: 10px;

  .icon_phone {
    margin-right: 5px;
  }
}

.complex__filter-wrapper {
  @include grid();
  margin-top: 30px;

  @include break-max($break-xs) {
    margin-top: 0px;
  }
}
.complex__filter {
  @include grid-col();
  @include grid-size(9);
  flex: 1;

  @include break-max($breakpoint-md) {
    @include grid-size(9, $grid-gutter-md);
  }

  @include break-max($breakpoint-sm) {
    @include grid-size(12, $grid-gutter-sm);
  }

  @include break-max($breakpoint-sm) {
    width: 100%;
  }

  @include break-max($break-xs) {
    margin-left: 10px;
    margin-right: 10px;
  }

  &-form {
    background: $color-brand-opaque-8;
    padding: 40px;
    margin-bottom: 20px;
    border-radius: 5px;

    @include break-max($breakpoint-md) {
      padding: 16px;
    }

    @include break-max($breakpoint-md) {
      // padding: 0 5px;
    }

    @include break-max($break-xs) {
      background: none;
      margin-bottom: 3px;
    }
  }

  &-row {
    margin: -25px -6px 0;
    flex-wrap: wrap;
    & .form__item {
      margin: 25px 6px 0;
      flex: 0 0 calc(100% / 5 - 12px);

      &:nth-child(even) {
        & .dropdown__block {
          @include break-max($break-xs) {
            left: auto;
            right: 0;
          }
        }
      }

      @include break-max($breakpoint-md) {
        margin: 15px 6px 0;
      }
      @include break-max($breakpoint-sm) {
        flex: 0 0 calc(100% / 4 - 12px);
      }
      @include break-max($break-xs) {
        border: 1px solid $color-brand-opaque-20;
        flex-basis: 46%;
        max-width: 46%;
      }

      & .form__drop-btn {
        @include break-max($breakpoint-md) {
          padding: 0 12px 0 16px;
        }
        .icon {
          @include break-max($breakpoint-md) {
            width: 10px;
            height: 6px;
          }
        }
      }
    }

    @include break-max($breakpoint-md) {
      margin: -15px -6px 0;
    }

    @include break-max($break-xs) {
      justify-content: space-between;
    }
  }

  &-switch {
    margin: 25px 12px 0;
    justify-content: flex-end;
    display: flex;
    height: 50px;
    @include break-max($breakpoint-sm) {
      margin-bottom: 25px;
    }
  }

  &-actions {
    margin-top: 10px;
    padding-top: 15px;
    border-top: 1px solid $color-brand-opaque-20;

    @include break-max($break-xs) {
      border-top: none;
      padding-top: 0;
      margin-top: 10px;
    }
  }
}

.complex-search__filter {
  &-controls {
    display: flex;
    align-items: center;
  }

  &-submit {
    flex: 1 0 auto;
    text-align: center;

    @include break-max($break-xs) {
      margin: 10px 0 0;
      width: 100%;

      .button {
        width: 100%;
      }
    }
  }

  &-clear {
    flex: 1 0 auto;

    &:first-of-type {
      visibility: hidden;

      @include break-max($break-xs) {
        display: none;
      }
    }
  }
}
.complex__manager {
  margin-left: 30px;
  margin-right: 30px;
  width: -webkit-calc(100% / 12 * 3 - 60px);
  width: calc(100% / 12 * 3 - 60px);
  display: flex;
  flex-direction: column;
  &-avatar {
    display: flex;
    flex: 0 0 150px;
    justify-content: center;
    &_wrapper {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      overflow: hidden;
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  &-title {
    font-weight: bold;
    font-size: 25px;
    line-height: 28px;
    margin-top: 22px;
  }
  &-post {
    font-size: 14px;
    line-height: 21px;
    color: $color-brand-3;
    margin-top: 4px;
  }
  &-contacts {
    margin-top: 16px;
    &_list {
      @include unlist();
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
    &_item {
      &:last-of-type {
      }
    }
  }
  &-action {
    margin-top: 30px;
  }

  @include break-max($breakpoint-sm) {
    display: none;
  }
}

.complex__section-about {
  position: relative;
  margin: 80px 0;


  @include break-max($breakpoint-sm) {
    margin: 40px 0;
  }

  @include break-max($break-xs) {
    margin: 0;
  }

  .section__title {
    font-size: 35px;
    line-height: 40px;
    font-weight: bold;
    margin-bottom: 40px;

    @include break-max($breakpoint-md) {
      font-size: 30px;
      line-height: 40px;
    }

    @include break-max($breakpoint-sm) {
      font-size: 21px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    @include break-max($breakpoint-xs) {
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 15px;
    }
  }
}

.complex__banks-slider {
  margin-top: 40px;
}

.show__map-mobile {
  @include break-max($break-xs) {
    cursor: pointer;
    background: url('../../../images/png/show-map.png') no-repeat center;
    background-size: cover;
    height: 50px;
    width: 50px;
  }
}

.complex__address {
  @include break-max($break-xs) {
    align-items: center;
    justify-content: space-between;
  }
}

.complex__filter-clear-filter {
  align-items: center;
  background-color: $color-brand-opaque-10;
  border: none;
  border-radius: 3px;
  color: $color-brand-2;
  display: flex;
  height: 30px;
  font-size: 15px;
  line-height: 20px;
  padding: 0 10px;
}

.complex__filter-clear-wrapper {
  flex-basis: 100%;
}

.complex-filter_wrapper {
  padding: 0 12px 12px;

  @include break-max($break-xs) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 6px;
  }
}

.complex-results-wrapper {
  @include break-max($break-xs) {
    background: $color-brand-opaque-8;
    padding: 0 10px 27px;
  }
}

.complex__appartments-row {
  @include break-max($break-xs) {
    margin-bottom: 8px;

    .status-assignment {
      font-size: 11px;
    }
  }
}

.complex__slider-info {
  @include break-max($break-xs) {
    bottom: -70px;
  }
}

.complex__mobile-address {
  margin: 0 10px;

  .show__map-mobile {
    @include break-max($break-xs) {
      margin-left: 10px;
    }
  }
}

.complex__info-routes {
  @include break-max($break-xs) {
    margin-top: 12px;
  }
}

.section.complex__section-filter {
  .index-search__filter-applied {
    @include break-max($break-xs) {
      margin: 25px 0;
    }
  }
}

.complex__routes-item {
  @include break-max($break-xs) {
    margin-right: 0;
    margin-bottom: 3px;
  }
}

div.complex-filter_wrapper {
  & > .filtered, .complex-filter_wrapper > .filtered {
    @include break-max($break-xs) {
      margin-top: 5px;
    }
  }
}

.tns-slider.tns-carousel {
  @include break-max($break-xs) {
    .complex__tabs-item.tns-item {
      border: none;
      display: flex;
      justify-content: center;
    }

    .complex__tabs-value.active {
      color: $color-brand-2;

      .complex__tabs-desc {
        color: $color-brand-3;
        font-weight: normal;
      }
    }
  }
}

.complex__tabs-desc,
.complex__tabs-value {
  @include break-max($break-xs) {
    font-size: 15px;
    line-height: 18px;
  }

  @include break-max($break-xxs - 1px) {
    font-size: 14px;
  }
}

.complex__appartments-tab.active {
  @include break-max($break-xs) {
    & .complex__views {
      bottom: 20px;
      display: flex;
      justify-content: space-between;
      position: sticky;
      z-index: 6;
      width: 50%;

      .presentation-filters-btn {
        margin: 0;
      }

      .complex__view {
        width: 100%;
      }

      & .complex__view-item {
        align-items: center;
        background: $color-white;
        box-shadow: 0px 4px 20px $color-brand-opaque-30;
        border-radius: 22px;
        display: flex;
        justify-content: center;
        height: 44px;
        padding: 0 20px;

        svg {
          stroke: $color-brand-2;
          margin-right: 8px;
        }

        &.active {
          display: none;
        }
      }
    }
  }
}

.complex__blocks-slider.for-one {
  @include break-max($break-xs) {
    .section__grid-col.tns-slide-active {
      margin-left: 0;
    }
  }
}

.complex__filter-row {
  @include break-max($break-xs) {
    margin: 0;
  }
}

.complex__section-about {
  @include break-max($breakpoint-xs) {
    margin: 0 10px 20px;
  }
}

.complex__slider-thumbnails.for-constructor {
  justify-content: center;
  padding-bottom: 10px;
}

.complex__slider-info.for-contstructor {
  right: 0;
  bottom: -25px;
}

.complex__appartments-navigation .complex__views_space-between {
  justify-content: space-between;
}

.complex__select .custom-select__selected {
  width: 385px;
  height: 50px;
  background: $color-white;
  box-shadow: inset 0 0 0 1px rgba(143,149,172, 0.2);
}

.complex__select .custom-select.is-showing .custom-select__selected {
  background: $color-white;
}
