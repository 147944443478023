@import "../../../styles/variables";
@import "../../../styles/mixins";

.download-btn-link {
  font-size: 14px;
  margin-left: 15px;
  font-weight: 400;
  display: flex;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }
}

.with-tg-link {
  justify-content: space-between;
  display: flex;
  align-items: center;

  &__link {
    display: flex;
    color: #777A89;
    font-size: 14px;
    font-weight: 400;
    transition-duration: .3s;
    cursor: pointer;
    &:hover {
      color: #FF2D23;
    }

    svg {
      margin-left: 10px;
    }
  }
}

.sf__special-filter {
  display: flex;
  align-items: center;
  background-color: $color-white;

  .currencys-seelect__list {
    margin-top: 0;
    border-radius: 0 0 15px 15px;
    width: 100%;
  }

  .currencys-seelect__value {
    width: 100%;
    border-radius: 15px 15px 0 0;

    &:hover {
      background-color: transparent;
    }
  }
}

.sf__special-filter_dubai {

  .special-filter-drop {
    height: 20px;
  }
  .currencys-seelect_cardlist {
    height: 24px;
  }
  @include break-max($breakpoint-sm) {
    .special-filter-drop {
      width: 250px;

    }
    .currencys-seelect_cardlist.isOpen {
      box-shadow: 0px -15px 20px rgba(143, 149, 172, 0.1);
    }
  }

  @include break-max($breakpoint-xsm) {
    .currencys-seelect_cardlist {
      width: 150px;
      margin-left: -15px;
      .currencys-seelect__cource-cards {
        display: none;
      }
      .currencys-seelect__rate {
        display: none;
      }
    }
  }

}


.about-complex-mobile {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 20px;
  background-color: $gray-light;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    font-weight: bold;

    svg {
      margin-right: 15px;
    }
  }
}


.complex-mobile-popup {
  position: fixed;
  min-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1234;
  background-color: white;
  overflow: auto;

  .sf__info-header {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sf__info-body {
    padding-left: 10px;
    padding-right: 10px;
  }


}




.complex-mobile-header {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid $color-brand-opaque-20;
  justify-content: center;

  a {
    position: absolute;
    left: 10px;
  }
}

.complex-mobile-contacts {
  margin-top: 15px;

  .sf__manager-block {
    margin-top: 0;
  }
  .sf__manager {
    margin-bottom: 0;
    padding: 0;
    border: 0;
  }
}

.complex-filter-mobbtn {
  margin-top: -25px;

  button {
    width: 100%;
  }
}

.dubai-complex-filters-mobile {
  @include break-max($breakpoint-xs) {
    .sf__filter.sf__filter_dubai {
      display: block;
      margin-top: 0;
    }
  }
}













