@import '../../../styles/variables';
@import '../../../styles/mixins';

.act-info-header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 22px;
  padding: 0;



  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  &__description {
    color: $gray-dark-2;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin-top: 3px;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-dark;
  }

  &__link {
    text-decoration: underline;
    color: $green;
    transition-duration: .3s;

    &:hover {
      transition-duration: .3s;
      color: $green-link-hover;
    }
  }

  &--just-act {
    padding: 30px;
    border-radius: 20px;
    background-color: $gray-light;

    .act-info-header__description {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}


