@import '../../styles/variables';
@import '../../styles/mixins';

.crm-table {
  margin-bottom: 30px;

  .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-radius: 5px 0 0 5px;
  }
  .ant-table-container table > thead > tr:first-child th {
    border-right: 1px solid #565865;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-right: none;
    border-radius: 0 5px 5px 0;
  }
  .ant-table-container table > thead > tr th {
    padding-top: 11px;
    padding-bottom: 12px;
  }
  .ant-table-container table > tbody > tr {
    height: 50px;
  }
  .ant-table-container table > tbody > tr td:first-child {
    border-radius: 5px 0 0 5px;
  }
  .ant-table-container table > tbody > tr td:last-child {
    border-radius: 0 5px 5px 0;
  }

  &__end-term {
    @include text-xs-L();
    color: $color-red-bottom-border;
  }

  &__employer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    svg {
      margin-bottom: -5px;
    }
  }

  &__comment {
    max-width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__status {
    padding: 4px 10px;
    border-radius: 20px;
    white-space: nowrap;
  }

  &__deals-object-cell {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__deals-object-text {
    max-width: 275px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  &__sf-action {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__sf-controls {
    background-color: white;
    box-shadow: 0px 2px 20px rgba(143, 149, 172, 0.1);
    border-radius: 20px;
    padding: 20px;
    min-width: 230px;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 123;
    display: none;

    .crm-table__sf-controlsclose {
      position: absolute;
      top: 16px;
      right: 16px;
    }
    li {
      display: flex;
      padding-top: 5px;
      padding-bottom: 3px;
      transition-duration: .3s;
      border-radius: 10px;
      padding-left: 5px;
      padding-right: 5px;

      button {
        font-size: 14px;
        font-weight: 400;
      }

      &:hover {
        background-color: #F6F7F8;
      }

      span {
        display: flex;
        align-items: center;
        font-weight: 400;
      }

      svg {
        margin-right: 8px;
      }
    }
    li.disabled {
      //opacity: 0.5;
      cursor: default;
      //background-color: #fbfbfb;
      color: #bababa;

      svg {
        path {
          stroke: #bababa;
        }
      }

      .hint {
        position: absolute;
        background-color: $color-bg-light;
        margin-top: -100px;
        padding: 15px;
        border-radius: 20px;
        opacity: 0;
        display: none;
        transition-duration: .3s;
        color: black;
      }
      .hint:after {
        content: "";
        position: absolute;
        border: 5px solid transparent;
        border-top: 5px solid $color-bg-light;
        left: calc(50% - 2px);
        bottom: -10px;
      }
    }
    li.disabled:hover {
      background-color: white;
      .hint {
        opacity: 1;
        display: block;
      }
    }

  }

  .crm-table__sf-controls.active {
    display: block;
  }

  &__sf-opencontrols {
    display: flex;
    align-items: center;
    border-radius: 50%;
    transition-duration: .3s;
    padding: 3px;
    cursor: pointer;

    &:hover {
      background-color: rgba(143, 149, 172, 0.3);
    }
  }

  &__sf-controlsmodal {
    background-color: rgba(0,0,0,0.2);

    .modal__inner {
      width: 380px;
      border-radius: 20px;
    }

    .modal__inner {
      padding-left: 65px;
      padding-bottom: 28px;
    }
  }

  &__sf-controlsmodaltitle {
    display: flex;
    margin-bottom: 7px;
    align-items: center;
    font-weight: 700;
    margin-left: -36px;

    svg {
      margin-right: 10px;
    }
  }

  &__sf-controlsmodalbtns {
    display: flex;
    margin-top: 15px;
    gap: 10px;
    button {
      width: 110px;
      height: 44px;
    }
  }
}

.crm-table_client {

  .ant-table-container table > tbody > tr td:last-child {
    width: 310px;
  }
}

.crm-table_deals {

  .ant-table-container table > tbody > tr td:last-child {
    width: 370px;
  }
}

.crm-table_filters {
  .ant-table .ant-table-container table > thead > tr th {
    height: 45px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .ant-table .ant-table-container table > thead > tr th:nth-last-child(2) {
    border-right: 0;
  }
}


.crm-filters-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  &__first-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  &__left-cell {
    display: flex;
    gap: 20px;
  }

  &__search {
    width: 295px;
  }
  .dropdown.form__drop.is-showing{
    border-radius: 4px 4px 0 0;
    background-color: rgba(143, 149, 172, 0.2);
  }
  .checkbox__marker:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgNC43NjVsLjg1NS0uOCA0LjE5IDIuMjcyTDEyLjI2NyAwIDEzIC42NjUgNS4zNiAxMCAwIDQuNzY1eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
}
  .checkbox__control:checked + .checkbox__input{
    background-color: #ff2d23;
  }
  &__select {
    .form__item .form__drop-btn {
      background-color: $color-brand-opaque-8;
    }

    .custom-select {
      height: 50px;
      margin-bottom: 0;
      margin-left: 0;

      &__option {
        padding: 16px;
      }

      &__selected {
        height: 100%;
        background-color: $gray-light;
        box-shadow: 0 0 0 $color-brand-opaque-20;

        &:hover {
          box-shadow: #{$shadow};
        }

        &:after {
          margin-left: 10px;
        }
      }
    }
    .is-showing {
      .custom-select__selected {

        &:after {
          transform: rotate(180deg);
        }
      }
    }

    .dropdown {
      &__btn {
        background-color: #f6f7f8;
      }
    }
  }

  &__second-row {
    margin-top: 30px;
    display: flex;
    gap: 40px;

    .checkbox__label {
      color: $label-gray-blue-color;
    }
  }
}
.skeleton-box {
  overflow: hidden;
  background-color: #8f95ac14;
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
  .loading {
    left: 50%;
    top: 50%;
    position: absolute;
    overflow: hidden;
    transform: translate(-50%, -60%);

    &:before {
        color: #aaa;
        content: attr(data-loading-text);
    }

    &:after {
        top: 0;
        left: 0;
        width: 0;
        opacity: 1;
        color: #444;
        overflow: hidden;
        position: absolute;
        content: attr(data-loading-text);
        animation: loading 2s infinite;
    }

    @keyframes loading {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
}
}


.crm-filters-header__first-row .rdrDateDisplayItem input {
  color: black;
}

.crm-filters-header__first-row .rdrCalendarWrapper.rdrDateRangeWrapper {
  padding-bottom: 35px;
}

.crm-filters-header__first-row .form__item {
  margin-left: 0;
}

.crm-filters-header__first-row.crm-filters-header__first-row_back .form__item.custom-select {
  min-width: 210px;
}

.end-all-fixations {
  color: #ff2d23;
}
