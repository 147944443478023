@mixin break-max($bp) {
  @media screen and (max-width: #{$bp}) {
    @content;
  }
}

@mixin break-min($bp) {
  @media screen and (min-width: #{$bp}) {
    @content;
  }
}

@mixin break-min-max($min, $max) {
  @media (min-width: #{$min}) and (max-width: #{$max}) {
    @content;
  }
}

@mixin unlist() {
  list-style: none;
  padding-left: 0;
}


// Old imports

@mixin unlist() {
  list-style: none;
  padding-left: 0;
}

@mixin h1() {
  font-weight: bold;
  font-size: 48px;
  line-height: 55px;

  @include break-max($breakpoint-md) {
    font-size: 42px;
    line-height: 50px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 36px;
    line-height: 42px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 24px;
    line-height: 26px;
  }

  @include break-max($break-xxs - 1px) {
    font-size: 21px;
    line-height: 24px;
  }
}

@mixin h2() {
  font-weight: bold;
  font-size: 35px;
  line-height: 40px;

  @include break-max($breakpoint-md) {
    font-size: 30px;
    line-height: 35px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 28px;
    line-height: 33px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 21px;
    line-height: 24px;
  }

  @include break-max($break-xxs - 1px) {
    font-size: 18px;
    line-height: 22px;
  }
}

@mixin h3() {
  font-weight: bold;
  font-size: 25px;
  line-height: 28px;

  @include break-max($breakpoint-md) {
    font-size: 20px;
    line-height: 26px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 18px;
    line-height: 22px;
  }
}

@mixin h4() {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;

  @include break-max($breakpoint-md) {
    font-size: 16px;
    line-height: 20px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin h5 {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: .3px;
}

@mixin h55() {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: normal;
  text-transform: unset;
}

@mixin h6() {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

@mixin link {
  color: $color-red;
  text-decoration: none;
  cursor: pointer;
  transition: color $transition-default;
  padding: 0;
  font-family: inherit;
  font-size: inherit;

  &:hover {
    color: $color-red-hover;
  }
}

@mixin big-text {
  font-size: 21px;
  line-height: 30px;
  @include break-max($breakpoint-md) {
    font-size: 18px;
    line-height: 26px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 16px;
    line-height: 24px;
  }
}

@mixin big-text-sm {
  font-size: 18px;
  line-height: 24px;
  @include break-max($breakpoint-sm) {
    font-size: 17px;
    line-height: 22px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 15px;
    line-height: 16px;
  }
}

@mixin break-max($bp) {
  @media screen and (max-width: #{$bp}) {
    @content;
  }
}

@mixin break-min($bp) {
  @media screen and (min-width: #{$bp}) {
    @content;
  }
}

@mixin wrapper() {
  position: relative;
  padding-left: #{$fields};
  padding-right: #{$fields};
  max-width: $wrapper;
  margin: 0 auto;

  @include break-max($breakpoint-md) {
    padding-left: 20px;
    padding-right: 20px;
    max-width: $wrapper-md;
  }

  // Отступ контейнера для фиксированной панели справа
  @include break-max(1330px) {
    padding-left: 40px;
    padding-right: 56px;
    max-width: $wrapper-md;
  }

  @include break-max($breakpoint-sm) {
    padding-left: #{$fields-sm};
    padding-right: #{$fields-sm};
    max-width: 100%;
  }

  @include break-max($break-xs) {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }
}

@mixin grid() {
  display: flex;
  flex-wrap: wrap;
  margin-left: -#{$grid-gutter / 2};
  margin-right: -#{$grid-gutter / 2};

  @include break-max($break-lg) {
    margin-left: -#{$grid-gutter-md / 2};
    margin-right: -#{$grid-gutter-md / 2};
  }

  @include break-max($break-md) {
    margin-left: -#{$grid-gutter-sm / 2};
    margin-right: -#{$grid-gutter-sm / 2};
  }

  @include break-max($break-sm) {
    margin-left: -#{$grid-gutter-xs / 2};
    margin-right: -#{$grid-gutter-xs / 2};
  }

  @include break-max($breakpoint-xs) {
    margin-left: 0;
    margin-right: 0;
  }
}

@mixin grid-col() {
  margin-left: #{$grid-gutter / 2};
  margin-right: #{$grid-gutter / 2};

  @include break-max($break-lg) {
    margin-left: #{$grid-gutter-md / 2};
    margin-right: #{$grid-gutter-md / 2};
  }

  @include break-max($break-md) {
    margin-left: #{$grid-gutter-sm / 2};
    margin-right: #{$grid-gutter-sm / 2};
  }

  @include break-max($break-sm) {
    margin-left: #{$grid-gutter-xs / 2};
    margin-right: #{$grid-gutter-xs / 2};
  }
}

@mixin grid-col-p() {
  padding-left: #{$grid-gutter / 2};
  padding-right: #{$grid-gutter / 2};

  @include break-max($break-lg) {
    padding-left: #{$grid-gutter-md / 2};
    padding-right: #{$grid-gutter-md / 2};
  }

  @include break-max($break-md) {
    padding-left: #{$grid-gutter-sm / 2};
    padding-right: #{$grid-gutter-sm / 2};
  }

  @include break-max($break-sm) {
    padding-left: #{$grid-gutter-xs / 2};
    padding-right: #{$grid-gutter-xs / 2};
  }
}

@mixin grid-size($n, $gutter: #{$grid-gutter}) {
  width: calc(100% / #{$grid-columns} * #{$n} - #{$gutter});
}

@mixin grid-size-p($n) {
  width: calc(100% / #{$grid-columns} * #{$n});
}

@mixin grid-shift-left($n, $gutter: #{$grid-gutter}) {
  margin-left: calc(100% / #{$grid-columns} * #{$n} + #{$gutter / 2});
}

@mixin grid-shift-left-p($n) {
  margin-left: calc(100% / #{$grid-columns} * #{$n});
}

@mixin grid-shift-right($n, $gutter: #{$grid-gutter}) {
  margin-right: calc(100% / #{$grid-columns} * #{$n} + #{$gutter / 2});
}

@mixin grid-shift($n, $gutter: #{$grid-gutter}) {
  margin-left: calc(100% / #{$grid-columns} * #{$n} + #{$gutter / 2});
  margin-right: calc(100% / #{$grid-columns} * #{$n} + #{$gutter / 2});
}

@mixin h5 {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: .3px;
}


@mixin h1-L() {
  font-weight: 700;
  // font-size: 48px;
  // line-height: 52px;
  font-size: 42px;
  line-height: 46px;

  @include break-max($breakpoint-md) {

  }

  @include break-max($breakpoint-sm) {
    font-size: 36px;
    line-height: 40px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 24px;
    line-height: 26px;
  }
}

@mixin h2-L() {
  font-weight: 700;
  // font-size: 35px;
  // line-height: 40px;

  font-size: 30px;
  line-height: 35px;

  @include break-max($breakpoint-md) {

  }

  @include break-max($breakpoint-sm) {
    font-size: 28px;
    line-height: 32px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 21px;
    line-height: 24px;
  }
}

@mixin h3-L() {
  font-weight: 700;
  // font-size: 25px;
  // line-height: 30px;

  font-size: 20px;
  line-height: 26px;

  @include break-max($breakpoint-md) {

  }

  @include break-max($breakpoint-sm) {
    font-size: 18px;
    line-height: 22px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 18px;
    line-height: 20px;
  }

}

@mixin h4-L() {
  font-weight: 700;
  // font-size: 20px;
  // line-height: 28px;
  
  font-size: 18px;
  line-height: 20px;

  @include break-max($breakpoint-md) {

  }

  @include break-max($breakpoint-sm) {
    font-size: 17px;
    line-height: 20px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 14px;
    line-height: 16px;
  }

}

@mixin h5-L() {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
  letter-spacing: normal;

  @include break-max($breakpoint-md) {
    font-size: 16px;
    line-height: 18px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 14px;
    line-height: 18px;
  }

}

@mixin h6-L() {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

@mixin big-text-L() {
  font-weight: 300;
  font-size: 21px;
  line-height: 30px;

  @include break-max($breakpoint-md) {
    font-size: 18px;
    line-height: 24px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 17px;
    line-height: 22px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 16px;
    line-height: 22px;
  }
}

@mixin medium-text-L() {
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;

  @include break-max($breakpoint-xs) {
    font-size: 15px;
    line-height: 18px;
  }
}

@mixin caps-L() {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;

  @include break-max($breakpoint-md) {
    font-size: 12px;
    line-height: 18px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 11px;
    line-height: 18px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 12px;
    line-height: 16px;
  }
}

@mixin text-L() {
  font-size: 14px;
  line-height: 18px;
}

@mixin text-xs-L() {
  font-size: 13px;
  line-height: 16px;
}
