@import "../../styles/variables";
@import "../../styles/mixins";

.prepaid-banner {
  margin: 45px 0;
  position: relative;
  cursor: pointer;
  max-width: 100%;

  @include break-max($break-xs) {
    margin: 20px 0;
  }

  a {
    display: block;
    max-width: 100%;
  }

  object {
    pointer-events: none;
    max-width: 100%;
  }

  &.events-banner {
    margin-top: 0;
    @include break-max($break-xs) {
      margin: 20px 0;
    }
  }
}
.banner-into{
  margin: 15px 0 45px;
  @media screen and (max-width: 1599px) {
    margin: 29px 0 45px
  }
}
