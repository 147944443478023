.form-attach {
  &__tab {
    display: none;

    &.active {
      display: block;
    }
  }

  &__body {
    width: 210px;
    color: $color-brand-2;
  }

  &__new-client {
    width: 325px;
  }

  &__clients {
    margin-bottom: 20px;
  }

  &__client {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    & .button {
      margin-left: 10px;
    }
  }

  &__add {

    & .button {
      display: flex;
      align-items: center;
      padding: 13px 18px;

      .icon {
        font-size: 9px;
      }
    }
  }

  &__submit {
    margin-top: 20px;
  }

  &__return {
    margin-bottom: 20px;

    & .button {
      padding: 13px 18px;
    }
  }
}
