.profile__header {
  display: flex;
  align-items: flex-end;
}

.profile__access {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 8px;
}