@import "../../styles/variables";
@import "../../styles/mixins";

.autopan-trigger {
  position: relative;
  font-size: 14px;
  line-height: 30px;
  background-color: $color-brand-opaque-10;
  padding: 0 12px;
  border-radius: 4px;
  margin-top: 10px;
  margin-right: 7px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  color: $color-brand-3;

  cursor: pointer;
  transition: background-color $transition-default;

  .icon {
    margin-left: 8px;
    width: 20px;
    height: 20px;
  }


  &::after {
    content: "Просмотр объекта с АвтоПАН"; /* Выводим текст */
    position: absolute;
    left: 80%;
    top: -100%;
    z-index: 1;
    background: white;
    font-size: 11px;
    padding: 5px 10px;
    box-shadow: 0px 2px 10px rgba(44, 46, 62, 0.2);
    border-radius: 4px;
    white-space: nowrap;
    color: $color-brand-2;
    font-weight: 700;
    display: none;
  }


  &:hover {
    background-color: $color-brand-opaque-20;
    &::after {
      display: block;

      @include break-max($break-xs) {
        content: '';
        display: none;
      }
    }

  }
}


.autopan-trigger_detail {
  position: relative;
  top: -4px;
  min-width: 180px;
  margin-top: 0;
  margin-right: 0;
  margin-left: auto;
  align-self: center;
  color: $color-red;
  font-weight: 400;

  @include break-max($break-xs) {
    margin-top: 15px;
  }

  .icon {
    fill: $color-brand-2;
  }
}
