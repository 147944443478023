.infra {
  .checkbox__marker {
    background: $color-1;
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='10'%3E%3Cpath d='M0 4.765l.855-.8 4.19 2.272L12.267 0 13 .665 5.36 10 0 4.765z' fill='%23ffffff'/%3E%3C/svg%3E");
    }
  }
}

.infra__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.infra__item {
  display: flex;
  flex-basis: 20%;

  &:nth-child(5n) {
    padding-right: 0;
  }
  &:nth-child(n+6) {
    margin-top: 20px;
  }
}

.infra__markers {
  display: flex;
}

.infra__marker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 41px;
  border-radius: 50%;

  &_kindergarten {
    background-color: #8790E5;
  }
  &_polyclinic {
    background-color: #FF2D23;
  }
  &_school {
    background-color: #6A4540;
  }
  &_relax {
    background-color: #48B30A;
  }
  &_gym {
    background-color: #FF6B23;
  }
  &_stores {
    background-color: #2C2E3E;
  }
  &_cafe {
    background-color: #2C2E3E;
  }
  &_banks {
    background-color: #6E489F;
  }
  &_parking {
    background-color: #8F95AC;
  }
}

.infra__icon {
  margin-left: 15px;
}
