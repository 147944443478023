.filter-disabled {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 90px;
  padding-bottom: 90px;
  max-width: 980px;
  min-height: 100vh;
  margin: 0 auto;
}

.filter-disabled__title {
  margin: 40px 0 60px;
  text-align: center;
}

.icon_filter-disabled {
  width: 565px;
  height: 255px;
}

.filter-disabled__agent {
  border: 1px solid rgba(143, 149, 172, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 40px;
}
