:root {
  --checkbox-width: 22px;
  --checkbox-height: 22px;
  --checkbox-animation-duration: 0.2s;
  --color-primary: #696D82;
  --color-input: #{$color-brand-2};
}

.checkbox {
  $c: &;
  display: flex;
  align-items: center;

  &_view_flex-start {
    align-items: flex-start;
  }

  &_view_invert {
    #{$c}__box {
      box-shadow: inset 0 0 0 1px $color-brand-opaque-50;
      background-color: transparent;
    }

    #{$c}__label {
      color: $color-brand-3;
    }
  }

  &_view_red {
    #{$c}__control:checked + #{$c}__input {
      background-color: $color-red;
    }

    #{$c}__control:checked + #{$c}__marker {
      background-color: $color-red;
    }

    #{$c}__marker:after {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgNC43NjVsLjg1NS0uOCA0LjE5IDIuMjcyTDEyLjI2NyAwIDEzIC42NjUgNS4zNiAxMCAwIDQuNzY1eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
    }
  }

  &_disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  &.is-excluded {
    #{$c}__box {
      background-color: $color-red;
      box-shadow: inset 0 0 0 1px $color-red;;
    }

    #{$c}__marker:after {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgNC43NjVsLjg1NS0uOCA0LjE5IDIuMjcyTDEyLjI2NyAwIDEzIC42NjUgNS4zNiAxMCAwIDQuNzY1eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
    }
  }

  &_type {

    &_btn {

      & #{$c}__box {
        box-shadow: none;
        width: auto;
        height: auto;
      }

      & #{$c}__control {
        margin: 0;
        padding: 0;
        opacity: 0;
        height: 0;
        width: 0;
        pointer-events: none;

        &:checked + .checkbox__label:after {
          content: '';
          height: calc(100% + 12px);
          position: absolute;
          top: -6px;
          left: -6px;
          width: calc(100% + 12px);
          background-color: $color-brand-opaque-10;
          border-radius: 4px;
        }
      }
    }
  }
}

.checkbox__box {
  position: relative;
  outline: none;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: var(--checkbox-width);
  height: var(--checkbox-height);
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px $color-brand-opaque-30;
  transition: box-shadow $transition-default;
  background-color: #fff;

  @include break-max($break-xs) {
    background-color: transparent;
  }
}

.checkbox__control, .checkbox__input {
  position: absolute;
  left: 0;
  top: 0;
}

.checkbox__control {
  margin: 0;
  padding: 0;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;

  &:checked + .checkbox__input + .checkbox__marker {
    opacity: 1;

    &:after {
      transform: rotate(360deg);
    }
  }


}

.checkbox__input {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: transparent;
  user-select: none;
  // background-color: var(--color-input);
  border-radius: inherit;
  z-index: 1;
  cursor: pointer;
  transition: background var(--checkbox-animation-duration);
}

.checkbox__marker {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 2;
  pointer-events: none;
  border-radius: inherit;
  opacity: 0;
  transition: opacity var(--checkbox-animation-duration);

  &::after, .checkbox__marker-icon {
    content: '';
    display: block;
    width: 13px;
    height: 10px;
    position: absolute;
    top: 6px;
    left: 4px;
    transform: rotate(0);
    transition: transform .3s cubic-bezier(0,0,0.12,1);
  }
  
  &::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMCI+PHBhdGggZD0iTTAgNC43NjVsLjg1NS0uOCA0LjE5IDIuMjcyTDEyLjI2NyAwIDEzIC42NjUgNS4zNiAxMCAwIDQuNzY1eiIgZmlsbD0iIzhGOTVBQyIvPjwvc3ZnPg==);
  }
}

.checkbox__label {
  color: $color-brand-2;
  font-size: 14px;
  line-height: 17px;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &_agreement {
    @include break-max($breakpoint-xs) {
      order: 10;
      margin-left: 10px;
    }
  }
}

.checkbox__label + .checkbox__box {
  padding-left: 14px;
}

.checkbox__box + .checkbox__label {
  padding-left: 14px;
}


.checkbox_view_color-2 {
  .checkbox__label {
    color: $color-brand-2;
  }
}