$grid-columns: 12;

$grid-gutter: 30px;
$grid-gutter-md: 22px;
$grid-gutter-sm: 16px;
$grid-gutter-xs: 10px;

$fields: 64px;
$fields-md: 40px;
$fields-sm: 20px;
$fields-xs: 15px;
$fields-xxs: 10px;

$wrapper: 1600px;
$wrapper-md: 1280px;
$wrapper-sm: 768px;
$wrapper-xsm: 375px;

$break-lg: #{$wrapper};
$break-md: #{$wrapper-md};
$break-sm: #{$wrapper-sm};
$break-xs: 640px;
$break-xxs: 375px;
$break-3xs: 320px;

$breakpoint-md: $wrapper - 1px; // 1599px
$breakpoint-sm: $wrapper-md - 1px; // 1279px
$breakpoint-xsm: 1024px - 1px; // 1023px
$breakpoint-xs: $wrapper-sm - 1px; // 767px
$breakpoint-xxs: $wrapper-xsm - 1px; // 374px

$layout-breakpoint-1200: 1200px;
$layout-breakpoint-1150: 1150px;
$layout-breakpoint-1100: 1100px;
$layout-breakpoint-1050: 1050px;


// Colors
$color-red: rgb(255, 45, 35);
$color-red-opaque-15: rgba($color-red, 0.15);
$color-red-opaque-20: rgba($color-red, 0.2);

$color-red-hover: rgb(207, 45, 35);
$color-red-active: rgb(179, 45, 35);

$color-red-bottom-border: #FF2D23;
$one-more-red: #CF2D23;
$one-more-blue: #EEEFFB;
$one-more-yellow: #FFF4CB;

$color-brand-1: #1e2030; // Black_dark
$color-brand-2: #2c2e3e; // Black_light
$color-brand-3: #8f95ac; // Gray_light
$color-brand-3-hover: #666b81;

$color-dark-theme: #bfc4d8;
$color-white: #fff;
$color-white-15: rgba($color-white, 0.15);
$color-icon: #91a3e9;
$color-disabled: #F6F7F9;

$color-brand-2-o-20: rgba($color-brand-2, 0.2);

 // Gray_light
$color-brand-opaque-8: rgba($color-brand-3, 0.08);
$color-brand-opaque-10: rgba($color-brand-3, 0.1);
$color-brand-opaque-12: rgba($color-brand-3, 0.12);
$color-brand-opaque-15: rgba($color-brand-3, 0.15);
$color-brand-opaque-20: rgba($color-brand-3, 0.2);
$color-brand-opaque-30: rgba($color-brand-3, 0.3);
$color-brand-opaque-40: rgba($color-brand-3, 0.4);
$color-brand-opaque-50: rgba($color-brand-3, 0.5);
$color-brand-opaque-60: rgba($color-brand-3, 0.6);
$color-brand-opaque-70: rgba($color-brand-3, 0.7);

$orange: #ffa100;
$purple: #7684bc;
$violet: $color-brand-3;
$green: #2cba2e;
$yellow: #FBA200;
$blue: #8790E5;
$green-light: #44D89F;
$green-light-hover: #29c78a;
$green-light-active: #22a472;
$gray: $color-brand-3;

$gray-light: #f6f7f8; /* GrayPallete/BkgGray */
$gray-dark: #DFE1E9; /* GrayPallete/LineGray */

$gray-light-2: #8F95AC;
$gray-dark-2: #777A89; /* BasePallete/DarkGray */

$gray-light-3: #ECEDF0;

$gray-20: rgba(143, 149, 172, 0.2);
$orange-20: rgba($orange, 0.2);
$orange-30: rgba(255, 204, 0, 0.3);
$purple-20: rgba(118, 132, 188, 0.2);
$violet-20: rgba(118, 132, 188, 0.2);
$blue-20: #8790e533;
$green-hover: #259e27;
$green-active: #1e8220;
$green-20: rgba(44, 186, 46, 0.2);
$color-red-10: rgba(255, 45, 35, 0.1);
$yellow-20: rgba(255, 204, 0, 0.2);

$color-brand-o-30: #dddfe6;

$color-border: #e9eaeb;
$color-bg-light: #f6f7f9;
$color-metro-1: #d4203c;
$color-metro-2: #137fc9;
$color-metro-3: #15985b;
$color-metro-4: #dc7021;
$color-metro-5: #6d1477;

$shadow: 0 2px 10px $color-brand-opaque-20;
$shadow-dark: 0 2px 10px $color-brand-2-o-20;

$transition-default: .15s ease-in-out;

// Background socials
$color-ig: linear-gradient(42.3deg, #FFD569 -11.65%, #F35633 29.09%, #C62992 65.58%, #585AD1 105.11%);
$color-vk: #4D7198;
$color-fb: #3B5998;
$color-tg: #0088CC;
$color-wa: #25D366;
$color-viber: #665CAC;


//deal statuses
$deal-completed-bg-status: #DFF5E0;
$deal-inwork-bg-status: #FFF4CB;
$deal-booked-color-status: #67b2f8;
$deal-booked-bg-status: #e6f3ff;
$deal-error-bg-status: #FFE6E5;
//67b2f8


$green-link-hover: #99d59a;

$label-gray-blue-color: #777A89;
