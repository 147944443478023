@import '../../../styles/variables';
@import '../../../styles/mixins';

.calculator-commercial {
  background-color: $gray-light;
  border-radius: 20px;
  position: relative;
  padding: 30px;

  .slider__input {
    width: calc(100% - 20px);
    margin-left: 10px;
  }

  .slider__input .noUi-handle {
    width: 16px;
    height: 16px;
    border: 2px solid $color-white;
    right: -8px;
    top: -8px;
  }

  .calculator__crumbs {
    display: none;
  }

  .calculator__grid {
   flex-direction: column;
  }

  .calculator__form {
    width: calc(100% - 20px);
    border-bottom: 1px solid $gray-dark;
    padding-bottom: 10px;
  }

  .calculator__result-list {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    gap: 35px;
  }

  .calculator__result {
    button:disabled {
      color: #DFE1E9;
    }

    .new-alert {
      margin-bottom: 0;
      padding-top: 12px;
      padding-bottom: 11px;

      @include break-max($breakpoint-xsm) {
        padding-left: 15px;
        padding-right: 15px;

        .new-alert__icon {
          margin-right: 10px;
        }
      }
    }
  }

  .calculator__result {
    width: 100%;
    margin-bottom: 15px;
    display: block;
  }
  .calculator__result-title {
    padding-bottom: 15px;
    margin-top: 10px;
  }


  .calculator__result-list-item {
    display: flex;
    margin-bottom: 10px;

    svg {
      margin-right: 13px;
    }
  }

  &__bottom {
    display: flex;
    gap: 35px;
    width: calc(100% - 20px);
    align-items: center;
  }

  .button-blue-link {
    display: flex;
    color: #8790E5;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    height: 30px;
    transition-duration: .3s;

    svg {
      margin-right: 10px;
    }
    &:hover {
      color: #4f57a0;

      svg {
        path {
          stroke: #4f57a0;
        }
      }
      &:disabled {
        svg {
          path {
            stroke: #DFE1E9;
          }
        }
      }
    }
    &:disabled {
      cursor: default;

      svg {
        path {
          stroke: #DFE1E9;
        }
      }
    }

  }

  .new-alert {
    width: auto;
  }

  .mortgage-percents {
    display: flex;
    justify-content: space-between;

    ul {
      display: flex;
      position: absolute;
      right: 0;

      li {
        color: #8790E5;
        cursor: pointer;
        transition-duration: .3s;
        margin-left: 16px;
        list-style-type: none;
        position: relative;

        &:after {
          content: '/';
          color: #777A89;
          margin-left: 5px;
          position: absolute;
        }

        &:last-child:after {
          content: '';
        }

        &:hover {
          color: #4f57a0;
        }
      }
    }
  }
}

.calculator-mortgage {
  .mortgage-percents {
    display: flex;
    justify-content: space-between;

    ul {
      display: flex;
      position: absolute;
      right: 0;

      li {
        color: #8790E5;
        cursor: pointer;
        transition-duration: .3s;
        margin-left: 16px;
        list-style-type: none;
        position: relative;

        &:after {
          content: '/';
          color: #777A89;
          margin-left: 5px;
          position: absolute;
        }

        &:last-child:after {
          content: '';
        }

        &:hover {
          color: #4f57a0;
        }
      }
    }
  }
}

.calculator.calculator-mortgage.calculator-mortgage_for-pdf {
  .mortgage-percents {
    ul {
      display: flex;
      position: absolute;
      right: 0;

      li {
        color: #8790E5;
        cursor: pointer;
        transition-duration: .3s;
        margin-left: 16px;
        list-style-type: none;
        position: relative;

        padding: 0;
        background-color: transparent;
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
  .calculator__crumbs {

  }
}
