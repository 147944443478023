@import '../../styles/variables';
@import '../../styles/mixins';

.common-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  &__title {
    display: flex;
  }

  &__chevron {
    margin-right: 20px;
    padding: 5px 10px;
    margin-left: -10px;
    height: max-content;
    cursor: pointer;
  }

}

.common-title {
  max-width: 728px;
}

.deal-content {
  max-width: 728px;
}

.deal-wrapper {
  position: relative;
}

.app-tabs {
  border-bottom: 1px solid $gray-dark;
  margin-top: 21px;
}

.app-tabs-list {
  display: flex;

  &__item {
    min-width: 140px;
    text-align: center;
    padding: 10px 30px;
    position: relative;
    cursor: pointer;

    &--active {
      &:before {
        content: '';
        height: 3px;
        width: 100%;
        background-color: $color-red-bottom-border;
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
      }

    }
  }

}


.sf-settings-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 510px;
}

.sf-settings__sf-overfilters .sf__tags {
    flex-direction: column;
    gap: 30px;

  .index-search__filter-inc {
    margin-right: 0;

    &:after {
      top: calc(100% + 15px);
      margin-left: 0;
      left: calc(50% - 10px);
    }
  }
}

.sf-settings__sf-overfilters {
  .form__item {
    margin-left: 0;
  }
  .index-search__show-booked_second {
    position: relative;
  }
}

.sf-setting-view {
  border-radius: 10px;
  background-color: $gray-light-3;
  padding: 20px;
  display: flex;
  align-items: center;
  color: $gray-dark-2;

  svg {
    margin-right: 10px;
  }
}

.sf-setting-common {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  border-radius: 20px;
  background-color: $gray-light;
  margin-bottom: 35px;

  .hp__form-item {
    input {
      box-shadow: none;
    }
  }
  .hp__form-item.disabled {
    opacity: 0.7;
  }

  .hp__form-item + .hp__form-item {
    margin-top: 0;
  }

  .hp__form-item.disabled {
    input:disabled {
      background-color: white;

    }
  }

  &__titleblock {
    display: flex;
    flex-direction: column;
    gap: 20px;


    .input_type_form .input__control {
      box-shadow: none;
    }


    h3 {
      margin-top: 20px;
    }
  }

  &__title {
    margin-top: 20px;

  }

  &__select {
    margin-left: 0;

    .form__drop-btn {
      background-color: white;
    }
  }

  .checkbox__label {
    color: $gray-dark-2;
    margin-top: -2px;
  }
}

.sf-setting-common__select_sf.form__item:last-of-type {
  margin-right: 6px;
}

.sf-setting-common__select_clear {
  margin-bottom: 0;
}

.sf-setting-links {
  display: flex;
  gap: 20px;

  &__item {
    border-radius: 5px;
    background-color: $gray-light-3;
    width: calc(100% / 3);
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition-duration: .3s;

    &:hover {
      background-color: #EEEFFB;

      p {
        color: #8790E5;
      }
    }
  }
  &__icon {
    background-color: white;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin-bottom: 5px;
  }
  &__text {
    color: $gray-dark-2;
  }
}



.hp__form-colorpicker {
  position: absolute;
  right: 8px;
  top: 13px;
}























