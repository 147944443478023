.index-search {
  padding-bottom: 50px;

  @include break-max($breakpoint-md) {
    padding-bottom: 40px;
  }

  @include break-max($breakpoint-sm) {
    padding-bottom: 0px;
  }

  &__header {
    @include grid-size(10);
    @include grid-shift(1);
    text-align: center;
    margin: 50px auto 40px;

    @include break-max($breakpoint-md) {
      @include grid-size(12);
      @include grid-shift(0);
    }

    @include break-max($breakpoint-md) {
      margin: 40px auto 40px;
    }

    @include break-max($breakpoint-xs) {
      margin: 20px auto 20px;
    }

  }

  &__filter {
    @include grid-size(10);
    @include grid-shift(1);

    @include break-max($breakpoint-md) {
      @include grid-size(12);
      @include grid-shift(0);
    }
      &-public {
        padding-top: 60px;
      }

    @include break-max($breakpoint-xs) {
      padding-top: 0;
      margin: 0;
      width: 100%;
    }

    @include break-max($breakpoint-xs) {
      margin: 0;
      max-width: 100%;
    }


    &.is-focused {
      & .button_type_reveal-filter {
        display: block;
      }

      & .form__item {
        &:not(.form__item_main) {
          display: none;
        }
      }

      & .form__item_main {
        flex: 1;
      }
    }

    & .button_type_reveal-filter {
      display: none;
    }

    @include break-max($breakpoint-sm) {
      & .button_type_reveal-filter {
        display: block !important;
      }
    }
  }

  &__filter-body {
    background-color: $color-brand-opaque-8;
    border-radius: 5px;

    @include break-max($breakpoint-xs) {
      background: none;
      border-radius: 0;
    }
  }

  &__filter-top {
    padding: 31px;
    background-color: $color-brand-3;
    border-radius: 4px;

    @include break-max($breakpoint-sm) {
      padding: 22px;

      & .form__item:not(.form__item_main) {
        order: 10;
        margin-top: 12px;
      }

      & .form__row .button_type_reveal-filter {
        display: inlinde-block;
      }
    }

    @include break-max($breakpoint-xs) {
      background: none;
      padding: 14px 10px 0;

      & .dropdown {
      }

      & .form__drop .dropdown__block {
        right: 0;
        min-width: 300px
      }

      & .form__drop-content {
        min-width: 300px;
      }
    }
  }

  &__filter-main {
    padding: 30px 30px 24px;

    @include break-max($breakpoint-sm) {
      padding: 22px;
    }
  }

  &__filter-params {
    margin-right: 20px;
    padding: 0 12px 12px;
    flex: 1;

    @include break-max($breakpoint-xs) {
      padding: 0;
      margin: 0 0 12px;
      text-transform: none;
    }
  }

  &__filter-applied {
    padding-top: 6px;
    display: flex;
    align-items: stretch;

    &.is-hidden {
      display: none;

      & + .orders__filter-mobile {
        display: none;
      }
    }

    @include break-max($breakpoint-xs) {
      flex-wrap: wrap;
      padding: 0;
    }

    @include break-max($breakpoint-xs) {
      padding: 0 5px;
    }
  }



  &__filter-inc,
  &__filter-exc {
    //padding: 0 12px 12px;
    padding: 12px;
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    //align-content: flex-start;
    flex-wrap: wrap;
    border-radius: 4px;
    transition: background-color $transition-default;
    border: 1px dashed $color-brand-opaque-20;

    align-content: center;

    @include break-max($breakpoint-xs) {
      border: none;
      padding: 0;
    }

    & > .filtered {
      flex-shrink: 0;
      margin-right: 12px;
      //margin-top: 12px;
      margin-top: 6px;
      margin-bottom: 6px;
    }

    & > .filtered-clear {
      //margin-top: 12px;
      margin-top: 6px;
      margin-bottom: 6px;
    }

    & > *:first-child {

      &.filtered-clear {
        display: none;
      }
    }

    &.is-dragover {
      background-color: $color-brand-opaque-8;
    }

    &, & > .filtered {
      @include break-max($breakpoint-xs) {
        border: none;
        margin: 0;
        padding: 0;
      }
    }

    & > .filtered {
      @include break-max($breakpoint-xs) {
        margin: 0 5px 5px 0;
      }
    }

    & .filtered-clear__wrapper {
      //margin-top: 12px;
      margin-top: 6px;
      margin-bottom: 6px;

      @include break-max($breakpoint-xs) {
        flex-basis: 100%;
        margin-top: 0px;

        .button {
          font-size: 15px;
        }
      }
    }
  }

  &__filter-tip {
    display: none;
    color: $color-brand-3;
    margin: 1.3em auto auto;
  }

  &__filter-inc {
    margin-right: 44px;
    position: relative;

    @include break-max($breakpoint-xs) {
      flex-basis: 100%;
      margin-right: 0;
      margin-top: 15px;
      padding: 0 5px;

      &:after {
        display: none;
      }
    }

    &:after {
      content: "";
      position: absolute;
      width: 20px;
      height: 13px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjg3IDIuMjY1VjBsNC44MyAyLjc2Ni00LjgzIDIuNzY1VjMuMjY1SDIuNDE1di0xaDExLjQ1M3pNNC44MzEgOS42MzRWNy4zNjlMMCAxMC4xMzRsNC44MzEgMi43NjV2LTIuMjY1aDExLjQ1NHYtMUg0LjgzMXoiIGZpbGw9IiM4Rjk1QUMiLz48L3N2Zz4=);
      background-position: center;
      background-repeat: no-repeat;
      top: 50%;
      left: 100%;
      margin-left: 12px;
      transform: translateY(-50%);
    }
  }

  &__filter-exc {
    @include break-max($breakpoint-xs) {
      margin-top: 20px;
    }
    & .filtered {
      background-color: $color-red-opaque-20;
      box-shadow: inset 0 0 0 1px transparent;

      @include break-max($breakpoint-xs) {
        background: none;
        box-shadow: none;

        .filtered__inner {
          background-color: $color-red-opaque-20;
          box-shadow: inset 0 0 0 1px transparent;
        }
      }

      &:hover {
        box-shadow: inset 0 0 0 1px transparent;
        background-color: $color-red;

        & .filtered__type {
          color: $color-white;
        }

        & .filtered__value {
          color: $color-white;
        }

        & .filtered__del .icon {
          stroke: $color-white;
        }
      }
    }
  }

  &__filter-controls {
    padding: 18px 0 0;
    display: flex;
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;
    @include break-max($breakpoint-sm) {
      display: none;
    }
  }

  &__filter-controls-m {
    padding: 18px 0 0;
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;
    display: none;
    @include break-max($breakpoint-sm) {
      display: flex;
      margin: 0;
    }

    @include break-max($breakpoint-xs) {
      flex-direction: column;
      padding-top: 0px;
    }
  }

  &__filter-controls-col {
    display: flex;
    flex-grow: 1;
    width: 100%;

    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }

    & + & {
      margin-left: 30px;

      @include break-max($breakpoint-xs) {
        display: flex;
        justify-content: center;
        margin-left: 0;
      }
    }
  }

  &__filter-control {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    width: 100%;

    @include break-max($breakpoint-sm) {
      margin: 0;
    }
  }

  &__filter-voice {
    flex: 1 0 calc(100% / 10 * 4 - 30px);

    @include break-max($breakpoint-sm) {
      flex: 0 0 auto;
      flex-shrink: 1;
    }

    & .button {
      margin-right: 12px;
    }

    & span {
      @include break-max($breakpoint-sm) {
        display: none;
      }
    }
  }

  &__filter-submit {
    flex: 0 0 calc(100% / 10 * 2 - 30px);
    text-align: center;
    display: flex;
    justify-content: center;
    @include break-max($breakpoint-xs) {
      margin-bottom: 25px;
    }
  }

  &__filter-options {
    flex: 0 0 calc(100% / 10 * 4 - 30px);
    justify-content: space-between;

    // @include break-max($breakpoint-md) {
    //   justify-content: flex-end;
    //   flex-grow: 1;
    // }
  }

  &__filter-save {
    display: flex;
    align-items: center;


    @include break-max($breakpoint-xs) {
      width: 100%;
      .button_view_outline {
        width: 100%;
        margin-top: 45px;
      }
    }

    & .button_type_settings {
      margin-left: 12px;
    }

    @include break-max($breakpoint-md) {
      justify-content: space-between;
    }
  }

  &__show-booked {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &__search {
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 14px;

    & .info {
      margin-left: auto;
    }
  }

  &__tooltip {
    width: 124px;
    height: 43px;
    align-self: flex-start;
    display: none;
    background-color: $color-white;
    padding: 7px 9px;
    margin-top: -14px;
    border-radius: 4px;
    box-shadow: $shadow;

    &.is-visible {
      display: block;
    }
  }

  &__search-input {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    padding-left: 40px;

    @include break-max($breakpoint-md) {
      //width: 90%;

      input::placeholder {
        color: $color-brand-2;
      }

      pre {
        overflow: hidden;
      }
    }

    & .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 17px;
      stroke: $color-brand-3;
    }

    & .zoom-icon {
      width: auto;
      height: auto;
      display: flex;
      left: 15px;
    }

    & .just-new-icon-search {
      //border: 1px solid red;
      position: absolute;
      left: 10px;
      top: 13px;
    }
  }

  @include break-max($breakpoint-xs) {
    & .form__drop-btn {
      border: 1px solid $color-brand-opaque-20;
      box-shadow: none;
    }

    & .form__row {
      justify-content: space-between;
      margin: 0;
    }

    & .form__item {
      margin: 0;
      flex-basis: 48%;

      &:nth-child(2n) {
        margin-left: 12px;
      }
    }

    & .complex__filter-clear-filter {
      margin: 0 0 12px 10px;
      width: calc(100% - 20px);
    }
  }

  &__clear-link {
    cursor: pointer;
    color: #777A89;
    transition-duration: .3s;

    &:hover {
      color: $color-red-bottom-border;
    }
  }
}


.section.index-search.for-main {
  @include break-max($breakpoint-xs) {
    padding-bottom: 0;
    z-index: 2;

    .button.button_view_default {
      width: calc(100% - 20px);
    }

    .index-search__filter-inc {
      padding: 0;
    }
  }
}

.mobile-filters,
.section.index-search.for-main {
  @include break-max($breakpoint-xs) {
    .filtered {
      box-shadow: none;
    }

    .filtered__inner {
      border: 1px solid $color-brand-opaque-30;
      border-radius: 4px;
      display: inline-flex;
      flex-wrap: wrap;
    }
  }
}

.index-search__filter-applied.for-dragable {
  @include break-max($breakpoint-xs) {
    margin: 0 5px 10px;
  }
}

.smartfilter-wrapper_dubai {
  .index-search__search-input {
    @include break-max($breakpoint-md) {
      width: 100%;
    }
  }
}
.form_dubai-secondfilter {
  padding: 0 20px;


  .form__item:nth-child(2) .form__drop-name span {
    flex-shrink: 0;
    margin-left: 5px;
    margin-right: 8px;
  }

  @include break-max($breakpoint-md) {
    padding: 0;
    width: 100%;

    .sf__filter-item_type {
      min-width: 208px;
    }
  }
}

.filter__max.form_dubai-secondfilter {
  margin-top: 20px;
  margin-bottom: 14px;

  @include break-max($breakpoint-sm) {
    grid-template-columns: repeat(3, 1fr);

    .sf__filter-item {
      width: 100%;
    }
  }
}










