.presentation {

  &__title {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 15px;
    margin-bottom: 25px;
    border-bottom: 3px solid $color-brand-1;
  }

  &__box {
    & + & {
      margin-top: 30px;
    }

  }

  &__img {
    display: flex;
    margin: 65px auto 25px;

    @include break-max($breakpoint-xs) {
      margin: 25px auto;
    }
  }

  &__row {
    display: flex;
  }

  &__showroom {
    position: relative;

    .button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
    .showroom-tooltip {
      width: auto;
      height: 35px;
      display: flex;
      align-items: center;
      padding: 5px 15px;
      padding-left: 28px;
    }

    .showroom-tooltip__decor {
      width: 35px;
      height: 35px;
    }
  }

  &__header {
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include break-max($breakpoint-sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    @include break-max($breakpoint-xs) {
      position: fixed;
      bottom: 0;
      margin-bottom: 0;
      padding: 30px 0 45px;
      background: linear-gradient(rgba($color-white, 0), rgba($color-white, 0.5) 10%, $color-white 25%);
      width: 100%;
    }

    @include break-max($breakpoint-xs) {
      .button_view_round-with-label {
        border-radius: 22px;
        box-shadow: 0px 4px 20px $color-brand-opaque-30;
        height: 44px;
        padding: 0 20px;

        &.is-round {
          justify-content: space-between;
          margin-left: 10px;
          padding: 0 10px;
          width: 44px;
        }
      }

      .button_view_round-with-label,
      .button.button_view_default {
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 0.3px;
        text-transform: uppercase;
      }
    }

    .dot {
      background: $color-brand-2;
      border-radius: 3px;
      height: 6px;
      width: 6px;
    }
  }

  &__controls {
    display: flex;
    align-items: center;

    @include break-max($breakpoint-sm) {
      margin-left: 0;
      margin-top: 30px;
    }

    @include break-max($breakpoint-xs) {
      justify-content: space-between;
      margin: 0 10px;
      width: calc(100% - 20px);

      & > .presentation__control:first-child {
        display: none;
      }
    }
  }

  &__control {
    text-align: center;
    width: 170px;
    margin-left: 20px;

    &:first-of-type {
      margin-left: 0;
      
    }

    &:last-of-type {
      width: auto;
      margin-left: 35px;
      
      @include break-max($breakpoint-xs) {
        margin-left: 0;
      }
    }

    @include break-max($breakpoint-xs) {
      margin: 0;
    }

    .button_view_round-with-label .button {
      width: 40px;
      height: 40px;
      svg {
        width: 18px;
      }
    }
  }

  &__body {
    display: flex;
    align-items: flex-start;
  }

  &__content {
    padding-bottom: 160px;
    //max-width: 845px;
    width: 100%;
    margin: 0 auto;

    @include break-max($breakpoint-md) {
      margin: 0 0 0 auto;
    }

    @include break-max($breakpoint-sm) {
      margin: 0 auto;
    }

    @include break-max($breakpoint-xs) {
      padding-bottom: 50px;
    }

    &-controls {
      display: flex;
      margin-bottom: 32px;
    }

    &-control {

      & + & {
        margin-left: 35px;
        @include break-max($breakpoint-xs) {
          margin-left: 0;
          margin-bottom: 10px;
        }
      }
      @include break-max($breakpoint-xs) {
        margin-left: 0;
        margin-bottom: 10px;
      }
      &:hover {
        .presentation__content-icon {
          background: $color-brand-opaque-20;
        }
      }
    }

    &-icon {
      width: 30px;
      height: 30px;
      background: $color-brand-opaque-10;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
      transition: background 0.3s;

      svg {
        width: 16px;
        height: 16px;
      }

      @include break-max($breakpoint-xs) {
        order: -1;
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }

  &__aside {
    //position: sticky;
    //top: 100px;

    display: flex;
    flex-direction: column;
    //max-height: calc(100vh - 120px);
    max-height: calc(100vh - 245px);
    //width: 345px;
    border-radius: 20px;
    //border-radius: 4px;
    background-color: $color-brand-opaque-8;
    padding: 35px;
    //margin-bottom: 160px;
    width: 100%;

    @include break-max($breakpoint-md) {
      //width: 290px;
      padding: 25px;
    }

    @include break-max($breakpoint-sm) {
      //display: none;
    }

    @include break-max($breakpoint-xs) {
      display: flex;
    }

    &-title {
      margin-bottom: 12px;
    }

    &-hint {
      margin-bottom: 25px;
      color: $color-brand-3;
    }

    &-wrapper {
      flex-grow: 1;
      max-height: calc(100vh - 285px);
    }

    &-filters {
      padding-right: 10px;
    }
  }

  &__filters {
    display: none;
    border: 1px solid $color-brand-opaque-8;
    padding: 30px 25px 15px;
    margin-top: 5px;

    &.active {
      display: block;
    }

    &_gray {
      background-color: $color-brand-opaque-10;

      .presentation__filters-switch {
        flex-grow: 0;
      }
    }

    &-label {
      margin-bottom: 10px;
      color: $color-brand-3;

      & .icon {
        fill: $color-brand-3;
      }
    }

    & .form {
      margin-bottom: 20px;
    }

    &-switches {
      display: flex;
      flex-wrap: wrap;

      @include break-max($breakpoint-xs) {
        flex-direction: column;
        margin-bottom: 5px;
        margin-top: 20px;
      }
    }

    &-switch {
      flex: 1 0 33%;
      margin-bottom: 15px;
    }
  }

  &__filter {
    margin-bottom: 30px;

    &:last-child .presentation__filter-dropdown {
      border-bottom: none;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      & .icon {
        stroke: $color-brand-2;
        font-size: 8px;
        transform: rotate(0deg);
        transition: transform $transition-default;
      }

      &:hover {
        & .button {
          background-color: $color-brand-opaque-20;
        }
      }

      &.is-active {
        .icon {
          transform: rotate(180deg);
        }
      }
    }

    &-mobile {
      margin-bottom: 40px;

      @include break-min($breakpoint-sm) {
        display: none;
      }
    }

    &-tabs {
      max-width: 845px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }

    &-tab {
      display: flex;
      align-items: center;
      padding: 15px 12px;
      border: 1px solid $color-brand-opaque-20;
      font-weight: bold;
      flex-grow: 1;

      & .icon {
        margin-left: 10px;
        stroke: $color-brand-2;
        font-size: 8px;
      }

      &.active {
        border-color: $color-brand-opaque-8;

        & .icon {
          transform: rotate(180deg);
        }
      }
    }

    &-dropdown {
      padding: 20px 0 25px;
      border-bottom: 1px solid $color-brand-opaque-20;

      @include break-max($breakpoint-xs) {
        display: flex;
        flex-direction: column;
      }
    }

    &-images {
      margin-left: -6px;
      margin-right: -6px;
      margin-bottom: 8px;
      display: flex;
      flex-wrap: wrap;

      @include break-max($breakpoint-xs) {
        justify-content: space-between;
        margin: 0;
      }
    }

    &-image {
      padding-left: 6px;
      padding-right: 6px;
      max-width: 120px;
      flex: 1 0 120px;
      box-sizing: content-box;
      margin-bottom: 12px;


      @include break-max($breakpoint-md) {
        max-width: 105px;
        flex-basis: 105px;
      }

      @include break-max($breakpoint-md) {
        display: flex;
        flex-basis: auto;
        justify-content: center;
      }
            
      @include break-max($breakpoint-xs) {
        max-width: 100%;
      }
    }

    &-button {
      text-align: center;
      margin-top: 20px;

      & .button {
        padding: 16px;
      }
    }

    &-switch {
      display: block;
      margin-bottom: 15px;
    }

    &-name {
      font-weight: bold;
    }
  }

  &__info {
    &_flat {
      font-size: 15px;
    }
    &-id {
      color: $color-brand-3;
    }
    &-title {
      margin-bottom: 20px;
    }

    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;
    }

    &-grid {
      display: flex;
      flex-wrap: wrap;

      & > .presentation__info-col {
        margin-bottom: 20px;
      }      

      &.grid-fluid {
        & > .presentation__info-col {
          margin-top: 45px;
          margin-bottom: 40px;

          @include break-max($breakpoint-xs) {
            margin-top: 0;
            padding-top: 0;
            margin-bottom: 15px;
            padding-bottom: 0;
          }
        }
      }
    }

    &-col {
      flex-basis: calc(50% - 20px);
      max-width: 50%;

      &:nth-of-type(2n + 1) {
        margin-right: 20px;
      }

      & img {
        width: auto;
        margin: auto;
        max-height: 300px;

        @include break-max($breakpoint-xs) {
          margin-top: 0;
          max-height: 100px;
        }
      }
    }

    &-main {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 480px;
      @include break-max($breakpoint-xs) {
        width: auto;
      }
    }

    &-name {
      font-weight: bold;
      font-size: 28px;
      line-height: 1em;
      max-width: 100%;
      flex-basis: 100%;
      @include break-max($breakpoint-xs) {
        font-size: 13px;
        line-height: 14px;
      }
    }

    &-address {
      max-width: 45%;
      flex-basis: 45%;
      padding-right: 20px;

    }

    &-routes {
      max-width: 55%;
      flex-basis: 55%;

      display: flex;
      flex-direction: column;
    }

    &-route {
      margin-bottom: 10px;

      .complex__route-time {

      }

      .complex__routes-item .nowrap {
        display: inline-block;

        @include break-max($breakpoint-xs) {
          display: block;
          margin-top: -12px;
          padding-bottom: 5px;
        }
      }

      .complex__metro-station {
        color: $color-brand-3;

      }

      .complex__metro-color {
        @include break-max($breakpoint-xs) {
          height: 3px;
          vertical-align: top;
          margin-top: 3px;
          width: 3px;
        }
      }

      .icon.icon_pedestrian {
        @include break-max($breakpoint-xs) {
          height: 9px;
          width: 8px;
        }
      }

      .icon.icon_auto {
        @include break-max($breakpoint-xs) {
          margin: 0px 6px 0 0;
          width: 7px;
        }
      }
    }

    &-priceItem {
      margin-bottom: 20px;
    }

    &-price {
      @include break-max($breakpoint-xs) {
        font-size: 8px;
        line-height: 10px;
      }
    }

    &-price.h3 {
      @include break-max($breakpoint-xs) {
        font-size: 12px;
        line-height: 14x;
        white-space: nowrap;
      }
    }

    &-label {
      color: $color-brand-3;
    }

    &-description {
      flex-basis: calc(100% - 120px);

      @include break-max($breakpoint-xs) {
        flex-basis: 83%;
        position: relative;

        &:before {
          content: "«";
          color: $color-brand-opaque-10;
          font-size: 44px;
          left: -17px;
          line-height: 27px;
          top: -10px;
          position: absolute;
        }
      }

      & p {
        font-size: 16px;
        line-height: 25px;
      }
    }

    &-details {
      display: flex;
      flex-wrap: wrap;
      padding: 40px 0;
    }

    &-detail {
      margin-bottom: 10px;

      &_value {
        font-weight: bold;
      }
      &_desc {
        color: $color-brand-3;
      }
    }
  }

  &__agent {
    flex: 0 0 100px;
    margin-right: 20px;

    @include break-max($breakpoint-xs) {
      flex-basis: 30%;
      margin-right: 0;
      padding-right: 20px;
      max-width: 17%;
    }

    &-name {
      font-weight: bold;
    }
  }

  &__footer {
    display: flex;
    padding-top: 10px;
    margin-top: auto;
    border-top: 1px solid $color-brand-opaque-20;

    @include break-max($breakpoint-sm) {
      flex-direction: column;
      padding-top: 15px;
      margin-top: auto;
    }

    @include break-max($breakpoint-xs) {
      padding-top: 5px;
      margin-top: 15px;
    }

    &-logo {
      height: 45px;
      margin-right: 25px;

      @include break-max($breakpoint-xs) {
        margin-right: 15px;
        height: 25px;
      }
    }

    &-row {
      display: flex;
      flex-grow: 1;
      @include break-max($breakpoint-sm) {
        flex-wrap: wrap;
      }
    }

    &-socials {
      display: flex;
      flex-grow: 0;
    }

    &-social {
      margin-right: 8px;
      &:last-of-type {
        margin-right: 0;
      }
      .button_view_round-control {
        width: 40px;
        height: 40px;
        .icon {
          fill: $color-brand-3;
          width: 1.4em;
          height: 1.4em;
        }
      }
    }

    &-item {
      margin-top: 10px;
      flex-grow: 1;

      @include break-max($breakpoint-sm) {
        flex-basis: 50%;
      }

      &_desc {
        color: $color-brand-3;
        margin-bottom: 3px;
      }

      &_value {
        font-weight: bold;

        & a {
          color: $color-brand-2;
        }
      }
    }
  }

  &__furnish {
    margin-bottom: 20px;

    &-title {
      font-weight: bold;
      font-size: 30px;
      line-height: 1.5;
      margin-bottom: 10px;

      @include break-max($breakpoint-xs) {
        font-size: 13px;
        line-height: 16px;
      }
    }

    &-description {
      font-size: 14px;
      line-height: 21px;
    }
  }

  &__images {
    display: flex;
    margin-bottom: 10px;

    &-main {
      flex-grow: 1;
      margin-bottom: 4px;
      border-radius: 4px;
      overflow: hidden;
      float: left;

      @include break-max($breakpoint-xs) {
        border-radius: 2px;
        width: 60%;
      }

      & img {
        width: 100%;
        height: 384px;
        object-fit: cover;

        @include break-max($breakpoint-xs) {
          height: 156px;
        }
      }
    }

    &-aside {
      flex: 0 0 250px;
      width: 250px;
      margin-left: 4px;

      @include break-max($breakpoint-xs) {
        flex: 0 0 40%;
        width: calc(40% - 4px);
      }
    }

    &-small {
      margin-bottom: 4px;
      border-radius: 4px;
      overflow: hidden;

      @include break-max($breakpoint-xs) {
        border-radius: 2px;
      }

      & img {
        width: 100%;
        height: 190px;
        object-fit: cover;

        @include break-max($breakpoint-xs) {
          height: 76px;
        }
      }
    }
  }

  &__more {

    &-title {
      font-weight: bold;
      font-size: 30px;
      line-height: 1.5;
      margin-bottom: 10px;

      @include break-max($breakpoint-xs) {
        font-size: 9px;
        line-height: 11px;
      }
    }

    &-images {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-image {
      width: 100%;
      flex-basis: calc(50% - 10px);
      border-radius: 4px;
      overflow: hidden;

      @include break-max($breakpoint-sm) {
        width: 32.5%;
      }

      & img {
        width: 100%;
        height: 190px;
        object-fit: cover;

        @include break-max($breakpoint-xs) {
          height: 80px;
        }
      }
    }
  }

  &__complex {
    &-title {
      font-weight: bold;
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 10px;

      @include break-max($breakpoint-xs) {
        font-size: 13px;
        line-height: 20px;
      }
    }

    &-images {
      margin-bottom: 30px;
    }

    &-details {
      display: flex;
      margin-bottom: 20px;

      &--description {
        padding-right: 40px;

        & p {
          font-size: 14px;
          line-height: 21px;
        }
      }

      &--items {
        flex: 0 0 250px;

        @include break-max($breakpoint-xs) {
          flex: 0 0 100px;
        }
      }

      &--description.hidden + &--items {
        flex-basis: 100%;

        display: flex;
        flex-wrap: wrap;

        .presentation__complex-details--item {
          margin-right: 29px;
        }
      }

      &--item {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 10px;

        &_value {
          font-weight: bold;
        }

        &_desc {
          color: $color-brand-3;
        }
      }
    }

    &-features {
      margin-bottom: 40px;

      & .complex__about-features {
        margin-top: 0;
      }

      & .complex__about-feature {
        flex-grow: 1;
        font-weight: 300;
      }
    }
  }

  &__infrastructure {
    &-title {
      font-weight: bold;
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 20px;

      @include break-max($breakpoint-xs) {
        font-size: 13px;
        line-height: 20px;
      }

      &_with-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .button {
          padding: 17px 27px;
        }
      }
    }

    & .infrastructure__map {
      margin-bottom: 30px;

      @include break-max($breakpoint-xs) {
        height: 144px;

        &.for-sertificate {
          margin-top: -30px;
          width: 50%;
        }
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .infrastructure__item {
      flex: 1 0 33.3333%;
      white-space: normal;

      & .icon {
        margin-right: 10px;
      }
    }
  }

  &__modal {
    .textarea {
      &__control {
        margin-top: 5px;
        height: 200px;
        padding: 25px;
      }
    }
  }
}

.presentation__prices-key {
  color: $color-brand-3;
  margin-bottom: 8px;
}

.presentation__prices-value {
  font-weight: bold;

  @include break-max($breakpoint-xs) {
    display: flex;
    flex-direction: column;
  }
}

.presentation__prices-sub {
  margin-top: 8px;
  color: $color-brand-3;
}

.presentation__prices-positive {
  margin-left: 5px;
}

.presentation__prices-plus {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 5px 15px;
  margin-left: 10px;
  border-radius: 5px;

  color: $green;
  background: rgba($green, 0.1);
  font-weight: bold;

  @include break-max($breakpoint-xs) {
    margin-left: 0;
    margin-top: 5px;
  }
}

.presentation__prices-info {
  color: $color-brand-3;
  font-size: 12px;
  padding-top: 10px;
  margin-top: 30px;
  border-top: 1px solid $color-brand-opaque-30;
}

.presentation__info_full-list {
  height: 100%;
  // .presentation__box:first-of-type {
  //   min-height: 50%;
  // }
}

.presentation__info-description {
  p + p {
    margin-top: 8px;
  }
}

.presentation__save-filters {
  padding-bottom: 25px;
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  svg {
    margin-right: 10px;
  }
}

.pdf-list {
  display: flex;
  flex-direction: column;
  //max-width: 845px;
  height: 1195px;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 20px;
  padding: 60px 80px 35px;
  margin-bottom: 35px;

  @include break-max($breakpoint-sm) {
    height: auto;
    min-height: 1195px;
  }

  & .hidden {
    position: absolute;
    opacity: 0;
    visibility: hidden;

    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  .infrastructure__items {
    @include break-max($breakpoint-xs) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @include break-max($breakpoint-xs) {
    border: 1px solid $color-brand-opaque-20;
    border-radius: 4px;
    height: auto;
    min-height: 500px;
    margin: 20px 10px 0px;
    padding: 18px 20px 10px;
  }
}

.presentation-image {
  $c: &;
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 4px;
  background-color: $color-white;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @include break-max($breakpoint-xs) {
    background: $color-brand-opaque-8;
    background-size: cover;
    border-radius: 4px;
  }

  &__input {
    display: none;
  }

  &__delete {
    display: flex;
    position: absolute;
    top: 7px;
    right: 7px;
    width: 24px;
    height: 24px;
    background-color: rgba($color-white, 0.6);
    z-index: 10;

    &:after {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi42NjY2NyAxLjNMMS4zMzMzMyA2LjciIHN0cm9rZT0iIzJBMjc2QyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGQ9Ik0xLjMzMzMzIDEuM0w2LjY2NjY3IDYuNyIgc3Ryb2tlPSIjMkEyNzZDIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==);
    }

    &:hover {
      background-color: rgba($color-white, 0.8);
    }
  }

  &.input {
    #{$c}__input {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    #{$c}__delete {
      display: none;
    }

    #{$c}__stub {
      & .icon {
        font-size: 50px;
      }
    }

    #{$c}__control {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      @include break-max($breakpoint-xs) {
        height: 30%;
        width: 30%;

        &::after {
          height: 40%;
          width: 40%;
        }
      }
    }

    &:hover {
      #{$c}__control {
        background-color: $color-brand-opaque-20;
      }
    }
  }
}

.mini-filter.for-favorites,
.mini-filter.for-contacts,
.mini-filter.for-meetings,
.index-search__filter.for-secondary,
.section__personal-header + .section .mini-filter {
  @include break-max($breakpoint-xs) {
    .mini-filter__col + .mini-filter__col {
      margin: 0;
    }

    .form__item {
      flex-basis: 100%;
      margin: 0 0 12px 0;
      width: 100%;
    }

    .form__drop-btn {
      border: 1px solid rgba(143, 149, 172, 0.2);
      box-shadow: none;
    }

    .form__drop .dropdown__block,
    .form__drop .dropdown__block {
      background: $color-white;
      bottom: 0;
      left: 0;
      overflow: scroll;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
    }

    .form__drop-content,
    .form__drop-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      max-height: 100%;
      padding: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .form {
      background: 0;
      margin: 0;
      padding: 0;
    }

    .form__drop-content {
      min-width: 100%;
    }

    .mini-filter__col {
      flex: 0;
    }

    .mobile-filters__content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-grow: 1;
    }

    .mobile-filters__submit {
      flex-basis: 100%;
    }

    .button-mobile.for-main  {
      font-size: 15px;
      margin: 0 10px 20px;
      width: calc(100% - 20px);
    }
  }
}



.lk-meetings__table,
.assignations-flats__tables,
.section__personal-header + .section {
  @include break-max($breakpoint-xs) {
    .pan-table {
      padding: 0 10px;
    }

    .table__show-more_btn {
      @include primary-button;
      font-weight: bold;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      bottom: 0;
      left: 0;
      position: absolute;
      width: calc(50% - 6px);

      .icon {
        display: none;
      }
    }

    .lk-presentations__header {
      margin: 0;
      padding: 20px 10px;
    }

    .lk-presentations__name {
      font-size: 15px;
      line-height: 20px;
      white-space: normal;
    }

    .ant-table-tbody > tr:hover {
      background: none;
    }

    .control_more > .form__item .dropdown__block {
      top: 32px;
      right: 0;
    }

    .lk-presentations__clients {
      flex-wrap: wrap;
      flex-grow: 1;
      justify-content: space-between;
    }

    .lk-presentations__client-name {
      max-width: 170px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .lk-presentation__clients {
      display: flex;
    }

    .flats__row-table-expanded-title {
      display: flex;
      margin-right: 10px;
      min-width: 120px;
      margin-bottom: 4px;
      width: 100px;

      &.is-centered {
        align-items: center;
      }
    }

    .lk-presentations__client {
      margin: 0;
      padding: 0;

      .label_with-icon_postfix {
        margin-top: -4px;
      }
    }

    .flats__row-table-expanded-column {
      width: 100%;
    }

    .popover__block {
      left: auto;
      right: auto;
      top: calc(100% + 10px);
      transform: translateX(-90%);

      &:after {
        display: none;
      }
    }

    @include break-max($break-xxs - 1px) {
      .flats__row-table-expanded-title {
        min-width: 100px;
      }
    }
  }

  @include break-min-max($break-3xs, $break-xxs - 1px) {
    .lk-presentations__client-name {
      max-width: 110px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .popover__block {
      transform: translateX(-50%);
    }
  }
}

.assignations-flats__tables {
  @include break-max($breakpoint-xs) {
    padding: 0 10px;

    .flats__row-table-expanded-list {
      white-space: normal;
    }
  }

  @include break-max($break-xxs - 1px) {
    .flats__row-table-expanded-title {
      min-width: 80px;
      width: 80px
    }
  }
}


.mobile-filters.for-presentation {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;

  &.is-active {
    overflow-x: hidden;
    transform: translateX(0);
  }

  .modal-form {
    margin-bottom: 20px;
  }

  .textarea__control {
    border: 1px solid $color-brand-opaque-20;
    box-shadow: none;
    height: 117px;
    -webkit-appearance: none;
  }
}

.mobile-presentation-settings {
  .button_view_default {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    width: 100%;
  }
}

.mobile-presentation-settings-item {
  border-bottom: 1px solid $color-brand-opaque-20;
  margin-bottom: 20px;

  &:last-child {
    border-bottom: none;
  }
}

.mobile-presentation-settings-title {
  margin-bottom: 15px;
}

.presentation__info-description p {
  @include break-max($breakpoint-xs) {
    white-space: normal;
  }
}

.presentation__complex-details--item_value,
.presentation__info-detail_value,
.presentation__info-title {
  @include break-max($breakpoint-xs) {
    font-size: 8px;
    line-height: 10px;
    white-space: nowrap;
  }
}
.presentation__complex-details--description {
  @include break-max($breakpoint-xs) {
    padding-right: 20px;
  }
}

.pan-popup-content {
  .dt__more,
  .presentation__content-controls {
    @include break-max($breakpoint-xs) {
      flex-direction: column;
      margin-bottom: 0;

      .label__icon {
        background: $color-brand-opaque-10;
        border-radius: 16px;
        height: 32px;
        width: 32px;
      }
    }
  }

  .dt__more {
    a {
      margin: 0;
    }
  }

  .dt__more div,
  .presentation__content-controls .button.label_with-icon_prefix.link-hover {
    @include break-max($breakpoint-xs) {
      align-items: center;
      border-top: 1px solid $color-brand-opaque-10;
      display: flex;
      height: 50px;
      margin: 0;

      &:first-of-type,
      &:first-child {
        border-top: none;
      }

      &:visited {
        color: inherit;
      }
    }
  }

  hr {
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }
}

.serticate-number-wrapper {
  color: $color-brand-3;
  font-size: 6px;
  margin-left: 20px;
}

.serticate-number {
  color: $color-brand-2;
  font-weight: bold;
  margin-top: 2px;
}

.presentation__infrastructure.for-sertificate {
  @include break-max($breakpoint-xs) {
    display: flex;
    flex-wrap: wrap;

    .presentation__infrastructure-title {
      flex-basis: 100%;
    }

    .infrastructure__map img {
      object-fit: contain;
    }
  }
}

.presentation-filters-btn {
  @include secondary-button;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.3px;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  width: calc(50% - 6px);

  svg {
    margin-right: 5px;
  }
}

.button-mobile.for-presentations {
  @include break-max($breakpoint-xs) {
    margin: 20px 10px 0;
    width: calc(100% - 20px);
  }
}

.presentation__controls {
  .presentation__control {
    @include break-max($breakpoint-xs) {
      margin-right: 12px;

      &:first-child {
        flex-grow: 1;
      }

      .button {
        width: 100%;
      }

      .button_view_round-with-label {
        border-radius: 5px;
      }

      .button_view_round-with-label.is-round {
        border-radius: 50em;
      }
    }
  }

  .pan-popup-trigger .presentation__control {
    margin-right: 0;
  }
}

.presentation__floor-plan  {
  height: 395px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include break-max($breakpoint-sm) {
    height: 360px;
  }

  @include break-max($breakpoint-xs) {
    height: auto;
  }

  img, svg {
    max-height: 100%;
  }

  &_full-img img {
    width: 100%;
    object-fit: cover;
  }

}

.presentation__bordered-block {
  padding: 15px;
  border: 1px solid $color-brand-opaque-30;
  border-radius: 4px;

  
  @include break-max($breakpoint-xs) {
    padding: 0;
    border: none;
  }
}

.presentation__info-details {
  @include break-max($breakpoint-xs) {
    padding: 0;
  }
}

.presentation__info-route {
  @include break-max($breakpoint-xs) {
    margin-bottom: 0;
  }
}

.presentation__info-priceItem {
  @include break-max($breakpoint-xs) {
    margin-bottom: 0;
  }
}

.presentation__info-col {
  @include break-max($breakpoint-xs) {
    max-width: 45%;
    min-width: 45%;
    width: 45%;
  }
}


.presentation__info-detail {
  @include break-max($breakpoint-xs) {
    margin-bottom: 5px;
  }
}

.presentation__complex-details--description p {
  white-space: normal;
}

.pdf-block {
  @include break-max($breakpoint-xs) {
    .presentation__complex-features {
      margin-bottom: 10px;
    }

    .complex__about-features {
      padding: 3px 20px;
    }

    .complex__about-feature {
      font-size: 5px;
      line-height: 8px;
      margin-top: 2px;
      margin-bottom: 2px;
    }

    .presentation__complex-details--items {
      flex-basis: 50px;
    }

    .presentation__infrastructure-title_with-btn .button {
      font-size: 12px;
      padding: 8px 15px;
    }

    .presentation__infrastructure-title {
      margin-bottom: 7px;
    }

    .presentation__complex-details--item {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 5px;
    }

    .presentation__infrastructure .infrastructure__map {
      margin-bottom: 10px;
    }
  }
}


.public-header {
  .presentation__footer-item {
    margin-top: 0;
    .presentation__footer-item {
      margin-top: 5px;
    }
  }
}

.sticky-settings {
  .presentation__aside {
    max-height: calc(100vh - 95px);
  }


}

.presentation__frame {
  width: 100%;
  min-height: 100vh;
  min-width: 100%;
  margin: 30px auto;
}


.sf__detail-row--common-page {
  .simplebar-track.simplebar-vertical {
    top: 15px;
    bottom: 15px;
  }
  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    bottom: 13px;
  }
}

