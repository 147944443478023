@import '../../styles/variables';
@import '../../styles/mixins';

.remains {
  display: block;
  padding: 15px;
  margin-top: -15px;
  cursor: pointer;
  position: relative;

  &__header {
    display: flex;
    @include text-L();
    font-weight: 400;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  &__body {
    margin-top: 15px;
    position: absolute;
    left: 0;
    width: 100%;
    background-color: $color-white;
    border-radius: 0 0 20px 20px;
    padding-bottom: 15px;
    //box-shadow: 0px 2px 20px rgba(143, 149, 172, 0.1);
    box-shadow: 0 10px 10px rgba(143, 149, 172, 0.2);
  }

  &__title {
    @include h6();
    padding-left: 24px;
  }

  &__list {
    margin-top: 5px;
  }

  &__list-link {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    color: #2C2E3E;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 12px;
    margin-right: 12px;
    align-items: center;
    height: 30px;
    transition-duration: .3s;

    &:hover {
      background-color: #ECEDF0;
      border-radius: 5px;
    }
  }

  &__item-overtext {
    display: flex;

    svg {
      margin-right: 10px;
    }
  }

  &__item-text {
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.remains.active {
  background-color: $color-white;
  border-radius: 20px 20px 0 0;
  width: 340px;
  box-shadow: 0px 2px 20px rgba(143, 149, 172, 0.1);
}
