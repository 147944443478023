.mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile__image .icon {
  width: 230px;
  height: 220px;
}

.mobile__title {
  text-align: center;
  margin: 30px 0 10px;
}

.mobile__text {
  margin: 25px;
  text-align: center;
}