.form {
  display: flex;
  flex-direction: column;
  width: 470px;
  max-width: 100%;
  margin: 0 auto;
}

.form__row {
  margin-bottom: 23px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.form__submit {
  margin-left: auto;
  margin-right: auto;
  @include break-max($breakpoint-sm) {
    padding: 18px 62px;
  }
}
