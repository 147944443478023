@import "../../styles/variables";
@import "../../styles/mixins";

.fixations {

  .modal-form__files {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .file-up {
    justify-content: space-between;
  }

  .file-up__inner {
    height: 30px;
  }

  &__container {
    max-width: 730px;
    display: flex;
    gap: 20px;
    position: relative;
  }

  &__settings-block {
    padding: 30px;
    border-radius: 20px;
    background-color: $gray-light;
    width: 410px;
    box-sizing: border-box;
    margin: 10px 0 30px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .hp__form-item input {
      box-shadow: none;
    }
  }
  &__block-dash {
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-dark;
  }

  .input_type_form .input__control {
    box-shadow: none;
  }
  .input_type_form .input__error{
    box-shadow: inset 0 0 0 1px #ff2d23;
  }
  .drop__error .form__drop-btn{
    box-shadow: inset 0 0 0 1px #ff2d23;
    border-radius: 6px;
  }

  .form__item{
    margin-bottom: 0;
  }
  .hp__form-item + .hp__form-item {
    margin-top: 0;
  }
  .textarea_type_form .textarea__control {
    box-shadow: none;
  }

  &__checkboxs {
    .checkbox__label {
      color: $gray-dark-2;
    }
  }
  .hp__form-label {
    display: flex;
    justify-content: space-between;
    .hp__form-label-rules {
      color: $blue;
      cursor: pointer;
    }
  }

  .new-alert {
    margin-bottom: 0;
  }

}
.crm-table__sf-controlsmodal.crm-table__sf-controlsmodal_fixation {
   .modal__inner {
    width: 450px;
  }
}

.select-filter-fix{
  margin-left: 0;
  width: 100%!important;
}
.fixation-dp-fix{
  .react-datepicker-popper{
    z-index: 99;
  }
  .react-datepicker-wrapper{
    width: 100%;
  }
}

.flexible-tag__container {
  display: flex;
  min-width: 0;
  font-size: 13px;
  padding: 5px 10px;
  background-color: #ECEDF0;
  border-radius: 20px;
  color: #2C2E3E;
  margin-right: 9px;

  .flexible-tag__title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .flexible-tag__button {
    margin-left: 8px;
    flex-shrink: 0;
    cursor: pointer;
  }
}

.rules-text {
  min-width: fit-content;
  color: $blue;
  cursor: pointer;
}

.custom-select_fixations {
  .custom-select__search {
    position: absolute;
    background-color: white;
    width: 100%;
  }
  .custom-select__options {
    margin-top: 48px;
  }
}

.custom-select_sf-filter-fixations {
  .input__control {
    height: 32px;
    padding: 0px 16px;
  }
  .form__drop-content {
    padding: 5px 22px 24px 16px;
    min-width: auto;
  }

  .form__drop-name {
    span {
      background-color: #ff2d23!important;
    }
  }
}

.advantages-list {

  &__item {
    font-size: 17px;
    line-height: 20px;
    margin-top: 20px;
    position: relative;

    &:before {
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      background-color: black;
      border-radius: 50%;
      left: -16px;
      top: 8px;
      position: absolute;
    }
  }
}

.sf__sticky.sf__sticky_fixations {
  @include break-max($breakpoint-sm) {
    display: block;
  }
}


.fixations.fixations_redesign {

  padding-bottom: 50px;

  .fixations__redesign {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .fixations__redesign-top {
    display: flex;
    gap: 10px;
  }

  .fixations__redesign-part {
    width: calc(100% / 3);
    padding: 20px;
    border: 1px solid #DFE1E9;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: max-content;

    .custom-select.custom-select_sf-filter {
      background-color: #f6f7f8;
    }

    .input_type_form .input__control {
      background-color: #f6f7f8;
    }

    .hp__form-item {
      position: relative;
    }

    .hp__form-label_hint {
      position: absolute;
      z-index: 1;
      right: 10px;
      top: 12px;
      cursor: pointer;
    }

    .modal-form__add {
      justify-content: center;
      width: 100%;

      h6 {
        margin: 0;
        margin-left: 20px;
      }
    }

    .modal-form__add-text {
      span {
        margin-left: 20px;
        margin-bottom: 3px;
      }
    }

    .fixations__redesign-partheader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .fixations__redesign-clientactions {
      display: flex;
      gap: 10px;

      li {
        display: flex;
        gap: 2px;
        align-items: center;
        cursor: pointer;
        transition-duration: .3s;
      }
      .fixations__redesign-actioncancel {
        color: #777A89;
      }
      .fixations__redesign-actioncancel:hover {
        color: #686b79;
        svg {
          path {
            stroke: #686b79;
          }
        }
      }
      .fixations__redesign-actionsave {
        color: #EF372E;
      }
      .fixations__redesign-actionsave:hover {
        color: #e03229;
        svg {
          path {
            stroke: #e03229;
          }
        }
      }
      .fixations__redesign-actionedit {
        color: #8790E5;
      }
      .fixations__redesign-actionedit:hover {
        color: #7780d3;
        svg {
          path {
            stroke: #7780d3;
          }
        }
      }

      .fixations__redesign-actiondelete {
        color: #8790E5;
      }

      .fixations__redesign-actiondelete:hover {
        color: #7780d3;
        svg {
          path {
            stroke: #7780d3;
          }
        }
      }

    }

  }

  .fixations__redesign-part_full {
    width: 100%;
    gap: 10px;
  }

  .fixations__redesign-part.fixations__redesign-part_client-block {
    input:disabled {
      background-color: transparent;
      box-shadow: inset 0 0 0 1px rgba(143, 149, 172, 0.2);
      color: #8F95AC;
    }
    input:disabled::placeholder {
      color: #8F95AC;
    }

    .custom-select.is-disabled.custom-select_sf-filter.custom-select_fixations {
      background-color: white;
      box-shadow: inset 0 0 0 1px rgba(143, 149, 172, 0.2)!important;

      .custom-select__selected {
        color: #8F95AC;
      }
    }
    .input_type_upload-btn.input_type_upload-btn-disabled {
      pointer-events: none;
      background-color: #bdc0cc;
    }
  }

  .fixations__redesign-bottomcontent {
    display: flex;
    gap: 10px;
  }
  .fixations__redesign-fullcontent {
    display: flex;
    flex-direction: column;
    width: calc(50% - 5px);
    gap: 10px;

    .form__drop-btn {
      background-color: #f6f7f8;
    }
  }

  .hp__form-item.hp__form-item_half {
    display: flex;
    gap: 10px;
  }

  .hp__form-container {
    width: calc(50% - 5px);
    position: relative;

    .hp__fixhalf-block {
      background-color: #f6f7f8;
      width: 100%;
      position: relative;
      padding-left: 12px;
      padding-right: 12px;
      display: flex;
      align-items: center;
      height: 50px;
      box-sizing: border-box;
      justify-content: space-between;
      border-radius: 4px;
    }
  }
  .hp__form-container.hp__form-container_close {
    width: 28px;
    height: 28px;
    background-color: #f6f7f8;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: 10px;
    cursor: pointer;
  }

  .textarea__control {
    background-color: #f6f7f8;
    height: 100%;
  }

  .fixations__redesign-send {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    gap: 10px;
  }
  .fixations__redesign-sendcontent {
    width: calc(50% - 5px);
  }

}
