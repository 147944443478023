.button {
  $c: &;
  background: none;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  font-family: "Formular", sans-serif;
  padding: 0;
  white-space: nowrap;
  appearance: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: $color-brand-3;
    pointer-events: none;

    .icon {stroke: $color-brand-3;}
  }

}

.button_width_100 {
  width: 100%;
}

.button.dist-map {
  @include break-max($break-xs) {
    display: flex;
    flex-basis: 100%;
  }
}

.button__mobile-filters {
  align-items: center;
  background: #f4f4f7;
  border-radius: 5px;
  display: flex;
  font-size: 15px;
  justify-content: center;
  height: 44px;
  padding-top: 0;
  padding-bottom: 0;
  flex-basis: 50%;
}

.button__mobile-logout {
  align-items: center;
  background: $color-brand-opaque-10;
  border-radius: 5px;
  color: $color-brand-3;
  display: flex;
  font-size: 15px;
  justify-content: center;
  height: 30px;
  line-height: 20px;
  padding: 0 16px;
}

.button_with-icon {
  display: flex;
  align-items: center;
  // justify-content: center;
  fill: #fff;
  font-size: 14px;
}


.button_view_control {
  height: 40px;
  box-shadow: inset 0 0 0 1px transparent;
  padding: 0 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;

  & .icon {
    font-size: 20px;
    margin-right: 8px;
    line-height: 17px;
  }

  &:hover {
    box-shadow: inset 0 0 0 1px $color-brand-opaque-30;
  }
}

.button_view_round-control {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $color-brand-opaque-10;
  transition: background-color $transition-default;

  &:hover {
    background-color: $color-brand-opaque-20;
  }
}

.button_view_grey {
  display: flex;
  align-items: center;
  background-color: $color-brand-3;
  transition: background-color $transition-default;
  color: $color-white;
  border-radius: 5px;
  padding: 0 20px;
  height: 48px;

  &:hover {
    background-color: $color-brand-3-hover;
  }

  & .icon {
    font-size: 22px;
    margin-right: 9px;
  }

  &:disabled {
    color: $color-white;
    background-color: $color-brand-opaque-30;
  }
}

.button_view_another {
  background-color: $color-brand-opaque-30;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  font-weight: 700;
  padding: 17px 48px;
  color: #fff;
  border-radius: 5px;
  transition: background-color $transition-default;
  text-decoration: none;

  &:hover {
    background-color: $color-brand-opaque-50;
  }

  &:active {
    background-color: $color-brand-opaque-70;
  }

  @include break-max($break-md) {
    padding: 22px 36px;
  }
}

.button_view_default {
  background-color: $color-red;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  font-weight: 700;
  padding: 17px 48px;
  color: #fff;
  border-radius: 5px;
  transition: background-color $transition-default;
  text-decoration: none;

  &:hover {
    background-color: $color-red-hover;
  }

  &:active {
    background-color: $color-red-active;
  }

  @include break-max($breakpoint-sm) {
    padding: 17px 36px;
  }

  @include break-max($breakpoint-xs) {
    padding: 14px 30px;
  }

  @include break-max($break-xs) {
    align-items: center;
    border-radius: 5px;
    display: flex;
    height: 44px;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  &:disabled {
    color: $color-white;
    background-color: $color-brand-opaque-30;
  }
}

.button_view_default-small {
  background-color: $color-red;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  font-weight: 700;
  padding: 13px 20px;
  color: #fff;
  border-radius: 5px;
  transition: background-color $transition-default;
  text-decoration: none;

  &:hover {
    background-color: $color-red-hover;
  }

  &:active {
    background-color: $color-red-active;
  }

  &.is-inactive {
    color: $color-brand-2;
    background-color: $color-white;
  }
}

.button_view_outline-small {
  background-color: $color-white;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  font-weight: 400;
  padding: 6px 14px;
  color: $color-brand-2;
  border-radius: 5px;
  box-shadow: 0 0 0 1px $color-brand-opaque-30;
  transition: background-color $transition-default, color $transition-default,
    box-shadow $transition-default;

  &:hover {
    background-color: $color-red;
    color: $color-white;
    box-shadow: 0 0 0 1px transparent;
  }

  &:active {
    background-color: $color-red-hover;
    box-shadow: 0 0 0 1px transparent;
  }
}

.button_view_outline {
  background-color: transparent;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  font-weight: 400;
  padding: 17px 24px;
  color: $color-brand-2;
  border-radius: 5px;
  transition: background-color $transition-default,
  box-shadow $transition-default;
  box-shadow: inset 0 0 0 1px $color-border;

  min-width: 150px;

  @include break-max($break-xs) {
    align-items: center;
    border-radius: 5px;
    display: flex;
    height: 44px;
    line-height: 44px;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  &:hover {
    box-shadow: none;
    background-color: $color-brand-opaque-20;
  }

  &:active {
    box-shadow: none;
    background-color: $color-brand-opaque-30;
  }
}

.button_view_round-with-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;

  & .button {
    width: 44px;
    height: 44px;
    margin-right: 10px;
  }

  & .icon {
    font-size: 22px;
    stroke: $color-brand-3;
  }

  .button__label {
    color: $color-brand-2;
  }

  &:hover {
    & .button {
      background-color: $color-brand-opaque-20;
    }
  }
}

.button_view_outline-with-icon {
  background-color: transparent;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  font-weight: 400;
  padding: 17px 24px;
  height: 50px;
  color: $color-brand-2;
  border-radius: 5px;
  transition: background-color $transition-default,
    box-shadow $transition-default;
  box-shadow: inset 0 0 0 1px $color-border;

  &:hover {
    box-shadow: none;
    background-color: $color-brand-opaque-20;
  }

  &:active {
    box-shadow: none;
    background-color: $color-brand-opaque-30;
  }

  & .icon {
    fill: $color-brand-3;
    margin-right: 10px;
    font-size: 20px;
    max-height: 1rem;
  }

  @include break-max($break-xs) {
    border-radius: 5px;
  }
}

.button_view_outline-with-icon-invert {
  background-color: transparent;
  font-size: 14px;
  white-space: nowrap;
  font-weight: 400;
  padding: 0 24px;
  height: 50px;
  color: $color-red;
  border-radius: 5px;
  transition: background-color $transition-default,
  box-shadow $transition-default, color $transition-default;
  box-shadow: inset 0 0 0 1px $color-red;
  display: flex;
  align-items: center;
  vertical-align: middle;
  line-height: 50px;

  &:hover {
    box-shadow: none;
    background-color: $color-red;
    color: $color-white;

    & .icon {
      fill: $color-white;
    }
  }

  &:active {
    box-shadow: none;
    background-color: $color-red-active;
    color: $color-white;

    & .icon {
      fill: $color-white;
    }
  }

  & .icon {
    fill: $color-red;
    margin-right: 10px;
    font-size: 22px;
    line-height: 60px;

    transition: fill $transition-default;

    &_list {
      font-size: 15px;
      margin-top: -2px;
    }
  }
}

.button_view_outline-strong {
  background-color: $color-white;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  font-weight: 700;
  padding: 17px 48px;
  color: $color-brand-2;
  border-radius: 5px;
  transition: background-color $transition-default,
    box-shadow $transition-default, color $transition-default;
  box-shadow: inset 0 0 0 1px $color-border;

  &:hover {
    box-shadow: none;
    background-color: $color-brand-2;
    color: $color-white;
  }

  &:active {
    box-shadow: none;
    background-color: $color-brand-1;
    color: $color-white;
  }
}

.button_view_outline-invert {
  background-color: transparent;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  font-weight: 400;
  padding: 17px 48px;
  color: $color-red;
  border-radius: 5px;
  transition: background-color $transition-default,
    box-shadow $transition-default, color $transition-default;
  box-shadow: inset 0 0 0 1px $color-red;

  &:hover {
    box-shadow: none;
    background-color: $color-red;
    color: $color-white;
  }

  &:active {
    box-shadow: none;
    background-color: $color-red-active;
    color: $color-white;
  }
}

.button_view_ghost {
  background-color: $color-white;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  font-weight: 400;
  padding: 19px 24px;
  color: $color-brand-2;
  border-radius: 5px;
  transition: box-shadow $transition-default, color $transition-default,
    background-color $transition-default;
  box-shadow: inset 0 0 0 1px transparent, $shadow;
  display: inline-flex;
  align-items: center;

  & .icon,
  & svg {
    display: inline-block;
    font-size: 22px;
    fill: $color-brand-3;
    margin-right: 10px;
    transition: fill $transition-default;
  }

  &:hover {
    box-shadow: inset 0 0 0 1px $color-brand-opaque-50, $shadow;
  }

  &:active {
    box-shadow: inset 0 0 0 1px $color-brand-3, $shadow;
  }

  & span {
    line-height: 16px;
  }

  &.is-active {
    background-color: $color-red;
    color: $color-white;

    & .icon,
    & svg {
      fill: $color-white;
    }
  }

  @include break-max($break-md) {
    box-shadow: 0px 4px 20px rgba(143, 149, 172, 0.3);
    height: 44px;
    padding: 11px 15px;
  }
}

.button_view_round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: $color-red;
  border-radius: 50em;
  transition: background-color $transition-default;

  &:hover {
    background-color: $color-red-hover;
  }

  &:active {
    background-color: $color-red-active;
  }
}

.button_view_fav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: $color-red;
  border-radius: 50em;

  @include break-max($break-md) {
    width: 38px;
    height: 38px;
  }
}

.button_view_social {
  font-size: 20px;
}

.button_view_link {
  color: $color-red;
  font-size: 14px;
  line-height: 17px;
  transition: color $transition-default;

  &:hover {
    color: $color-red-hover;
  }
}

.button_view_link-invert {
  color: $color-brand-3;
  font-size: 14px;
  line-height: 17px;
  transition: color $transition-default;

  &:hover {
    color: $color-red;
  }
}

.button_view_white {
  background-color: $color-white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border-radius: 5px;
  padding: 0 42px;
  transition: all 0.3s;
  &:hover {
    background-color: $gray-light;
  }
}

.button_type {

  &_task-assigned {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    background-color: transparent;
    font-size: 14px;
    color: $color-brand-3;
    width: 234px;
    white-space: normal;
    text-align: left;

    & .icon-block {
      margin-right: 7px;
      margin-left: 4px;
      margin-top: -5px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      background-color: $color-brand-opaque-10;
      transition: background-color $transition-default;

      & .icon {
        font-size: 16px;
        stroke: $color-brand-3;
      }
    }

    &.is-active {

      & .icon-block {
        background-color: $color-red;

        & .icon {
          stroke: $color-white;
        }
      }

      &:hover {

        & .icon-block {
          background-color: $color-red-hover;
        }
      }
    }

    &:hover {

      & .icon-block {
        background-color: $color-brand-opaque-20;
      }
    }

    @include break-max($break-lg) {
      width: 140px;
    }
  }

  &_task {
    height: 35px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: transparent;
    font-size: 14px;
    color: $color-brand-3;
    padding: 0 14px;
    white-space: nowrap;
    text-align: left;

    & > .icon {
      font-size: 10px;
      stroke: $color-brand-3;
      margin-right: 15px;
    }

    &:hover {
      background-color: $color-brand-opaque-10;
    }

    &:active {
      background-color: $color-brand-opaque-20;
    }
  }

  &_add {
    cursor: pointer;
    background-color: $color-brand-opaque-10;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    transition: background-color $transition-default;

    &:hover {
      background-color: $color-brand-opaque-20;

      // &:after {
      //   transform: rotate(-90deg);
      // }
    }

    &:after {
      content: '';
      display: block;
      width: 11px;
      height: 11px;
      margin: auto;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02IC41VjBINXY1SDB2MWg1djVoMVY2aDVWNUg2Vi41eiIgZmlsbD0iIzhGOTVBQyIvPjwvc3ZnPg==);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      // transform: rotate(90deg);
    }
  }

  &_delete {
    flex-shrink: 0;
    background-color: $color-brand-opaque-10;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    transition: background-color $transition-default;

    &:hover {
      background-color: $color-brand-opaque-20;
    }

    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      margin: auto;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi42NjY2NyAxLjNMMS4zMzMzMyA2LjciIHN0cm9rZT0iIzhGOTVBQyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGQ9Ik0xLjMzMzMzIDEuM0w2LjY2NjY3IDYuNyIgc3Ryb2tlPSIjOEY5NUFDIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &_burger {
    display: none;

    @include break-max($breakpoint-md) {
      display: block;
    }

    & .icon {
      stroke: $color-white;
      font-size: 19px;

      @include break-max($breakpoint-xs) {
        stroke: $color-brand-1;
      }
    }
  }

  &_pill {
    background-color: $color-brand-3;
    color: $color-white;
    white-space: nowrap;
    margin-right: 24px;
    line-height: 30px;
    padding: 0 12px;
    border-radius: 4px;
    font-size: 14px;

    transition: background-color $transition-default;
    cursor: pointer;

    &:hover {
      background-color: $color-brand-3-hover;
    }
  }

  &_filtered {
    font-size: 14px;
    line-height: 17px;
    padding: 6px 12px;
    box-shadow: inset 0 0 0 1px $color-brand-opaque-30;
    transition: box-shadow $transition-default,
      background-color $transition-default;

    &:hover {
      box-shadow: inset 0 0 0 1px transparent;
      background-color: $color-brand-opaque-20;
      cursor: pointer;
    }

    @include break-max($break-xs) {
      font-size: 15px;
      line-height: 20px;
    }
  }

  &_reveal-filter {
    color: $color-white;
    box-shadow: inset 0 0 0 1px $color-white;
    padding: 18px 24px;
    font-size: 14px;
    line-height: 1;
    white-space: nowrap;
    border-radius: 4px;
    cursor: pointer;
    transition: color $transition-default, background-color $transition-default;

    &:hover {
      background-color: $color-white;
      color: $color-brand-3;
    }

    @include break-max($break-md) {
      order: 2;
      flex: 0 1 calc(100% / 4 - 12px);
      background-color: $color-brand-2;
      box-shadow: none;

      &:hover {
        color: $color-white;
        background-color: $color-brand-1;
      }
    }
  }

  &_settings {
    width: 44px;
    height: 44px;
    border-radius: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $color-bg-light;
    transition: background-color $transition-default;

    & .icon {
      fill: $color-brand-3;
      font-size: 20px;
    }

    &:hover {
      background-color: $color-brand-2;
    }

    &:active {
      background-color: $color-brand-1;
    }
  }

  &_clear {
    color: $color-brand-2;
    font-size: 14px;
    line-height: 17px;
    transition: color $transition-default;

    & .icon {
      stroke: $color-brand-2;
      font-size: 9px;
      margin-right: 10px;
      transition: stroke $transition-default;
    }

    &:hover {
      color: $color-red;

      & .icon {
        stroke: $color-red;
      }
    }

    &:active {
      color: $color-red-hover;

      & .icon {
        stroke: $color-red-hover;
      }
    }

    &.bordered {
      border-radius: 5px;
      padding: 16px 24px;
      box-shadow: inset 0 0 0 1px $color-border;

      @include break-max($breakpoint-md) {
        padding: 16px;
      }
    }
  }

  &_round {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: $color-brand-opaque-10;
    transition: all $transition-default;

    .icon {
      width: 15px;
      height: 15px;
    }

    &:hover {
      background-color: $color-brand-opaque-30;

      .icon {
        opacity: 1;
      }
    }
  }

  &_edit-small {
    display: inline-flex;
    align-items: center;
    border-radius: 35px;
    height: 35px;
    box-shadow: inset 0 0 0 1px $color-brand-opaque-20;
    color: $color-brand-3;
    padding: 0 14px;
    font-size: 14px;
    background-color: $color-white;
    transition: background-color $transition-default;

    & .icon {
      flex-shrink: 0;
      font-size: 16px;
      margin-right: 7px;
    }

    &:hover {
      background-color: $color-brand-opaque-8;
    }
  }
}

.button_type_task-assigned {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  background-color: transparent;
  font-size: 14px;
  color: $color-brand-3;
  width: 234px;
  white-space: normal;
  text-align: left;

  .icon-block {
    margin-right: 7px;
    margin-left: 4px;
    margin-top: -5px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: $color-brand-opaque-10;
    transition: background-color $transition-default;

    .icon {
      font-size: 16px;
      stroke: $color-brand-3;
    }
  }

  &:hover .icon-block {
    background-color: $color-brand-opaque-20;
  }

  @include break-max($break-lg) {
    width: 140px;
  }
}

.button_type_task-assigned.is-active {

  .icon-block {
    background-color: $color-red;

    .icon {
      stroke: $color-white;
    }
  }

  &:hover .icon-block {
    background-color: $color-red-hover;
  }
}

.button_type_task {
  height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: transparent;
  font-size: 14px;
  color: $color-brand-3;
  padding: 0 14px;
  white-space: nowrap;
  text-align: left;

  & > .icon {
    font-size: 10px;
    stroke: $color-brand-3;
    margin-right: 15px;
  }

  &:hover {
    background-color: $color-brand-opaque-10;
  }

  &:active {
    background-color: $color-brand-opaque-20;
  }
}

.button_type_add {
  cursor: pointer;
  background-color: $color-brand-opaque-10;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  transition: background-color $transition-default;

  &:hover {
    background-color: $color-brand-opaque-20;

    // &:after {
    //   transform: rotate(-90deg);
    // }
  }

  &:after {
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    margin: auto;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02IC41VjBINXY1SDB2MWg1djVoMVY2aDVWNUg2Vi41eiIgZmlsbD0iIzhGOTVBQyIvPjwvc3ZnPg==);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    // transform: rotate(90deg);
  }
}

.button_type_delete {
  flex-shrink: 0;
  background-color: $color-brand-opaque-10;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  transition: background-color $transition-default;

  &:hover {
    background-color: $color-brand-opaque-20;
  }

  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin: auto;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi42NjY2NyAxLjNMMS4zMzMzMyA2LjciIHN0cm9rZT0iIzhGOTVBQyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGQ9Ik0xLjMzMzMzIDEuM0w2LjY2NjY3IDYuNyIgc3Ryb2tlPSIjOEY5NUFDIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.button_type_burger {
  display: none;

  @include break-max($breakpoint-md) {
    display: block;
  }

  & .icon {
    stroke: $color-white;
    font-size: 19px;

    @include break-max($breakpoint-xs) {
      stroke: $color-brand-1;
    }
  }
}

.button_type_pill {
  background-color: $color-brand-3;
  color: $color-white;
  white-space: nowrap;
  margin-right: 24px;
  line-height: 30px;
  padding: 0 12px;
  border-radius: 4px;
  font-size: 14px;

  transition: background-color $transition-default;
  cursor: pointer;

  &:hover {
    background-color: $color-brand-3-hover;
  }
}

.button_type_filtered {
  font-size: 14px;
  line-height: 17px;
  padding: 6px 12px;
  box-shadow: inset 0 0 0 1px $color-brand-opaque-30;
  transition: box-shadow $transition-default,
    background-color $transition-default;

  &:hover {
    box-shadow: inset 0 0 0 1px transparent;
    background-color: $color-brand-opaque-20;
    cursor: pointer;
  }

  @include break-max($break-xs) {
    font-size: 15px;
    line-height: 20px;
  }
}

.button_type_reveal-filter {
  color: $color-white;
  box-shadow: inset 0 0 0 1px $color-white;
  padding: 18px 24px;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  border-radius: 4px;
  cursor: pointer;
  transition: color $transition-default, background-color $transition-default;

  &:hover {
    background-color: $color-white;
    color: $color-brand-3;
  }

  @include break-max($break-md) {
    order: 2;
    flex: 0 1 calc(100% / 4 - 12px);
    background-color: $color-brand-2;
    box-shadow: none;

    &:hover {
      color: $color-white;
      background-color: $color-brand-1;
    }
  }
}

.button_type_settings {
  width: 44px;
  height: 44px;
  border-radius: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $color-bg-light;
  transition: background-color $transition-default;

  & .icon {
    fill: $color-brand-3;
    font-size: 20px;
  }

  &:hover {
    background-color: $color-brand-2;
  }

  &:active {
    background-color: $color-brand-1;
  }
}

.button_type_clear {
  color: $color-brand-2;
  font-size: 14px;
  line-height: 17px;
  transition: color $transition-default;

  & .icon {
    stroke: $color-brand-2;
    font-size: 9px;
    margin-right: 10px;
    transition: stroke $transition-default;
  }

  &:hover {
    color: $color-red;

    & .icon {
      stroke: $color-red;
    }
  }

  &:active {
    color: $color-red-hover;

    & .icon {
      stroke: $color-red-hover;
    }
  }

  &.bordered {
    border-radius: 5px;
    padding: 16px 24px;
    box-shadow: inset 0 0 0 1px $color-border;

    @include break-max($breakpoint-md) {
      padding: 16px;
    }
  }
}

.button_type_round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: $color-brand-opaque-10;
  transition: all $transition-default;

  .icon {
    width: 15px;
    height: 15px;
  }

  &:hover {
    background-color: $color-brand-opaque-30;

    .icon {
      opacity: 1;
    }
  }
}

.button_type_edit-small {
  display: inline-flex;
  align-items: center;
  border-radius: 35px;
  height: 35px;
  box-shadow: inset 0 0 0 1px $color-brand-opaque-20;
  color: $color-brand-3;
  padding: 0 14px;
  font-size: 14px;
  background-color: $color-white;
  transition: background-color $transition-default;

  & .icon {
    flex-shrink: 0;
    font-size: 16px;
    margin-right: 7px;
  }

  &:hover {
    background-color: $color-brand-opaque-8;
  }
}


.button_presentation {
  padding-left: 41px;
  padding-right: 41px;
}

.expanding-button {
  background: $color-brand-opaque-10;
  border-radius: 14px;
  border: none;
  position: relative;
  height: 29px;
  width: 29px;

  &__expanded {
    &::after {
      transform: rotate(90deg);
    }
  }

  &::before, &::after {
    background: $color-brand-3-hover;
    content: '';
    position: absolute;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  }

  &::before {
    top: 14px;
    height: 1px;
    left: 10px;
    right: 10px;
    transform: rotate(-180deg);
  }

  &::after {
    top: 10px;
    left: 14px;
    bottom: 10px;
    width: 1px;
    transform: rotate(0deg);
  }
}

.expanding-button__expanded {
  &::after {
    transform: rotate(90deg);
  }
}

@mixin primary-button() {
  align-items: center;
  background: $color-white;
  box-shadow: 0px 4px 20px $color-brand-opaque-30;
  border-radius: 5px;
  color: $color-brand-2;
  display: flex;
  justify-content: center;
  height: 44px;
}

@mixin secondary-button() {
  align-items: center;
  background: $color-brand-2;
  box-shadow: 0px 4px 20px $color-brand-opaque-30;
  border-radius: 5px;
  color: $color-white;
  display: flex;
  justify-content: center;
  height: 44px;
}

.button-mobile {
  align-items: center;
  background-color: $color-brand-opaque-10;
  border: none;
  border-radius: 5px;
  color: $color-brand-2;
  display: flex;
  font-size: 15px;
  justify-content: center;
  line-height: 18px;
  margin-top: 12px;
  padding-top: 0;
  padding-bottom: 0;
  height: 44px;
  width: 100%;

  &.for-main {
    font-size: 15px;
    margin: 10px 0px;
    width: 100%;
  }

  &.for-assignations,
  &.for-commercial {
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin: 0px 10px 5px;
    width: calc(100% - 20px);
  }
}

.button-mobile.is-add-client {
  margin-bottom: 5px;

  .button_type_add {
    background: none;
  }
}

.button.button_view_outline.button_presentation {
  @include break-max($break-xs) {
    border-radius: 5px;
    margin-top: 10px;
    width: 100%;
  }
}

.button_view_outline.for-mobile-filters {
  display: block;
  margin: 20px auto 0;
}

.button_view_grey {
  @include break-max($break-xs) {
    border-radius: 5px;
  }
}

.input_type_upload-btn {
  @include break-max($break-xs) {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 44px;
    padding: 0;
  }
}


.button-blick {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.button-blick:after {
  content: "";
  position: absolute;
  top: -50%;
  bottom: -50%;
  background: -webkit-gradient(linear, left top, right top, from(hsla(0, 0%, 100%, 0.1)), to(hsla(0, 0%, 100%, 0.6)));
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0.6) 100%);
  width: 45px;
  -webkit-transform: skewX(-22deg);
  transform: skewX(-22deg);
  left: -65%;
  -webkit-animation-name: blick;
  animation-name: blick;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes blick {
  60% {
      left: 100%;
  }
  to {
      left: 130%;
  }
}
@keyframes blick {
  60% {
      left: 100%;
  }
  to {
      left: 130%;
  }
}