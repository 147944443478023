.modal {
  $c: &;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  transition: opacity .3s cubic-bezier(0,0,0.12,1);
  z-index: 1005;
  overflow-y: auto;
  background-color: transparent;
  color: $color-brand-2;

  &.has-darken {
  background-color: rgba(143, 149, 172, 0.7);

  }

  &__grey-wrapper {
    margin-top: 30px;
    padding: 50px 0;
    background: $color-brand-opaque-8;
  }

  &__hint {
    margin: 25px 0;
    text-align: center;
    color: $color-brand-3;
  }

  &.is-opened {
    display: block;
  }

  &_task {
    overflow: hidden;

    #{$c}__shadow {
      background-color: $color-brand-2;
    }

    #{$c}__content {
      overflow-y: auto;
      padding: 100px 0;
      display: block;
    }

    #{$c}__inner {
      width: 720px;
      padding: 50px 125px;
      position: relative;

      @include break-max($break-xs) {
        padding: 20px 30px;
        margin: 0 10px;
        width: calc(100% - 20px);
      }
    }

    &_lg {
      #{$c}__inner {
        width: 970px;
        max-width: 100%;

        .react-datepicker-wrapper {
          width: 100%;
        }
      }
    }

    #{$c}__title {
      text-align: center;
    }

    #{$c}__close-outer {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: $color-brand-opaque-20;
      cursor: pointer;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -150%);
      transition: background-color $transition-default;

      &:after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE3IDFMOSA5IDEgMU0xNyAxN0w5IDlsLTggOCIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz48L3N2Zz4=);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }

      &:hover {
        background-color: $color-brand-opaque-50;
      }
    }
  }

  &_pres {
    #{$c}__inner {
      width: 840px;
      max-width: 100%;
      padding: 50px 55px;
      position: relative;
    }
    .modal-form__row {
      padding: 0 130px;
    }

    .modal-form__wrap .modal-form__row {
      padding: 0;
    }
  }

  &_small {

    #{$c}__inner {
      width: 470px;
      padding: 40px 50px;
    }

    #{$c}__content {
      display: flex;
    }
  }

  &_medium {

    #{$c}__inner {
      width: 570px;
      padding: 40px 75px;
    }

    #{$c}__content {
      display: flex;
    }
  }

  &_large {

    #{$c}__inner {
      width: 970px;
      max-width: 100%;
      padding: 40px 125px;

      @include break-max($breakpoint-sm) {
        width: 720px;
      }
    }

    #{$c}__content {
      display: flex;
    }
  }

  &_top-overflow {

    .modal__inner {
      margin-top: auto;
    }
  }

  &_filter {
    overflow: hidden;

    #{$c}__shadow {
      background-color: $color-brand-2;
    }

    #{$c}__content {
      overflow-y: auto;
      padding: 100px 0;
      display: block;
    }

    #{$c}__inner {
      width: 970px;
      max-width: 100%;
      padding: 50px 85px 50px 85px;
      position: relative;
    }

    #{$c}__title {
      text-align: center;
    }

    #{$c}__close-outer {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: $color-brand-opaque-20;
      cursor: pointer;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -150%);
      transition: background-color $transition-default;

      &:after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE3IDFMOSA5IDEgMU0xNyAxN0w5IDlsLTggOCIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz48L3N2Zz4=);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }

      &:hover {
        background-color: $color-brand-opaque-50;
      }
    }

    &-wide {
      #{$c}__inner {
        padding: 50px 54px;
      }
    }
  }

  &__shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &__close {
    width: 44px;
    height: 44px;
    cursor: pointer;
    position: absolute;
    top: 17px;
    right: 31px;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;

    @include break-max($break-xs) {
      right: 0;
      top: 0;
    }

    & .icon {
      font-size: 14px;
      stroke: $color-brand-2;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  &__inner {
    pointer-events: all;
    position: relative;
    z-index: 1;
    width: calc(#{$wrapper} - #{$fields} * 2);
    margin: 0 auto;
    background-color: #fff;
    padding: 28px 45px 45px;
    box-shadow: 0 2px 20px $color-brand-opaque-10;
    border-radius: 5px;

    @include break-max($break-lg) {
      width: calc(#{$wrapper-md} - #{$fields-md} * 2);
    }

    @include break-max($break-md) {
      width: calc(#{$wrapper-sm} - #{$fields-sm} * 2);
    }

    @include break-max($break-xs) {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 20px;
    }
  }

  &__title {
    margin-bottom: 24px;
  }

  &_centered {

    & .modal__content {
      max-width: 90%;
      margin: 0 auto;
    }
  }

  &__subtitle {
    margin-bottom: 25px;
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
  }

  &__warning {
    display: flex;
    margin-bottom: 30px;
    padding: 12px 15px;
    border: 1px solid $color-red;
    border-radius: 5px;
    font-weight: 400;

    .info {
      flex-shrink: 0;
      margin-right: 15px;
      width: 25px;
      height: 25px;
      background: $color-red-opaque-15;

      .icon_info {
        fill: $color-red;
      }
    }
  }
}

div.modal-form__gist.modal-form__row,
div.modal-form__gist {
  @include break-max($break-xs) {
    margin-left: 0;
    margin-right: 0;
  }
}

div.modal-form__gist.modal-form__row,
div.modal-form__gist.with-margin {
  @include break-max($break-xs) {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.modal-form__row {
  @include break-max($break-xs) {
    .checkbox.checkbox_view_invert {
      flex-direction: row-reverse;
      justify-content: center;
    }

    .checkbox__box {
      margin-right: 5px;
    }
  }
}

div.modal-form__gist {
  @include break-max($break-xs) {
    .checkbox {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }
}
