.color-brand-1 {
  color: $color-brand-1;
}

.color-brand-2 {
  color: $color-brand-2;
}

.color-brand-3 {
  color: $color-brand-3;
}

.color-red {
  color: $color-red;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.caps {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-weight: bold;
}

.sor__author {
  padding-bottom: 10px;
}


.h1-l {
  @include h1-L();
}

.h2-l {
  @include h2-L();
}

.h3-l {
  @include h3-L();
}

.h4-l {
  @include h4-L();
}

.h5-l {
  @include h5-L();
}

.big-text-l {
  @include big-text-L();
}

.italic-l {
  font-style: italic;
}

.medium-text-l {
  @include medium-text-L();
}

.caps-l {
  @include caps-L();
}

.text-l {
  @include text-L();
}

.text-xs-l {
  @include text-xs-L();
}