.inner-form {
  color: $color-white;
  display: flex;
  align-items: center;
  height: 280px;
  @include break-max($breakpoint-xs) {
    height: auto;
  }
}

.inner-form__form {
  display: flex;
  align-items: flex-start;

  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }

  @include break-max($breakpoint-xs) {
    padding: 40px 0 120px;
    position: relative;
  }

}

.inner-form__block {
  display: flex;
  flex-direction: column;
  margin-left: auto;

  @include break-max($breakpoint-sm) {
    margin-left: 0;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.inner-form__title {
  width: 330px;
  max-width: 100%;

  @include break-max($breakpoint-md) {
    width: 205px;
  }

  @include break-max($breakpoint-sm) {
    width: 100%;
    margin-bottom: 25px;
  }

  @include break-max($breakpoint-xs) {
    text-align: center;
  }
}

.inner-form__row {
  display: flex;
  align-items: flex-start;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.inner-form__input {
  width: 255px;
  max-width: 100%;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }

  @include break-max($breakpoint-md) {
    width: 230px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    &:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

.inner-form__submit {
  margin-top: 0;
  margin-left: 20px;

  @include break-max($breakpoint-md) {
    max-width: 230px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    max-width: 100%;
    position: absolute;
    bottom: 50px;
    margin-left: 0;
  }
}

.inner-form__checkbox {
  margin-top: 20px;
  width: 100%;

  @include break-max($breakpoint-xs) {
    align-items: flex-start;
  }


  .checkbox__label {
    color: $color-white;
    @include break-max($breakpoint-xs) {
      font-size: 16px;
      line-height: 18px;
      width: 230px;
    }
  }
}

.inner-form__input .input__control {
  border-radius: 8px;
  color: $color-white;
  box-shadow: none;
  background: rgba($color-white, 0.1);
}

.inner-form__input .input__control::placeholder {
  color: $color-white;
}

.inner-form__checkbox-box {
  width: 32px;
  height: 32px;
  box-shadow: none;
  background: rgba($color-white, 0.1);

  .checkbox__marker {
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      display: none;
    }
    .checkbox__marker-icon {
      position: static;
    }
  }
}


.link.inner-form__agreement {
  color: rgba($color-white, 0.5);
  &:hover {
    color: $color-white;
  }

  &:active {
    color: rgba($color-white, 0.3);
  }
 }

 .inner-form__checkbox-box .checkbox__marker-icon {
  fill: $color-white;
}