@import '../../styles/variables';
@import '../../styles/mixins';

.videos {
  position: relative;
  margin-bottom: 20px;

  .tabs-nav {
    width: 540px;
    @include break-max($breakpoint-xsm) {
      width: 426px;
    }

    .tabs-nav-item {
      width: calc(100% / 3);

      &__button {
        width: 100%;
      }
    }
  }

  .ant-table-cell {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .ant-table-container table > thead > tr:first-child {

  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    width: 120px;
    border-radius: 5px 0 0 5px;
  }
  .ant-table-container table > thead > tr:first-child th {
    border-right: 1px solid #565865;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-right: none;
    border-radius: 0 5px 5px 0;
  }
  .ant-table-container table > thead > tr:first-child th:nth-last-child(2) {
    border-right: none;
  }


  .common-link {
    svg {
      margin-bottom: 0;
    }
  }

  .tab-bar {
    width: 540px;
    @include break-max($breakpoint-xsm) {
      width: 426px;
    }

    .button {
      width: calc(100% / 3);
      @include break-max($breakpoint-xsm) {
        min-width: auto;
      }
    }
  }

  &__table-img {
    width: 90px;
    height: 54px;
    border-radius: 5px;
    object-fit: cover;
  }

  &__table-speaker-name {
    color: $color-brand-2;
  }

  &__table-speaker-post {
    color: $gray-light-2;
  }

  &__table-status {
    padding: 4px 10px;
    border-radius: 20px;
  }

}

.video-search {
  width: 540px;
  margin-bottom: 10px;

  @include break-max($breakpoint-xsm) {
    width: 426px;
  }
}

.section-search {
  position: relative;

  &__inp {
    height: 50px;
    background-color: $gray-light;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 14px;
    font-family: 'Formular';
    width: 100%;
    padding-right: 40px;

    &::placeholder {
      color: #3C2E3E;
    }
  }

  &__icon {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;

  }
}

