@import "../../styles/variables";
@import "../../styles/mixins";

.hp .wrapper-lands {


  padding-top: 85px;
  @include break-max($breakpoint-sm) {
    padding-top: 50px;
  }

  @include break-max($breakpoint-xs) {
    max-width: calc(100% - 20px);
  }
}



.wrapper.panschool-new {
  @include break-max(950px) {
    padding-left: 10px;
    padding-right: 10px;
    overflow-x: hidden;
  }
}

.panschool-sec1 {
  border-radius: 60px;
  background-color: #E3F0F7;
  padding: 60px 90px;
  margin-top: 10px;
  margin-bottom: 5px;
  position: relative;
  min-height: 670px;

  @include break-max($breakpoint-md) {
    padding: 60px 80px;
    min-height: 550px;
    border-radius: 40px;
  }

  @include break-max(1120px) {
    min-height: 400px;
    border-radius: 30px;
  }

  @include break-max($breakpoint-xsm) {
    padding: 30px 45px;
    border-radius: 20px;
  }

  @include break-max(950px) {
    min-height: auto;
    background-color: rgba(0,0,0,0.4);
    height: 350px;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 5px;
  }

  &__toplogo {
    position: absolute;
    top: 0;
    right: 0;

    @include break-max($breakpoint-md) {
      width: 500px;
    }

    @include break-max(1120px) {
      width: 420px;
    }

    @include break-max($breakpoint-xsm) {

    }

    @include break-max(950px) {
      width: 100%;
      z-index: -1;
      height: 350px;
      object-fit: cover;

    }

  }

  &__panschool {
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 60px;

    @include break-max($breakpoint-md) {
        margin-bottom: 50px;
    }
    @include break-max($breakpoint-xsm) {
      margin-bottom: 40px;
    }

    @include break-max(950px) {
      color: white;
      text-align: center;
      font-size: 12px;
      margin-bottom: 20px;
    }
  }

  &__leveltext {
    font-size: 18px;
    margin-bottom: 30px;

    @include break-max($breakpoint-sm) {
      font-size: 14px;
    }

    @include break-max(950px) {
      font-size: 12px;
      color: white;
      text-align: center;
      margin-bottom: 50px;
    }

  }
  &__leveltext:before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #EF372E;
    margin-right: 10px;
    display: inline-block;

    @include break-max(950px) {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }

  &__maintitle {
    font-size: 80px;
    line-height: 85px;
    font-weight: bold;

    @include break-max($breakpoint-md) {
      font-size: 64px;
      line-height: 66px;
    }

    @include break-max($breakpoint-sm) {
      font-size: 36px;
      line-height: 38px;
    }

    @include break-max(950px) {
      color: white;
      text-align: center;
    }

  }

  &__secondtitle {
    font-size: 24px;
    font-weight: 500;
    margin-top: 15px;

    @include break-max($breakpoint-md) {
      font-size: 20px;
    }

    @include break-max($breakpoint-sm) {
      font-size: 16px;
    }

    @include break-max(950px) {
      color: white;
      text-align: center;
      font-size: 12px;
    }
  }

  &__btns {
    margin-top: 40px;
    display: flex;
    gap: 30px;

    @include break-max($breakpoint-xsm) {
      margin-top: 20px;
    }

    button {
      min-width: 220px;
      height: 50px;

      @include break-max($breakpoint-xsm) {
        min-width: 170px;
        //font-size: 12px;
      }
    }

    @include break-max(950px) {
      margin-top: 5px;
      background-color: #E3F0F7;
      border-radius: 20px;
      padding: 20px 30px;
      flex-direction: column;
      gap: 5px;
    }
  }

}

.panschool-sec2 {
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 60px;
  padding: 40px 90px;
  background-color: #EF372E;
  color: white;
  display: flex;
  gap: 80px;
  width: 100%;

  @include break-max($breakpoint-md) {
    padding: 40px 80px;
    gap: 40px;
    border-radius: 40px;
  }

  @include break-max(1120px) {
    border-radius: 30px;
  }

  @include break-max($breakpoint-xsm) {
    border-radius: 20px;
  }

  @include break-max(950px) {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    border-radius: 15px;
  }

  @include break-max(950px) {
    margin-top: 5px;
    padding-left: 55px;
    padding-right: 55px;
    margin-bottom: 0;
  }


  &__title {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 15px;
    white-space: nowrap;

    @include break-max($breakpoint-md) {
      white-space: normal;
    }

    @include break-max(950px) {
      margin-bottom: 5px;
    }
  }

  &__item {
    width: calc(33.3333% - 20px);

    @include break-max(950px) {
      text-align: center;
      width: 100%;
    }
  }

  &__text {
    @include break-max(950px) {
      font-size: 12px;
    }
  }



}

.panschool-sec3 {
  border-radius: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background-color: #E3F0F7;
  position: relative;
  display: flex;


  @include break-max($breakpoint-md) {
    border-radius: 40px;
  }

  @include break-max(1120px) {
    border-radius: 30px;
  }

  @include break-max($breakpoint-xsm) {
    border-radius: 20px;
  }

  @include break-max(950px) {
    border-radius: 15px;
    margin-top: 5px;
    flex-direction: column;
    gap: 5px;
    background-color: transparent;
  }

  &__right {
    padding: 70px 90px;

    @include break-max($breakpoint-md) {
      padding: 70px 40px;
    }

    @include break-max($breakpoint-sm) {
      padding: 50px;
    }


    @include break-max(950px) {
      text-align: center;
      padding: 30px;
      border-radius: 15px;
      background-color: #E3F0F7;
    }
  }

  &__right-title {
    font-size: 48px;
    font-weight: 500;
    margin-bottom: 40px;

    @include break-max($breakpoint-md) {
      font-size: 30px;
      margin-bottom: 20px;
    }

    @include break-max($breakpoint-sm) {
      font-size: 18px;
    }

    @include break-max($breakpoint-xsm) {
      font-size: 18px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    li {
      display: flex;
      gap: 40px;
      font-size: 24px;
      align-items: center;

      @include break-max($breakpoint-md) {
        font-size: 18px;
      }

      @include break-max($breakpoint-sm) {
        font-size: 14px;
        gap: 20px;
      }

      @include break-max(950px) {
        text-align: left;
      }

      svg {
        flex-shrink: 0;
      }
    }
  }

  &__left {
    border-radius: 60px;
    bottom: 0;
    background-color: #EF372E;
    padding: 70px 90px;
    display: flex;
    flex-direction: column;
    max-width: 625px;

    @include break-max($breakpoint-md) {
      padding: 70px 80px;
      border-radius: 30px;
    }

    @include break-max($breakpoint-sm) {
      padding: 50px;
      max-width: 550px;
    }

    @include break-max($breakpoint-xsm) {
      max-width: 400px;
    }

    @include break-max(950px) {
      border-radius: 15px;
      max-width: 100%;
      text-align: center;
      padding: 30px;
      display: flex;
      order: 1;
    }
  }

  &__lady {
    width: 222px;
    height: 222px;
    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;

    @include break-max($breakpoint-md) {
      width: 190px;
      height: 190px;
    }

    @include break-max(950px) {
      margin: 0 auto;
    }

  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    margin-top: 55px;
    margin-bottom: 20px;
    color: white;

    @include break-max($breakpoint-md) {
      margin-top: 20px;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  &__text {
    font-size: 18px;
    color: white;

    @include break-max($breakpoint-sm) {
      font-size: 14px;
    }
  }

  &__btn {
    margin-top: 80px;

    @include break-max($breakpoint-md) {
      margin-top: 35px;
    }

    button {
      width: 220px;

      @include break-max(950px) {
        width: 100%;
      }
    }
  }
}

.panschool-sec4 {
  padding: 70px 90px 30px 90px;
  border-radius: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background-color: #E3F0F7;

  @include break-max($breakpoint-xsm) {
    .panschool-sec1__maintitle {
      color: black;
    }
  }
  @include break-max($breakpoint-md) {
    padding: 70px 80px;
    border-radius: 40px;
  }

  @include break-max(1120px) {
    border-radius: 30px;
  }

  @include break-max($breakpoint-xsm) {
    border-radius: 20px;
  }

  @include break-max(950px) {
    border-radius: 15px;
    padding: 30px 25px;
    margin-top: 5px;
  }

  .panschool-sec1__maintitle {
    text-align: center;
  }

  &__list {
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
    gap: 40px;

    @include break-max($breakpoint-md) {
      gap: 20px;
    }

    @include break-max($breakpoint-xsm) {
      flex-wrap: wrap;
    }

    @include break-max(950px) {
      flex-wrap: nowrap;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 10px;
    }


    li {
      padding: 40px;
      border-radius: 20px;
      background-color: white;
      list-style-type: none;

      @include break-max($breakpoint-md) {
        padding: 25px 30px;
      }

      @include break-max($breakpoint-xsm) {
        flex: 0 1 calc(50% - 10px);
      }

      @include break-max(950px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
      }

    }


  }

  &__item-number {
    color: #EF372E;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;

    @include break-max(950px) {
      margin-bottom: 5px;
    }
  }

  &__item-title {
    color: #2C2E3E;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;

    @include break-max($breakpoint-md) {
      font-size: 18px;
    }

    @include break-max(950px) {
      margin-bottom: 5px;
    }
  }

  &__item-text {
    font-size: 18px;

    @include break-max($breakpoint-sm) {
      font-size: 14px;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;

    @include break-max($breakpoint-xsm) {
      justify-content: center;
    }
  }

  &__bottom-left {
    padding: 25px 50px;
    background-color: #EF372E;
    color: white;
    font-size: 24px;
    font-weight: 700;
    width: 66.6666%;
    border-radius: 20px;

    @include break-max($breakpoint-md) {
      font-size: 18px;
    }
  }

  &__bottom-right {
    display: flex;
    align-items: flex-end;

    @include break-max(950px) {
      width: 100%;
    }

    button {
      min-width: 240px;

      @include break-max(950px) {
        width: 100%;
      }
    }
  }

}

.panschool-sec5 {
  margin-top: 10px;
  margin-bottom: 5px;
  min-height: 740px;
  padding: 60px 90px;
  border-radius: 60px;
  background: url("../../assets/images/panschool/panschool-sec5-bg.png");
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: #EF372E;
  justify-content: space-between;

  @include break-max($breakpoint-md) {
    padding: 60px 80px;
    border-radius: 40px;
  }

  @include break-max(1120px) {
    border-radius: 30px;
  }

  @include break-max($breakpoint-sm) {
    min-height: auto;
  }

  @include break-max($breakpoint-xsm) {
    border-radius: 20px;
  }

  @include break-max(950px) {
    border-radius: 15px;
    padding: 50px;
    margin-top: 5px;
  }

  &__row {
    display: flex;
    gap: 40px;

    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
  }

  &__item {
    flex: 1;

    @include break-max(950px) {
      text-align: center;
      flex-grow: 1;
    }
  }

  &__item:last-child {
    flex: 1.2;
    padding-right: 80px;

    @include break-max($breakpoint-md) {
      padding-right: 0;
    }

    @include break-max(950px) {
      flex: 1;
    }
  }

  &__title {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;

    @include break-max($breakpoint-sm) {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &__text {
    font-size: 18px;

    @include break-max($breakpoint-sm) {
      font-size: 14px;
    }
  }

  &__bigtitle {
    font-size: 48px;
    line-height: 52px;

    @include break-max($breakpoint-md) {
      font-size: 42px;
    }
    @include break-max($breakpoint-sm) {
      font-size: 34px;
      line-height: 42px;
    }

  }

}

.panschool-sec6 {
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 740px;
  padding: 60px 90px;
  border-radius: 60px;
  background-color: #E3F0F7;
  position: relative;

  .panschool-sec1__maintitle {
    @include break-max($breakpoint-xs) {
      font-size: 22px;
      line-height: 22px;
    }
  }

  @include break-max($breakpoint-md) {
    border-radius: 40px;
  }

  @include break-max(1120px) {
    border-radius: 30px;
  }

  @include break-max($breakpoint-xsm) {
    border-radius: 20px;
    padding: 50px;
  }

  @include break-max(950px) {
    border-radius: 15px;
    padding: 30px;
    margin-top: 5px;
  }

  .panschool-sec1__maintitle {
    text-align: center;
    color: black;
  }

  &__bottomline {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__form {

    .sor__form {
      flex-direction: column;
      align-content: center;
    }

    .sor__form-box {
      display: block;
      width: 360px;
      margin: 0 auto;
      @include break-max(950px) {
        width: auto;
      }
    }

    .sor__form-item {
      margin: 20px auto;
      width: 100%;
    }

    .input_type_form .input__control {
     width: 100%;
    }
    .sor__form-footer {
      width: 360px;
      margin: 20px auto 0 auto;
    }
  }
}






























