.auth {
  position: relative;
  min-height: 100vh;
  padding-top: 90px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include break-max($breakpoint-xs) {
    padding-top: 0;
    justify-content: flex-start;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;

    &_left {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;

      .icon {
        width: 650px;
        height: 100%;
        margin-left: -135px;

        @include break-max($breakpoint-sm) {
          width: 530px;
        }
      }

      @include break-max($breakpoint-md) {
        top: 70px;
      }

      @include break-max($breakpoint-xs) {
        display: none;
      }
    }

    &_right {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;

      .icon {
        width: 600px;
        height: 100%;

        @include break-max($breakpoint-md) {
          margin-right: -100px;
        }
      }

      @include break-max($breakpoint-xs) {
        display: none;
      }
    }

    &_planet-1 {
      position: absolute;
      top: 144px;
      left: 63px;

      @include break-max($breakpoint-sm) {
        left: 20px;

        .icon_saturn {
          width: 91px;
          height: 91px;
        }
      }

      @include break-max($breakpoint-xs) {
        top: 50px;
        left: 62px;

        .icon_saturn {
          width: 61px;
          height: 61px;
        }
      }
    }

    &_planet-2 {
      position: absolute;
      right: 195px;
      bottom: 302px;

      @include break-max($breakpoint-md) {
        right: 125px;
      }

      @include break-max($breakpoint-sm) {
        right: 89px;
        bottom: 150px;

        .icon_jupiter {
          width: 71px;
          height: 71px;
        }
      }

      @include break-max($breakpoint-xs) {
        right: 15px;
        bottom: 40px;

        .icon_jupiter {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  &__content {
    position: relative;
    padding-bottom: 55px;
    z-index: 5;

    display: flex;
    align-items: center;
    flex-direction: column;

    @include break-max($breakpoint-xs) {
      padding-bottom: 30px;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    @include break-max($breakpoint-md) {
      margin-bottom: 30px;
    }

    @include break-max($breakpoint-sm) {
      margin-bottom: 80px;
    }

    @include break-max($breakpoint-xs) {
      margin-top: 15px;
      margin-bottom: 30px;
      padding: 0 10px;
      z-index: 10;
      justify-content: flex-start;

      & .icon {
        width: 52.5px;
        height: 28px;
      }
    }

    .icon {
      width: 85px;
      height: 45px;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 50px;

    @include break-max($breakpoint-xs) {
      margin-bottom: 20px;
    }
  }

  &__form {
    max-width: 470px;
    width: 100%;

    &-tab {
      display: none;
      &.active {
        display: block;
      }
    }
  }

  &__sign-in {
    padding-top: 50px;

    @include break-max($breakpoint-md) {
      padding-top: 0;
    }
  }

  &__footer {
    margin-top: auto;

    @include break-max($breakpoint-xs) {
      background-color: $color-white;
      z-index: 10;
    }

    &-content {
      border-top: 1px solid $color-brand-opaque-20;
      padding: 10px 0;

      display: flex;
      align-items: center;
      justify-content: space-between;

      @include break-max($breakpoint-xs) {
        justify-content: center;
        padding: 0;
      }
    }

    &-pan {
      color: $color-brand-3;

      @include break-max($breakpoint-xs) {
        display: none;
      }
    }

    .footer__social {
      width: auto;
      height: 50px;
      margin: 0;
    }

    &-socials {
      display: flex;
      align-items: center;
    }

    &-join {
      color: $color-brand-3;
      margin-right: 30px;

      @include break-max($breakpoint-xs) {
        display: none;
      }
    }
  }

  &__department {
    position: absolute;
    top: 65px;
    right: 65px;
    border-radius: 5px;
    background-color: #F9FAFC;
    z-index: 5;
    padding: 20px;

    @include break-max($breakpoint-sm) {
      top: 40px;
      right: 20px;
      padding: 10px 20px;
    }

    @include break-max($breakpoint-xs) {
      top: 15px;
      right: 10px;
      background: transparent;
      padding: 0;
    }

    &-phone {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 10px;


      @include break-max($breakpoint-sm) {
        font-size: 14px;
        margin-bottom: 0;
      }

      & a {
        color: $color-brand-2;
      }
    }

    &-name {
      color: $color-brand-3;
    }
  }
}

.sign-in, .sign-up {

  &__title {
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;

    @include break-max($breakpoint-xs) {
      font-weight: normal;
      margin-bottom: 20px;
      padding: 0 45px;
    }
  }

  &__row {
    display: flex;
    padding: 8px 0;
  }

  &__input {
    flex-grow: 1;
  }

  &__remember {
    flex-grow: 1;
  }

  &__forgot {
    color: $color-brand-3;
    display: flex;
    align-items: center;
  }

  &__submit {
    @include break-max($breakpoint-xs) {
      flex-grow: 1;
    }
    margin: auto;
    text-align: center;
  }

  &__footer {
    flex-grow: 1;
    text-align: center;
  }

  &__sign-up {
    color: $color-brand-3;
  }
}
