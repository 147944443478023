
@import "../../styles/variables";
@import "../../styles/mixins";
.lk-meetings {
  &__banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
    border: 1px solid $color-brand-opaque-20;
    border-radius: 5px;
    margin-bottom: 50px;
    color: $color-brand-1;

    @include break-max($break-xs) {
      background: $blue;
      color: $color-white;
      margin: 20px 10px 30px;
    }

    &-icon {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &-title {
      text-align: center;

      @include break-max($break-xs) {
        z-index: 1;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @include break-max($break-xs) {
      align-items: flex-start;
      flex-direction: column;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.lk-events__time {
  white-space: nowrap;
  margin-right: 30px;
}

.checkbox__marker, .checkbox__input {
  z-index: 0;
}

.lk-meetings__leaf-icon {
  bottom: 0;
  left: 0;
  position: absolute;
}

.lk-meetings__title {
  @include break-max($break-xs) {
    margin-bottom: 15px;
  }
}

.lk-meetings__table {
  @include break-max($break-xs) {
    margin: 0 10px 50px;

    .flats__row-table-expanded {
      padding-right: 0;
    }
  }

  @include break-max($break-xxs - 1px) {
    .orders__table-client {
      width: 100px;
    }
  }
}

.lk-meetings__table-name {
  @include break-max($break-xs) {
    display: flex;
    white-space: normal;
    width: 130px;
  }

  @include break-max($break-xxs - 1px) {
    width: 110px;
  }
}

.lk-meetings__all {
  @include break-max($break-xs) {
    text-align: center;
    width: 100%;
  }
}

.lk-events__date {
  @include break-max($break-xs) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.lk-events__time {
  @include break-max($break-xs) {
    margin: 5px 0 0 0;
  }
}

.assignation-reservation.for-meetings {
  padding-top: 300px;

  @include break-max($breakpoint-xs) {
    padding-top: 0;
  }

  .modal__title {
    margin-bottom: 48px;
  }

  .assignation-reservation__header {
    height: 290px;
    top: -40px;

    svg {
      height: 100%;
    }
  }

  @include break-max($breakpoint-xs) {
    .modal-form__item.for-date {
      flex-basis: 100%;
      width: 100%;
    }

    .modal-form__item.for-time {
      flex-basis: 60%;
      margin-top: 15px;
      width: 60%;

      .modal-form__row {
        flex-direction: row;
      }

      .modal-form__item {
        margin: 0;
      }

      .modal-form__item + .modal-form__item {
        margin-left: 15px;
      }
    }

    .modal-form__row.for-shedule {
      flex-direction: row;
    }
  }
}

.lk-events__eventCancel-info {
  @include break-max($break-xs) {
    margin-top: 5px;

    .lk-events__date {
      align-items: center;
      flex-direction: row;
    }

    .lk-events__time {
      margin: 0 0 0 15px;
    }
  }
}

#reservation-new-client {
  @include break-max($break-xs) {
    background: none;
    padding: 0;
  }
}
