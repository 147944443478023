@import '../../styles/variables';
@import '../../styles/mixins';

.app-modal {
  width: 510px;
  margin: 32px auto 50px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__qr {
    margin-bottom: 30px;
  }

  &__text {
    color: $color-brand-2;
    max-width: 342px;
    text-align: center;
    font-size: 14px;
    margin-bottom: 30px;
  }

  &__action {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
  }
}



