@import "../../styles/variables";
@import "../../styles/mixins";

.faq {
  .sor__footer {
    
  }
}

.faq__category {
  padding-top: 65px;
  min-height: 800px;
  text-align: center;

  @include break-max($breakpoint-md) {
    min-height: 650px;
  }

  @include break-max($breakpoint-sm) {
    min-height: auto;
    padding-bottom: 65px;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.faq__sub {
  color: $color-brand-3;
}

.faq__category-list {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @include break-max($breakpoint-md) {
    margin-top: 40px;
  }

  @include break-max($breakpoint-sm) {

  }

  @include break-max($breakpoint-xs) {
    margin-top: 35px;
    flex-direction: column;
  }
}

.faq__category-item {
  width: 377px;
  max-width: 100%;
  height: 420px;
  margin-right: 32px;
  margin-top: 45px;
  &:nth-of-type(3n+3) {
    margin-right: 0;
  }
  &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
    margin-top: 0;
  }

  @include break-max($breakpoint-md) {
    width: 290px;
    height: 320px;
  }

  @include break-max($breakpoint-sm) {
    width: 232px;
    height: 280px;
    margin-right: 15px;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 35px;
    width: 280px;
    height: 230px;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.faq__category-btn {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 45px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: $color-brand-opaque-8;

  @include break-max($breakpoint-md) {

  }

  @include break-max($breakpoint-sm) {
    padding-top: 30px;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 25px;
  }
}

.faq__category-img {
  height: 235px;

  @include break-max($breakpoint-md) {
    height: 190px;
  }
  @include break-max($breakpoint-sm) {
    height: 145px;
  }

  @include break-max($breakpoint-xs) {
    height: 115px;
  }
}


.faq__category-name {
  position: absolute;
  width: 100%;
  bottom: 65px;
  text-align: center;

  @include break-max($breakpoint-md) {
    bottom: 45px;
  }

  @include break-max($breakpoint-sm) {
    bottom: 50px;
  }

  @include break-max($breakpoint-xs) {
    bottom: 40px;
  }
}

.faq__result {
  padding-top: 70px;
  padding-bottom: 70px;

  @include break-max($breakpoint-md) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @include break-max($breakpoint-sm) {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 25px;
    padding-bottom: 35px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.faq__result-header {
  margin-bottom: 60px;
  display: flex;
  @include break-max($breakpoint-sm) {
    margin-bottom: 35px;
  }
  @include break-max($breakpoint-xs) {
    margin-bottom: 25px;
    align-items: center;

    .button {
      font-size: 0;
      padding: 0 18px;
      height: 40px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 0;
      }
    }
  }
}

.faq__h2 {
  margin-left: 45px;
  @include break-max($breakpoint-xs) {
    margin-left: 15px;
  }
}

.faq__menu {
  margin-right: auto;
  position: sticky;
  top: 100px;

  @include break-max($breakpoint-md) {
    margin-right: 160px;
  }

  @include break-max($breakpoint-sm) {
    margin-right: 40px;
  }
  
  @include break-max($breakpoint-xs) {
    margin-bottom: 35px;
    margin-right: 0;
    position: static;
    width: 100%;
  }

  ul {
    list-style: none;
    @include break-max($breakpoint-xs) {
      display: flex;
      overflow: auto;
      white-space: nowrap;
    }
  }
}

.faq__menu-item {
  margin-bottom: 14px;
  &:last-of-type {
    margin-bottom: 0;
  }

  @include break-max($breakpoint-xs) {
    margin-bottom: 0;
    margin-right: 15px;
  }
}

.faq__menu-btn {
  width: 300px;
  max-width: 100%;
  min-height: 70px;
  display: flex;
  align-items: center;
  padding: 5px 35px;
  border-radius: 5px;

  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  text-align: left;

  background: $color-white;
  color: $color-brand-1;
  border: 1px solid $color-brand-opaque-20;

  cursor: pointer;

  transition: all 0.3s;

  font-family: inherit;

  &.active {
    color: $color-white;
    background: $color-brand-3;
  }

  @include break-max($breakpoint-md) {
    width: 248px;
    padding: 0 22px;
  }

  @include break-max($breakpoint-sm) {
    width: 208px;
    padding: 5px 18px;
    min-height: 85px;
  }

  @include break-max($breakpoint-xs) {
    width: 160px;
    padding: 0 14px;
    white-space: normal;
    height: auto;

  }
}

.faq__result-box {
  display: flex;
  position: relative;
  align-items: flex-start;
  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.faq__result-content {
  width: 940px;
  max-width: 100%;
  
  font-size: 14px;
  line-height: 21px;

  @include break-max($breakpoint-md) {
    width: auto;
    flex-grow: 1;
    // width: 800px;
  }

  // @include break-max($breakpoint-sm) {
  //   width: 680px;
  // }

  // @include break-max($breakpoint-xsm) {
  //   width: 480px;
  // }

  @include break-max($breakpoint-xs) {
    width: 100%;
  }

  figure {
    max-width: 100%;
    height: auto !important;
  }

  figure img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    height: auto !important;
    margin: 5px 0;
  }

  ol, ul {
    margin: 10px 0;
    margin-left: 15px;
  }

  ol li, ul li {
    margin-bottom: 5px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    color: $color-red;
    transition: all 0.3s;
    &:hover, &:focus {
      color: $color-red-hover;
    }
    &:active {
      color: $color-red-active;
    }
  }

  .h3 {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid $color-brand-opaque-30;
  }

  iframe {
    height: 530px;
    @include break-max($breakpoint-md) {
      height: 420px;
    }

    @include break-max($breakpoint-sm) {
      height: 400px;
    }

    @include break-max($breakpoint-xsm) {
      height: 270px;
    }

    @include break-max($breakpoint-xs) {
      height: 200px;
    }
  }



}

.faq__result-section {
  padding-bottom: 45px;

  @include break-max($breakpoint-xs) {
    padding-bottom: 0;
  }
}