.order-comments {
  display: none;

  &.show {
    display: block;
  }

  &__body {
    padding: 32px 125px 80px;
    margin-left: 125px;
    margin-right: 125px;
    border-bottom: 1px solid $color-brand-opaque-20;

    @include break-max($breakpoint-md) {
      padding-left: 205px;
      padding-right: 205px;
      margin-left: 0;
      margin-right: 0;
    }

    @include break-max($breakpoint-sm) {
      padding-left: 60px;
      padding-right: 60px;
      padding-bottom: 30px;
      border-bottom: none;
    }
  }

  &__header {
    display: flex;
    align-items: center;

    @include break-max($breakpoint-sm) {
      align-items: flex-start;
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;

      @include break-max($breakpoint-sm) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &__step-name {
    width: 300px;
  }

  &__leave-comment {
    padding: 16px 25px;
    text-decoration: none;

    @include break-max($breakpoint-sm) {
      margin-top: 20px;
    }
  }

  &__hide {
    display: flex;
    align-items: center;
    margin-left: 40px;
    color: $color-brand-3;
    cursor: pointer;
    transition: color $transition-default;

    &:hover {
      color: $color-red;
    }

    &-label {
      font-size: 14px;
      line-height: 21px;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: $color-brand-opaque-10;
      margin-left: 12px;

      .icon_arrow_up {
        width: 9px;
        height: 4px;
        transform-origin: 4.5px 2.5px;
        transition: transform $transition-default;
      }
    }

    &.active {
      // .order-comments__hide-icon {
      //   .icon_arrow_up {
      //     transform: rotate(-180deg)
      //   }
      // }
    }
  }

  &__section {
    @include grid-size(9);
    margin-top: 60px;

    @include break-max($breakpoint-md) {
      @include grid-size(10, $grid-gutter-md);
    }

    @include break-max($breakpoint-sm) {
      @include grid-size(12, $grid-gutter-sm);
      margin-top: 35px;
    }
  }

  &__form {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 70px;

    #form-commentary-anchor {
      position: absolute;
      top: -150px;
      opacity: 0;
      z-index: -1;
    }

    &-title {
      margin-bottom: 35px;
    }

    &-input {
      width: 100%;
      height: 165px;
      border: 1px solid $color-brand-opaque-20;
      border-radius: 4px;
      padding: 22px 28px;
      font-size: 14px;
      line-height: 18px;
      color: $color-brand-3;
      resize: none;
      outline: none;
      transition: border $transition-default;

      &::placeholder {
        color: inherit;
      }

      &:hover {
        border-color: $color-brand-opaque-60;
      }

      &:focus {
        border-color: $color-brand-3;
      }
    }

    &-submit {
      padding: 17px 32px;
      margin-top: 25px;
    }
  }
}
