.reglament {

  &__grid {
    @include grid();
  }

  &__grid-col {
    @include grid-col();
  }

  &__back {
    @include grid-size(2);
    margin-top: 15px;
    @include break-max($break-md) {
      @include grid-size(12, $grid-gutter-sm);
      margin-bottom: 22px;
    }
  }

  &__info {
    @include grid-size(8);

    @include break-max($break-lg) {
      @include grid-size(9, $grid-gutter-md);
    }

    @include break-max($break-md) {
      @include grid-size(12, $grid-gutter-sm);
    }
  }

  &__body {
    padding-bottom: 90px;
  }

  &__message {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-radius: 5px;
    border: 1px solid $color-brand-opaque-30;
    margin-bottom: 10px;


    &-icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 31px;
      height: 29px;
      margin-right: 20px;
      flex-shrink: 0;

      & .icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      & span {
        color: $color-white;
        z-index: 10;
        font-size: 16px;
        padding-top: 3px;
      }
    }

    &-text {
      font-weight: bold;
      flex-grow: 1;
    }

  }

  &__sign {
    margin-top: 30px;
  }

  &__submit {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
