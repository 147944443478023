@import "../../styles/variables";
@import "../../styles/mixins";

.contacts-schedule {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 50px;
  background: $color-brand-3;
  border-radius: 4px;
  color: $color-white;
  margin-bottom: 45px;
  @include break-max($breakpoint-xsm) {
    flex-direction: column;
    height: auto;
    padding: 20px 30px;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 25px;
  }
}

.contacts-schedule__title {
  font-size: 20px;
  font-weight: bold;
  @include break-max($breakpoint-xsm) {
    margin-bottom: 5px;
  }
}

.constacts-schedule__item {
  display: flex;
  align-items: baseline;
  margin-right: 60px;
  @include break-max($breakpoint-sm) {
    margin-right: 25px;
  }
  @include break-max($breakpoint-xsm) {
    margin-right: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
}

.contacts-schedule__days {
  margin-right: 14px;
  font-size: 16px;
  font-weight: bold;
}

.contacts-schedule__time {
  font-size: 21px;
  font-weight: 300;
}
