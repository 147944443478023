@import "../../styles/variables";
@import "../../styles/mixins";

.mortgage {

  &__top-text {
    margin-top: -10px;
    margin-bottom: 20px;
  }

  &__managers {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: flex-start;

    .contact-card {
      width: 100%;
    }

    @include break-max($breakpoint-sm) {
      .contact-card {
        padding: 20px;
      }
    }

  }

  &__managers-item {
    width: calc(100% / 3 - 14px);
    align-self: stretch;
    display: inline-flex;

    @include break-max($breakpoint-xsm) {
      width: calc(100% / 2 - 10px);
    }
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__menu-left {
    display: flex;
    gap: 40px;

    a {
      display: inline-flex;
      align-items: center;

      img {
        margin-right: 3px;
      }
    }

    @include break-max($breakpoint-xsm) {
      flex-direction: column;
      gap: 10px;
    }
  }

  &__menu-right {
    display: flex;
    gap: 40px;

    span {
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      svg {
        margin-right: 3px;
      }
    }

    @include break-max($breakpoint-xsm) {
      flex-direction: column;
      gap: 10px;
    }
  }

}

.mortgage-calc-container {
  display: flex;
  gap: 20px;
}

.mortgage-calc-add {
  background-color: $gray-light-3;
  padding: 30px;
  border-radius: 20px;
  width: calc(100% / 3 - 14px);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition-duration: .3s;

  &:hover {
    background-color: $gray-dark;
  }

  &__plus {
    width: 80px;
    height: 80px;
    border-radius: 20px;
    background-color: $color-white;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    margin-top: 10px;
    color: $gray-dark-2;
    text-align: center;
  }

  &__tit {
    text-align: center;
  }

}

.calculator-mortgage {
  background-color: $gray-light;
  padding: 45px 30px 30px 30px;
  border-radius: 20px;
  width: calc(100% / 3 - 14px);
  position: relative;

  .calculator__form {
    width: 100%;
  }

  .calculator__item {
    width: 100%;
    flex-basis: auto;
    margin-bottom: 20px;
  }

  .slider__input {
    width: calc(100% - 20px);
    margin-left: 10px;
  }

  .slider__input .noUi-handle {
    width: 16px;
    height: 16px;
    border: 2px solid $color-white;
    right: -8px;
    top: -8px;
  }

  .calculator__result {
    width: 100%;
    margin-bottom: 15px;
    display: block;
  }
  .calculator__result-title {
    padding-bottom: 15px;
    border-bottom: 1px solid $gray-dark;
    margin-bottom: 15px;
  }


  .calculator__result-list-item {
    display: flex;
    margin-bottom: 15px;

    svg {
      margin-right: 13px;
    }
  }

  .calculator__result-list-title {

  }

  .calculator__result {
    button:disabled {
      color: #DFE1E9;
    }

    .new-alert {
      margin-bottom: 0;
      padding-top: 12px;
      padding-bottom: 11px;

      @include break-max($breakpoint-xsm) {
        padding-left: 15px;
        padding-right: 15px;

        .new-alert__icon {
          margin-right: 10px;
        }
      }
    }
  }


}

.calculator-mortgage.calculator-mortgage_for-pdf {
  background-color: transparent;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0px;
  input {
    background-color: #F6F7F8;
  }
  .calculator__item:first-child {
    pointer-events: none;
    opacity: 0.4;
    color: rgb(199, 202, 213);
  }
  .calculator__crumbs {
    //display: none;
  }
}

.calculator__result-list-overstat_active {
  .calculator__result-list-title {
    color: #2CBA2E;
  }

  .calculator__result-list-value {
    color: #2CBA2E;
  }
}

.slider-input__control-postfix {
  position: absolute;
  right: 10px;
  bottom: 17px;
  color: $gray-dark-2;
}

.calculator__crumbs {
  padding: 6px 10px;
  border-radius: 20px;
  background-color: $gray-light-3;
  margin-right: 10px;
  margin-bottom: 6px;
  cursor: pointer;
}


.calculator__close {
  position: absolute;
  right: 15px;
  top: 25px;
  cursor: pointer;
}

.mortgage-calc {
  background-color: #F6F7F8;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  width: 100%;
  gap: 20px;

  .input_type_form .input__control {
    box-shadow: none;
  }
  .input_type_form .input__error {
    box-shadow: inset 0 0 0 1px #ff2d23;
}
  &__left {
    width: 66.6666%;
  }

  &__right {
    width: calc(33.3333% - 20px);

    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__rates {
    display: flex;
    gap: 20px 40px;
    flex-wrap: wrap;
    margin-bottom: 30px;

    li {
      background-color: white;
      border-radius: 50px;
      padding: 6px 35px;
      cursor: pointer;
      white-space: nowrap;
    }

    li.active {
      color: white;
      background-color: #FF2D23;
    }
  }

  &__calcs {
    display: flex;
    gap: 50px;

    .mortgage-calc__part {
      width: 50%;
      position: relative;


    }

    .mortgage-calc__part:first-child {
      //border-right: 1px solid red;
    }

    .mortgage-calc__part:first-child:after {
      content: '';
      width: 1px;
      position: absolute;
      top: 40px;
      bottom: 0;
      right: -25px;
      background-color: #D9D9D9;

      display: none;
    }




  }

  &__part-row {
    display: flex;
    width: 100%;
    gap: 20px;

    .hp__form-item {
      flex-grow: 1;
    }
    .hp__form-label.text {
      white-space: nowrap;
    }
  }

  &__name-calc {
    margin-bottom: 15px;
    font-weight: bold;
  }


  &__early-btn {
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition-duration: .3s;

    svg path {
      transition-duration: .3s;
    }
  }
  &__early-btn:hover {
    background-color: #f8f8f8;
  }

  &__early-btn.mortgage-calc__early-btn_half {
    height: 50%;
  }

  &__early-btn:hover {
    svg path:first-child {
      fill: #f1f3f6;
    }
  }

  &__early-form {
    padding: 20px;
    border-radius: 20px;
    background-color: #E3F0F7;

    .hp__form-item {
      margin-top: 20px;
    }
  }

  &__early-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: -15px;


    svg {
      transition-duration: .3s;
      cursor: pointer;
    }
    svg:hover {
      rect {
        fill: #f1f3f6;
      }
    }
  }


  .hp__form-item {
    margin-top: 20px;
  }

  .input__control_postfix {
    position: absolute;
    right: 10px;
    bottom: 17px;
    color: #777A89;
  }

  .mortgage-calc__select {
    .custom-select__selected {
      height: 50px;
      background-color: white;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

}

.mortgage-calc__bottom {
  display: flex;
  gap: 20px;
}
.mortgage-calc__send {
  width: 33.333%;
  margin-top: 25px;

  display: flex;
  flex-direction: column;

  h4 {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }

  .mortgage-calc__send-content {
    margin-top: 15px;

    .custom-select {
      max-width: 250px;
      margin: 0 auto;
    }
    .custom-select__selected {
      height: 50px;
    }

    .mortgage-calc__send-list {
      display: flex;
      gap: 10px;
      justify-content: center;
      position: relative;
      margin-top: -30px;

      li {
        list-style-type: none;
        padding: 7px;
        border-radius: 50%;
        background-color: #f6f7f8;
        display: flex;
        cursor: pointer;
        justify-content: center;
        transition-duration: .3s;
        width: 40px;
        height: 40px;

        svg {
          padding-left: 0!important;
        }
      }
      li:hover {
        background-color: #eeeeee;
      }
    }

  }

}

.mortgage-results {
  //width: 66.666%;
  width: 74%;
  margin-top: 25px;
  min-height: 404px;
  background: url("../../assets/images/calcbg2.jpg");
  background-size: cover;
  //background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  display: flex;
  gap: 30px;
  padding: 30px;
  color: white;
  flex-direction: column;

  &__part {
    display: flex;
    width: 100%;
    gap: 50px;
  }

  &__section {
    //width: 33.3333%;
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    h4 {
      margin-bottom: 25px;
    }
  }

  &__progress {
    margin-bottom: 25px;
  }

  &__designations {
    gap: 20px;
    display: flex;

    span {
      margin-bottom: 15px;
    }

    span:first-child:before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #2C2E3E;
      display: inline-block;
      margin-right: 10px;
    }
    span:last-child:before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: white;
      display: inline-block;
      margin-right: 10px;
    }
  }


  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;

    li {
      display: flex;
      justify-content: space-between;
      color: #E3F0F7;

      span:last-child {
        font-weight: 700;
        width: 100px;
        text-align: left;
      }
    }
  }

  &__chart-btn {
    width: 100%;
    height: 50px;
    border: 1px solid white;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    background-color: transparent;
    color: white;
    font-family: "Formular", sans-serif;
    border-radius: 5px;
    cursor: pointer;
  }

  .mortgage-results__chart-btn_dark {
    background-color: #2C2E3E;
    border: 1px solid #2C2E3E;
  }

}

.mortgage-calc__send {
  .custom-select__options {
    z-index: 2;
    position: relative;
  }
}


































