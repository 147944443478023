@import "../../styles/variables";
@import "../../styles/mixins";
.header__bottom {
  @include break-max($breakpoint-md) {
    padding: 7px 0;
  }
}

.header {
  & .header__top-profile {
    margin-left: 0;
    margin-right: 0;

    & .dropdown__block:not(.side-menu) {
      left: auto;
      right: 0;
      transform: none;
    }

    & .profile-drop:before {
      left: auto;
      right: 75px;
    }

  }

    .button_type_burger {
      display: none;
    }

  @include break-max($breakpoint-sm) {
    .button_type_burger {
      display: block;
      margin-right: 10px;
    }
  }


  .banner {
    background-color: $blue;
    color: $color-white;
    height: 36px;
    text-align: center;
    padding: 6px 0;
    @include break-max($breakpoint-sm) {
      height: auto;
    }
  }

  &__nottifications {
    margin-top: 3px;
    margin-right: 15px;
    cursor: pointer;
    position: relative;
    margin-left: 10px;

    svg path {
      transition-duration: .3s;
    }
    svg:hover path {
      stroke: #ff2d23;
    }

    &-status {
      //width: 5px;
      //height: 5px;
      //border-radius: 50px;
      //position: absolute;
      //background-color: #FF2D23;
      //right: 5px;
      //top: 5px;
      position: absolute;
      color: #FF2D23;
      left: 14px;
      background: #2c2d3e;
      font-size: 11px;
      font-weight: bold;
      line-height: 14px;
      top: 2px;
      padding-left: 2px;
    }
  }

  &__favorites {
    margin-right: 18px;
    cursor: pointer;
    position: relative;
    svg path {
      transition-duration: .3s;
    }
    svg:hover path {
      stroke: #ff2d23;
    }

    &-count {
      position: absolute;
      color: #ff2d23;
      left: 14px;
      background: #2c2d3e;
      font-size: 11px;
      font-weight: bold;
      line-height: 14px;
      top: 3px;
      padding-left: 2px;
    }
  }
  &__bottom {
    position: sticky;
    top: 44px; /* Расстояние от верхней части экрана */
    z-index: 1000;
    background: white;
    transition: transform 0.3s ease, opacity 0.3s ease;

  }

  &__bottom--hidden {
    transform: translateY(-100%);
    opacity: 0;
    pointer-events: none;
  }
}

.header_redesign-mobile {

  @include break-max($breakpoint-xs) {
    margin-top: 15px;
    width: calc(100% - 20px);
    margin-left: 10px;
    top: 15px;

    .header__top {
      background-color: $gray-light;
      border-radius: 20px;
      border-bottom: none;
    }

    .header__top-rightblock {
      display: none;
    }
    .header__mobile-search-id {
      display: none;
    }

  }

}


.profile-drop.menu-drop {
  width: 555px;
  margin-top: 0;
  &::before {
    display: none;
  }

  .profile-drop__item {
    flex-basis: calc(100% / 2);
    cursor: pointer;
  }

  .profile-drop__subtitle {
    color: $color-white;
  }

  .profile-drop__content {
    background-color: $color-brand-2;
    border-radius: 9px;
    padding-bottom: 0;
  }

  .profile-drop__icon {
    margin-right: 2px;
  }

  .icon {
    width: 1.2em;
    margin-right: 6px;
  }

}

.side-menu {
  display: block;
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  transform: translateX(200%);

  transition: all 0.3s;

  @include break-max($breakpoint-sm) {
    display: none;
  }

  .profile-drop {
    width: 400px;
    max-width: 100%;
    margin-top: 0;
    border-radius: 0;
    height: 100vh;
    background: $color-white;
    &::before {
      display: none;
    }

    &::after {
      content: '';
      width: 100%;
      height: 40px;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgba($color-white, 0.8);
      filter: blur(4px);
    }
  }
  .profile-drop__wrap {
    border-bottom: none;
    padding: 0;
  }
  .profile-drop__header {
    position: relative;
    background: $color-white;
    border-radius: 0;
    padding-bottom: 15px;

    &::after {
      content: '';
      width: 100%;
      height: 16px;
      display: block;
      position: absolute;
      bottom: -8px;
      left: 0;
      background: rgba($color-white, 0.8);
      filter: blur(4px);
      z-index: 3;
    }
  }

  .profile-drop__content {
    position: relative;
    padding: 0;
    height: calc(100vh - 131px);

    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    & {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

  }

  .profile-drop__wrap:last-of-type {
    padding-bottom: 40px;
  }

  .profile-drop__icon {
    flex-basis: 20px;
    font-size: 20px;
  }

  .profile-drop__exit {
    margin-top: 10px;
    height: 30px;
    background: $color-brand-opaque-10;
    color: $color-brand-3;

    &:hover {
      background: $color-brand-opaque-20;
    }

    .icon {
      fill: $color-brand-3;
    }
  }

  .profile-drop__item {
    width: 100%;
    flex-basis: 100%;
    padding-left: 38px;
    flex-wrap: nowrap;
  }

  .profile-drop__subtitle {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
  }

  .profile-drop__item-title {
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-drop__desc {
    padding-left: 0;
  }

  .header__top-profile-id {

    color: $color-brand-1;
    font-weight: bold;
  }

  .header__top-profile-position {
    padding-top: 2px;
    color: $color-brand-3;
  }

  .header__top-profile-info {
    position: relative;
    display: flex;
    width: 100%;
  }

  .header__top-profile-descr {
    padding-top: 10px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-drop__close {
    position: absolute;
    top: -24px;
    right: -30px;
    width: 60px;
    height: 60px;
    background: none;
    cursor: pointer;

    .icon {
      width: 16px;
      height: 16px;
      stroke: $color-brand-3;
    }

    &:hover .icon {
      stroke: $color-brand-opaque-70;
    }
  }
}

.sidemenu-wrapper {
  position: relative;
  z-index: 9999;
}

.notification {
  position: relative;
  z-index: 1000;
  color: $color-white;
  background: linear-gradient(89.99deg, #1E91F9 -11.49%, #29599E 14.11%, #5639BF 25.02%, #2C2E3E 35.34%, #452775 65.12%, #2C2E3E 75.22%, #5E3356 84.5%, #265993 99.43%);

  a {
    color: inherit;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }

  p {
    margin: 0 2%;
    @include break-max($breakpoint-xs) {
      text-align: center;
      padding: 8px 0;
      font-size: 12px;
      line-height: 14px;
    }
  }

  img {
    max-height: 36px;

    @include break-max($breakpoint-xs) {
      display: none;
    }
  }


}

.notification__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lsr-banner__container {
  background: #E01D42;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification_mac {
  background: linear-gradient(89.9deg, #056BE2 -5.31%, #1C2160 7.99%, #1C2160 22.08%, #1D64C8 33.94%, #1C2160 47.37%, #1C2160 68.76%, #1D64C8 78.55%, #1C2160 87.95%);
  .notification__container {
    height: 36px;
  }
  p {
    margin: 0 1%;
  }
}

.notification_arsenal {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/images/arsenal.png'), linear-gradient(90.17deg, #1E2030 18.26%, #761313 47.85%, #1E2030 62.86%, rgba(118, 19, 19, 1) 72%, #1E2030 80.93%);
}

.notification_mac_bg-gray {
  color: $color-brand-2;
  background: linear-gradient(89.99deg, #DBDDE4 6%, #E8EAF1 16.84%, #DBDDE4 25.88%, #FFFFFF 35.75%, #DBDDE4 77.13%, #FFFFFF 87.44%, #F7F7F7 97.75%);;
}


.notification_webinar {
  background: linear-gradient(89.9deg, #056BE2 -5.31%, #1C2160 7.99%, #1C2160 29.7%, #1D64C8 55.63%, #1C2160 69.35%, #1D64C8 82.3%, #1C2160 98.1%);
  a {
    display: flex;
    width: 100%;
    height: 36px;
    line-height: 1;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    @include break-max($breakpoint-xs) {
      height: auto;
      padding: 5px 0;
    }
  }

  p {
    margin: 0;
    margin-left: 15px;
    @include break-max($breakpoint-xs) {
      margin-top: 5px;
      text-align: left;
      width: 60%;
    }
  }

  @keyframes blinker {
    from { opacity: 1; }
    to { opacity: 0.1; }
  }

  .go-live {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 20px;
    background: #14215A;
    border-radius: 100px;

    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    &::before {
      content: '';
      margin-top: -1px;
      width: 8px;
      height: 8px;
      background: $color-white;
      border-radius: 50%;
      margin-right: 5px;
      animation: blinker .5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
    }
  }

  .notification__container {
    width: 100%;
    @include break-max($breakpoint-xs) {
      // flex-direction: column;
    }
  }
}

.notification_january {
  color: $color-brand-2;
  background-image:
    url('../../assets/images/banner/jan-01.png'),
    url('../../assets/images/banner/jan-02.png'),
    url('../../assets/images/banner/jan-03.png'),
    url('../../assets/images/banner/jan-04.png'),
    linear-gradient(89.89deg, #AEB4CC 0.08%, #CFD5E8 12.47%, #FFFFFF 27.39%, #CFD5E8 60.99%, #FFFFFF 77.13%, #AEB4CC 98.97%);

  background-repeat: no-repeat;
  background-position-x: 23%, 39%, 64%, 76%;

  @include break-max($breakpoint-md) {
    background-position-x: 0%, 25%, 75%, 100%;
  }

  @include break-max($breakpoint-xs) {
    background-image:
    url('../../assets/images/banner/jan-01.png'),
    url('../../assets/images/banner/jan-02.png'),
    linear-gradient(89.89deg, #AEB4CC 0.08%, #CFD5E8 12.47%, #FFFFFF 27.39%, #CFD5E8 60.99%, #FFFFFF 77.13%, #AEB4CC 98.97%);
    background-position-x: 0%, 100%;
  }

  a {
    @include break-max($breakpoint-xs) {
      padding: 0;
    }
  }

  p {
    margin-left: 0;
    @include break-max($breakpoint-xs) {
      margin-top: 0;
      text-align: center;
      width: 100%;
    }
  }
}

.header__bottom-controls_stock-link {
  padding: 7px 15px;
  color: white;
  background-color: #ff2d23;
  border-radius: 4px;
  font-weight: 700;
  cursor: pointer;
}

.body-map .header__bottom {
  display: none;
}

.header-redesign {
  margin-left: 30px;
  .header__top-menu-link {
    color: white;
  }
}
