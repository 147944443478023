@import "../../../styles/variables";
@import "../../../styles/mixins";
@import "../constructorVariables";

.mobile-modal {
  $color-1: $c-green;
  $color-2: $c-black-1;
  $color-3: $c-green_dark;

  &_color-blue {
    $color-1: $c-blue;
    $color-2: $c-black-2;
    $color-3: $c-blue_dark;
    @import "../themes.scss";
  }

  &_color-yellow {
    $color-1: $c-yellow;
    $color-2: $c-black-3;
    $color-3: $c-yellow_dark;
    @import "../themes.scss";
  }

  &_color-red {
    $color-1: $c-red;
    $color-2: $c-black-4;
    $color-3: $c-red_dark;
    @import "../themes.scss";
  }

  &_color-gray {
    $color-1: $c-gray;
    $color-2: $c-black-5;
    $color-3: $c-gray_dark;
    @import "../themes.scss";
  }

  &_color-asphalt {
    $color-1: $c-asphalt;
    $color-2: $c-black-6;
    $color-3: $c-asphalt_dark;
    @import "../themes.scss";
  }

  &_color-purple {
    $color-1: $c-purple;
    $color-2: $c-black-7;
    $color-3: $c-purple_dark;
    @import "../themes.scss";
  }

  &_color-burgundy {
    $color-1: $c-burgundy;
    $color-2: $c-black-8;
    $color-3: $c-burgundy_dark;
    @import "../themes.scss";
  }

  &_color-nightsky {
    $color-1: $c-nightsky;
    $color-2: $c-black-9;
    $color-3: $c-nightsky_dark;
    @import "../themes.scss";
  }
  &_color-turquoise {
    $color-1: $c-turquoise;
    $color-2: $c-black-10;
    $color-3: $c-turquoise_dark;
    @import "../themes.scss";
  }

  @import "../common.scss";
}

// modal styles from panpartner

.mobile-modal {
  background: $color-white;
  bottom: 0;
  left: 0;
  overflow: scroll;
  right: 0;
  position: fixed;
  top: 0;
  z-index: 100;

  .icon.icon_chevron {
    transform: rotate(-90deg);
  }

  &.with-padding {
    padding-top: 50px;
  }
}

.mobile-modal.for-map {
  @include break-max($break-xs) {
    & .modal__inner {
      padding: 0;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;

      & .mobile-modal__submit {
        bottom: 40px;
        padding: 0 10px;
        position: absolute;
      }

      & .button__mobile-modal {
        background: $color-border;
        color: $color-brand-2;
      }
    }

    & #dist-map-container {
      max-height: 90vh;
    }

    & .modal__title {
      margin-bottom: 0;
    }
  }
}

.mobile-modal__title {
  align-items: center;
  border-bottom: 1px solid $color-brand-opaque-20;
  min-height: 50px;
  display: flex;
  justify-content: center;
  position: relative;
  @include break-max($breakpoint-xs) {
    padding: 15px;
    text-align: center;
  }
  & > svg.icon.icon_arrow_up {
    left: 0;
    position: absolute;
    transform: rotate(-90deg);
    height: 30px;
    width: 34px;
    padding: 9px;
  }

  &.is-fixed {
    background: $color-white;
    left: 0;
    right: 0;
    position: fixed;
    top: 0;
    z-index: 1;
  }
}

.mobile-modal__content {
  padding: 23px 10px 115px;
}

.mobile-modal__submit {
  background: linear-gradient(rgba($color-white, 0), rgba($color-white, 0.5) 10%, $color-white 70%);
  align-items: flex-end;
  bottom: 0;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  left: 10px;
  padding: 30px 0 20px;
  position: fixed;
  right: 0;
  width: calc(100% - 20px);
  z-index: 10;

  & .button:first-child {
    margin-right: 14px;
  }
}

.mobile-modal__show-all {
  background: linear-gradient(rgba($color-white, 0.8), $color-white);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 12px 0 15px;
  position: fixed;
  right: 0;
  z-index: 10;

  .button {
    font-size: 15px;
    width: calc(100% - 20px);
  }
}
