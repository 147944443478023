@import "../../styles/variables";
@import "../../styles/mixins";

.assignations__slider-item img {
  height: 520px;
  max-height :520px;
  object-fit: contain;
  margin: 0 auto;
}


.assignations-pannels {
  display: flex;
  margin: 0 auto 25px;

  @include break-max($break-xs) {
    flex-direction: column;
    width: calc(100% - 20px);
  }

  .info {
    margin-left: 15px;
    background: #6E78DA;
    color: $color-brand-1
  }

  .info .icon {
    fill: $color-white;
  }
}

.assignations-pannels__item {
  display: flex;
  align-items: center;
  width: 345px;
  height: 55px;
  padding-left: 35px;
  background: $blue;
  color: $color-white;
  border-radius: 5px;
  &:first-child {
    margin-right: 30px;

    @include break-max($break-xs) {
      margin-right: 0;
    }
  }

  img {
    margin-right: 30px;
  }

  @include break-max($break-xs) {
    margin: 0 0 20px;
    width: 100%;
  }
}

.assignation-photos {
  @include break-max($breakpoint-xs) {
    padding-bottom: 40px;
  }
  .complex__slider-controls li {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba($color-brand-3, 0.2);
    z-index: 2;
    transition: all 0.3s;
    .icon {
      transition: all 0.3s;
    }
  }
  .complex__slider-controls li:hover {
    background: #f6f7f9;
  }
  .complex__slider-controls li:hover .icon {
    stroke: $color-brand-3;

  }
  .complex__slider-magnifier {
    background: $color-white;
    box-shadow: 0px 4px 20px rgba($color-brand-3, 0.2);
    right: 40px;
    bottom: 20px;
  }
  .complex__images-navs {
    display: flex;
    justify-content: center;
    margin-bottom: 14px;

    @include break-max($breakpoint-xs) {
      overflow-x: scroll;
      border-bottom: 1px solid rgba($color-brand-3, 0.2);
    }
  }
  .complex__images-nav {
    padding: 14px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 17px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
    background: rgba($color-brand-3, 0.1);
    margin-right: 20px;
    &:last-of-type {
      margin-right: 0;
    }

    svg {
      stroke: $color-brand-3;
    }

    @include break-max($breakpoint-xs) {
      margin-right: 0;
      font-weight: bold;
      border-radius: 0;
      border-bottom: 3px solid transparent;
      flex-shrink: 0;
      background: none;
    }

    &.active {
      background: rgba($color-brand-3, 0.2);
      @include break-max($breakpoint-xs) {
        background: none;
        border-bottom: 3px solid $color-red;
      }
    }

    &:hover, &:focus {
      background-color: rgba(143, 149, 172, 0.2);
      @include break-max($breakpoint-xs) {
        background: none;
      }
    }
  }

}

.assignation-photos .complex__slider-item img {
  position: relative;
  width: 100%;

  border-radius: 5px;
  height: 490px;
  padding: 0 40px;
  object-fit: contain;
  @include break-max($breakpoint-md) {
    height: 430px;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
    height: 200px;
    margin-top: 0;
    padding: 0;
    position: static;
  }
}

.flat__col_width_100 .assignation-photos .complex__slider-item img {
  height: 650px;
  padding: 0;
  @include break-max($breakpoint-md) {
    height: 530px;
  }
  @include break-max($breakpoint-xs) {
    height: 200px;
  }
}

.assignation-photos .tns-inner {
  border: 1px solid $color-brand-opaque-30;
}


.assignation-photos .complex__slider-blur-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  filter: blur(20px);
  z-index: -1;
  @include break-max($breakpoint-sm) {
    display: none;
  }
}

.assignation-photos .tns-horizontal.tns-subpixel>.tns-item {
  position: relative;
}

.flat__col_width_100 .assignation-photos .complex__images-navs {
  margin-bottom: 38px;
  @include break-max($breakpoint-sm) {
    margin-bottom: 0;
  }
}

.assignation-features {
  @include break-max($breakpoint-sm) {
    margin-top: 20px;
  }
  .complex__about-feature {
    @include break-max($breakpoint-sm) {
      font-size: 16px;
      line-height: 18px;
    }
  }
  @include break-max($breakpoint-xs) {
    margin-top: 20px;
  }
}

.assignation-info .flat__col-images {
  @include break-max($breakpoint-xs) {
    idth: calc(100% / 12 * 12 - 16px);
  }
}




.assigments-new {

  .h3.sf__h1.with-tg-link {
    margin-bottom: 30px;
  }

  &__header-right {
    display: flex;
    align-items: center;
    gap: 30px;

    .assignations-pannels {
      margin: 0;

      .info__popup.info__popup_lg p {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
      }

      .info {
        //background: #ff2d23;
        border: 1px solid #ff2d23;
        background-color: transparent;
        width: 24px;
        height: 24px;
        order: -1;
        margin-left: 0;
        margin-right: 10px;
        cursor: pointer;

         .icon {
          fill: #ff2d23;
        }
      }
    }

    .assignations-pannels__item {
      width: auto;
      height: auto;
      color: #2c2e3e;
      padding-left: 0;
      background-color: transparent;

      img {
        display: none;
      }

      p.h5 {
        font-weight: normal;
        text-transform: none;
        font-size: 14px;
      }
    }

    .assignations-attention__btns {
      position: relative;
      top: auto;
      right: auto;
      gap: 30px;
      flex-direction: row;

      .assignations-attention__icon {
        animation: none;
        width: 24px;
        margin-right: 10px;
        height: 24px;
        border: 1px solid #2c2e3e;
        background-color: transparent;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMTJIMTYiIHN0cm9rZT0iIzJDMkUzRSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGQ9Ik0xMiA3Ljk5OTk4TDEyIDE2IiBzdHJva2U9IiMyQzJFM0UiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K");
        background-position: center;

        svg {
          display: none
        }
      }
      .assignations-attention__btn {
        margin-bottom: 0;

        .button {
          font-weight: normal;
          color: #2c2e3e;
          transition-duration: .3s;

          &:hover {
            color: #ff2d23;
          }
        }
      }

      .color-brand-3 {
        color: #2c2e3e;
        position: relative;
        transition-duration: .3s;

        &:hover {
          color: #ff2d23;
        }

        &:before {
          content: '';
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuNjQyODYgN0gxMy45Mjg2QzE0LjYxMDYgNyAxNS4yNjQ2IDcuMjcwOTIgMTUuNzQ2OCA3Ljc1MzE1QzE2LjIyOTEgOC4yMzUzOSAxNi41IDguODg5NDQgMTYuNSA5LjU3MTQzVjE3LjI4NTdDMTYuNSAxNy40NTYyIDE2LjQzMjMgMTcuNjE5NyAxNi4zMTE3IDE3Ljc0MDNDMTYuMTkxMiAxNy44NjA4IDE2LjAyNzYgMTcuOTI4NiAxNS44NTcxIDE3LjkyODZINS41NzE0M0M0Ljg4OTQ0IDE3LjkyODYgNC4yMzUzOSAxNy42NTc3IDMuNzUzMTUgMTcuMTc1NEMzLjI3MDkyIDE2LjY5MzIgMyAxNi4wMzkxIDMgMTUuMzU3MVY3LjY0Mjg2QzMgNy40NzIzNiAzLjA2NzczIDcuMzA4ODUgMy4xODgyOSA3LjE4ODI5QzMuMzA4ODUgNy4wNjc3MyAzLjQ3MjM2IDcgMy42NDI4NiA3VjdaIiBzdHJva2U9IiMyQzJFM0UiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTYuNSAxMS4xNzg2TDIxIDguNjA3MThWMTYuMzIxNUwxNi41IDEzLjc1IiBzdHJva2U9IiMyQzJFM0UiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;
          margin-right: 10px;
        }
      }
    }
    .wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }


  .index-search__filter {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .section__grid {
    margin-left: 0;
    margin-right: 0;
  }

  .index-search__filter-body {
    .form__item {
      //flex-basis: calc(100% / 7 - 20px);
      //margin-left: 10px;
      //margin-right: 10px;

      margin-left: 0px;
      margin-right: 0px;
      margin: 0;
    }
  }

  .index-search__filter-top {
    background-color: #F6F7F8;
    border-radius: 20px;
    padding: 16px 20px;
    margin-bottom: 20px;

    .form__row {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 20px;
      margin-left: 0;
      margin-right: 0;


    }
  }

  .index-search__filter-main {
    background-color: transparent;
    padding: 16px 20px;
    padding-top: 10px;

    .form__row {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 20px;
      margin-left: 0;
      margin-right: 0;
    }
  }


  .index-search__filter-body {
    background-color: transparent;
  }
  .index-search__show-booked {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1279px) {
  .assigments-new .sf__filter-item {
    width: auto;
  }
  .assigments-new .index-search__filter-main .form__row {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 1050px) {
  .assigments-new .index-search__filter-top .form__row {
    grid-template-columns: repeat(4, 1fr);
  }
}

.redisign-slider-assignments {

  .slider {
    border: none;
  }
  .assignations-top {

    .flats__card-image {
      min-width: 180px;
      margin-right: 0;
      img {
        //object-fit: cover;
        object-fit: contain;
        z-index: 2;
        max-height: 100%;
        height: 190px;
        border-radius: 20px 0 0 20px;
      }
    }

    .flats__card-info {
      padding: 0;
      background-color: $gray-light;
      border-radius: 20px;
      padding-left: 20px;
      padding-right: 30px;
      box-sizing: border-box;
      margin-left: -20px;
      z-index: 1;
      width: calc(100% - 160px);

      @include break-max($breakpoint-sm) {
        align-self: stretch;
      }
    }
  }

  .slider__item {
    border-right: none;
  }

  .top__title {
    font-size: 14px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      //display: inline-block;
    }
  }
  .card__company {
    border-bottom: 1px solid $gray-dark;
    padding-bottom: 10px;
  }
  .card__address {
    margin-top: 8px;
  }
  .flats__card-price {
    font-size: 14px;
    margin-bottom: 0;
  }
  .assignations-top.suburbans-top .flats__card-price.suburbans {
    padding-bottom: 0;
    padding-top: 8px;
  }

  .assignations-top__title {
    text-align: left;
    margin-bottom: 16px;

    h2.h2 {
      @include h3();
    }
  }

  .slider__nav {
    position: absolute;
    right: auto;
    left: auto;
  }
  .slider__nav-item.prev {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border: 1px solid rgba(143, 149, 172, 0.2);
    border-radius: 50%;
    cursor: pointer;
    background: #fff;
    transition: all 0.3s;

    svg {
      margin-right: 0;
    }
  }

  .slider__nav-item.next {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border: 1px solid rgba(143, 149, 172, 0.2);
    border-radius: 50%;
    cursor: pointer;
    background: #fff;
    transition: all 0.3s;

    svg {
      margin-left: 0;
    }
  }
  .flats__card .card__row {
    margin-top: 10px;
  }
  .card__pill.card__pill_comission {
    background-color: #DFF5E0;
    color: #2CBA2E;
    border-radius: 50px;
    padding: 3px 10px;
    max-width: max-content;
  }

  .card__company {
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .card__dates {
    margin-top: 6px;
    color: #8f95ac;
  }
  .card__title {
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .flats__card_compact {
    padding: 0;
  }

  .slider__item:hover {
    img {
      transform: none!important;
    }
  }

}


.assignations-flats.redesign {
  .complex__view-item .view__btn {
    width: auto;
    height: 50px;
    border: none;
    font-weight: 700;

    &:hover {
      border: none;
      box-shadow: none;
    }
  }

  .assignations-flats__btns .complex__view-item:not(:last-of-type) {
    margin-right: 40px;
  }

  .view__icon {
    width: 20px;
    height: 20px;
  }
  .complex__view-item.active .view__btn {
    background-color: transparent;
    color: #EF372E;

    .view__icon {
      fill: #EF372E;
      stroke: #EF372E;
    }
  }
  .flats__row {
    border-radius: 20px;
  }
}
