@import '../../../styles/variables';
@import '../../../styles/mixins';

.new-alert {
  margin-bottom: 20px;
  padding: 16px 30px 16px 33px;
  border-radius: 20px;
  display: flex;
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__icon {
    margin-right: 13px;
  }

  &__title {
    margin: 0;
    padding: 0;
    @include h6();
    color: $color-brand-2;
    margin-bottom: 6px;
  }

  &__link {
    transition-duration: .3s;
    display: inline-block;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  &__text {
    color: $color-brand-2;
  }

}
.new-alert_bordered-red {
  border: 1px solid #FF2D23;
}
