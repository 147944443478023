.textarea {
  $c: &;


  &_type {

    &_form {

      & #{$c}__control {
        background-color: $color-white;
        border-radius: 4px;
        resize: vertical;
        box-shadow: inset 0 0 0 1px $color-brand-opaque-20;
      }

      & #{$c}__label {
        display: block;
        color: $color-brand-3;
        line-height: 25px;
        margin-bottom: 4px;
      }

    }
  }

  &_width {

    &_available {

      width: 100%;
    }
  }

  &__control {
    border: 0;
    background: none;
    width: 100%;
    outline: none;
    padding: 16px;
    font-size: 14px;
    line-height: 17px;
    color: $color-brand-2;
    font-family: "Formular", sans-serif;
  }
}
