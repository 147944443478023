.add-company {

  &__title {
    margin-top: 30px;
  }

  &__dashboard {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 100px;
  }

  &__dashboard-left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h4 {
      margin-bottom: 20px;
    }
  }

  &__text {
    font-size: 17px;
    margin-bottom: 20px;
    margin-top: -10px;
  }

  &__message {
    width: 560px;
    box-sizing: border-box;
    padding: 40px;
    border-radius: 20px;
    background-color: #E3F0F7;
  }

  &__message-title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
  }

  &__request-name {
    font-size: 16px;
    text-align: center;
    margin-bottom: 15px;
  }
}
