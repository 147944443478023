.mini-filter {
  margin-bottom: 25px;

  & .form {
    padding: 20px;
    background: $color-brand-opaque-8;
    border-radius: 5px;

    @include break-max($breakpoint-sm) {
      margin-left: -20px;
      margin-right: -20px;
    }

    
    @include break-max($breakpoint-xs) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }

  &__col {
    flex: 1 1 150px;

    & + & {
      margin-left: 12px;
    }

    @include break-max($breakpoint-sm) {
      flex-basis: 130px;
    }

    & .form__item {
      margin-bottom: 0;
      margin-left: 0;
      @include break-max($breakpoint-xs) {
        width: 100%;
      }
    }

    &_2x {
      flex: 2 1 300px;
    }

    &_clear {
      flex-grow: 0;
    }

    &_search {
      flex: 1 1 250px;

      & .input_type_search .input__control {
        height: 50px;
        border-radius: 4px;
        background-color: $color-white;
        color: $color-brand-1;
      }
    }
  }

  .form__drop-content {
    min-width: inherit;
  }

  &__button {
    margin-left: 30px;

    @include break-max($breakpoint-md) {
      margin-left: 20px;
    }
  }

  &__mobile {
    display: none;
    justify-content: center;
    margin-top: 12px;

    @include break-max($breakpoint-sm) {
      display: flex;
    }
  }

  .index-search__filter {
    &-inc {
      padding-left: 0;
      border: none;

      &:after {
        display: none;
      }
    }
  }

  .is-hidden-sm {
    @include break-max($breakpoint-sm) {
      display: none;
    }
  }
  .is-hidden-desktop {
    @include break-min($breakpoint-sm) {
      display: none;
    }
  }
}

.chart__filter + .mini-filter {
  margin-bottom: 10px;
}
