@import "../../styles/variables";
@import "../../styles/mixins";

:root {
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
}


.animate__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animate__animated.animate__delay-1s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-delay: var(--animate-delay);
    animation-delay: var(--animate-delay);
}
.animate__animated.animate__delay-2s {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-delay: calc(var(--animate-delay) * 2);
    animation-delay: calc(var(--animate-delay) * 2);
}
.animate__animated.animate__delay-3s {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
    -webkit-animation-delay: calc(var(--animate-delay) * 3);
    animation-delay: calc(var(--animate-delay) * 3);
}
.animate__animated.animate__delay-4s {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-delay: calc(var(--animate-delay) * 4);
    animation-delay: calc(var(--animate-delay) * 4);
}
.animate__animated.animate__delay-5s {
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
    -webkit-animation-delay: calc(var(--animate-delay) * 5);
    animation-delay: calc(var(--animate-delay) * 5);
}
.animate__animated.animate__faster {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-duration: calc(var(--animate-duration) / 2);
    animation-duration: calc(var(--animate-duration) / 2);
}
.animate__animated.animate__fast {
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-duration: calc(var(--animate-duration) * 0.8);
    animation-duration: calc(var(--animate-duration) * 0.8);
}
.animate__animated.animate__slow {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-duration: calc(var(--animate-duration) * 2);
    animation-duration: calc(var(--animate-duration) * 2);
}
.animate__animated.animate__slower {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-duration: calc(var(--animate-duration) * 3);
    animation-duration: calc(var(--animate-duration) * 3);
}
@media (prefers-reduced-motion: reduce), print {
    .animate__animated {
        -webkit-animation-duration: 1ms !important;
        animation-duration: 1ms !important;
        -webkit-transition-duration: 1ms !important;
        transition-duration: 1ms !important;
        -webkit-animation-iteration-count: 1 !important;
        animation-iteration-count: 1 !important;
    }
    .animate__animated[class*="Out"] {
        opacity: 0;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.animate__fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}
@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
.animate__fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}
@-webkit-keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
.animate__fadeInDownBig {
    -webkit-animation-name: fadeInDownBig;
    animation-name: fadeInDownBig;
}
@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
.animate__fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
.animate__fadeInLeftBig {
    -webkit-animation-name: fadeInLeftBig;
    animation-name: fadeInLeftBig;
}
@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
.animate__fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}
@-webkit-keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
.animate__fadeInRightBig {
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig;
}
@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
.animate__fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}
@-webkit-keyframes fadeInUpBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@keyframes fadeInUpBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
.animate__fadeInUpBig {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig;
}

.preview {
  background-color: $color-brand-1;
  color: $color-white;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.preview .h1 {
  @include break-max($breakpoint-xs) {
    font-size: 24px;
    line-height: 26px;
  }
}

.preview .button_view_default {
  @include break-max($breakpoint-xs) {
    display: flex;
    max-width: 320px;
    height: 50px;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}

.preview__wrapper {
  position: relative;
}


.preview__submit {
  display: flex;
  justify-content: center;
}

.preview__anchor {
  color: $color-red;
  cursor: pointer;
}

.preview__countdown p {
  display: flex;
  align-items: center;
  color: $color-brand-3;
}

.preview__counter {
  margin-left: 15px;
  padding: 5px 21px;
  background: $color-brand-opaque-10;
  border-radius: 100px;
  font-size: 24px;
  line-height: 34px;
}

.preview__wrapper--bg {

}

.preview__wrapper.width-half {
  width: 50%;
}

.preview__container {
  position: relative;
  width: 1260px;
  max-width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  @include break-max($breakpoint-md) {
    width: 1038px;
  }

  @include break-max($breakpoint-sm) {
    width: 786px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    padding: 0 10px;
  }

}

.preview__halfbox {
  width: 50%;
  @include break-max($breakpoint-sm) {
    width: 45%;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.preview__auth {
  position: relative;
  flex-grow: 1;
  margin-top: 150px;
  @include break-max($breakpoint-xs) {
    margin-top: 75px;
  }
}

.preview__auth .wrapper {
  @include break-max($breakpoint-xs) {
    padding: 0 10px;
  }
}

.auth__form.preview__auth-form {
  max-width: 720px;
  padding: 60px 124px;
  border-radius: 5px;
  background-color: $color-brand-2;

  z-index: 10;

  @include break-max($breakpoint-xs) {
    padding: 20px;
  }

  .button {
    width: 100%;
  }

  .auth__sign-in .button {
    margin-top: 15px;
  }
}

.preview__auth-title {

  &_nomob {
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }
}

.preview__auth-dots {
  position: absolute;
  right: 160px;
  bottom: -30px;

  @include break-max($breakpoint-sm) {
    right: 20px;
  }
}

.preview__auth .auth__sign-in {
  padding-top: 0;
}

.preview__header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  &_bg {
    background-color: $color-brand-1;
  }
}

.preview__header .wrapper {
  @include break-max($breakpoint-xs) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.preview__header.fixed {
  z-index: 100;
  background-color: $color-brand-1;
}

.preview__header-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 85px;

  @include break-max($breakpoint-sm) {
    height: 60px;
  }
}

.preview__header .header__logo {
  width: 81px;
  height: 44px;
  border-radius: 5px;
  overflow: hidden;
  @include break-max($breakpoint-sm) {
    width: 64px;
    height: 100%;
  }
  @include break-max($breakpoint-xs) {
    width: 52px;
  }
}

.preview__header-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: auto;

}

.preview__header-sign-up,
.preview__header-sign-in {
  font-size: 12px;
  line-height: 21px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  color: $color-white;
  cursor: pointer;
  @include break-max($breakpoint-xs) {
    padding: 10px 22px;
  }
}

.preview__header-sign-up a,
.preview__header-sign-in a {
  color: inherit;
}

.preview__header-sign-up {
  margin-right: 40px;
  &:hover {
    color: $color-brand-3;
  }

  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.preview__header-sign-in {
  background-color: $color-brand-opaque-10;
  border-radius: 5px;
  padding: 15px 30px;
  transition: all 0.3s;

  &:hover {
    background-color: $color-brand-3;
  }
}

.preview__header-phone {
  margin-left: 46px;
  @include break-max($breakpoint-xs) {
    margin-left: auto;
  }
}

.preview__header-phone a {
  color: $color-white;
}

.preview footer {
  margin-top: auto;
}

.preview__footer {
  display: flex;
  padding-top: 45px;
  border-top: 1px solid $color-brand-3;
  margin-top: 114px;
  padding-bottom: 50px;

  @include break-max($breakpoint-md) {
    padding-top: 35px;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 45px;
    margin-top: 0;
    border-top: none;
    padding-left: 15px;
    padding-right: 15px;
  }

  @include break-max(365px) {
    flex-direction: column;
  }

}

.preview__footer-logo {
  width: 81px;
  min-width: 81px;
  height: 44px;
  border-radius: 5px;
  overflow: hidden;
  @include break-max($breakpoint-xs) {
    width: 52px;
    min-width: 52px;
    height: 28px;
  }

  @include break-max(365px) {
    margin-left: 20px;
    margin-bottom: 20px;
  }
}

.preview__footer-logo .icon_logo {
  fill: $color-white;
}

.preview__footer-content {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  @include break-max(967px) {
    flex-direction: column;
  }
}

.preview__footer-col {
  margin: 0 50px;
  @include break-max($breakpoint-md) {
    &:last-child {
      margin-right: 0;
    }
  }
  @include break-max($breakpoint-sm) {
    margin: 0 20px;
  }

  @include break-max(967px) {
    margin-right: 0;
    margin-left: 20px;
    margin-bottom: 25px;
  }
}

.preview__footer-top {
  margin-bottom: 25px;
  @include break-max(967px) {
    width: 80%;
    margin-bottom: 10px;
  }
}

.preview__footer-address {
  width: 260px;
  max-width: 100%;
}

.preview__footer-policy {
  color: $color-brand-3;
  cursor: pointer;

  &:hover {
    color: $color-white;
  }

}

.preview__footer-phone {
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: $color-white;
  @include break-max($breakpoint-sm) {
    font-size: 18px;
    line-height: 22px;
  }
}

.preview__main {
  position: relative;
  display: flex;
  min-height: 100vh;
  margin-bottom: 70px;

  background-image: url('../../assets/images/preview/bg-mob.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 85%;
  background-position-y: -15px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 100%;
    height: 150px;
    background: linear-gradient(
      180deg,
      rgba(30, 32, 48, 0) -7.95%,
      #1e2030 84.11%
    );
  }

  @include break-max($breakpoint-md) {
    background-position-x: 100%;
    background-position-y: 0;
    height: auto;
    min-height: 715px;
    &::after {
      height: 90px;
    }
  }

  @include break-max($breakpoint-sm) {
    min-height: 700px;
    margin-bottom: 110px;
    background-image: url('../../assets/images/preview/bg-mob.png');
    background-position-x: center;
    background-position-y: top;
    background-size: 760px;
    &::after {
      display: none;
    }
  }

  @include break-max($breakpoint-xs) {
    min-height: 570px;
    margin-bottom: 0;

    background: none;
  }

}


.preview__main-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}


.preview__main-bg img {
  height: 100vh;
  object-fit: cover;
}

.preview__main-content {
  position: relative;
  width: 490px;
  max-width: 100%;

  margin-top: 280px;
  z-index: 1;
  @include break-max($breakpoint-md) {
    margin-top: 200px;
  }

  @include break-max($breakpoint-sm) {
    width: 100%;
    margin-top: 520px;
    text-align: center;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 80px;
    margin-bottom: 80px;

    display: flex;
    flex-direction: column;

    &::after {
      content: '';
      display: block;
      height: 434px;
      background-image: url('../../assets/images/preview/bg-mob.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.preview__main-title {
  margin-bottom: 43px;
  @include break-max($breakpoint-xs) {
    margin-bottom: 18px;
  }
}

.preview__main-title h1 {

  @include break-max($breakpoint-sm) {
    width: 80%;
    margin: 0 auto;

    font-size: 28px;
    line-height: 33px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 24px;
    line-height: 26px;
  }

}

.preview__main-text {
  margin-bottom: 77px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;

  @include break-max($breakpoint-md) {
    margin-bottom: 57px;
  }
  @include break-max($breakpoint-sm) {

  }
  @include break-max($breakpoint-xs) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;

    br {
      display: none;
    }
  }
}

.preview__main-btn {
  @include break-max($breakpoint-xs) {
    margin-top: -100px;
    order: 1;
  }
}

.preview__partner {
  position: relative;
}

.preview__bg-svg {
  position: absolute;
  top: 5px;
  right: -95px;

  &_lines {
    top: -10px;
    right: -60px;
  }

  &_dots-rotated {
    top: 180px;
    right: -40px;
    transform: rotate(45deg);
  }


  @include break-max($breakpoint-sm) {
    right: 20px;
    top: 40px;

    svg {
      width: 170px;
      height: 170px;
    }

    &_dots-rotated {
      top: 80px;
      right: 50px;
    }
  }

  @include break-max($breakpoint-xs) {
    right: 0px;
    top: 10px;

    svg {
      width: 130px;
      height: 130px;
    }

    &_lines svg {
      width: 160px;
      height: 160px;
    }

    &_dots-rotated {
      top: 30px;
      right: 50px;
    }

  }
}

.preview__partner-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 70px;
  @include break-max($breakpoint-md) {
    padding-top: 95px;
  }

  @include break-max($breakpoint-sm) {
    padding-top: 52px;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 40px;
  }
}

.preview__partner-item {
  position: relative;
  width: 460px;
  max-width: 100%;
  padding-left: 175px;

  @include break-max($breakpoint-md) {
    width: 390px;
    padding-left: 135px;
  }

  @include break-max($breakpoint-sm) {
    width: 290px;
    padding-left: 95px;
  }

  @include break-max($breakpoint-xs) {
    width: 50%;
    padding-left: 55px;
  }

  @include break-max(365px) {
    padding-left: 35px;
  }


  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 135px;
    height: 1px;
    background-color: $color-red;
    top: 50px;
    left: 0;

    @include break-max($breakpoint-md) {
      top: 35px;
      width: 105px;
    }

    @include break-max($breakpoint-sm) {
      top: 27px;
      width: 65px;
    }

    @include break-max($breakpoint-xs) {
      width: 40px;
      top: 15px;
    }

    @include break-max($breakpoint-xs) {
      width: 25px;
    }
  }

  &:nth-child(n+3) {
    margin-top: 105px;
    @include break-max($breakpoint-md) {
      margin-top: 75px;
    }
    @include break-max($breakpoint-sm) {
      margin-top: 60px;
    }
  }

}

.preview__partner-item-value {
  font-weight: bold;
  font-size: 60px;
  line-height: 100px;
  @include break-max($breakpoint-md) {
    font-size: 50px;
    line-height: 1.4;
  }
  @include break-max($breakpoint-sm) {
    font-size: 40px;
  }
  @include break-max($breakpoint-xs) {
    font-size: 25px;
    line-height: 30px;
  }
}

.preview__partner-item-desc {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  @include break-max($breakpoint-md) {
    font-size: 18px;
    line-height: 26px;
  }
  @include break-max($breakpoint-sm) {

  }
  @include break-max($breakpoint-xs) {
    font-size: 16px;
    line-height: 24px;
  }
}

.preview__benefits {
  margin-top: 180px;
  @include break-max($breakpoint-md) {
    margin-top: 150px;
  }

  @include break-max($breakpoint-sm) {
    margin-top: 120px;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 65px;
  }
}

.preview__benefits-btn {
  @include break-max($breakpoint-xs) {
    text-align: center;
  }
}

.preview__benefits-title {
  margin-bottom: 40px;
  @include break-max($breakpoint-md) {
    margin-bottom: 33px;
  }
  @include break-max($breakpoint-sm) {
    margin-bottom: 20px;
  }
  @include break-max($breakpoint-xs) {

  }
}

.preview__benefits-text {
  color: $color-brand-3;
  font-weight: 300;
  font-size: 25px;
  line-height: 40px;

  @include break-max($breakpoint-md) {
    font-size: 18px;
    line-height: 26px;
  }
  @include break-max($breakpoint-sm) {

  }
  @include break-max($breakpoint-xs) {
    font-size: 16px;
    line-height: 24px;
  }
}

.preview__benefits-list {
  display: flex;
  margin-top: 75px;
  margin-bottom: 48px;

  @include break-max($breakpoint-md) {
    margin-top: 65px;
    justify-content: space-between;
  }

  @include break-max($breakpoint-sm) {
    margin-top: 56px;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    margin-top: 0;
  }
}

.preview__benefits-item {
  width: 320px;
  max-width: 100%;
  &:first-child {
    margin-right: 115px;
  }
  &:last-child {
    width: 345px;
    margin-left: auto;
  }

  &:nth-child(2) {
    @include break-max($breakpoint-sm) {
      width: 200px;
    }
  }

  &:nth-child(n) {
    @include break-max($breakpoint-md) {
      width: 290px;
      margin: 0;
    }
    @include break-max($breakpoint-sm) {
      width: 220px;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
      margin-top: 40px;
    }

  }


}


.preview__benefits-item-value {
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  @include break-max($breakpoint-md) {
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 26px;
  }
  @include break-max($breakpoint-sm) {
    height: 40px;
    font-size: 18px;
    line-height: 22px;
  }
  @include break-max($breakpoint-xs) {
    margin-bottom: 0;
  }
}

.preview__benefits-item-desc {
  line-height: 21px;
  &_90 {
    width: 90%;
    @include break-max($breakpoint-sm) {
      width: 100%;
    }
  }

}

.preview__objects {
  position: relative;
  padding-bottom: 322px;

  @include break-max($breakpoint-md) {
    padding-bottom: 270px;
  }
  @include break-max($breakpoint-sm) {
    padding-bottom: 138px;
  }
  @include break-max($breakpoint-xs) {
    padding-bottom: 90px;
  }
}

.preview__objects-wrap {
  position: relative;

  margin-top: 165px;

  &:first-child {
    margin-top: 185px;
    @include break-max($breakpoint-md) {
      margin-top: 140px;
    }
    @include break-max($breakpoint-sm) {
      margin-top: 90px;
    }

  }

  &:nth-child(3), &:nth-child(5) {
    margin-top: 275px;
    @include break-max($breakpoint-md) {
      margin-top: 225px;
    }
    @include break-max($breakpoint-sm) {
      margin-top: 95px;
    }
    @include break-max($breakpoint-xs) {
      margin-top: 60px;
    }

  }

  &:nth-child(4) {
    @include break-max($breakpoint-sm) {
      margin-top: 90px;
    }

    @include break-max($breakpoint-xs) {
      margin-top: 60px;
    }
  }

  @include break-max($breakpoint-md) {
    margin-top: 140px;
    margin-bottom: 0;
  }

  @include break-max($breakpoint-sm) {
    margin-top: 90px;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 60px;
  }

}

.preview__objects-wrap.margin-x2 {
  margin-top: 285px;
  @include break-max($breakpoint-md) {
    margin-top: 270px;
  }
  @include break-max($breakpoint-sm) {
    margin-top: 125px;
  }
  @include break-max($breakpoint-xs) {
    margin-top: 60px;
  }
}

.preview__objects-bg {
  position: absolute;
  right: -170px;
  top: 10px;
}

.preview__objects-title {
  margin-top: 50px;
  margin-bottom: 64px;
  &_mb57 {
    margin-bottom: 57px;
  }
  @include break-max($breakpoint-md) {
    margin-top: 0;
    margin-bottom: 56px;
  }

  @include break-max($breakpoint-sm) {
    margin-bottom: 40px;
  }

  @include break-max($breakpoint-xs) {
    margin-bottom: 20px;
    padding-right: 20px;
  }

}

.preview__objects-list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.preview__objects-list_row {
  flex-direction: row;
  @include break-max($breakpoint-md) {
    justify-content: space-between;
  }
  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.preview__objects-col {
  width: 50%;
  &:last-child {
    padding-left: 10px;
    padding-right: 55px;
  }

  &_easyway {
    width: 42%;
    margin-top: 10px;
    margin-right: auto;
    &:last-child {
      width: 40%;
      padding-left: 0;
      padding-right: 0;
    }
    @include break-max($breakpoint-sm) {
      width: 50%;
      &:last-child {
        width: 50%;
      }
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
      &:last-child {
        width: 100%;
      }
    }
  }

  @include break-max($breakpoint-md) {
    width: 46%;
    &:last-child {
      padding: 0;
    }
  }

  @include break-max($breakpoint-sm) {
    width: 50%;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.preview__objects-item {
  position: relative;
  flex-basis: 50%;
  margin-bottom: 18px;
  padding-left: 50px;
  padding-right: 40px;
  line-height: 18px;

  &:before {
    content: '\2022';
    position: absolute;
    left: 0;
    color: $color-red;
    font-size: 16px;
  }

  @include break-max($breakpoint-sm) {
    padding-left: 40px;
  }
  @include break-max($breakpoint-xs) {
    padding-left: 20px;
  }
}

.preview__objects-label {
  position: relative;
  left: 33%;
  margin-top: 45px;

  font-size: 14px;
  line-height: 18px;
  color: $color-brand-3-hover;
  @include break-max($breakpoint-xs) {
    left: 0;
    margin-top: 25px;

  }

  &_mob {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    margin-top: 35px;
    left: 0;
    @include break-max($breakpoint-xs) {
      opacity: 1;
      visibility: visible;
    }
  }
}

.preview__objects-mockups {
  margin-top: 107px;
  display: flex;
  align-items: flex-start;
  overflow-x: hidden;

  &_ipad {
    padding-top: 42px;
    min-height: 685px;

    @include break-max($breakpoint-md) {
      min-height: 610px;
    }

    @include break-max($breakpoint-sm) {
      min-height: 550px;
    }

    @include break-max($breakpoint-xs) {
      padding-top: 30px;
      min-height: 385px;
    }

    @include break-max(500px) {
      padding-top: 20px;
      min-height: 245px;

      padding-top: 20px;
    }


  }

  @include break-max($breakpoint-md) {
    margin-top: 55px;
  }
  @include break-max($breakpoint-xs) {
    margin-top: 0;

    &_ipad {
      margin-top: 20px;
      padding: 0 20px;
    }
  }


  &_mac {
    position: relative;
  }
}

.preview__objects-container {
  display: flex;
  align-items: flex-start;

}

.preview__object {
  position: relative;
}

.preview__object_iphone {
  margin-top: 70px;
  margin-left: 73px;
  @include break-max($breakpoint-md) {
    margin-top: 60px;
    margin-left: 120px;
  }
  @include break-max($breakpoint-sm) {
    margin-top: 65px;
    margin-left: 20px;
  }
  @include break-max($breakpoint-xs) {
    display: none;
  }

}

.preview__object_iphone svg {
  @include break-max($breakpoint-md) {
    width: 255px;
    height: auto;
  }
  @include break-max($breakpoint-sm) {
    width: 210px;
  }

}

.preview__object_mac {
  @include break-max($breakpoint-sm) {
    margin-top: 40px;
    margin-left: 40px;
    width: 465px;
    max-width: 100%;
  }
  @include break-max($breakpoint-xs) {
    width: 320px;

    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.preview__object_mac svg {
  @include break-max($breakpoint-md) {
    width: 590px;
    max-width: 100%;
    height: auto;
  }

  @include break-max($breakpoint-sm) {
    width: 465px;
  }
  @include break-max($breakpoint-xs) {

  }
}

.preview__object_ipad {
  margin: 0 auto;
}

.preview__object_ipad svg {
  @include break-max($breakpoint-md) {
    width: 790px;
    max-width: 100%;
    height: auto;
  }
  @include break-max($breakpoint-sm) {
    width: 645px;
  }

}

.preview__object-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 500px;
  height: 500px;
  background-position: top;

  transition: background-position 4s linear;
  background-repeat: no-repeat;
}

.preview__object_mac .preview__object-box {
  top: 1px;
  left: 1px;
  right: 1px;
  width: auto;
  height: 495px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-image: url('../../assets/images/preview/property.jpg');
  background-size: 100%;
  @include break-max($breakpoint-md) {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    height: 390px;
  }
  @include break-max($breakpoint-sm) {
    height: 305px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }
  @include break-max($breakpoint-xs) {
    width: 318px;
    height: 200px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

.preview__object_iphone .preview__object-box {
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 462px;
  border-radius: 16px;
  background-image: url('../../assets/images/preview/property-mob.jpg');
  background-size: cover;
  @include break-max($breakpoint-md) {
    width: 160px;
    height: 370px;
  }
  @include break-max($breakpoint-sm) {
    top: 6px;
    width: 132px;
    height: 305px;
  }
  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.preview__object_ipad .preview__object-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 50px;
  border-radius: 0;
  width: auto;
  height: auto;
  background-image: url('../../assets/images/preview/property.jpg');
  background-size: cover;
  @include break-max($breakpoint-sm) {
    margin: 30px;
  }
  @include break-max($breakpoint-sm) {
    margin: 20px;
  }
}

.preview__objects-mockups:hover .preview__object-box {
  transition-duration: 8s;
  background-position: bottom;
}

.preview__objects-bg {
  position: absolute;
  top: -114px;
  right: 0;

  @include break-max($breakpoint-md) {
    top: 0;
  }

  &_spiral {
    top: 10px;
    right: 0;
    @include break-max($breakpoint-sm) {
      top: 200px;

      svg {
        width: 304px;
        height: 304px;
      }
    }

    @include break-max($breakpoint-xs) {
      top: 0;

      svg {
        width: 230px;
        height: 230px;
      }
    }
  }

  &_ipad {
    overflow-x: hidden;

    img {
      position: relative;
      right: -120px;
    }

    @include break-max($breakpoint-md) {
      top: -80px;

      img {
        right: -260px;
      }
    }

    @include break-max($breakpoint-sm) {
      top: 20px;

      img {
        width: 505px;
        right: -117px;
      }
    }

    @include break-max($breakpoint-xs) {
      position: static;
      padding: 25px;

      img {
        width: 100%;
        position: static;
      }
    }
  }

  &_filters {
    top: -39px;
    @include break-max($breakpoint-md) {
      top: 0;
    }
  }
}

.preview__objects-bg_filters {
  overflow-x: hidden;
  @include break-max($breakpoint-sm) {
    top: 70px;
  }
  @include break-max($breakpoint-xs) {
    position: static;
    padding: 25px;
  }
}

.preview__objects-bg_filters img {
  position: relative;
  left: 33%;
  width: 970px;
  @include break-max($breakpoint-sm) {
    max-width: 560px;
  }

  @include break-max($breakpoint-xs) {
    max-width: 100%;
    position: static;
    border-radius: 4px;
  }
}

.preview__objects-bg_mobile {
  /* временно */
  display: none;
  @include break-max($breakpoint-xs) {
    display: block;
  }
}

@keyframes moveImage1 {
  to {
    top: 180px;
    left: 0;
  }
}

@keyframes moveImage2 {
  to {
    top: 705px;
    left: 175px;
  }
}

@keyframes moveImage3 {
  to {
    left: 639px;
    top: 100px;
  }
}

@include break-max($breakpoint-md) {
  @keyframes moveImage1 {
    to {
      left: 0;
    }
  }

  @keyframes moveImage2 {
    to {
      top: 530px;
      left: 200px;
    }
  }

  @keyframes moveImage3 {
    to {
      left: 550px;
      top: 55px;
    }
  }
}

@include break-max($breakpoint-sm) {
  @keyframes moveImage1 {
    to {
      left: 0;
    }
  }

  @keyframes moveImage2 {
    to {
      top: 430px;
      left: 145px;
    }
  }

  @keyframes moveImage3 {
    to {
      left: 390px;
      top: 60px;
    }
  }
}


.preview__objects-images {
  position: relative;
  min-height: 1160px;
  @include break-max($breakpoint-md) {
    min-height: 930px;
  }
  @include break-max($breakpoint-sm) {
    min-height: 800px;
  }
  @include break-max($breakpoint-xs) {
    min-height: auto;
  }
}

.preview__objects-image {
  position: absolute;
  box-shadow: 0px 0px 50px rgba(4, 4, 10, 0.4);

  img {
    border-radius: 4px;
  }

  @include break-max($breakpoint-xs) {
    box-shadow: none;


  }

}


.preview__objects-image-1 {
  width: 580px;
  max-width: 100%;
  top: 165px;
  left: 123px;
  z-index: 10;

  @include break-max($breakpoint-md) {
    width: 475px;
    max-width: 100%;
    top: 105px;
    left: 100px;
  }

  @include break-max($breakpoint-sm) {
    width: 360px;
    top: 90px;
    left: 66px;
  }

  @include break-max($breakpoint-xs) {
    position: static;
    width: 100%;
    padding: 25px;
  }


}

.preview__objects-image-2 {
  width: 250px;
  max-width: 100%;
  top: 710px;
  left: 345px;
  z-index: 10;

  @include break-max($breakpoint-md) {
    width: 240px;
    top: 560px;
    left: 285px;
    img {
      width: 200px;
    }
  }

  @include break-max($breakpoint-sm) {
    width: 220px;
    top: 450px;
    left: 230px;
    img {
      width: 155px;
    }
  }


  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.preview__objects-image-3 {
  top: 115px;
  left: 515px;
  max-width: 581px;

  @include break-max($breakpoint-md) {
    top: 65px;
    left: 420px;
    width: 478px;
    max-width: 100%;
  }

  @include break-max($breakpoint-md) {
    top: 65px;
    left: 315px;
    width: 356px;
  }

  @include break-max($breakpoint-xs) {
    position: static;
    width: 100%;
    padding: 25px;
  }

}



.preview__objects-image {
  will-change: left, top;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  box-shadow: none;
  &.animate__ {
    .preview__objects-label_mob {
      opacity: 1;
      visibility: visible;
    }
  }

  &.moved {

    @include break-max($breakpoint-xs) {
      animation: none;
    }
  }

  &-1.moved {
    animation-name: moveImage1;
  }
  &-2.moved {
    animation-name: moveImage2;
    animation-delay: 0;
  }
  &-3.moved {
    animation-name: moveImage3;
  }
}

.preview__banner {
  position: relative;
  display: flex;
  width: 100%;
  background-color: $color-red;
  padding-top: 120px;
  padding-bottom: 120px;

  @include break-max($breakpoint-md) {
    padding-top: 70px;
    padding-bottom: 65px;
  }

  @include break-max($breakpoint-sm) {
    padding-top: 70px;
    padding-bottom: 45px;
    padding-left: 64px;
    padding-right: 100px;
  }

  @include break-max($breakpoint-xs) {
    padding: 75px 30px 155px;
    background-image: url('../../assets/images/preview/mail.svg');
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

}

.preview__banner-body {
  position: relative;
  width: 600px;
  max-width: 100%;

  @include break-max($breakpoint-md) {
    width: 485px;
  }
  @include break-max($breakpoint-sm) {
    width: 375px;
  }
  @include break-max($breakpoint-xs) {
    margin: auto;
  }
}

.preview__banner-title {
  margin-bottom: 30px;

  @include break-max($breakpoint-md) {

  }
  @include break-max($breakpoint-sm) {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 35px;
  }
  @include break-max($breakpoint-xs) {
    font-size: 21px;
    line-height: 24px;
  }
}

.preview__banner-text {
  margin-bottom: 45px;

  font-weight: 300;
  font-size: 24px;
  line-height: 34px;

  @include break-max($breakpoint-md) {
    font-size: 18px;
    line-height: 26px;
  }
  @include break-max($breakpoint-sm) {
    width: 80%;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 24px;
  }
}

.preview__banner-btn {

}

.preview__banner-btn .button {
  background-color: $color-brand-1;
}

.preview__banner-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.preview__banner-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 100%;
}

.preview__banner_thin {
  min-height: 210px;
  margin-top: 85px;
  margin-bottom: 0;
  padding-top: 55px;
  padding-bottom: 50px;
  padding-left: 125px;
  padding-right: 205px;
  border-radius: 5px;
  background-color: $color-red;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../assets/images/preview/convert_thin.svg');

  @include break-max($breakpoint-md) {
    padding-top: 65px;
    padding-bottom: 65px;
    padding-right: 120px;
    padding-left: 100px;
  }

  @include break-max($breakpoint-sm) {
    padding-top: 52px;
    padding-bottom: 52px;
    padding-right: 62px;
    padding-left: 62px;
    background-position: center;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 20px;
    padding-left: 20px;
    background-image: url('../../assets/images/preview/banner_bg-mob.svg');
  }

  .preview__banner-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }

  }

  .preview__banner-title {
    margin-bottom: 25px;
    @include break-max($breakpoint-md) {
      font-size: 20px;
      line-height: 26px;
    }
    @include break-max($breakpoint-xs) {
      margin-bottom: 15px;
      font-size: 18px;
      line-height: 20px;
    }
  }

  .preview__banner-text {
    margin-bottom: 0;
    padding-right: 70px;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;

    @include break-max($breakpoint-sm) {
      padding-right: 0;
    }
    @include break-max($breakpoint-xs) {
      font-size: 15px;
      line-height: 24px;
    }
  }

  .preview__banner-btn {
    margin-top: 30px;
    @include break-max($breakpoint-md) {
      margin-top: 20px;
    }
    @include break-max($breakpoint-sm) {
      margin-top: 40px;
    }
  }

  .preview__banner-bg img {
    object-fit: cover;
  }

}

.preview__importants {
  padding-top: 152px;
  @include break-max($breakpoint-md) {
    padding-top: 140px;
  }
  @include break-max($breakpoint-sm) {
    padding-top: 85px;
  }
  @include break-max($breakpoint-xs) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.preview__importants-title {
  margin-bottom: 105px;
  @include break-max($breakpoint-md) {
    margin-bottom: 65px;
  }
  @include break-max($breakpoint-xs) {
    margin-bottom: 30px;
  }
}

.preview__benefits-list_importants {

  .preview__benefits-item-value {
    min-height: 65px;
    margin-top: 8px;
  }

  @include break-max($breakpoint-md) {
    margin-top: 0;
  }
  @include break-max($breakpoint-xs) {
    margin-bottom: 0;
  }

}

.preview__importants-item-index {
  margin-bottom: 42px;
}

.preview__importants-icon {
  margin-top: auto;
}

.preview__importants-item {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  padding-bottom: 208px;

  background-image: url('../../assets/images/preview/imp-icon-1.svg');
  background-repeat: no-repeat;
  background-position: bottom 30px left 0;

  &:nth-child(1) {
    width: 360px;

    background-position: bottom 5px left -26px;
    @include break-max($breakpoint-md) {
      width: 270px;
    }
    @include break-max($breakpoint-sm) {
      width: 230px;
      background-size: 130px;
      background-position: bottom 10px left -15px;
    }
    @include break-max($breakpoint-xs) {
      width: 310px;
    }
  }

  &:nth-child(2) {
    margin-left: auto;
    background-image: url('../../assets/images/preview/imp-icon-2.svg');

    @include break-max($breakpoint-md) {
      width: 260px;
    }

    @include break-max($breakpoint-sm) {
      width: 170px;
      .preview__benefits-item-value {
        width: 80%;
      }
    }

    @include break-max($breakpoint-xs) {
      width: 280px;
      .preview__benefits-item-value {
        width: 50%;
      }
    }

  }

  &:nth-child(3) {
    width: 290px;
    margin-left: auto;
    background-image: url('../../assets/images/preview/imp-icon-3.svg');

    @include break-max($breakpoint-md) {
      width: 260px;
    }

    @include break-max($breakpoint-sm) {
      width: 230px;
    }
    @include break-max($breakpoint-xs) {
      width: 230px;
    }
  }

  @include break-max($breakpoint-sm) {
    padding-bottom: 155px;
    background-size: 100px;
  }

  &:nth-child(n) {
    @include break-max($breakpoint-xs) {
      margin: 0;
      margin-bottom: 50px;
    }
  }

  &:last-child {
    @include break-max($breakpoint-xs) {
      margin-bottom: 0;
    }
  }


}



.preview__form {
  display: flex;
  padding: 115px 125px 60px;
  border-radius: 5px;
  background-color: $color-brand-2;
  margin-top: 75px;

  @include break-max($breakpoint-md) {
    padding: 100px;
    padding-bottom: 65px;
    margin-top: 45px;
  }
  @include break-max($breakpoint-sm) {
    padding: 65px 60px;
  }
  @include break-max($breakpoint-xs) {
    flex-direction: column;
    padding: 60px 40px;

    .button {
      width: 174px;
      height: 50px;
      margin: 10px auto 0;
    }
    .checkbox__label br {
      display: none;
    }
  }

}


.preview__form-description {
  width: 300px;
  max-width: 100%;
  margin-right: 80px;
  @include break-max($breakpoint-md) {
    width: 600px;
    margin-right: 110px;
  }

  @include break-max($breakpoint-md) {
    width: 260px;
    margin-right: 60px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-right: 0;
  }

}

.preview__form-title {
  margin-bottom: 35px;
  @include break-max($breakpoint-md) {
    margin-bottom: 45px;
    font-size: 30px;
    line-height: 35px;
  }
  @include break-max($breakpoint-xs) {
    margin-bottom: 20px;
    font-size: 21px;
    line-height: 24px;
    text-align: center;
  }

}

.preview__form-text {
  color: $color-brand-3;
  font-size: 18px;
  line-height: 26px;
  @include break-max($breakpoint-xs) {
    color: $color-white;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}

.preview__form-body {
  width: 100%;
  @include break-max($breakpoint-xs) {
    margin-top: 50px;
  }
}


.preview .list-index {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: $color-red;
  margin-bottom: 38px;
  @include break-max($breakpoint-md) {
    margin-bottom: 35px;
  }

  @include break-max($breakpoint-sm) {
    margin-bottom: 39px;
  }

  @include break-max($breakpoint-sm) {
    margin-bottom: 20px;
  }

  &:after {
    content: '';
    display: block;
    margin-left: 20px;
    width: 52px;
    height: 1px;
    background-color: $color-red;
  }
}

.preview .input__control,
.preview .select {
  color: $color-brand-3;
}


.preview .select_type_form .custom-select.is-showing .custom-select__selected,
.preview .custom-select.is-showing .custom-select__options {
  box-shadow: inset 0 0 0 1px $color-brand-opaque-20;
}

.preview .custom-select.is-showing {
  box-shadow: none;
}

.preview .custom-select__selected::after {
  background-image: url('../../assets/images/preview/arrow_down.svg');
}
.preview .sign-in__tabs-row{
  display: flex;
  user-select: none;
}
.preview .sign-in__tab-item {
  padding: 10px 18px;
  cursor: pointer;
  &.active{
    background-color: rgba($color-brand-3, 0.2);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: $color-white
  }
}
.preview .sign-in__tab-content {
  background-color: rgba($color-brand-3, 0.2);
  border-radius: 4px;
  padding: 28px;
  input {
    background-color: $color-white;
    color: $color-brand-1
  }
}
.preview .input__control,
.preview .select_type_form .custom-select__selected,
.preview .custom-select__options,
.preview .custom-select.is-showing .custom-select__selected {
  background-color: $color-brand-2;
}

.preview .newpayment .input__control,
.preview .newpayment .select_type_form .custom-select__selected,
.preview .newpayment .custom-select__options,
.preview .newpayment .custom-select.is-showing .custom-select__selected {
  background-color: white;
}

.preview.preview_newpayment .newpayment {
  color: #1e2030;
  padding-top: 150px;
}


.mac-svg__mockup {
  width: 100%;
  height: 495px;
  overflow: hidden;
  x: 0;
  y: 0;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}


.mac-svg__mockup-body {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px 20px 0 0;
  overflow: scroll;


  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.iphone-svg__mockup {
  position: relative;
  width: 200px;
  height: 462px;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  x: 59px;
  y: 10px;
}


.iphone-svg__mockup-head {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 105px;
  height: 18px;
  border-radius: 0 0 16px 16px;
  background-color: $color-brand-1;
  z-index: 1;
}

.iphone-svg__mockup-body {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding-top: 25px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.ipad-svg__mockup {
  position: relative;
  width: 718px;
  height: 535px;
  x: 40px;
  y: 40px;
}


.ipad-svg__mockup-body {
  width: 100%;
  height: 100%;
  overflow: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.preview .modal-form__row {
  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }
}

.preview .modal-form__item {
  @include break-max($breakpoint-sm) {
    margin-left: 0;
  }
}



.preview .modal-form__item+.modal-form__item {
  @include break-max($breakpoint-sm) {
    margin-top: 18px;
  }
}

.preview__mobile-tabs {
  display: none;
  width: 100%;
  margin-bottom: 13px;
  @include break-max($breakpoint-xs) {
    display: flex;
  }
}

.preview__mobile-tab {
  display: flex;
  justify-content: center;
  min-width: 130px;
  padding: 15px;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  background-color: transparent;
  color: $color-brand-3;
  border: 1px solid $color-brand-3;
  border-radius: 4px;

  &:last-child {
    margin-left: 15px;
    flex-grow: 1;
  }

  &_active {
    background-color: $color-brand-3;
    color: $color-white;
    border: none;
  }

  @include break-max(350px) {
    min-width: auto;
  }
}

.auth__sign-up .sign-up__input input,
.auth__sign-up .sign-up__input .custom-select .custom-select__selected,
.auth__sign-up .sign-up__input .custom-select .custom-select__option {
  background-color: $color-white;
  color: $color-brand-1;
}


.preview__app-download {
  display: flex;
  width: 100%;
  align-items: center;
  height: 50px;
  background-color: $blue;

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: $color-white;
  padding-left: 20px;
  padding-right: 13px;
  border-radius: 4px;
  margin-bottom: 13px;

  span {
    margin-left: 10px;
    margin-right: auto;
  }
}
