.favorites {

  .is-hidden-md {
    @include break-max($breakpoint-md) {
      display: none;
    }
  }

  .is-hidden-sm {
    @include break-max($breakpoint-sm) {
      display: none;
    }
  }

  &__header {
    position: relative;
    display: flex;

    @include break-max($break-xs) {
      flex-direction: column;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    @include break-max($break-xs) {
      margin: 20px 10px 30px;
    }
  }

  &__switcher {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;

    @include break-max($break-xs) {
      height: 50px;
      margin: 0 10px 12px;
      position: static;
      width: calc(100% - 20px);
    }
  }

  &__tab {
    display: none;

    &.active {
      display: block;
    }

    @include break-max($break-xs) {
      .section_view_gray {
        background: none;
      }

      .card__pills {
        width: 90%;
      }

      .flats__card {
        border: 1px solid $color-brand-opaque-10;
        padding: 16px 14px 25px;

        .card__fav {
          background: $color-brand-opaque-10;
          border-radius: 50em;
          height: auto;
          width: 60px;
        }

        .icon_fav {
          height: 16px;
          width: 16px;
          margin-right: 5px;
        }
      }
    }
  }

  &__list {
    padding: 50px 0;

    @include break-max($break-xs) {
      padding-top: 0;
    }
  }

  &__item {
    @include grid-size(4);
    margin-bottom: 40px;

    @include break-max($breakpoint-md) {
      @include grid-size(12, $grid-gutter-md);
      margin-bottom: 30px;
    }

    @include break-max($breakpoint-sm) {
      @include grid-size(12, $grid-gutter-sm);
      margin-bottom: 20px;
    }
  }
}

.switcher__btn {
  @include break-max($break-xs) {
    border: 1px solid $color-brand-opaque-10;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    white-space: nowrap;
    width: calc(50% - 6px);

    &.active {
      border: none;
    }
  }
}
