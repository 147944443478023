@import "../../styles/variables";
@import "../../styles/mixins";

.booking-new {
  @include break-max(950px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .autopan-sec1 {
    background-image: url("../../assets/images/BookingNew/booking-slide1-bg.png");
    height: 670px;

    @include break-max($breakpoint-md) {
      height: 540px;
    }

    @include break-max($breakpoint-sm) {
      height: 440px;
      min-height: 440px;
      background-size: contain;
      background-position: center bottom;
    }
    @include break-max($breakpoint-xsm) {
      height: 370px;
      min-height: 370px;
    }

    @include break-max($breakpoint-xs) {
      background-size: cover;
      background-position: right;
      text-align: center;
      margin-bottom: 5px;
    }
  }

  .autopan-sec1__title {
    margin-top: 150px;
    line-height: 82px;

    @include break-max($breakpoint-md) {
      margin-top: 80px;
      line-height: 64px;
    }

    @include break-max($breakpoint-sm) {
      font-size: 36px;
      line-height: 36px;
    }

    @include break-max($breakpoint-xsm) {
      margin-top: 40px;
    }
  }

  .booking-new__sec1-btn {
    margin-top: 30px;
    width: 220px;
    @include break-max($breakpoint-xs) {
      margin-top: 20px;
    }
  }

  .autopan-sec4.booking-new__slide2 {
    background-image: url("../../assets/images/BookingNew/booking-slide2-bg.png");
    background-position: top;

    @include break-max($breakpoint-md) {
      padding-left: 70px;
      padding-right: 70px;
    }

    @include break-max($breakpoint-xs) {
      padding-left: 30px;
      padding-right: 30px;
    }

    .autopan-sec4__list {
      @include break-max($breakpoint-md) {
        gap: 10px;
      }
    }

    .autopan-sec4__list-logo {
      font-size: 24px;
      font-weight: 700;
    }

    .autopan-sec4__list-logo {
      @include break-max($breakpoint-xs) {
        display: flex;
        width: 45px;
        height: 45px;
        border-radius: 10px;
        font-size: 18px;
      }
    }

    .autopan-sec4__list li {
      @include break-max($breakpoint-xs) {
        padding-top: 50px;
      }
    }

    .autopan-sec4__list-title {
      @include break-max($breakpoint-xs) {
        padding-left: 0;
      }
    }

    .autopan-sec4__list-text {
      @include break-max($breakpoint-xs) {
        padding-left: 0;
      }
    }
  }

  .office-training {
    .autopan-sec6.panschool-sec6 {
      background-image: url("../../assets/images/BookingNew/booking-slide3-bg.png");
      background-size: cover;
    }
  }

  @include break-max(950px) {
    .office-training {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .office-training {
    @include break-min($breakpoint-md) {
      .autopan-sec6.panschool-sec6 {
        display: flex;
      }
      .panschool-sec1__maintitle {
        text-align: left;
        font-size: 48px;
        line-height: 52px;
        z-index: 2;
      }
      .sor__form-box {
        padding-top: 0;
        margin-top: -20px;
      }
    }
    @include break-max($breakpoint-md) {
      .panschool-sec1__maintitle {
        font-size: 48px;
        line-height: 50px;
      }
    }
    @include break-max($breakpoint-sm) {
      .panschool-sec1__maintitle {
        font-size: 30px;
        line-height: 32px;
      }
    }
    @include break-max($breakpoint-xs) {
      .panschool-sec1__maintitle {
        font-size: 22px;
        line-height: 24px;
      }
      .sor__form-box {
        padding-top: 0;
      }
    }
  }

  .booking-new__sec4 {
    border-radius: 60px;
    background-color: #E3F0F7;
    padding: 60px 90px;
    margin-bottom: 10px;
    background-image: url("../../assets/images/BookingNew/booking-slide4-bg.png");
    background-repeat: no-repeat;
    background-position: right;

    .autopan-sec1__title {
      margin-top: 0;
      color: black;
      text-align: center;

      @include break-max($breakpoint-sm) {
        margin-bottom: 10px;
      }
    }

    .booking-new__sec4-text {
      font-size: 32px;
      line-height: 36px;
      text-align: center;
      font-weight: 700;

      @include break-max($breakpoint-md) {
        font-size: 24px;
        line-height: 26px;
      }
    }

    @include break-max($breakpoint-md) {
      padding: 60px 80px;
      border-radius: 40px;
    }

    @include break-max($breakpoint-sm) {
      border-radius: 30px;
    }
    @include break-max($breakpoint-xsm) {
      border-radius: 20px;
    }

    @include break-max($breakpoint-xs) {
      border-radius: 15px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .booking-new__sec4-variants {
      display: flex;
      margin-top: 50px;
      margin-bottom: 70px;
      width: 100%;
      gap: 40px;

      @include break-max($breakpoint-md) {
        gap: 10px;
      }

      @include break-max($breakpoint-sm) {
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 50px;

        margin-left: 40px;
        margin-right: 40px;
        width: calc(100% - 80px);
      }


      @include break-max($breakpoint-xs) {
        margin-left: 0px;
        margin-right: 0px;
        width: 100%;
      }

      .booking-new__sec4-var {
        padding: 50px 70px;
        border-radius: 40px;
        background-color: #EF372E;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include break-max($breakpoint-xsm) {
          padding: 30px;
        }
      }

      .booking-new__variants-list {

        li {
          @include break-max($breakpoint-sm) {
            max-width: 200px;
          }
          @include break-max($breakpoint-xsm) {
            width: 50%;
            max-width: 50%;
          }
        }
      }

      .booking-new__sec4-var.booking-new__sec4-var_1 {
        width: 405px;
        min-width: 405px;

        @include break-max($breakpoint-sm) {
          width: 100%;
        }

        @include break-max($breakpoint-xs) {
          min-width: 100%;
          width: 100%;
        }

        .booking-new__variants-list {
          flex-direction: column;

          @include break-max($breakpoint-sm) {
            flex-direction: row;
          }
        }
      }

      .booking-new__sec4-var.booking-new__sec4-var_2 {
        width: 100%;

        .booking-new__variants-list {
          flex-wrap: wrap;
          gap: 30px;

          li {
            flex-basis: calc(40% - 30px);

            @include break-max($breakpoint-md) {
              flex-basis: calc(50% - 30px);
            }
          }
        }
      }

      .booking-new__sec4-vartit {
        font-size: 35px;
        line-height: 42px;
        font-weight: 700;
        color: white;

        @include break-max($breakpoint-md) {
          font-size: 30px;
          line-height: 36px;
        }

        @include break-max($breakpoint-xs) {
          font-size: 18px;
          line-height: 20px;
        }

      }

      .booking-new__variants-list {
        display: flex;
        gap: 30px 60px;
        margin-top: 20px;
        margin-bottom: 25px;

        @include break-max($breakpoint-xsm) {
          gap: 20px;
        }

        li {
          display: flex;
          flex-direction: column;
          color: white;
          font-size: 18px;
          font-weight: 700;
          line-height: 22px;

          @include break-max($breakpoint-xs) {
            font-size: 16px;
          }

          svg {
            margin-bottom: 10px;
          }
        }
      }

      .booking-new__sec4-vartext {
        font-size: 18px;
        line-height: 22px;
        color: white;
        padding-bottom: 23px;
        border-bottom: 1px solid white;
        margin-bottom: 20px;

        @include break-max($breakpoint-sm) {
          font-size: 14px;
          line-height: 16px;
        }
      }

      .booking-new__sec4-varprice {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        font-weight: 700;
        color: white;
        span {
          font-size: 14px;
          font-weight: 400;
          padding-bottom: 3px;
        }
      }


    }

    .autopan-sec4__title {
      margin-bottom: 20px;
    }

    .sor__form-box {
      padding-top: 0;
    }

    .autopan-sec1__intensive {
      @include break-max($breakpoint-xs) {
        text-align: left;
        margin-bottom: 20px;
      }
    }

    .booking-new__sec4-text {
      @include break-max($breakpoint-xs) {
        font-size: 16px;
        line-height: 18px;
      }
    }

  }
}


























