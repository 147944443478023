@import '../../styles/variables';
@import '../../styles/mixins';

.view-info {
  border-radius: 20px;
  background-color: white;
  padding: 18px 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cancel {
    cursor: pointer;
  }

  &__dates {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    .hp__form-item {
      width: calc(50% - 10px);
    }
    .input__control {
      background-color: $gray-light;
    }
  }
}


.fixations__settings-block .react-datepicker-popper {
  z-index: 10;
}

.react-datepicker__tab-loop .react-datepicker-popper {
  z-index: 11;
}

.subuebans-book {
  margin: 20px auto 0 auto;
  width: 616px;

  .custom-select_sf-filter {
    background-color: #F6F7F8;
  }

  .textarea_type_form .textarea__control {
    background-color: #F6F7F8;
    margin-bottom: 20px;
    color: #2C2E3E;
  }

  .checkbox.fixations__checkboxs {
    justify-content: center;
    margin-bottom: 36px;
  }
}

.subuebans-book-form {

  .modal-form__new-client {
    margin-bottom: 30px;
  }

  &__dates {
    display: flex;
    gap: 40px;

    .react-datepicker-wrapper {
      display: block;
    }

    .hp__form-item + .hp__form-item {
      margin-top: 0;
    }
    .hp__form-item {
      width: calc(50% - 20px);
    }
  }
}

.subuebans-object {
  .sf__elem-btn {
    width: auto;
  }
}
