.requests_select-filter {
    .checkbox__label{
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
    }
    .checkbox {
        max-width: 100%;
    }
}