@import "../../styles/variables";
@import "../../styles/mixins";

.pe {
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 80px;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .button_view_default {
    padding-left: 32px;
    padding-right: 32px;
    min-width: 150px;
    max-width: 100%;

    @include break-max($breakpoint-md) {
      padding-left: 22px;
      padding-right: 22px;
    }

    @include break-max($breakpoint-sm) {
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
      font-size: 13px;
    }
    @include break-max($breakpoint-xs) {
      width: 100%;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  h5, .h5 {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    text-transform: none;
  }

}

.pe__left {
  border-radius: 4px;
  position: sticky;
  top: 100px;
  z-index: 1;
  @include break-max($breakpoint-xs) {
    position: static;
    width: 100%;
    margin-bottom: 15px;
  }
}

.pe__info {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 345px;
  max-width: 100%;
  padding: 30px 65px 35px;
  background: $color-white;

  @include break-max($breakpoint-md) {
    width: 284px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @include break-max($breakpoint-sm) {
    width: 232px;
    padding: 20px 15px 25px;
  }

  @include break-max($breakpoint-xs) {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding: 25px;
    border: 1px solid $color-brand-opaque-20;
  }
}

.pe__mobile-info {
  display: none;
  .label-item {
    margin-bottom: 10px;
  }
  @include break-max($breakpoint-xs) {
    display: block;
  }
}

.pe__manager {
  padding-bottom: 25px;
  border-bottom: 1px solid $color-brand-opaque-20;
}

.pe__manager-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
}

.pe__manager-name {
  margin-bottom: 15px;
}

.pe__date {
  padding-top: 30px;
}

.pe__date-key {
  margin-bottom: 5px;
}

.pe__date-block {
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.pe__date-map {
  margin-top: 10px;
}

.pe__main {
  margin: 0 30px;
  width: 720px;
  max-width: 100%;

  @include break-max($breakpoint-sm) {
    margin-right: 0;
    margin-left: 15px;
  }

  @include break-max($breakpoint-xs) {
    margin-left: 0;
    background: $color-white;
  }
}

.pe__main-box {
  margin-bottom: 30px;
  padding: 35px 72px 50px;
  background: $color-white;
  border-radius: 4px;
  @include break-max($breakpoint-md) {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
  }

  @include break-max($breakpoint-sm) {
    padding: 20px 25px 40px;
    margin-bottom: 20px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    padding: 10px 20px;
    .pe__main-h2, .label-item {
      display: none;
    }
  }
}

.pe__main-content {
  padding-top: 30px;

  @include break-max($breakpoint-md) {
    padding-top: 25px;
  }

  @include break-max($breakpoint-sm) {
    padding-top: 20px;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 5px;
  }

  ul {
    li {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      @include break-max($breakpoint-md) {
        margin-bottom: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        content: '';
        flex-shrink: 0;
        width: 5px;
        height: 5px;
        background: $color-brand-1;
        border-radius: 50%;
        margin-right: 30px;

        @include break-max($breakpoint-sm) {
          margin-right: 20px;
        }

        @include break-max($breakpoint-xs) {
          margin-right: 15px;
        }
      }
    }
  }

  p {
    line-height: 1.5;
  }

  ol {
    list-style: none;
    counter-reset: my-awesome-counter;

    li {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      counter-increment: my-awesome-counter;
      @include break-max($breakpoint-sm) {
        align-items: baseline;
      }
    }

    li::before {
      content: counter(my-awesome-counter) " /";
      color: $color-red;
      font-weight: bold;
      margin-right: 22px;
      white-space: nowrap;
      @include break-max($breakpoint-sm) {
        margin-right: 15px;
      }
    }
  }
}

.pe__main-h2 {
  margin-bottom: 40px;
  @include break-max($breakpoint-sm) {
    margin-bottom: 30px;
  }
  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
  }

  &_small-margin {
    margin-bottom: 20px;
    @include break-max($breakpoint-sm) {
      margin-bottom: 15px;
    }
  }
}

.pe__main-list {
  margin-bottom: 45px;
  margin-top: 25px;
  @include break-max($breakpoint-sm) {
    margin-top: 15px;
    margin-bottom: 35px;
  }
}

.pe__main-h5 {
  margin-bottom: 25px;
  @include break-max($breakpoint-md) {
    margin-bottom: 15px;
  }

  @include break-max($breakpoint-sm) {
    margin-bottom: 10px;
  }

}

.pe__main-text {
  margin-bottom: 45px;
  @include break-max($breakpoint-md) {
    margin-bottom: 35px;
  }

  @include break-max($breakpoint-sm) {
    margin-bottom: 25px;
  }

}

.pe__banner {
  display: flex;
  align-items: center;
  padding: 20px 60px 30px;
  background: $color-white;
  border-radius: 4px;
  @include break-max($breakpoint-md) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include break-max($breakpoint-sm) {
    padding: 20px 25px 25px;
    align-items: flex-start;
    .button_view_default {
      width: 180px;
    }
  }

  @include break-max($breakpoint-xs) {
    padding: 20px 25px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $color-brand-opaque-20;
    text-align: center;
    flex-direction: column;
    align-items: center;
    .button_view_default {
      width: 100%;
    }
  }

}

.pe__banner-img {
  flex-shrink: 0;
  max-height: 152px;
  @include break-max($breakpoint-sm) {
    max-height: 116px;
  }
}

.pe__banner-box {
  margin-left: 50px;
  width: 380px;
  max-width: 100%;

  @include break-max($breakpoint-sm) {
    margin-left: 20px;
  }

  @include break-max($breakpoint-xs) {
    margin-left: 0;
    margin-top: 15px;
  }

  p {
    margin: 10px 0 15px;
  }
}

.pe__banner-btn {
  display: inline-flex;
  justify-content: center;
}

.pe__sign {
  //position: sticky;
  top: 100px;
  width: 344px;
  max-width: 100%;
  flex-shrink: 0;
  @include break-max($breakpoint-md) {
    width: 284px;
  }

  @include break-max($breakpoint-sm) {
    width: 232px;
    margin-top: 15px;
  }

  @include break-max($breakpoint-xs) {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

.pe-vebinars {
  background-color: white;
  width: 344px;
  padding: 30px;
  padding-top: 10px;
  margin-top: 20px;
  border-radius: 5px;

  @include break-max($breakpoint-md) {
    width: 284px;
  }

  @include break-max($breakpoint-sm) {
    width: 232px;
  }

  .new-events__vebinar-item {
    width: 100%;
  }

  .new-events__vebinars {
    flex-direction: column;
    gap: 0;
  }

  .new-events__videolist-link a {
    justify-content: center;
    margin-top: 20px;
  }
}

.pe__sign-block {
  margin-bottom: 30px;
  color: $color-brand-3;
  border-radius: 4px;
  background: $color-brand-2;

  @include break-max($breakpoint-sm) {
    margin-bottom: 15px;
  }

  &:only-of-type {
    margin-bottom: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }

  .button_view_outline {
    width: 205px;
    color: $color-white;
    border: 1px solid $color-brand-opaque-40;
    box-shadow: none;
    &:hover, &:active {
      border-color: transparent;
    }

    @include break-max($breakpoint-sm) {
      width: 100%;
    }
  }
}

.pe__sign-content {
  padding: 30px 40px 40px;
  @include break-max($breakpoint-md) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include break-max($breakpoint-sm) {
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }

  @include break-max($breakpoint-xs) {
    padding-left: 25px;
    padding-right: 25px;
  }

  &_dark {
    background: $color-brand-1;
    border-radius: 4px;

    & ~ .pe__edit {
      padding-top: 0;
      margin-top: -20px;
    }
  }
}

.pe__sign-content_white_and_border {
  display: flex;
  align-items: flex-start;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 30px;

  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }

  .button_view_default {
    max-width: 180px;
    display: inline-flex;
    justify-content: center;
    @include break-max($breakpoint-xs) {
      max-width: 100%;
    }
  }

  .pe__inactive-warning {
    margin-bottom: 0;
    margin-right: 15px;
    @include break-max($breakpoint-sm) {
      margin-bottom: 5px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .pe__inactive-title, .pe__inactive-text {
    color: $color-brand-1;
  }

}


.pe__sign-progress {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 95px;
  background: $color-brand-opaque-10;
  border-radius: 50%;
  margin-top: 15px;
  margin-bottom: 32px;

  @include break-max($breakpoint-sm) {
    margin-left: auto;
    margin-right: auto;
  }

  @include break-max($breakpoint-xs) {
    width: 80px;
    height: 80px;
  }

  .circle-background,
  .circle-progress {
    fill: none;
  }

  .circle-background {
    stroke: transparent;
  }

  .circle-progress {
    // stroke: $green;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

.pe__sign-number {
  color: $color-white;
  fill: $color-white;
  font-size: 2em;
}

.pe__sign-h5 {
  margin-top: 5px;
  margin-bottom: 25px;
  color: $color-white;
}

.pe__sign-footer {
  margin-top: 25px;
  padding-top: 15px;
  border-top: 1px solid $color-brand-opaque-20;
}

.pe__add-staff {

  display: flex;
  align-items: center;
  color: $color-brand-3;

  .button {
    display: flex;
    margin-right: 7px;
    width: 28px;
    height: 28px;
  }

  &:hover .button {
    background-color: $color-brand-opaque-20;
  }
}

.pe__edit {
  border-radius: 4px;
  padding: 30px 40px 35px;
  background: $color-brand-1;

  @include break-max($breakpoint-md) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include break-max($breakpoint-sm) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @include break-max($breakpoint-xs) {

  }

  .custom-select {
    color: $color-brand-2;
  }

  &.hidden {
    display: none;
  }
}

.pe__edit-workers {
  margin-top: 15px;
}

.pe__edit-title {
  margin-bottom: 15px;
  color: $color-white;
}

.pe__edit-worker {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: $color-brand-3;

  &:last-of-type {
    margin-bottom: 0;
  }

  p {
    margin-left: 8px;
  }
}

.pe__edit-btn {
  margin-top: 20px;
}

.pe__inactive {
  a {
    color: inherit;
  }
}

.pe__inactive-title {
  margin-bottom: 15px;
  color: $color-white;
}

.pe__inactive-text {
  margin-bottom: 25px;
  color: $color-white;
}

.pe__inactive-warning {
  margin-bottom: 10px;
  @include break-max($breakpoint-sm) {
    margin-left: auto;
    margin-right: auto;
  }
}

.pe__inactive-tg {
  margin-left: 15px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: $color-brand-opaque-10;
  .icon {
    width: 15px;
    fill: $color-brand-3;
    transition: fill 0.15s ease-in-out;
  }
  &:hover .icon {
    fill: $color-red;
  }
}

.pe__inactive-manager {
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $color-brand-opaque-20;
}

.pe__inactive-contacts {
  display: flex;
  align-items: center;
  @include break-max($breakpoint-sm) {
    justify-content: center;
    margin-top: 5px;
  }
}

.pe__inactive-general {
  padding-top: 15px;
  color: $color-white;

  .pe__inactive-contacts {
    margin-top: 3px;
  }

  a {
    font-weight: bold;
  }
}
.modal__events .modal__inner{
  padding: 28px 45px 45px;
}
.modal__event_only .modal__inner{
  padding: 0;
}

.event_form_item {
  width: 100%;
}
