.modal {
  $c: &;
  &-banks {

    #{$c}__title {
      max-width: 545px;
      margin-left: auto;
      margin-right: auto;
    }

    #{$c}__inner {
      width: 1220px;
      max-width: 90%;

      @include break-max($breakpoint-sm) {
        padding-left: 25px;
        padding-right: 25px;
      }

      @include break-max($break-xs) {
        padding-left: 10px;
        padding-right: 10px;
        margin: 0;
        max-width: none;
        width: 100%;
      }
    }

    &__tab {
      display: none;
      margin-top: 30px;

      &.active {
        display: block;
      }
    }
  }
}
