.custom-select {
  $c: &;
  border-radius: 4px;
  min-width: 162px;
  position: relative;
  transition: box-shadow $transition-default;
  user-select: none;
  width: 100%;

  &.is-disabled {
    pointer-events: none;
    .custom-select__selected {
      background-color: #F6F7F9;
      color: $color-brand-opaque-50;
      &::after {
        background-image: none;
      }
    }
  }

  &__select-hidden {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 0;
    overflow: hidden;
  }

  &__selected {
    background-color: $color-brand-opaque-10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 16px 11px;
    cursor: pointer;
    transition: background-color $transition-default;
    border-radius: 4px;

    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNLjY0Ni42NDZhLjUuNSAwIDAxLjcwOCAwTDQgMy4yOTMgNi42NDYuNjQ2YS41LjUgMCAxMS43MDguNzA4TDQgNC43MDcuNjQ2IDEuMzU0YS41LjUgMCAwMTAtLjcwOHoiIGZpbGw9IiMyQzJFM0UiLz48L3N2Zz4=);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transform: rotate(0);
      transition: transform $transition-default;
      flex-shrink: 0;
      margin-left: auto;
    }

    &:hover {
      background-color: $color-brand-opaque-20;
    }

    &.input__control:hover {
      background-color: $color-white;
    }

  }

  &__options {
    @include unlist();
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    display: none;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    transition: box-shadow $transition-default;
    max-height: 300px;

    ul {
      @include unlist();
    }
  }

  &__search {
    padding: 8px 16px;
    opacity: 0;
    visibility: hidden;
  }
  &__option {
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
      background-color: $color-brand-opaque-10;
    }

    &:last-of-type {
      padding-bottom: 8px;
    }
  }

  &.is-active {
    box-shadow: $shadow;
    background-color: #fff;

    & #{$c}__options {
      display: block;
      z-index: 100;
      box-shadow: $shadow;
    }

    & #{$c}__selected {
      border-radius: 4px 4px 0 0;
      background-color: $color-brand-opaque-20;
    }

    & #{$c}__selected:after {
      transform: rotate(180deg);
    }
  }

  &-label {
    margin-right: 15px;
    cursor: pointer;
  }

  &__with-button {
    padding-right: 47px;
  }
}
.custom-select.is-showing {
  .custom-select__search {
    display: block;
    z-index: 100;
    visibility: visible;
    opacity: 1;
  }
}
.custom-select.error {
  box-shadow: 0 0 0 1px #ff2d23;
}
.select {

  &_type {

    &_form {

      .custom-select-label {
        color: $color-brand-3;
        line-height: 25px;
        margin-bottom: 4px;
      }

      .custom-select__selected {
        height: 50px;
        border-radius: 4px;
        box-shadow: inset 0 0 0 1px $color-brand-opaque-20;
        background-color: $color-white;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .custom-select.is-active {
        .custom-select__selected {
          background-color: $color-white;
          box-shadow: none;
        }
      }
    }

    &_table {
      .custom-select {
        width: 170px;
        min-width: 170px;
      }

      .custom-select__selected {
        height: 38px;
        background-color: transparent;
        box-shadow: none;
        border: 1px solid transparent;
        justify-content: flex-start;

          &:after {
            margin-left: 1em;
          }
      }

      .custom-select.is-active {
        box-shadow: none;

        .custom-select__selected {
          background-color:  $color-white;
          border: 1px solid $color-brand-opaque-20;
          border-bottom-color: transparent;

        }

        .custom-select__options {
          box-shadow: none;
        }
      }

      .custom-select__options {
        box-shadow: none;
        border: 1px solid $color-brand-opaque-20;
        border-top-color: transparent;
      }
    }
  }

  &-multiple {

    &__item {
      position: relative;
      margin-bottom: 10px;
    }

    &__add {
      display: inline-flex;
      align-items: center;
      color: $color-brand-3;
      cursor: pointer;

      .button_type_add {
        margin-right: 15px;
      }

      &:hover {
        .button_type_add {
          background-color: $color-brand-opaque-20;
        }
      }
    }
  }
}


.custom-select.colored .custom-select__selected,
.custom-select.is-showing.colored .custom-select__selected,
.custom-select.colored .custom-select__option {

  &.green,
  &.hex-2CBA2E {
    background: $green-20;
    color: $green;
  }

  &.red,
  &.hex-FF2D23 {
    background: $color-red-opaque-20;
    color: $color-red;
  }

  &.blue,
  &.hex-8790E5 {
    background: $blue-20;
    color: $blue;
  }
  &.gray,
  &.hex-8F95AC {
    background: $gray-20;
    color: $gray;
  }
  &.yellow {
    background: $yellow-20;
    color: $yellow;
  }

  &.hex-67B2F8 {
    background: rgba(#67B2F8, 0.2);
    color: #67B2F8;
  }

  &.orange,
  &.hex-FFA100 {
    background: $orange-20;
    color: $orange;
  }

}

.custom-select.open-top {
  .custom-select__options {
    top: auto;
    bottom: 100%;
  }
  .custom-select__selected::after {
    transform: rotate(180deg);
  }
}

.custom-select.custom-select_sf-filter .custom-select__search {
  display: none;
}
.custom-select.custom-select_sf-filter.is-showing .custom-select__search {
  display: block;
}

.custom-select.custom-select_sf-filter {
  background-color: white;
  .custom-select__selected {
    height: 50px;
    background-color: transparent;
  }

  .custom-select__search {
    .input__control {
      height: 32px;
      border: 1px solid #DFE1E9;
      box-shadow: none;
    }
  }

}
.custom-select__selected_noselect:after {
  display: none;
}
