@import "../../styles/variables";
@import "../../styles/mixins";

.mr {

  .ref {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
  }

  .big-text {
    font-size: 25px;
    line-height: 30px;
    font-weight: 300;
    @include break-max($breakpoint-sm) {
      font-size: 20px;
      line-height: 25px;
    }

    @include break-max($breakpoint-xs) {
      font-size: 16px;
      line-height: 22px;
    }

  }

  .h2 {
    @include break-max($breakpoint-xs) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .wrapper__center {
    @include break-max($breakpoint-sm) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.mr__promo {
  background: $color-brand-1;
  color: $color-white;
  padding-top: 60px;
  padding-bottom: 190px;
  text-align: center;
  @include break-max($breakpoint-sm) {
    padding-bottom: 175px;
  }
  @include break-max($breakpoint-xs) {
    padding-bottom: 455px;
  }
}

.mr__title {
  padding-bottom: 40px;

  @include break-max($breakpoint-sm) {

  }
}

.mr__subtitle {
  padding-bottom: 50px;
  @include break-max($breakpoint-sm) {
    padding-bottom: 40px;
  }
}

.mr__promo-box {
  width: 830px;
  max-width: 100%;
  margin: 0 auto;
  @include break-max($breakpoint-sm) {
    width: 600px;
  }
}

.mr__nav {
  display: flex;
  justify-content: space-between;

  a {
    cursor: pointer;
    color: $color-white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.3px;
    transition: all 0.3s;
    &:hover {
      color: $color-red;
    }
  }

  @include break-max($breakpoint-xs) {
    padding: 0 10px;
    overflow-x: scroll;
    border-bottom: 1px solid $color-brand-opaque-50;
    li {
      margin-right: 25px;
      padding-bottom: 5px;
      &:last-child {
        margin-right: 0;
        padding-right: 25px;
      }
    }
    a {
      white-space: nowrap;
    }
  }
}

.mr__partner-form {
  display: flex;
  min-height: 268px;
  transform: translateY(-50%);
  border-radius: 4px;
  box-shadow: 0px 4px 40px $color-brand-opaque-10;
  color: $color-brand-1;
  background: $color-white;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    transform: none;
    margin-top: -430px;
  }
}

.mr__partner-descr {
  display: flex;
  flex-basis: 50%;
}

.mr__partner-box {
  margin: auto;
  width: 426px;
  max-width: 100%;
  @include break-max($breakpoint-md) {
    padding-left: 20px;
  }
  @include break-max($breakpoint-sm) {
    width: 257px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.mr__partner-title {
  padding-bottom: 20px;
  @include break-max($breakpoint-sm) {
    padding-bottom: 10px;
  }
  @include break-max($breakpoint-xs) {
    padding-top: 20px;
    text-align: center;
  }
}

.mr__partner-effect {
  display: flex;
  font-size: 25px;
  line-height: 30px;
  font-weight: 300;
  margin-bottom: 15px;
  @include break-max($breakpoint-sm) {
    flex-direction: column;
    margin-bottom: 10px;
  }
  @include break-max($breakpoint-xs) {
    flex-direction: row;
    margin-bottom: 0;
  }
}

.mr__partner-percent {
  font-size: 55px;
  line-height: 55px;
  font-weight: bold;
  @include break-max($breakpoint-md) {
    font-size: 50px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 35px;
    margin-right: 5px;
  }

}

.mr__partner-percentsign {
  padding-top: 5px;
  padding-left: 5px;
  @include break-max($breakpoint-md) {
    font-size: 18px;
  }
  @include break-max($breakpoint-sm) {
    padding: 0;
  }
}

.mr__partner-text {
  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
  }
}

.mr__partner-fieldset {
  flex-basis: 50%;
  padding: 40px 60px 35px;
  background: $color-brand-opaque-8;

  @include break-max($breakpoint-md) {
    padding: 40px 30px 35px;
  }

  @include break-max($breakpoint-sm) {
    flex-basis: 457px;
  }

  @include break-max($breakpoint-xs) {
    .button {
      width: 100%;
    }
  }
}

.mr__partner-row {
  display: flex;
  &:first-child {
    margin-bottom: 10px;
  }
  &:nth-child(2) {
    margin-bottom: 20px;
  }

  .checkbox__input {
    background: $color-white;
    box-shadow: inset 0 0 0 1px $color-brand-opaque-20;
  }

  .mr__agreement {
    color: $color-brand-1;

    a {
      color: $color-brand-3;
      transition: all 0.3s;
      &:hover {
        color: $color-red;
      }
    }
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.mr__partner-input {
  flex: 1 1;
  &:first-child {
    margin-right: 20px;
  }
  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
  }
}

.mr__advantages {
  display: flex;
  justify-content: space-between;
  padding-bottom: 160px;

  @include break-max($breakpoint-sm) {
    margin-top: -70px;
    padding-bottom: 90px;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.mr__advantage {
  width: 320px;
  max-width: 100%;
  @include break-max($breakpoint-md) {
    width: 250px;
  }
  @include break-max($breakpoint-sm) {
    width: 26%;
  }

  @include break-max($breakpoint-sm) {
    width: 300px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.mr__advantage-img {
  @include break-max($breakpoint-sm) {
    height: 145px;
  }

  @include break-max($breakpoint-xs) {
    height: 95px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
}

.mr__advantage-title {
  margin-bottom: 25px;
}

.mr__advantage-text {
  line-height: 21px;
}

.mr__consultation {
  display: flex;
  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }
}

.mr__consultation-aside {
  width: 425px;
  max-width: 100%;
  margin-right: auto;

  @include break-max($breakpoint-md) {
    width: 320px;
  }

  @include break-max($breakpoint-sm) {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
  }
  @include break-max($breakpoint-xs) {
    margin-bottom: 20px;
  }
}

.mr__consultation-asidebox {
  @include break-max($breakpoint-sm) {
    width: 340px;
    margin-right: auto;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-right: 0;
    text-align: center;
  }
}

.mr__consultation-img {
  @include break-max($breakpoint-sm) {
    width: 330px;
    max-width: 100%;
  }
  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.mr__consultation-title {
  margin-bottom: 25px;
}

.mr__consultation-text {
  margin-bottom: 40px;
  @include break-max($breakpoint-sm) {
    width: 290px;
  }
  @include break-max($breakpoint-xs) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }
}

.mr__consultation-img {

}

.mr__consultation-form {
  width: 718px;
  padding: 85px 60px 60px;
  background: $color-brand-opaque-8;
  border-radius: 5px;

  @include break-max($breakpoint-md) {
    width: 560px;
    padding: 60px 40px 60px;
  }

  @include break-max($breakpoint-sm) {
    width: 100%;
  }

  @include break-max($breakpoint-xs) {
    padding: 30px 20px;
  }

}

.mr__consultation-row {
  display: flex;
  justify-content: space-between;
  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }
}

.mr__consultation-row_footer {
  @include break-max($breakpoint-sm) {
    flex-direction: row;
  }
  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.mr__consultation-col {
  width: 270px;
  max-width: 100%;
  @include break-max($breakpoint-md) {
    width: 215px;
  }
  @include break-max($breakpoint-sm) {
    width: 100%;
    display: flex;
  }
  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.mr__consultation-checkbox {
  margin-bottom: 35px;
  @include break-max($breakpoint-sm) {
    width: 134px;
    margin-right: 50px;
    .checkbox {
      align-items: flex-start;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    .checkbox {
      align-items: center;
    }
    .checkbox_view_flex-start {
      align-items: flex-start;
    }
  }
}

.mr__consultation-item {
  display: flex;
  align-items: center;
  @include break-max($breakpoint-xs) {
    flex-direction: row;
  }
  .button {
    padding: 17px 20px;
  }
  .info {
    margin-left: 20px;
    @include break-max($breakpoint-md) {
      flex-shrink: 0;
      margin-left: 10px;
    }
  }
}

.mr__consultation-sign {
  color: $color-brand-3;

  @include break-max($breakpoint-md) {
    padding-left: 20px;
  }

  @include break-max($breakpoint-sm) {
    display: block;
    padding-right: 60px;
  }

  @include break-max($breakpoint-xs) {
    display: block;
    padding-left: 0;
    padding-right: 0;
    margin-top: 20px;
  }
}

.mr__consultation-btn {

}

.mr__meetings {
  padding-top: 180px;
  text-align: center;

  @include break-max($breakpoint-md) {
    padding-top: 130px;
  }

  @include break-max($breakpoint-sm) {
    padding-top: 70px;
  }

  @include break-max($breakpoint-sm) {
    padding-top: 55px;
  }
}

.mr__meetimngs-box {
  width: 860px;
  max-width: 100%;
  margin: 0 auto;
}

.mr__meetings-title {
  padding-bottom: 30px;
  @include break-max($breakpoint-sm) {
    padding-bottom: 20px;
  }
}

.mr__meetings-subtitle {
  padding-bottom: 45px;
  @include break-max($breakpoint-xs) {
    width: 310px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 25px;
  }
}

.mr__slider {
  position: relative;
  height: 756px;

  @include break-max($breakpoint-md) {
    height: 604px;
  }

  @include break-max($breakpoint-sm) {
    height: 450px;
  }

  @include break-max($breakpoint-xs) {
    height: auto;
    padding-bottom: 60px;

    img {
      height: 310px;
    }
  }

  .tns-outer, .tns-ovh, .tns-inner, .tns-slider {
    height: 100%;
  }

  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include break-max($breakpoint-xs) {
      position: static;
      transform: none;
    }

  }

  .wrapper__center {
    position: relative;
    @include break-max($breakpoint-xs) {
      position: static;
    }
  }

  .block__title-nav {
    position: absolute;
    top: -30px;
    right: 0;
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  .block__title-navs {
    background: $color-white;
  }
}

.mr__slider-nav {
  display: flex;
  flex-direction: column;
  @include break-max($breakpoint-xs) {
    flex-direction: row;
    overflow-x: scroll;
    margin-bottom: 10px;
  }
}

.mr__slider-nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  max-width: 100%;
  height: 162px;
  border-radius: 4px;
  background: $color-white;
  color: $color-brand-1;
  margin-bottom: 2px;


  @include break-max($breakpoint-md) {
    height: 133px;
  }

  @include break-max($breakpoint-sm) {
    width: 170px;
    height: 85px;

    p {
      display: none;
    }
  }

  @include break-max($breakpoint-xs) {
    width: 256px;
    height: 125px;
    flex-shrink: 0;
    border: 1px solid $color-brand-opaque-20;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    p {
      display: block;
    }
  }

  &.active {
    background: $color-brand-1;
    color: $color-white;
    border-color: $color-brand-1;
  }

  button {
    height: 100%;
    padding: 0 22px;
    color: inherit;
    background: none;
    text-align: left;
    cursor: pointer;
  }

  .h4 {
    margin-bottom: 15px;
    @include break-max($breakpoint-sm) {
      margin: 0;
    }

  }

  p {
    font-size: 14px;
    line-height: 21px;
  }
}

.mr__slide-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.mr__slide-btn {
  padding: 22px 38px;
  position: absolute;
  bottom: 0;
  right: 0;
  @include break-max($breakpoint-xs) {
    width: 90%;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

.mr__service {
  padding-top: 100px;
  @include break-max($breakpoint-xs) {
    padding-top: 60px;
  }

  .h2 {
    width: 944px;
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 55px;
    text-align: center;
    @include break-max($breakpoint-sm) {
      width: 520px;
    }
    @include break-max($breakpoint-xs) {
      width: 325px;
      padding-bottom: 20px;
    }
  }
}

.mr__service-slider {
  position: relative;
  padding-top: 60px;
  border-top: 1px solid $color-brand-opaque-20;
  @include break-max($breakpoint-xs) {
    border-top: none;
    padding-top: 0;
  }


  .block__title-nav {
    position: absolute;
    top: -30px;
    right: 0;
    padding-left: 25px;
    background: $color-white;
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  .block__title-navs {
    background: $color-white;
  }
}

.mr__service-slide {
  width: 444px;
  max-width: 100%;
  height: 455px;
  padding-top: 45px;
  padding-left: 50px;
  padding-right: 45px;
  padding-bottom: 35px;
  max-width: 100%;
  border: 1px solid $color-brand-opaque-10;
  border-radius: 4px;

  @include break-max($breakpoint-md) {
    width: 370px;
    height: 434px;
  }

  @include break-max($breakpoint-sm) {
    width: 345px;
    padding-right: 30px;
    margin-left: auto;
    margin-right: auto;
  }

}

.mr__service-header {
  display: flex;
  align-items: center;
  @include break-max($breakpoint-md) {
    padding-bottom: 15px;
  }
}

.mr__service-counter {
  font-size: 70px;
  font-weight: 900;
  line-height: 100px;
  margin-right: 20px;
  color: $color-brand-opaque-20;
  @include break-max($breakpoint-md) {
    font-size: 50px;
    line-height: 60px;
  }

}

ul.mr__service-ul {
  list-style: disc;

  li {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.mr__service-slide {

}

.mr__service-counter {

}

.mr__service-ul {

}

.mr__footer {
  padding-bottom: 150px;

  @include break-max($breakpoint-md) {
    padding-bottom: 100px;
  }

  @include break-max($breakpoint-xs) {
    padding-bottom: 65px;
  }

  .wrapper {
    position: relative;
  }

  .wrapper__center {
    padding-top: 40px;
    margin-top: 55px;
    @include break-max($breakpoint-xs) {
      padding-top: 0;
    }
  }

}

.mr__banner {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 55px;
  height: 206px;
  padding: 0 95px;
  background: $color-brand-1;
  color: $color-white;

  @include break-max($breakpoint-md) {
    padding: 0 60px;
  }

  @include break-max($breakpoint-sm) {
    margin-top: 20px;
    padding: 0 30px;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    text-align: center;
    padding: 30px 30px 15px;
    height: 275px;
  }

}

.mr__banner-title {
  width: 225px;
  margin-right: 85px;
  @include break-max($breakpoint-sm) {
    width: 150px;
    margin-right: 50px;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }

}

.mr__banner-info {
  display: flex;
  img {
    margin-right: 35px;
    @include break-max($breakpoint-md) {
      display: none;
    }
  }

}

.mr__banner-box {
  @include break-max($breakpoint-sm) {
    width: 220px;
  }
  @include break-max($breakpoint-xs) {
    margin-top: 10px;
  }
}

.mr__banner-price {
  display: flex;
  align-items: flex-start;
  @include break-max($breakpoint-xs) {
    justify-content: center;
  }
}

.mr__banner-bigprice {
  font-size: 55px;
  line-height: 55px;
  font-weight: bold;

  @include break-max($breakpoint-sm) {
    font-size: 40px;
    line-height: 40px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 35px;
    line-height: 35px;
  }
}

.mr__banner-smallprice {
  margin-left: 12px;
  font-weight: 300;
  font-size: 25px;
  @include break-max($breakpoint-md) {
    font-size: 18px;
    padding-top: 5px;
  }
}

.mr__banner-btn {
  margin-left: auto;
  @include break-max($breakpoint-sm) {
    width: 190px;
    padding-left: 0;
    padding-right: 0;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-top: auto;
  }
}

.mr__banner-bg {
  position: absolute;
  top: 0;
  left: 6%;
  @include break-max($breakpoint-md) {
    top: 40px;
    left: 50px;
  }

  @include break-max($breakpoint-sm) {
    width: 140px;
    left: 0;
    top: 20px;
  }

  @include break-max($breakpoint-xs) {
    display: none;
  }

}

.mr__banner-text {
  margin-top: 10px;
  font-size: 14px;
  line-height: 16px;
  color: $color-brand-3;
}

.mr__footer-title {
  width: 695px;
  max-width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 95px;
  margin-bottom: 30px;
  @include break-max($breakpoint-md) {
    width: 566px;
    margin-top: 65px;
  }

  @include break-max($breakpoint-xs) {
    width: 324px;
    margin-top: 40px;
  }
}

.mr__btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}
