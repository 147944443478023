.map-list {
  max-height: 824px;
  // overflow: hidden;
  padding-bottom: 48px;
  padding-right: $grid-gutter / 2;

  @include break-max($break-lg) {
    padding-right: $grid-gutter-md / 2;
  }

  @include break-max($break-md) {
    padding-right: $grid-gutter-sm / 2;
  }

  @include break-max($break-xs) {
    padding-right: 0;
    padding-bottom: 0;
  }

  &__list {
    // overflow-y: auto;
    height: auto;
  }

  &__link {
    color: inherit;
  }

  &__item {
    padding-bottom: 27px;
    border-bottom: 1px solid $color-brand-opaque-10;
  }

  &__flat {
    padding: 20px 0;

    @include break-max($breakpoint-md) {
      padding: 10px 0;
    }

    & .flats__card {
      padding: 0;
      margin-bottom: 5px;
      flex-direction: column;

      &-image {
        width: 100%;
        box-sizing: border-box;
        padding: 0;
      }

      .flats__card-features {
        display: none;
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  &__btn {
    padding: 17px 30px;
  }
}

.map-list-title {
  margin: 24px 0;
}

.map-list__list.for-assignations {
  @include break-max($break-xs) {
    padding-bottom: 40px;
    
    .flats__card {
      flex-direction: row;
    }

    .flats__card-image {
      padding: 0 10px;
      width: 40%;

      img {
        object-fit: contain;
      }
    }

    .flats__card-price {
      font-size: 14px;
    }

    .flats__card-info {
      font-size: 12px;
      width: 60%;
    }
  }
}
