.clip {
  position: relative;

  &__container {
    position: relative;
    overflow: hidden;

    &-input {
      position: absolute;
      left: 9999px;
      opacity: 0;
    }
  }


  .popover__block {
    top: -20px;
    right: calc( 100% + 20px );

    &_align_right {
      right: auto;
      left: calc( 100% + 20px );
    }
  }

  &__tooltip {

    &:after {
      display: none;
    }

    .popover__inner {
      width: 275px;
      padding: 20px 50px 20px 20px;
      background-color: $color-brand-2;
      border-radius: 4px;
    }

    &.is-active {
      display: block;
    }

    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &-text {
      color: $color-white;
    }
  }
}
