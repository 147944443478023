.filter-row {
  max-width: 400px;

  &__block {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 12px;
    // max-width: 400px;

    &-name {
      font-weight: 700;
      display: inline-block;
      margin-right: 18px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__item {
    display: flex;
    margin-right: 6px;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-right: 16px;
  }

  &__type {
    color: $color-brand-3;
    margin-right: .5em;
  }

  &__value {
    
  }
}