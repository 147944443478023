body {
  font-family: "Formular", sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: $color-brand-1;
  overflow-x: hidden;
  @include break-min($breakpoint-sm) {
      overflow-y: scroll;
    }
  &.no-scroll {
    overflow: hidden;
  }

  &.is-fixed {
    position: fixed;
  }

  &.no-map-scroll {
    overflow: hidden;

    .public-header {
      z-index: 11;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
    }
  }
}

.wrapper {
  @include wrapper();

  &_fluid {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  &__center {
    @include grid-shift(1);
    @include break-max($breakpoint-sm) {
      margin: 0;
    }
  }

  &__for-list {
    @include break-max($break-xs) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__main-bg {
    background: $color-white;
  }
}

input[type="submit"] {
  appearance: none;
}

.visible {
  &_note {
    @include break-max($breakpoint-sm) {
      display: none;
    }
  }

  &_desktop {
    @include break-max($breakpoint-md) {
      display: none;
    }
  }

  &_mobile {
    display: none;
    @include break-max($breakpoint-sm) {
      display: block;
    }
  }
}

::selection {
  color: $color-white;
  background-color: $color-brand-3;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

h1,
.h1 {
  @include h1();

  &.false {
    font-size: 36px;
    line-height: 42px;
  }
}

.h1_default {
  text-align: center;
  margin: 75px 0 15px;
}

h2,
.h2 {
  @include h2();

  &.false {
    font-size: 28px;
    line-height: 33px;
  }
}

h3,
.h3 {
  @include h3();

  &.false {
    font-size: 18px;
    line-height: 22px;
  }
}

h4,
.h4 {
  @include h4();
}

h5, .h5 {
  @include h5();
}


.h55 {
  @include h55();
}

.h6 {
  @include h6();
}

.big-text {
  @include big-text();
}

.big-text-sm {
  @include big-text-sm();
}

select {
  background-color: $color-white;
}

.shake {
  color: $color-red!important;
}

.nowrap {
  white-space: nowrap;
}

.flatpickr-monthDropdown-month:hover {
  box-shadow: 0 0 10px 100px $color-red inset;
}

.flatpickr-current-month {
  line-height: 2;
  font-size: 100%;
  color: $color-brand-2;
}

.link {
  @include link();
  &_color_black {
    color: $color-brand-1;
  }
  &_color_brand-3 {
    color: $color-brand-3;
  }
}

.link-hover {
  text-decoration: none;
  transition: color $transition-default;
  cursor: pointer;

  &:hover {
    color: $color-red;
  }
}

.link-red {
  @include link();
  color: $color-red;
  &:hover, &:focus {
    color: $color-red-hover;
  }
  &:active {
    color: $color-red-active;
  }
}


.ten-years-link {
  background-color: rgb(255, 45, 35);
  border-radius: 100px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  padding: 0px 20px;
  position: absolute;
  top: 3px;
  margin-left: 15%;
  cursor: pointer;
  user-select: none;
  @include break-max($breakpoint-sm) {
    position: static;
    display: inline-flex;
    margin-left: 5px;
  }
}

.ten-years-link:hover {
  background-color: #cf2d23;
}
.is-hidden {
  display: none !important;
}

.hide-mobile {
  @include break-max($breakpoint-xs) {
    display: none;
  }
}

a {
  -webkit-tap-highlight-color: transparent;
}



.is-hidden-md {
  @include break-max($breakpoint-md) {
    display: none;
  }
}

.is-hidden-sm {
  @include break-max($breakpoint-sm) {
    display: none;
  }
}

.sm-show {
  @include break-min($break-md) {
    display: none;
  }
}

@mixin debug($background, $outline) {
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;

  > div {
    height: inherit;
    @include wrapper();

    > div {
      height: inherit;
      @include wrapper();

      > div{
          height: inherit;
          @include grid();

          > div{
              @include grid-col();
              @include grid-size(1);
              height: inherit;
              outline: $outline;
              background: $background;

              @include break-max($break-lg) {
                @include grid-size(1, $grid-gutter-md)
              }

              @include break-max($break-md) {
                @include grid-size(1, $grid-gutter-sm)
              }

              @include break-max($break-sm) {
                @include grid-size(1, $grid-gutter-sm);
              }
          }
      }
    }
  }
}

.debug {
  @include debug(transparent, 1px dotted rgba(215, 0, 28, 0.3));
  z-index: 1000000;
  pointer-events: none;
  will-change: transform;

  & > * {
    pointer-events: none;
  }

  & > div > div > div {
    background-color: rgba(215, 0, 28, 0.02);
  }
}

.simplebar-track.simplebar-vertical {
  width: 1px;
  background-color: $color-brand-opaque-20;
  overflow: visible;
}
.simplebar-scrollbar {
  overflow: visible;
}
.simplebar-scrollbar:before {
  opacity: 1;
  background-color: $color-red;
  left: -1px;
  right: -1px;
}

.is-red-text {
  color: $color-red;
}

#dist-map-container {
  height: 720px;
  max-height: 60vh;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: $color-red;
  border-color: $color-red;
}

.container {
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 20px;

  @include break-max($breakpoint-md) {
    max-width: 1038px;
  }

  @include break-max($breakpoint-sm) {
    max-width: 900px;
  }

  @include break-max($breakpoint-xsm) {
    max-width: 768px;
  }

  @include break-max($breakpoint-xs) {
    max-width: 100%;
  }

  .h1 {
    padding-top: 55px;
  }
}

.container_sm {
  max-width: 1010px;
}

.text-center {
  text-align: center;
}

.justify-center {
  justify-content: center;
}

.highlighted {
  padding: 0 5px;
  background: $color-red;
  border-radius: 10px;
}

textarea {
  @include break-max($break-xs) {
    border: 1px solid $color-brand-opaque-20;
  }
}

.new-year {
  .header__top-inner {
    position: relative;
    z-index: 1;
  }
}

.new-year__lights {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;

  @include break-max($breakpoint-xsm) {
    height: 55px;
  }

  @include break-max($breakpoint-xs) {
    height: 50px;
  }

  object {
    position: absolute;
    max-width: 100%;
    top: -11px;
    left: 0;
    margin-left: -5%;
    width: 100%;
    height: 100%;

    @include break-max($breakpoint-md) {
      margin-left: -7%;
    }
    @include break-max($breakpoint-sm) {
      top: -12px;
      margin-left: 0;
    }
    @include break-max($breakpoint-xsm) {

    }
  }
}

.defenders-day {
  position: relative;
  background-image: url('../../assets/images/23feb-left.svg'), url('../../assets/images/23feb-right.svg');
  background-repeat: no-repeat;
  background-position: left, right;
  &::after {
    content: '';
    width: 47px;
    height: 32px;
    position: absolute;
    background: url('../../assets/images/23feb-star.svg') no-repeat;
    right: 22%;
    bottom: 0;
    @media (min-width: 1800px) {
      right: 26%;
    }
    @include break-max($breakpoint-xs) {
      right: 42%;
    }
  }
  .header__top-profile-btn {
    background-color: #52576B;
  }
}

.march-8, .may-1, .may-9 {
  position: relative;
  background-image: url('../../assets/images/march-8-left.svg'), url('../../assets/images/march-8-right.svg');
  background-repeat: no-repeat;
  background-position: left 1% center, right 1% center;
  @media (min-width: 1700px) {
    background-position: left 9% center, right 9% center;
  }

  @include break-max($breakpoint-sm) {
    background-position: left 4% center, right 5% center;
  }

  @include break-max($breakpoint-xs) {
    background-image: url('../../assets/images/march-8-left.svg');
    background-position: center;
  }

  &::after {
    content: '';
    width: 112px;
    height: 69px;
    position: absolute;
    background: url('../../assets/images/march-8-mid.svg') no-repeat;
    right: 37%;
    bottom: 0;
    @media (min-width: 1700px) {
      right: 39%;
    }
    @include break-max($breakpoint-md) {
      display: none;
    }

    @include break-max($breakpoint-xs) {
      //display: block;
      right: 42%;
    }
  }

  .header__top-profile-btn {
    background-color: #52576B;
  }
}

.may-1 {
  background-image: url('../../assets/images/1may-left.svg'), url('../../assets/images/1may-right.svg');
  background-position: left 20% center, right 1% bottom;

  @media (min-width: 1700px) {
    background-position: left 20% center, right 9% bottom;
  }

  @include break-max($breakpoint-sm) {
    background-position: left 4% center, right 5% bottom;
  }


  @include break-max($breakpoint-xs) {
    background-image: url('../../assets/images/1may-right.svg');
    background-position: left 35% bottom;
  }

  &::after {
    top: 0;
    bottom: auto;
    background: url('../../assets/images/1may-mid.svg') no-repeat;
  }
}

.may-9 {
  background-image: url('../../assets/images/9may-left.svg'), url('../../assets/images/9may-right.svg');
  background-position: left 0 center, right 0 bottom;

  @media (min-width: 1700px) {
    background-position: left  9% center, right 0 bottom;
  }

  @include break-max($breakpoint-sm) {
    background-position: left 0 center, right 0% bottom;
  }


  @include break-max($breakpoint-xs) {
    background-image: url('../../assets/images/9may-left.svg');
    background-position: left 0 bottom;
  }

  &::after {
    top: 0;
    bottom: auto;
    background: url('../../assets/images/9may-mid.svg') no-repeat;
    width: 250px;
    height: 80px;
    @media (min-width: 1700px) {
      right: 36%;
    }
  }
}



.pswp__img {
  object-fit: contain;
}

//
// .header_fixed .new-year__lights {
//   display: none;
// }
