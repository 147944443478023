@import "../../styles/variables";
@import "../../styles/mixins";

.autopan-info {
  position: relative;
}

.autopan-info__title {
  margin-top: 25px;
  margin-bottom: 25px;

  @include break-max($breakpoint-xs) {
    margin-top: 0;
  }
}

.autopan-info__box {
  display: flex;
  gap: 28px;
  margin-bottom: 60px;

  a {
    display: inline-flex;
    margin-top: 2px;
    color: inherit;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    margin-bottom: 25px;
  }
}

.autopan-info__block {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  height: 160px;
  padding-top: 30px;
  padding-bottom: 35px;
  padding-left: 45px;
  padding-right: 15px;
  background: $color-brand-opaque-8;

  &_tg {
    background-color: $color-tg;
    background-image: url('../../assets/images/autopan-info-tg.svg');
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
    color: $color-white;
  }

  p + p {
    margin-top: auto;
  }
}

.autopan-info + .modal__title {
  @include break-max($breakpoint-xs) {
    text-align: center;
  }
}
