.preview .trade-in {
  margin-top: 85px;

  @include break-max($breakpoint-sm) {
    margin-top: 60px;
  }

  & ~ .section {
    color: $color-brand-1;
  }
}

.trade-in {
  color: $color-brand-1;

  & ~ .section {
    color: $color-brand-1;
  }

  &__title {
    max-width: 840px;
    text-align: center;
    margin: 75px auto 0;
    @include break-max($breakpoint-xs) {
      margin-top: 30px;
    }

  }

  &__head {
    display: flex;
    padding-bottom: 60px;

    @include break-max($breakpoint-sm) {
      flex-direction: column-reverse;
    }

    @include break-max($breakpoint-xs) {
      padding-bottom: 30px;
    }

    &-block {
      flex: 0 0 390px;
      margin-right: 50px;
      padding-top: 30px;

      @include break-max($breakpoint-sm) {
        position: relative;
        width: 720px;
        max-width: 100%;
        margin: 0 auto;
        padding: 10px 120px;
      }

      @include break-max($breakpoint-xs) {
        width: 100%;
        padding: 10px;
        text-align: center;
        flex-basis: auto;
      }

      &_cloud {

        & .icon {
          width: 255px;
          height: 95px;
        }

        @include break-max($breakpoint-sm) {
          position: absolute;
          top: 20px;
          left: 0;
        }

        @include break-max($breakpoint-xs) {
          display: none;
        }
      }

      &_pill {
        margin-top: 40px;
        @include break-max($breakpoint-xs) {
          margin-top: 20px;

          .pill {
            margin-right: 0;
            font-size: 15px;
            line-height: 32px;
          }
        }
      }

      &_title {
        margin-top: 30px;
        @include break-max($breakpoint-xs) {
          max-width: 85%;
          margin-top: 15px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &_label {
        margin-top: 25px;
        @include break-max($breakpoint-xs) {
          margin-top: 15px;
          font-size: 15px;
        }
      }

      &_btn {
        margin-top: 30px;
        display: flex;

        .label {
          margin-left: 40px;
        }

        @include break-max($breakpoint-xs) {
          width: 100%;
          flex-direction: column;

          .label {
            margin-top: 15px;
            margin-left: auto;
            margin-right: auto;
          }

          .button {
            width: 100%;
            font-size: 15px;
          }
        }
      }
    }

    &-image {
      flex-grow: 1;

      & .icon {
        width: 100%;
        height: 460px;
      }

      @include break-max($breakpoint-sm) {
        width: 470px;
        margin: 20px auto 10px;

        & .icon {
          height: 290px;
        }
      }

      @include break-max($breakpoint-xs) {
        max-width: 100%;

        & .icon {
          height: 214px;
        }
      }
    }
  }

  &__steps {

    padding-top: 60px;
    padding-bottom: 100px;


    @include break-max($breakpoint-xs) {
      padding-top: 0;
      padding-bottom: 50px;

      .button_view_default {
        width: 100%;
        font-size: 15px;
      }
      .trade-in__step-pill .pill {
        font-size: 15px;
      }
    }

    &-title {
      text-align: center;
      margin-bottom: 50px;

      @include break-max($breakpoint-xs) {
        margin-bottom: 10px;
      }
    }

    &-grid {
      margin-left: -35px;
      margin-right: -35px;
      display: flex;
      flex-wrap: wrap;

      @include break-max($breakpoint-sm) {
        margin-left: -8px;
        margin-right: -8px;
      }
    }

    &-col {
      padding: 0 35px;
      margin-bottom: 45px;
      max-width: 33.3333%;
      flex-basis: 33.3333%;

      @include break-max($breakpoint-sm) {
        padding-left: 8px;
        padding-right: 8px;
        margin-bottom: 16px;
      }
    }

    &-conditions {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      @include break-max($breakpoint-xs) {
        padding-left: 10px;
        padding-right: 10px;
        .button {
          width: 100%;
          font-size: 15px;
        }
      }
    }
  }

  &__step {
    height: 100%;
    padding: 32px 35px 25px;
    background-color: $color-white;
    border-radius: 4px;
    box-shadow: 0 4px 60px $color-brand-opaque-10;

    @include break-max($breakpoint-sm) {
      max-width: 310px;
      margin: 0 auto;
      padding: 25px;
    }

    @include break-max($breakpoint-xs) {
      max-width: 84%;
      min-height: 380px;
      border: 1px solid $color-brand-opaque-20;
      box-shadow: none;
    }

    &-num {
      font-weight: 900;
      font-size: 48px;
      line-height: 55px;
      color: $color-brand-opaque-8;

      @include break-max($breakpoint-sm) {
        font-size: 35px;
      }

      @include break-max($breakpoint-sm) {
        color: $color-brand-opaque-20;
      }
    }

    &-name {
      margin-top: 10px;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 25px;

      @include break-max($breakpoint-sm) {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 15px;
      }
    }

    &-pill {
      margin-bottom: 10px;
    }

    &-text {
      font-size: 14px;
      line-height: 21px;
      @include break-max($breakpoint-sm) {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }

  &__benefits {
    border-radius: 4px;
    background-color: $color-brand-2;
    padding: 95px 125px 55px;
    color: $color-white;
    margin-bottom: 40px;


    @include break-max($breakpoint-sm) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include break-max($breakpoint-xs) {
      border-radius: 0;
      padding-top: 45px;
      padding-left: 10px;
      padding-right: 10px;
    }

    &-title {
      max-width: 840px;
      margin: 0 auto 65px;
      text-align: center;

      @include break-max($breakpoint-xs) {
        margin-bottom: 15px;
      }
    }

    &-mobile-title {
      display: none;

      @include break-max($breakpoint-xs) {
        display: block;
        padding-bottom: 15px;
        font-size: 15px;
        line-height: 20px;
        color: $color-brand-3;
        &:last-of-type {
          padding-top: 30px;
        }
      }
    }

    &-table {
      margin-bottom: 30px;
      @include break-max($breakpoint-xs) {
        margin-bottom: 0;
      }

      &_row {
        display: flex;
        margin-left: -15px;
        margin-right: -15px;

        @include break-max($breakpoint-xs) {
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
          padding-right: 0;
        }

        &:last-child {

          .trade-in__benefits {
            &-compare {
              border-bottom: none;
            }
          }
        }


        @include break-max($breakpoint-xs) {
          flex-direction: column;

          &:first-child {
            display: none;
          }
        }

        .accordeon__title {
          height: 70px;
          padding-right: 15px;

          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          border-bottom: 1px solid $color-brand-opaque-20;
          transition: border-color 0.3s;
        }

        .accordeon__btn {
          font-size: 14px;
        }

        &.opened .accordeon__title {
          border-color: transparent;
        }
      }

      &_benefit {
        flex: 0 0 30%;
        padding: 15px;
        @include break-max($breakpoint-xs) {
          font-size: 15px;
          font-weight: bold;
          flex-basis: 100%;
          padding-left: 0;
          padding-right: 0;
        }
      }

      &_compare {
        flex: 1 0 70%;
        padding: 15px;
        margin-left: -15px;
        margin-right: -15px;

        display: flex;
        justify-content: space-between;
        @include break-max($breakpoint-xs) {
          flex-basis: 100%;
          flex-direction: column;
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
          padding-right: 0;
        }
      }

      &_th {
        padding: 0 15px;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        flex: 1 1 auto;
        max-width: 350px;
        margin-bottom: 30px;
      }
    }

    &-compare {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      margin-left: -15px;
      margin-right: -15px;
      padding: 0 15px 35px;
      border-bottom: 1px solid $color-brand-opaque-20;

      @include break-max($breakpoint-xs) {
        flex-basis: 100%;
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
      }

      &_col {
        flex: 1 1 auto;
        max-width: 350px;
        padding: 0 15px;

        @include break-max($breakpoint-xs) {
          display: flex;
          flex-basis: 100%;
          max-width: 100%;
          padding-left: 0;
          padding-right: 0;
        }
      }

      & .icon {
        margin-bottom: 20px;

        @include break-max($breakpoint-xs) {
          margin-bottom: 0;
          margin-right: 15px;
          width: 21px;
          height: 21px;
          flex-shrink: 0;
        }
      }

      &_text {
        font-size: 14px;
        line-height: 21px;
        @include break-max($breakpoint-xs) {
          font-size: 15px;
        }
      }
    }

    &-footer {
      padding-top: 50px;
      border-top: 1px solid $color-brand-opaque-20;
      @include break-max($breakpoint-xs) {
        padding: 40px 12px 0;
        margin-top: -1px;
      }
      &_text {
        max-width: 690px;
        margin: 0 auto;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        @include break-max($breakpoint-xs) {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }

  &__tariffs {

    padding-top: 60px;
    padding-bottom: 60px;

    @include break-max($breakpoint-xs) {
      padding-top: 0;
      padding-bottom: 40px;
    }

    &-title {
      margin-bottom: 12px;
      text-align: center;
    }

    &-label {
      margin-bottom: 40px;
      text-align: center;
      @include break-max($breakpoint-xs) {
        margin-bottom: 30px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 15px;

        .link {
          display: block;
          margin-top: 2px;
        }
      }
    }

    & .custom-table {
      $c: &;

      @include break-max($breakpoint-xs) {
        padding: 0 10px;
      }

      &__body {
        box-shadow: 0 2px 20px $color-brand-opaque-12;

        @include break-max($breakpoint-xs) {
          box-shadow: none;
          border-bottom: 1px solid $color-brand-opaque-20;
          padding-bottom: 15px;
        }

        & .custom-table__tr {
          border-bottom: 1px solid $color-brand-opaque-20;
          @include break-max($breakpoint-xs) {
            border: none;
            &:not(:first-child) {
              padding-left: 16px;
              padding-right: 0;
            }
          }

          &_flex-start {
            align-items: flex-start;
          }

        }

        & .custom-table__item {

          &:last-child {
            & .custom-table__tr {
              border-bottom: 0;
            }
          }
        }

        &:not(.custom-table__body_show-all) .custom-table__item_hidden {
          display: none;
        }
      }

      &__label {
        margin-right: 10px;

        @include break-max($breakpoint-xs) {
          font-weight: bold;
          font-size: 15px;
          margin-right: 0;
        }
      }

      & .motivation__objects {
        justify-content: center;
      }

      & .motivation__object {
        &-header {
          padding: 0;
        }
      }
      & .motivation__fee {
        &-header {
          padding: 0;
        }
      }

      &__head {
        @include break-max($breakpoint-xs) {
          display: none;
        }
      }

      .sm-show {
        @include break-max($breakpoint-xs) {
          display: none !important;
        }
      }

      &__td {

        @include break-max($breakpoint-xs) {
          font-size: 15px;
          padding-left: 0;
          &:first-child {
            width: 50%;
            padding-left: 0;
          }
          &:last-child {
            width: 50%;
            padding-left: 15px;
          }
          &:only-child {
            width: 100%;
          }

        }

      }

      &__tr_reverse-width {
        align-items: flex-start;
        .custom-table__td:first-child {
          width: 45%;
        }
        .custom-table__td:last-child {
          width: 55%;
          padding-left: 10px;
          justify-content: center;
        }
      }


      &__mobile-header {
        color: $color-brand-3;
        font-weight: normal;
        font-size: 15px;
      }

      &__showmore-btn {
        display: flex;
        align-items: center;
        margin: 15px auto 0;
        color: $color-brand-3;
        font-size: 15px;

        svg {
          margin: 0;
          margin-top: 4px;
          margin-left: 10px;
          font-size: 12px;
          stroke: $color-brand-3;
          fill: transparent;
          transition: transform 0.3s;
          cursor: pointer;
          width: 1em;
          height: 1em;
          vertical-align: middle;
        }

        &.hidden {
          display: none;
        }
      }
    }
  }

  &__partnership {
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 160px;

    @include break-max($breakpoint-sm) {
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
      padding-bottom: 70px;
    }

    @include break-max($breakpoint-xs) {
      padding-top: 20px;
    }

    &-image, &-block {
      width: 490px;
      max-width: 100%;
      margin: 0 50px;
    }

    &-image {
      @include break-max($breakpoint-sm) {
        margin-bottom: 15px;
      }

      @include break-max($breakpoint-xs) {
        width: 100%;
        margin: 0 auto;
      }

      & .icon {
        width: 100%;
        height: 270px;
        @include break-max($breakpoint-xs) {
          height: 178px;
        }
      }
    }

    &-block {
      padding-top: 30px;

      @include break-max($breakpoint-sm) {
        text-align: center;
      }

      @include break-max($breakpoint-xs) {
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
      }

      &_title {
        margin-bottom: 25px;
        @include break-max($breakpoint-xs) {
          margin-bottom: 15px;
        }
      }

      &_label {
        margin-bottom: 30px;
        @include break-max($breakpoint-xs) {
          margin-bottom: 25px;
          font-size: 15px;
        }
      }

      &_btn {
        @include break-max($breakpoint-xs) {
          width: 100%;
          .button {
            width: 100%;
            font-size: 15px;
          }
        }
      }
    }
  }
}

.pill {
  display: inline-block;
  background-color: $color-brand-3;
  color: $color-white;
  white-space: nowrap;
  margin-right: 24px;
  line-height: 30px;
  padding: 0 12px;
  border-radius: 4px;
  transition: background-color $transition-default;
  cursor: pointer;

  &:hover {
    background-color: $color-brand-3-hover;
  }
}

.trade-in__slider.slider .slider__item {
  height: 100%;
}

.trade-in__slider.slider .slider__item.tns-slide-active {
  margin-right: -45px;
}


.trade-in__manager {
  position: fixed;
  bottom: 5%;
  right: 3%;
  z-index: 2;
  display: flex;
  width: 345px;
  padding: 18px;
  background: $color-brand-2;
  color: $color-white;
  border-radius: 4px;
  border: 1px solid $color-brand-opaque-20;

  opacity: 1;
  visibility: visible;
  transition: all 0.3s;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  @include break-max($breakpoint-xs) {
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }


}

.trade-in__manager-block {
  display: flex;
  flex-direction: column;
}

.trade-in__manager-name {
  margin-bottom: 7px;
}

.trade-in__manager-position {
  margin-bottom: 10px;
  color: $color-brand-3;
  font-size: 14px;
  line-height: 18px;
}

.trade-in__manager-img {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  margin-right: 15px;
}

.trade-in__manager-phone {
  color: inherit;
}