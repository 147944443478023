@import '../../styles/variables';
@import '../../styles/mixins';

.video-search {
  width: 540px;
  margin-bottom: 10px;

  @include break-max($breakpoint-xsm) {
    width: 426px;
  }
}

.section-search {
  position: relative;

  &__inp {
    height: 50px;
    background-color: $gray-light;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 14px;
    font-family: 'Formular';
    width: 100%;
    padding-right: 40px;

    &::placeholder {
      color: #3C2E3E;
    }
  }

  &__icon {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;

  }
}




.cases-table {
  .ant-table-cell {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    width: 132px;
    border-radius: 5px 0 0 5px;
  }
  .ant-table-container table > thead > tr:first-child th {
    border-right: 1px solid #565865;
    padding-left: 10px;
    padding-right: 10px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-radius: 0 5px 5px 0;
  }

  .ant-table-container table > tbody tr {
    height: 78px;
  }

  .table-img img {
    min-height: 50px;
    width: 90px;
    padding: 7px 15px;
    background-color: $color-white;
    border-radius: 5px;
    border: 1px solid $gray-dark;
  }

  .ant-table-container table > tbody > tr td:first-child {
    border-radius: 5px 0 0 5px;
  }
  .ant-table-container table > tbody > tr td:last-child {
    border-radius: 0 5px 5px 0;
  }

}



.cases {
  .tabs-nav {

    .tabs-nav-item {

      &__button {
        width: 100%;
      }
    }
  }
}
































