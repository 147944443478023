.property {
  .map-icon{
    fill: $color-1;
    color: red;
  }

  .section_view_gray {
    background: $color-white;
  }

  .card__dates {
    color: $color-1;
  }

  .map-card__dates {
    color: $color-1;
  }

  .map-card__options,
  .map-card__routes {
    margin-top: 10px;
  }

  .cards__pagination {
    display: none;
  }

  .button_view_outline-with-icon-invert {
    box-shadow: none;
    color: $color-1;
    &:hover {
      background: none;
      color: $color-1;
    }
  }

  .simplebar-scrollbar:before {
    background: $color-1;
  }

  .section.index-search.index-search_dark {
    display: none;
  }

  .button_view_default {
    background: $color-1;
  }

  .cards {
    padding-bottom: 0;
  }

  .constructor__btn {
    display: flex;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .cards__item {
    // box-shadow: 0px 10px 40px rgba(148, 134, 204, 0.15);
    border-radius: 15px;
    transition: all 0.3s;
    border: 2px solid $c-gray_m;

    &_no-border {
      border: none;
    }
    &:hover {
      // box-shadow: 0 2px 15px rgba(143, 149, 172, 0.5);
      box-shadow: none
    }
    .card {
      box-shadow: none;
    }
    .card__body {
      border-radius: 12px;
    }
    @include break-max($breakpoint-xs) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

  }

}
.map__container_yellow #myMap{
  & [class*="-hint"] {
    background-color: rgb(255,211,54);
    &:after {
      background-image: url("data:image/svg+xml;charset=UTF-8,<svg width='13' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M0 0l13 10V0H0z' fill='rgb(255,211,54)'/></svg>");
    }
  }
}
.map__container_gray #myMap{
  & [class*="-hint"] {
    background-color: rgb(169,169,184);
    &:after {
      background-image: url("data:image/svg+xml;charset=UTF-8,<svg width='13' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M0 0l13 10V0H0z' fill='rgb(169,169,184)'/></svg>");
    }
  }
}
.map__container_green #myMap{
  & [class*="-hint"] {
    background-color: rgb(44,186,46);
    &:after {
      background-image: url("data:image/svg+xml;charset=UTF-8,<svg width='13' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M0 0l13 10V0H0z' fill='rgb(44,186,46)'/></svg>");
    }
  }
}
.map__container_blue #myMap{
  & [class*="-hint"] {
    background-color: rgb(98,184,231);
    &:after {
      background-image: url("data:image/svg+xml;charset=UTF-8,<svg width='13' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M0 0l13 10V0H0z' fill='rgb(98,184,231)'/></svg>");
    }
  }
}
.property__tabs {
  display: flex;
  position: relative;
  z-index: 2;
  margin-bottom: 25px;

  @include break-max($breakpoint-xsm) {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin-top: 24px;

    .property__btn.constructor__btn {
      border-radius: 4px;
      padding: 7px 8px;
      font-size: 12px;
      font-weight: normal;
      line-height: 18px;
      margin-bottom: 12px;
      text-transform: none;
      margin-top: 0;
    }
  }

  @include break-max($breakpoint-xsm) {
    .property__tabs-item  {
      margin-right: 8px;
    }
  }
}
.property__tabs_wrapper {
  display: flex;
  justify-content: space-between;
}
.property__btn {
  margin-top: 0;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400;
  text-transform: none;
}

// .property__btn:not(.property__btn_active) {
//   background: none;
//   color: $color-2;
//   transition: all 0.3s;
//   &:hover {
//     color: $color-1;
//   }
// }

.types-tabs__wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.types-tabs {
  background: $color-white;
  border-radius: 5px;
  display: flex;
  padding: 5px;
  position: relative;

  li + li {
    margin-left: 5px;
  }

  @include break-max($breakpoint-sm) {
    padding: 4px 5px;
  }
}

.types-tabs__background {
  background: $color-1;
  border-radius: 5px;
  height: 48px;
  position: absolute;
  transform: translateX(0px);
  transition: all 0.3s ease-out 0s;
  z-index: 0;
}

.types-tabs__item {
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  
  max-width: 100%;
  height: 48px;
  padding: 0 37px;
  font-size: 16px;
  line-height: 18px;
  transition: color 0.4s ease-out 0s;
  position: relative;
  z-index: 1;
  font-weight: bold;

  svg {
    width: 32px;
  }

  path {
    transition: all 0.8s ease-out 0s;
    fill: transparent;
    stroke: $color-brand-2;
  }
}

.types-tabs__item.is-active {
  // background: $color-1;
  color: $color-white;
  z-index: 1;

  path {
    fill: $color-1;
    stroke: $color-white;
  }
}

.types-tabs__name {
  margin-left: 8px;
}

.property .property__btn:not(.property__btn_active) {
  background: $color-bg-light !important;
  color: $color-brand-2 !important;
}

.types-tabs__subtabs {
  align-items: center;
  display: flex;
  margin-top: 40px;
  position: relative;

  .property__tabs {
    margin-right: 24px;
    margin-bottom: 0;

    @include break-max($breakpoint-sm) {
      display: flex;
    }
  }

  .property__tabs-item {
    white-space: nowrap;

    .property__btn.constructor__btn {
      border-radius: 4px;
      padding: 11px 8px;
      font-size: 14px;
      line-height: 18px;


      @include break-max($breakpoint-sm) {
        font-size: 12px;
        line-height: 18px;
        padding: 7px 8px;
      }


    }

    & + .property__tabs-item {
      margin-left: 6px;
    }
  }

  .property__btn {
    padding-left: 30px;
    padding-right: 30px;
  }

  .property__btn.constructor__btn.property__btn_active {
    color: $color-white;
  }
}

.mobile-tabs {
  display: flex;
  flex-wrap: wrap;

  .custom-select {
    flex-grow: 1;
    width: auto;
  }
}

.small-types-tabs {
  display: flex;
}

.types-tabs__name {
  transition: color 0.4s ease-out 0s;
}
