.reviews-list {
  margin-top: 60px;
  margin-bottom: 100px;

  @include break-max($breakpoint-sm) {
    margin-top: 30px;
    margin-bottom: 60px;
  }
  @include break-max($breakpoint-xs) {
    margin-bottom: 30px;
  }

  
  &_mt-0 {
    margin-top: 0;
  }
}

.review {
  display: flex;
  margin-bottom: 20px;
  padding-top: 40px;
  padding-bottom: 35px;
  background: $color-brand-opaque-8;
  border-radius: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.review-container {
  width: 1020px;
  max-width: 100%;
  margin: auto;

  @include break-max($breakpoint-md) {
    width: 775px;
  }

  @include break-max($breakpoint-sm) {
    width: 600px;
  }

  @include break-max($breakpoint-xs) {
    width: 300px;
  }
}

.review-author {
  display: block;
  margin-bottom: 15px;
  color: $color-brand-3;
}