.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @include break-max($break-md) {
    flex-wrap: wrap;
  }

  &__control {
    position: relative;
    z-index: 1;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .icon {
      stroke: $color-brand-3;
      font-size: 12px;
      transition: stroke $transition-default;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      z-index: -1;
      border-radius: 4px;
      background-color: $color-brand-opaque-10;
      transition: background-color $transition-default;
    }

    &:hover {

      & .icon {
        stroke: $color-white;
      }

      &:after {
        background-color: $color-brand-3;
      }
    }
  }

  &__prev {
    margin-right: 10px;
  }

  &__next {
    margin-left: 10px;
  }

  &__list {
    @include unlist();
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    color: $color-brand-1;
    text-decoration: none;
    margin: 0 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: color $transition-default;
    position: relative;
      z-index: 1;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      z-index: -1;
      border-radius: 4px;
      background-color: transparent;
      transition: background-color $transition-default;
    }

    &.is-active {
      color: $color-white;


      &:after {
        background-color: $color-red;
      }
    }

    &:hover {
      color: $color-white;

      &:after {
        background-color: $color-brand-3;
      }
    }
  }

  &__view-results {
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    @include break-max ($break-md) {
      position: static;
      flex: 1 0 100%;
      margin-top: 21px;
    }

    &-inner {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-label {
      white-space: nowrap;
      margin-left: 23px;
    }

    & .custom-select {
      width: 72px;
      min-width: 72px;

      &__selected:after {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0zLjkgN0wwIC4yNWg3LjhMMy45IDd6IiBmaWxsPSIjRkYyRDIzIi8+PC9zdmc+);

      }
    }
  }
}

.sf.sf--apt-modal .presentation__content-icon {
  display: none;
}

.sf.sf--apt-modal .presentation__content-control:nth-child(1):before {
  content: '';
  margin-right: 5px;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgMTIuNDI4NlY1LjYxOTA1QzYgNS40NTQ4NyA2LjA2NTIyIDUuMjk3NDEgNi4xODEzMSA1LjE4MTMxQzYuMjk3NDEgNS4wNjUyMiA2LjQ1NDg3IDUgNi42MTkwNSA1SDE0LjA0NzZMMTguMzgxIDkuMzMzMzNWMTIuNDI4NiIgc3Ryb2tlPSIjRkYyRDIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE0LjA0NzYgNVY5LjMzMzMzSDE4LjM4MDkiIHN0cm9rZT0iI0ZGMkQyMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik02IDE4LjAwMDFINy4yMzgxQzcuNTY2NDYgMTguMDAwMSA3Ljg4MTM3IDE3Ljg2OTcgOC4xMTM1NiAxNy42Mzc1QzguMzQ1NzUgMTcuNDA1MyA4LjQ3NjE5IDE3LjA5MDQgOC40NzYxOSAxNi43NjJDOC40NzYxOSAxNi40MzM3IDguMzQ1NzUgMTYuMTE4NyA4LjExMzU2IDE1Ljg4NjZDNy44ODEzNyAxNS42NTQ0IDcuNTY2NDYgMTUuNTIzOSA3LjIzODEgMTUuNTIzOUg2VjE5LjIzODIiIHN0cm9rZT0iI0ZGMkQyMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xOSAxNS41MjM5SDE2LjgzMzNWMTkuMjM4MiIgc3Ryb2tlPSIjRkYyRDIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE4LjY5MDUgMTcuNjkwNEgxNi44MzMzIiBzdHJva2U9IiNGRjJEMjMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTIuMTkwNCAxOS4yMzgyQzEyLjY4MyAxOS4yMzgyIDEzLjE1NTQgMTkuMDQyNSAxMy41MDM2IDE4LjY5NDNDMTMuODUxOSAxOC4zNDYgMTQuMDQ3NiAxNy44NzM2IDE0LjA0NzYgMTcuMzgxMUMxNC4wNDc2IDE2Ljg4ODUgMTMuODUxOSAxNi40MTYyIDEzLjUwMzYgMTYuMDY3OUMxMy4xNTU0IDE1LjcxOTYgMTIuNjgzIDE1LjUyMzkgMTIuMTkwNCAxNS41MjM5SDExLjEwNzFWMTkuMjM4MkgxMi4xOTA0WiIgc3Ryb2tlPSIjRkYyRDIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}
.sf.sf--apt-modal .presentation__content-control:nth-child(1):hover:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgMTIuNDI4NlY1LjYxOTA1QzYgNS40NTQ4NyA2LjA2NTIyIDUuMjk3NDEgNi4xODEzMSA1LjE4MTMxQzYuMjk3NDEgNS4wNjUyMiA2LjQ1NDg3IDUgNi42MTkwNSA1SDE0LjA0NzZMMTguMzgxIDkuMzMzMzNWMTIuNDI4NiIgc3Ryb2tlPSIjY2YyZDIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE0LjA0NzYgNVY5LjMzMzMzSDE4LjM4MDkiIHN0cm9rZT0iI2NmMmQyMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik02IDE4LjAwMDFINy4yMzgxQzcuNTY2NDYgMTguMDAwMSA3Ljg4MTM3IDE3Ljg2OTcgOC4xMTM1NiAxNy42Mzc1QzguMzQ1NzUgMTcuNDA1MyA4LjQ3NjE5IDE3LjA5MDQgOC40NzYxOSAxNi43NjJDOC40NzYxOSAxNi40MzM3IDguMzQ1NzUgMTYuMTE4NyA4LjExMzU2IDE1Ljg4NjZDNy44ODEzNyAxNS42NTQ0IDcuNTY2NDYgMTUuNTIzOSA3LjIzODEgMTUuNTIzOUg2VjE5LjIzODIiIHN0cm9rZT0iI2NmMmQyMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xOSAxNS41MjM5SDE2LjgzMzNWMTkuMjM4MiIgc3Ryb2tlPSIjY2YyZDIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE4LjY5MDUgMTcuNjkwNEgxNi44MzMzIiBzdHJva2U9IiNjZjJkMjMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTIuMTkwNCAxOS4yMzgyQzEyLjY4MyAxOS4yMzgyIDEzLjE1NTQgMTkuMDQyNSAxMy41MDM2IDE4LjY5NDNDMTMuODUxOSAxOC4zNDYgMTQuMDQ3NiAxNy44NzM2IDE0LjA0NzYgMTcuMzgxMUMxNC4wNDc2IDE2Ljg4ODUgMTMuODUxOSAxNi40MTYyIDEzLjUwMzYgMTYuMDY3OUMxMy4xNTU0IDE1LjcxOTYgMTIuNjgzIDE1LjUyMzkgMTIuMTkwNCAxNS41MjM5SDExLjEwNzFWMTkuMjM4MkgxMi4xOTA0WiIgc3Ryb2tlPSIjY2YyZDIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}

.sf.sf--apt-modal .presentation__content-control:nth-child(2):before {
  content: '';
  margin-right: 5px;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5LjY2MjYgNi4zMzMzNkMxOS40NDY3IDYuMTcxNTEgMTkuMTUzNyA2LjA4MDU4IDE4Ljg0ODIgNi4wODA1OEg1LjE1OTFDNS4wMDcyMyA2LjA3OTg2IDQuODU2NjggNi4xMDE2NSA0LjcxNjA5IDYuMTQ0N0M0LjU3NTUgNi4xODc3NCA0LjQ0NzY3IDYuMjUxMiA0LjMzOTk0IDYuMzMxNDFDNC4yMzIyMSA2LjQxMTYyIDQuMTQ2NzIgNi41MDcgNC4wODgzOCA2LjYxMjA3QzQuMDMwMDQgNi43MTcxNCA0IDYuODI5ODIgNCA2Ljk0MzYxVjE3LjIxNzVDNCAxNy4zMzEzIDQuMDMwMDQgMTcuNDQ0IDQuMDg4MzggMTcuNTQ5MUM0LjE0NjcyIDE3LjY1NDEgNC4yMzIyMSAxNy43NDk1IDQuMzM5OTQgMTcuODI5N0M0LjQ0NzY3IDE3LjkwOTkgNC41NzU1IDE3Ljk3MzQgNC43MTYwOSAxOC4wMTY0QzQuODU2NjggMTguMDU5NSA1LjAwNzIzIDE4LjA4MTMgNS4xNTkxIDE4LjA4MDZIMTguODQ4MkMxOS4xNTM3IDE4LjA4MDYgMTkuNDQ2NyAxNy45ODk2IDE5LjY2MjYgMTcuODI3OEMxOS44Nzg2IDE3LjY2NTkgMjAgMTcuNDQ2NCAyMCAxNy4yMTc1VjYuOTQzNjFDMjAgNi43MTQ3MiAxOS44Nzg2IDYuNDk1MjEgMTkuNjYyNiA2LjMzMzM2WiIgc3Ryb2tlPSIjRkYyRDIzIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNMjAgNy4wODA1N0wxMiAxNC4wODA2TDQgNy4wODA1NyIgc3Ryb2tlPSIjRkYyRDIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}
.sf.sf--apt-modal .presentation__content-control:nth-child(3):hover:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOC4zMzIyIDEwLjA5OTZDMTguNzIxMyAxMC4wOTk2IDE5LjAzNjUgMTAuNDE0OSAxOS4wMzY1IDEwLjgwNEMxOS4wMzY1IDExLjE5MTQgMTguNzIxMyAxMS41MDY2IDE4LjMzMjIgMTEuNTA2NkMxNy45NDM5IDExLjUwNjYgMTcuNjI4NyAxMS4xOTE0IDE3LjYyODcgMTAuODA0QzE3LjYyODcgMTAuNDE0OSAxNy45NDM5IDEwLjA5OTYgMTguMzMyMiAxMC4wOTk2WiIgZmlsbD0iI2NmMmQyMyIvPgo8cGF0aCBkPSJNMTcuMjc3NCA4LjY5MzAzVjQuNTQ1NzZDMTcuMjc3NCA0LjExNjAyIDE2LjkyODIgMy43Njc1OCAxNi40OTkzIDMuNzY3NThMNy41MDIwNCAzLjc2NzU4QzcuMDcxNDYgMy43Njc1OCA2LjcyMzAyIDQuMTE2MDIgNi43MjMwMiA0LjU0NTc2TDYuNzIzMDIgOC42OTMwMyIgc3Ryb2tlPSIjY2YyZDIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy4yNzc0IDE0LjQwMDhWMTkuODcwNUMxNy4yNzc0IDIwLjMwMjggMTYuOTI2NSAyMC42NTM3IDE2LjQ5NDMgMjAuNjUzN0g3LjUwNzAxQzcuMDczOTUgMjAuNjUzNyA2LjcyMzAyIDIwLjMwMjggNi43MjMwMiAxOS44NzA1TDYuNzIzMDIgMTQuNDAwOEM2LjcyMzAyIDEzLjk2ODYgNy4wNzM5NSAxMy42MTc3IDcuNTA3MDEgMTMuNjE3N0wxNi40OTQzIDEzLjYxNzdDMTYuOTI2NSAxMy42MTc3IDE3LjI3NzQgMTMuOTY4NiAxNy4yNzc0IDE0LjQwMDhaIiBzdHJva2U9IiNjZjJkMjMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNi43MjMyIDE3LjQ4NzdINC4yNjA4OUMzLjg3MTggMTcuNDg3NyAzLjU1NzM3IDE3LjE3MjQgMy41NTczNyAxNi43ODMzTDMuNTU3MzcgOS4zOTYzOUMzLjU1NzM3IDkuMDA4MTMgMy44NzE4IDguNjkyODcgNC4yNjA4OSA4LjY5Mjg3TDE5LjczOTEgOC42OTI4N0MyMC4xMjc0IDguNjkyODcgMjAuNDQyNiA5LjAwODEzIDIwLjQ0MjYgOS4zOTYzOVYxNi43ODMzQzIwLjQ0MjYgMTcuMTcyNCAyMC4xMjc0IDE3LjQ4NzcgMTkuNzM5MSAxNy40ODc3SDE3LjI3NzYiIHN0cm9rZT0iI2NmMmQyMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
}

.sf.sf--apt-modal .presentation__content-control:nth-child(3):before {
  content: '';
  margin-right: 5px;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOC4zMzIyIDEwLjA5OTZDMTguNzIxMyAxMC4wOTk2IDE5LjAzNjUgMTAuNDE0OSAxOS4wMzY1IDEwLjgwNEMxOS4wMzY1IDExLjE5MTQgMTguNzIxMyAxMS41MDY2IDE4LjMzMjIgMTEuNTA2NkMxNy45NDM5IDExLjUwNjYgMTcuNjI4NyAxMS4xOTE0IDE3LjYyODcgMTAuODA0QzE3LjYyODcgMTAuNDE0OSAxNy45NDM5IDEwLjA5OTYgMTguMzMyMiAxMC4wOTk2WiIgZmlsbD0iI0ZGMkQyMyIvPgo8cGF0aCBkPSJNMTcuMjc3NCA4LjY5MzAzVjQuNTQ1NzZDMTcuMjc3NCA0LjExNjAyIDE2LjkyODIgMy43Njc1OCAxNi40OTkzIDMuNzY3NThMNy41MDIwNCAzLjc2NzU4QzcuMDcxNDYgMy43Njc1OCA2LjcyMzAyIDQuMTE2MDIgNi43MjMwMiA0LjU0NTc2TDYuNzIzMDIgOC42OTMwMyIgc3Ryb2tlPSIjRkYyRDIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy4yNzc0IDE0LjQwMDhWMTkuODcwNUMxNy4yNzc0IDIwLjMwMjggMTYuOTI2NSAyMC42NTM3IDE2LjQ5NDMgMjAuNjUzN0g3LjUwNzAxQzcuMDczOTUgMjAuNjUzNyA2LjcyMzAyIDIwLjMwMjggNi43MjMwMiAxOS44NzA1TDYuNzIzMDIgMTQuNDAwOEM2LjcyMzAyIDEzLjk2ODYgNy4wNzM5NSAxMy42MTc3IDcuNTA3MDEgMTMuNjE3N0wxNi40OTQzIDEzLjYxNzdDMTYuOTI2NSAxMy42MTc3IDE3LjI3NzQgMTMuOTY4NiAxNy4yNzc0IDE0LjQwMDhaIiBzdHJva2U9IiNGRjJEMjMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNi43MjMyIDE3LjQ4NzdINC4yNjA4OUMzLjg3MTggMTcuNDg3NyAzLjU1NzM3IDE3LjE3MjQgMy41NTczNyAxNi43ODMzTDMuNTU3MzcgOS4zOTYzOUMzLjU1NzM3IDkuMDA4MTMgMy44NzE4IDguNjkyODcgNC4yNjA4OSA4LjY5Mjg3TDE5LjczOTEgOC42OTI4N0MyMC4xMjc0IDguNjkyODcgMjAuNDQyNiA5LjAwODEzIDIwLjQ0MjYgOS4zOTYzOVYxNi43ODMzQzIwLjQ0MjYgMTcuMTcyNCAyMC4xMjc0IDE3LjQ4NzcgMTkuNzM5MSAxNy40ODc3SDE3LjI3NzYiIHN0cm9rZT0iI0ZGMkQyMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
}
.sf.sf--apt-modal .presentation__content-control:nth-child(2):hover:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5LjY2MjYgNi4zMzMzNkMxOS40NDY3IDYuMTcxNTEgMTkuMTUzNyA2LjA4MDU4IDE4Ljg0ODIgNi4wODA1OEg1LjE1OTFDNS4wMDcyMyA2LjA3OTg2IDQuODU2NjggNi4xMDE2NSA0LjcxNjA5IDYuMTQ0N0M0LjU3NTUgNi4xODc3NCA0LjQ0NzY3IDYuMjUxMiA0LjMzOTk0IDYuMzMxNDFDNC4yMzIyMSA2LjQxMTYyIDQuMTQ2NzIgNi41MDcgNC4wODgzOCA2LjYxMjA3QzQuMDMwMDQgNi43MTcxNCA0IDYuODI5ODIgNCA2Ljk0MzYxVjE3LjIxNzVDNCAxNy4zMzEzIDQuMDMwMDQgMTcuNDQ0IDQuMDg4MzggMTcuNTQ5MUM0LjE0NjcyIDE3LjY1NDEgNC4yMzIyMSAxNy43NDk1IDQuMzM5OTQgMTcuODI5N0M0LjQ0NzY3IDE3LjkwOTkgNC41NzU1IDE3Ljk3MzQgNC43MTYwOSAxOC4wMTY0QzQuODU2NjggMTguMDU5NSA1LjAwNzIzIDE4LjA4MTMgNS4xNTkxIDE4LjA4MDZIMTguODQ4MkMxOS4xNTM3IDE4LjA4MDYgMTkuNDQ2NyAxNy45ODk2IDE5LjY2MjYgMTcuODI3OEMxOS44Nzg2IDE3LjY2NTkgMjAgMTcuNDQ2NCAyMCAxNy4yMTc1VjYuOTQzNjFDMjAgNi43MTQ3MiAxOS44Nzg2IDYuNDk1MjEgMTkuNjYyNiA2LjMzMzM2WiIgc3Ryb2tlPSIjY2YyZDIzIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNMjAgNy4wODA1N0wxMiAxNC4wODA2TDQgNy4wODA1NyIgc3Ryb2tlPSIjY2YyZDIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+");
}

.presentation__content-control_copy {
  display: inline-flex;
  margin-left: 35px;
  margin-top: 5px;
  transition-duration: .3ms;

  &:hover {
    svg path {
      stroke: #cf2d23;
    }
  }

  svg {
    margin-right: 5px;
    margin-top: -2px;
  }
}

.presentation__action {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  cursor: pointer;
  gap: 35px;

  .presentation__action-item {
    display: flex;
    align-items: center;
    transition-duration: .3ms;
    color: $color-brand-2;

    &:hover {
      color: #cf2d23;

      svg path {
        stroke: #cf2d23;
      }
    }
  }

  svg {
    margin-right: 5px;
  }
}



