.header {
  &_fixed {
    position: fixed;
    //position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    & .header__top {
      transform: translateY(-100%);
      transition: all 0.5s ease;
    }

    & .header__bottom {
      display: none;
      //opacity: 0;
    }

  }

  .header__top_translateY {
    .dropdown.is-showing .side-menu {
      transform: translate(0, 160px);
    }
  }

  &.hidden {
    display: none;
  }

  &.is-dark {
    .header__mobile-profile-menu,
    .header__mobile {
      background: $color-brand-1;
      color: $color-brand-3;
    }

    .mobile-panel__text,
    .header__mobile-services,
    .header__top-profile-id,
    .header__top-menu-link {
      color: $color-brand-3;
    }

    .header__mobile-link-icon svg {
      fill: $color-brand-3;
    }
  }

  &__top {
    background-color: $color-brand-2;

    transform: translateY(0%);
    position: relative;
    z-index: 20;

    @include break-max($breakpoint-xs) {
      background-color: $color-white;
      align-items: center;
      display: flex;
      height: 50px;
      padding: 0;
      border-bottom: 1px solid $color-brand-opaque-20;

      & .wrapper {
        width: 100%;
      }
    }
  }

  & .switch {
    @include break-max($break-xs) {
      justify-content: space-between;
    }
  }

  & .switch__label {
    @include break-max($break-xs) {
      color: $color-brand-2;
    }
  }

  & .switch_view_invert .switch__label {
    @include break-max($break-xs) {
      color: inherit;
    }
  }

  &__top-inner {
    display: flex;
    align-items: center;
    min-height: 44px;

    @include break-max($breakpoint-xs) {
      justify-content: space-between;
      padding: 0 10px;
      width: 100%;
    }
  }

  &__logo {
    display: block;
    width: 72px;
    height: 39px;
    flex-shrink: 0;

    & svg {
      fill: $color-red;
    }
  }

  &__top-menu {
    @include unlist();
    display: flex;
    align-items: center;
    margin-left: -15px;

    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  &__top-menu-item {
    margin-right: 14px;

    @include break-max($breakpoint-sm) {
      margin-right: 5px;
    }

    &:last-of-type {
      margin-right: 0;
    }
    & .dropdown.is-showing .icon {
      transform: rotate(180deg);
    }

    & .dropdown__block {
      left: 0;
      transform: translateX(0);
      margin-top: 14px;
    }

  }

  &__top-menu-link {
    display: block;
    background-color: transparent;
    border-radius: 32px;
    color: inherit;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    padding: 5px 16px;
    transition: all 0.15s ease-in-out;

    @include break-max($breakpoint-xs) {
      font-size: 15px;
      padding-left: 0;
    }

    &:hover {
      background-color: $color-brand-opaque-12;
      border-radius: 20px;
    }

    & .icon {
      stroke: $color-brand-2;
      fill: transparent;
      font-size: 9px;
      margin-left: 5px;
      transform: rotate(0);
      transition: transform $transition-default;
    }
  }

  &__top-menu-drop {
    background-color: $color-white;
    padding: 10px;
    border-radius: 20px;
    min-width: 215px;
    box-shadow: 0px 4px 25px rgba(44, 46, 62, 0.1);
  }

  &__top-drop-list {
    @include unlist();
  }

  &__top-drop-link {
    display: block;
    padding: 5px 16px;
    background-color: transparent;
    border-radius: 4px;
    text-decoration: none;
    color: $color-brand-2;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    border-radius: 20px;
    transition: all 0.15s ease-in-out;
    &:hover {
      background-color: $color-brand-opaque-12;
    }
  }

  &__top-profile {
    margin-left: auto;
    margin-right: auto;
    & .dropdown__block {
      @include break-max(calc(#{$breakpoint-xs} + 2px)) {
        top: 80px !important;
        position: fixed !important;
      }
    }
  }



  .sidemenu-btn {
    position: relative;
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: flex-start;


    @include break-max($breakpoint-sm) {
      display: none;
    }
  }



  .dropdown.is-showing {
    .sidemenu-btn {
      background-color: $color-brand-1 !important;
    }
  }

  &__top-profile-btn {
    position: relative;
    display: flex;
    align-items: center;
    border: 0;
    background-color: $color-brand-opaque-20;
    &::after {
      position: absolute;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      width: 16px;
      height: 1px;
      background: $color-white;
      box-shadow: 0 6px $color-white, 0 12px $color-white;
    }

    @include break-max($breakpoint-xsm) {
      display: none;
    }

  }

  &__top-profile-avatar {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: $color-red;
    color: #fff;
    font-size: 14px;
    margin-right: 14px;
    font-weight: bold;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &__top-profile-id {
    color: #fff;
    font-size: 14px;

    & .icon {
      fill: #fff;
      margin-left: 9px;
      font-size: 10px;
      transform: rotate(0);
      transition: transform $transition-default;
    }
  }

  &__top-phone {
    display: block;
    margin-right: 15px;

    &-anchor {
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
      transition: color $transition-default;

      @include break-max($breakpoint-xs) {
        color: $color-brand-2;
      }

      &:hover {
        color: $color-red;
      }
    }

    &.sm-hide {
      @include break-max($break-xs) {
        display: block !important;
        margin-right: 15px;
        padding-left: 30px;
        white-space: nowrap;

        &-anchor {
          font-size: 15px;
          font-weight: normal;
          line-height: 20px;
          white-space: nowrap;
        }
      }
    }

  }

  &__top-anchors {
    display: flex;
    align-items: center;
  }

  &__wa {
    margin-top: 5px;
    margin-right: 12px;
  }

  &__top-rightblock {
    display: flex;
    align-items: center;
    margin-left: auto;
    // @include break-max($breakpoint-md) {
    //   margin-left: 0;
    //   flex-grow: 1;
    // }
  }

  &__bottom {
    background-color: $gray-light;
    padding: 12px 0;
    position: relative;
    z-index: 1;

    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  &__bottom-inner {
    display: flex;
    align-items: center;
  }

  &__bottom-menu {
    @include unlist();
    display: flex;
    align-items: center;

    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  &__bottom-menu-item {
    margin-right: 24px;
    position: relative;

    &:last-of-type {
      margin-right: 0;
    }

    & .dropdown.is-showing .icon.icon_chevron {
      transform: rotate(180deg);
    }

    & .dropdown__block {
      margin: 16px 10px;
      left: 0;
      transform: translateX(-10px);
    }

    @include break-max($breakpoint-md) {
      margin-right: 2px;
    }

  }

  &__bottom-menu-link {
    display: inline-block;
    background-color: transparent;
    border-radius: 32px;
    font-size: 14px;
    line-height: 32px;
    color: $color-white;
    text-decoration: none;
    transition: all $transition-default;
    cursor: pointer;
    padding: 0 10px;
    white-space: nowrap;

    &:hover,
    &.is-active {
      background-color: $color-brand-opaque-40;
      border-radius: 20px;

      .icon_chevron {
        transition: all 0.3s;
      }

      @include break-max($breakpoint-xs) {
        background-color: $color-white;
      }


    }

    &.is-active {
      .icon_chevron {
        transform: rotate(180deg);
      }
    }

    & .icon {
      stroke: #fff;
      fill: transparent;
      font-size: 12px;
      margin-left: 5px;
      transform: rotate(0);
      transition: all $transition-default;

      &_percent {
        stroke-width: 1.5;
      }
    }
  }



  .header__bottom-menu-item_highlighted .header__bottom-menu-link {
    color: #fff;
    font-weight: 700;
    display: flex;

    svg {
      margin-top: 5px;
      margin-right: 10px;

      @include break-max($breakpoint-xs) {
        padding-left: 0;
        margin-right: 0;
        margin-left: 10px;
      }
    }

    @include break-max($breakpoint-xs) {
      color: $color-brand-2;
    }
  }

  .header__mobile .header__bottom-menu-item .header__bottom-menu-link {
    color: $color-brand-2;
  }

  &__bottom-menu-drop {
    border-radius: 0 0 4px 4px;
    min-width: 240px;
  }

  &__bottom-drop-list {
    @include unlist();
    padding: 10px 0;
    border-radius: 16px;
    background-color: $color-brand-2;
    overflow: hidden;
  }

  &__bottom-drop-link {
    display: block;
    padding: 10px 10px 10px 16px;
    background-color: transparent;
    border-radius: 4px;
    text-decoration: none;
    color: #fff;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: $color-brand-opaque-20;
    }
  }

  &__bottom-controls {
    margin-left: auto;
    display: flex;
    align-items: center;
    @include break-max($breakpoint-sm) {
      display: none;
    }
  }

  &__bottom-search-id {
    width: 190px;
    margin-left: 20px;

    @include break-max($breakpoint-md) {
      margin-left: 20px;
    }
  }

  &__mobile {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: $color-brand-1;
    width: 350px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;

    &.is-active {
      transform: translateX(0);
    }

    .wrapper,
    .banners__grid {
      padding: 0;
    }

    .banners__grid {
      flex-direction: column;

      @include break-max($breakpoint-md) {
        flex-direction: column;
        margin: 0;
        padding: 0 10px;
        width: 100%;
      }
    }

    @include break-max($breakpoint-md) {
      .banners .banner-card__name {
        font-size: 15px;
        line-height: 18px;
      }

      .banners .banner-card__description {
        font-size: 12px;
        line-height: 16px;
        padding-right: 0;
      }

      .banners .banners__grid-col + .banners__grid-col {
        margin: 20px 0 0 0;
      }

      .header__top-menu-link {
        color: $color-brand-2;
      }

      .banners .banner-card {
        flex-direction: row-reverse;
      }
    }

    background: $color-white;

    @include break-max($breakpoint-sm) {
      display: block;
      overflow-y: scroll;
    }

    @include break-max($break-xs) {
      width: 100%;
    }
  }

  &__mobile-shadow {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-brand-opaque-70;
    opacity: 0;
    pointer-events: none;
    transition: opacity $transition-default;

    &.is-active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__mobile-close {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    & .icon {
      font-size: 16px;
      stroke: $color-white;
    }
  }

  &__mobile-inner {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 10px 20px;
    // max-height: calc(100vh - 92px);
    max-height: none;
    overflow-y: auto;

    @include break-max($break-xs) {
      overflow: visible;
    }
  }

  &__mobile-search-id {
    margin-bottom: 16px;
    padding: 20px 20px 0;
    margin-bottom: 20px;

    @include break-max($breakpoint-xs) {
      padding: 0;
      margin: 0;

      margin-right: 15px;

      .input_type_search_round {
        position: relative;
      }

      & .input__control {
        background: $color-brand-opaque-20;
        border-radius: 50em;
        height: 30px;
        padding: 0;
        width: 30px;
      }

      & .icon.icon_search {
        left: 2px;
        right: 0;
        margin: 0 auto;
        top: 8px;
        transform: none;
        width: 14px;
      }
    }
  }

  &__mobile-menu {
    display: flex;
    display: none;
    justify-content: space-between;
    margin-bottom: 36px;

    &-link {
      display: inline-block;
      background-color: transparent;
      border-radius: 32px;
      color: #fff;
      font-size: 12px;
      font-weight: 700;
      text-decoration: none;
      text-transform: uppercase;

      & .icon {
        stroke: #fff;
        fill: transparent;
        font-size: 12px;
        margin-left: 5px;
        transform: rotate(0);
        transition: transform $transition-default;

        &_percent {
          stroke-width: 1.5;
        }
      }
    }
  }

  &__mobile-sub {
    &-menu {
      margin-top: 20px;

      &.is-hidden {
        display: none;
      }

      &-item {
        margin-bottom: 12px;

        &:last-of-type {
          margin-bottom: 0;
        }

        & .dropdown__block {
          position: static;
          transform: translate(0);
        }

        & .dropdown .icon {
          transform: rotate(0deg);
          transition: transform $transition-default;
        }

        & .dropdown.is-showing .icon {
          transform: rotate(180deg);
        }
      }

      &-link {
        display: inline-block;
        background-color: transparent;
        font-size: 14px;
        line-height: 32px;
        color: #7f849a;
        text-decoration: none;
        transition: all $transition-default;
        & .icon {
          stroke: #7f849a;
          fill: transparent;
        }
        &:hover,
        &.is-active {
          color: #fff;

          & .icon {
            stroke: #fff;
            fill: transparent;
          }
        }
      }
    }

    &-drop-link {
      font-size: 14px;
      line-height: 24px;
      color: #7f849a;
      text-decoration: none;
    }
  }

  &__mobile-section {
    padding: 17px 0;
    margin: 0 10px;
  }

  &__mobile-phone {
    display: inline-block;
    align-self: flex-start;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    margin-top: 32px;
  }
}

.search-id {
  $c: &;
  position: relative;

  &__result {
    position: absolute;
    top: calc(100% + 11px);
    right: 0;
    left: auto;
    width: 575px;
    padding: 12px;
    background-color: $color-white;
    box-shadow: 0px 4px 20px $color-brand-opaque-20;
    white-space: nowrap;
    border-radius: 4px;
    z-index: 100;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -6px;
      right: 75px;
      left: auto;
      border-bottom: 10px solid $color-white;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }

    &.align_right {
      right: 0;
      left: auto;

      &:after {
        right: 75px;
        left: auto;
      }
    }

    &.align_left {
      right: auto;
      left: auto;

      &:after {
        right: auto;
        left: 75px;
      }
    }

    @include break-max($break-xs) {
      &.align_left,
      &.align_right {
        position: fixed;
        left: auto;
        right: 0;
        width: 100%;
      }
    }

    &-empty {
      padding: 40px 0;
      text-align: center;
      font-weight: bold;
    }

    &-list {
      padding: 6px 0;
    }

    &-item {
      display: flex;
      align-items: center;
      color: $color-brand-3;
      padding: 12px;
      transition: all $transition-default;
      background-color: transparent;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: $color-brand-opaque-8;

        #{$c}__result-item--type {
          background-color: $color-brand-2;
          color: $color-white;
        }
      }

      &--type {
        transition: all $transition-default;
        background-color: $color-brand-opaque-10;
        margin-right: 15px;
        padding: 6px;
        border-radius: 4px;
      }

      &--details {
      }

      &--rooms {
        color: $color-brand-2;
      }

      &--id {
        margin-left: auto;
      }

      &--arrow {
        display: flex;
        align-items: center;
        margin-left: 30px;

        .icon {
          width: 12px;
          height: 4px;
          stroke: $color-brand-2;
          transform: rotate(-90deg);
        }
      }
    }
  }
}

.header__mobile-profile {
  align-items: center;
  background: $color-brand-opaque-8;
  border-radius: 20px;
  display: flex;
  margin: 16px 10px 0;
  min-height: 80px;
  padding: 0 15px;

  .header__top-profile-avatar {
    height: 60px;
    width: 60px;
  }

  .header__top-profile-id {
    color: $color-brand-2;
    font-weight: bold;
    font-size: 15px;
    flex-grow: 1;
    line-height: 18px;
  }

  .icon.icon_triangle-down {
    display: none;
  }

  .arrow-right {
    margin-left: 15px;
    transform: rotate(90deg);
    width: 10px;
  }
}

.header__mobile-link {
  align-items: center;
  display: flex;
  margin-top: 12px;

  &:first-child {
    margin-top: 0;
  }
}

.header__mobile-link-icon {
  margin-right: 8px;
  text-align: center;
  width: 25px;
}

.header__mobile-profile-menu {
  background: $color-white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  right: 0;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;

  &.is-active {
    transform: translateX(0%);
  }
}

.header__mobile-profile-logout {
  background: none;
  color: $color-brand-3;
  position: absolute;
  right: 5px;
  top: 17px;
  font-size: 15px;
}

.header__mobile-back {
  background: $color-brand-opaque-8;
  border-radius: 5px;
  padding: 15px 17px;

  .mobile-panel__link {
    color: $color-brand-2;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
  }

  .icon {
    background: $color-brand-opaque-10;
    border-radius: 16px;
    padding: 7px;
    height: 32px;
    width: 32px;
  }

  .mobile-panel__item {
    margin-bottom: 7px;
  }
}

.header__mobile-no-back {
  padding: 20px 24px 0;

  .mobile-panel__text {
    color: $color-brand-2;
    font-weight: bold;
    font-size: 15px;
    line-height: 21px;
  }

  .mobile-panel__link {
    flex-wrap: wrap;
  }
}

.mobile-panel__desc {
  color: $color-brand-3;
  font-weight: 300;
  font-size: 15px;
  flex-basis: 100%;
  line-height: 20px;
  margin-left: 45px;
}

.mobile__panel-icon {
  width: 45px;
}

.header__mobile-services {
  color: $color-brand-2;
  font-size: 15px;
  line-height: 24px;
  padding: 20px 0;
}

.mobile-search-id {
  background: $color-white;
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 110px;
  bottom: 0;
  z-index: 20;

  &.is-open {
    display: flex;
    flex-direction: column;
  }

  .search-id__result-item {
    border-bottom: 1px solid $color-brand-opaque-20;
    border-radius: 0;
    flex-wrap: wrap;
    padding-bottom: 20px;
  }

  .search-id__result-item--desc {
    flex-basis: 100%;
    order: 3;
    margin-top: 10px;
  }

  .input__control {
    background: $color-brand-opaque-10;
    border-radius: 4px;
    height: 50px;
    padding-left: 50px;
    width: 100%;

    &:focus {
      background: $color-brand-opaque-10;
    }
  }

  .search-id.header__mobile-search-id {
    margin: 15px 10px 0 10px;
  }

  .icon.icon_search {
    margin: 0 0 0 18px;
    top: 0;
    bottom: 0;
    transform: translateY(100%);
  }

  .search-id__result.align_left,
  .search-id__result.align_right {
    bottom: 0;
    box-shadow: none;
    top: 130px;

    @include break-max($break-xs) {
      padding-top: 0;
      top: 120px;
    }

    &:after {
      display: none;
    }
  }
}

.header__mobile {
  .banners__grid {
    @include break-max($break-xs) {
      padding: 0;
    }
  }
}

.mobile-services__link {
  display: flex;
}

.mobile-services__icon {
  flex-shrink: 0;
  width: 40px;

  .icon_car {
    height: 12px;
    width: 26px;
  }
  .icon_refresh {
    height: 22px;
    width: 25px;
  }
  .icon_meeting_room-01 {
    height: 18px;
    width: 25px;
  }
  .icon_chat {
    height: 18px;
    width: 20px;
  }
  .icon_hat {
    height: 18px;
    width: 21px;
  }
  .icon_camera {
    height: 18px;
    width: 25px;
  }
  .icon_legal {
    height: 22px;
    width: 22px;
  }

  //
  .icon {
    width: 26px;
    height: 26px;
    fill: $color-brand-3;
  }
}

.mobile-services__item {
  margin-bottom: 25px;

  h4 {
    color: $color-brand-2;
  }
}

.mobile-services__text {
  color: $color-brand-3;
}

.header__bottom-menu-link {
  @include break-max($breakpoint-xs) {
    width: 100%;
    color: $color-brand-2;
    padding-left: 0;
  }
}

.header__bottom-menu-item {
  @include break-max($breakpoint-xs) {
    align-items: center;
    display: flex;
    height: 50px;
  }
}

.header__mobile-sub-menu {
  margin-bottom: 20px;

  @include break-max($break-xs) {
    margin-top: 0;
  }
}

.header__bottom-menu-item.is-services {
  @include break-max($break-xs) {
    position: relative;

    &::after {
      content: "ᐳ";
      color: $color-brand-2;
      font-size: 11px;
      position: absolute;
      right: 12px;
      transform: scaleX(0.6);
    }
  }
}

.header__mobile-links {
  @include break-max($breakpoint-xs) {
    border-top: 1px solid $color-brand-opaque-20;
    border-bottom: 1px solid $color-brand-opaque-20;
    padding: 20px 0;
  }
}

.header__mobile-profile-links {
  @include break-max($break-xs) {
    padding: 0;
  }
}

.header__top-menu-item .dropdown.dropdown.is-showing .header__top-menu-link {
  background-color: $color-brand-opaque-12;
}
