.filter {

  .checkbox, .checkbox__label,
  .radio, .radio__label {
    flex-grow: 1;
  }

  &__filter {
    margin-bottom: 22px;

    @include break-max($break-xs) {
      margin-top: 6px;
      margin-bottom: 10px;
    }
  }

  &__checks {
    max-height: 190px;
  }

  &__exclude-btn {
    display: none;
  }

  &__cols, &__fields {
    display: flex;
  }

  &__col, &__field {
    flex: 0 0 calc(50% - 10px);
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__range {
    margin-bottom: 22px;
  }

  &__fields + &__fields {
    margin-top: 22px;
  }

  &__dist-btn {
    color: $color-brand-3;
    font-size: 14px;
    margin-bottom: 15px;
    transition: color $transition-default;

    & .icon {
      font-size: 16px;
      fill: $color-brand-3;
      transform: rotate(0)!important;
      margin-right: 11px;

      transition: fill $transition-default;
    }

    &:hover {
      color: $color-brand-2;

      & .icon {
        fill: $color-brand-2;
      }
    }
  }
}

.clients {

  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 14px;
  }

  &__control {
    margin-right: 11px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.filters {
  &__filter-applied {
    margin-top: 32px;
    display: flex;
    align-items: stretch;

    &.is-hidden {
      display: none;

      & + .orders__filter-mobile {
        display: none;
      }
    }
  }

  &__filter-inc,
  &__filter-exc {
    padding: 0 12px 12px;
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    border-radius: 4px;
    transition: background-color $transition-default;
    border: 1px dashed $color-brand-opaque-20;

    & > .filtered {
      flex-shrink: 0;
      margin-right: 12px;
      margin-top: 12px;
    }

    & > .filtered-clear {
      margin-top: 12px;

      @include break-max($break-xs) {
        margin-top: 0;
      }
    }

    & > *:first-child {

      &.filtered-clear {
        display: none;
      }
    }

    &.is-dragover {
      background-color: $color-brand-opaque-8;
    }
  }

  &__filter-tip {
    color: $color-brand-3;
    margin: 1.3em auto auto;
  }

  &__filter-inc {
    margin-right: 44px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 20px;
      height: 13px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjg3IDIuMjY1VjBsNC44MyAyLjc2Ni00LjgzIDIuNzY1VjMuMjY1SDIuNDE1di0xaDExLjQ1M3pNNC44MzEgOS42MzRWNy4zNjlMMCAxMC4xMzRsNC44MzEgMi43NjV2LTIuMjY1aDExLjQ1NHYtMUg0LjgzMXoiIGZpbGw9IiM4Rjk1QUMiLz48L3N2Zz4=);
      background-position: center;
      background-repeat: no-repeat;
      top: 50%;
      left: 100%;
      margin-left: 12px;
      transform: translateY(-50%);
    }
  }

  &__filter-exc {
    & .filtered {
      background-color: $color-red-opaque-20;
      box-shadow: inset 0 0 0 1px transparent;

      &:hover {
        box-shadow: inset 0 0 0 1px transparent;
        background-color: $color-red;

        & .filtered__type {
          color: $color-white;
        }

        & .filtered__value {
          color: $color-white;
        }

        & .filtered__del .icon {
          stroke: $color-white;
        }
      }
    }
  }
}

.mobile-filters .filter__cols.for-floors {
  @include break-max($breakpoint-xs) {
    overflow: hidden;
    margin-bottom: 0;
    padding: 0 1px;
    position: relative;

    &::after {
      background: linear-gradient(rgba($color-white, 0), rgba($color-white, 0.5) 10%, $color-white 70%);
      content: '';
      height: 30px;
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 120;
    }

    .filter__col {
      overflow: scroll;
      padding: 0 1px;
      position: relative;
    }

    .filter__filter {
      background: $color-white;
      margin-top: 0;
      position: fixed;
      transform: translate3d(0,0,0);
      width: calc(50% - 24px);
      z-index: 1;
    }

    .filter__checks {
      margin-top: 60px;
    }
  }
}
