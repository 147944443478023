@import "../../../styles/variables";
@import "../../../styles/mixins";


.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed $color-brand-opaque-20;
  border-radius: 4px;
  width: 100%;
  height: 110px;
  padding: 0 15px;

  color: $color-brand-3;
  text-align: center;

  cursor: pointer;

  svg {
    margin-bottom: 5px;
  }
}
