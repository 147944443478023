.breadcrumbs {
  display: flex;
  margin-top: 10px;
  height: 50px;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid $color-brand-opaque-20;

  li {
    position: relative;
    &:not(:last-child)::after {
      content: '/';
      margin: 0 5px;
    }
  }

  a:not([href]) {
    color: $color-brand-3;
  }
}