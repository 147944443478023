@import '../../styles/variables';
@import '../../styles/mixins';

.currencys-seelect {

  &__value {
    cursor: pointer;
    display: inline-flex;
    border-radius: 20px;
    padding: 5px 15px;
    //margin-top: -5px;
    //margin-left: -15px;
    transition-duration: .3s;

    &:hover {
      background-color: $gray-light-3;
    }
  }

  &__list {
    //margin-left: -15px;
    border-radius: 15px;
    background-color: $color-white;
    padding: 10px;
    margin-top: 10px;
    position: absolute;
    z-index: 1;
  }

  &__item {
    cursor: pointer;
    padding: 3px 10px;
    transition-duration: .3s;

    &:hover {
      background-color: $gray-light-3;
      border-radius: 20px;
    }
  }
  &__item:last-child {
    margin-bottom: 0;
  }

  &__rate {
    margin-left: 22px;
    font-size: 13px;
  }

  &__arrow {
    margin-top: 6px;
    margin-left: 10px;
    transition-duration: .2s;

  }
  &__arrow.active {
    transform: rotate(180deg);
  }

  &__item.active {
    color: $color-red-bottom-border;

    .currencys-seelect__rate {
      color: $color-brand-3;
    }
  }
}

.currencys-seelect_infocard {
  .currencys-seelect__value {
    margin-top: -5px;
    margin-left: -15px;
  }
  .currencys-seelect__list {
    margin-left: -15px;
  }
}

.currencys-seelect_cardlist {
  width: 360px;
  position: relative;
  padding: 10px 0;

  .currencys-seelect__value {
   align-items: center;

    svg {
      margin-right: 10px;
    }
  }
  .currencys-seelect__item {
    padding: 7px 10px;

    &:hover {
      border-radius: 5px;
    }
    .currencys-seelect__rate {
      color: #8F95AC;
    }
  }

  .currencys-seelect__cource-cards {
    margin-left: 15px;
    display: inline-block;
    color: #8F95AC;
  }
}

.currencys-seelect_cardlist.isOpen {
  box-shadow: 0px 2px 20px rgba(143, 149, 172, 0.1);
  border-radius: 15px 15px 0 0;
}

