@import '../../styles/variables';
@import '../../styles/mixins';

.suburban-presentation {
  max-width: 1280px;
  margin: 30px auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .complex-view-list .swiper-container {
    @include break-max($breakpoint-xsm) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__land-status {
    margin-top: 30px;
    margin-left: 30px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;

    @include break-max($breakpoint-xsm) {
      margin-top: 15px;
      margin-left: 10px;
      font-size: 14px;
      margin-right: 10px;
    }
  }

  .about-redesign__listtext-bd {
    @include break-max($breakpoint-xsm) {
      font-size: 12px;
    }
  }

  .about-redesign__list-text {
    @include break-max($breakpoint-xsm) {
      font-size: 12px;
    }
  }


  .pan-comfort__map {
    margin-top: 0;
    min-height: 320px;
    height: 320px;

    @include break-max($breakpoint-xsm) {
      border-radius: 20px;
    }
  }

  .pan-comfort__map .pan-comfort__map-map {
    height: 320px;
  }

  &__grid {
    display: flex;
    gap: 30px;
    width: 100%;

    @include break-max($breakpoint-xsm) {
      gap: 15px;
    }
  }



  &__grid.suburban-presentation__grid_header {
    @include break-max($breakpoint-xsm) {
      flex-direction: column;
      gap: 15px;

      .suburban-presentation__left {
        width: 100%;
        order: 1;
      }

      .sf__block.sf__manager_modal {
        padding: 20px;
        margin-bottom: 0;
      }

      .suburban-presentation__top-info {
        flex-direction: column;

        .suburban-presentation__top-infoPrice {
          padding-left: 0;
          align-self: flex-start;
          margin-top: 15px;
        }
      }
    }
  }

  &__grid.suburban-presentation__grid_full {
    @include break-max($breakpoint-xsm) {
      flex-direction: column;

      .suburban-presentation__left {
        width: 100%;
        //order: 1;
      }
    }
  }

  &__grid_full {
    background-color: #F6F7F8;
    border-radius: 20px;
    flex-wrap: wrap;

    .swiper-pan {
      @include break-max($breakpoint-sm) {
        margin: 0;
      }
    }

    .suburban-presentation__left-content {
      @include break-max($breakpoint-sm) {
        margin: 10px;

        .communications-redesign__list .about-redesign__list-item {
          width: calc(50% - 20px);
          flex-basis: calc(50% - 5px);
        }

        .about-redesign__list {
          gap: 10px;
        }
      }
    }

    .complex-view-list {
      @include break-max($breakpoint-sm) {
        margin-top: 10px;
      }
    }

    .swiper-pan img:not(.pswp__img) {
      max-height: 420px;

      @include break-max($breakpoint-sm) {
        max-height: 270px;
      }
    }
    .swiper-thumbs-block {
      display: none;
    }




  }

  &__grid_wasted {
    background-color: #FFE6E5;


    .suburban-presentation__right-labels span.suburban-presentation__right-labelActive {
      background-color: #FF2D23;
    }
    .suburban-presentation__right-labels span.suburban-presentation__right-labelActive:before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgNy41TDEyIDEyLjVMMTcgNy41IiBzdHJva2U9IiNFM0YwRjciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNNyAxNy41TDEyIDEyLjVMMTcgMTcuNSIgc3Ryb2tlPSIjRTNGMEY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==");
      margin-bottom: -6px;
      margin-right: 12px;
    }
  }

  &__left {
    width: 685px;

    @include break-max($breakpoint-sm) {
      width: 550px;
    }
  }

  &__left-content {
    margin: 30px 0 30px 30px;

    .about-redesign {
      background-color: white;
      margin-top: 30px;

      @include break-max($breakpoint-xsm) {
        margin-top: 15px;
        padding: 15px 10px;

        .about-redesign__top {
          gap: 15px;
        }
      }
    }
  }

  &__right {
    flex: 1;

    @include break-max($breakpoint-xsm) {
      padding-left: 0px;
      padding-right: 0px;
    }

    .sf__manager {
      height: 100%;

      .sf__manager-header {
        align-items: flex-start;
      }
    }

    .sf__manager_modal .sf__manager-col {
      flex-wrap: wrap;

      .link.link_color_black {
        flex-basis: 100%;
        margin-top: 10px;
      }
    }
    .sf__manager-social {
      margin-top: 13px;
      margin-left: 0;
    }
    .sf__manager-name {
      margin-bottom: 10px;
    }
    .sf__manager-pos {
      margin-bottom: 10px;
    }
  }

  &__right-content {
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include break-max($breakpoint-xsm) {
      margin: 0px 10px 10px 10px;
    }

    .communications-redesign__list .about-redesign__list-item {
      flex-basis: calc(50% - 20px);
    }

    .communications-redesign__list {
      margin-top: 10px;
    }

    .communications-redesign__title {
      font-size: 14px;
    }

    .about-redesign__complectation-list {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }


  .suburban-presentation__right-content .communications-redesign__list .about-redesign__list {
    @include break-max($breakpoint-xsm) {
      gap: 10px;
    }
  }


  .suburban-presentation__right-content .communications-redesign__list .about-redesign__list-item {
    @include break-max($breakpoint-xsm) {
      width: calc(50% - 20px);

      flex-basis: calc(50% - 5px);
    }
  }

  .about-redesign__complectation-list {
    margin-top: 10px;
    margin-bottom: 10px;
  }


  &__right-labels {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 18px;
      font-weight: 700;
    }
    span.suburban-presentation__right-labelActive {
      padding: 5px 25px;
      border-radius: 50px;
      color: white;
      background-color: #2C2E3E;
      white-space: nowrap;
    }
  }

  &__right-labels.suburban-presentation__right-labels_mobile {
    padding: 15px 10px;

    span {
      font-size: 16px;
    }

    span.suburban-presentation__right-labelActive {
      padding: 3px 15px;
    }
  }

  &__top-info {
    padding: 30px 25px;
    background-color: #e3f0f7;
    border-radius: 20px;
    background-image: url("../../assets/images/suburban-presentation-topinfo.png");
    background-position: bottom -25px right -25px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  &__top-infoList {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__top-title {
    font-size: 18px;
    font-weight: 700;
  }

  &__top-infoPrice {
    flex-shrink: 0;
    align-self: flex-end;
    font-size: 24px;
    font-weight: 700;
    padding-left: 40px;
  }

  &__top-infoItem {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__ride {
    display: flex;
    gap: 10px;

    .sf-card__metro-time {
      margin-left: 0;
      background-color: #F6F7F8;
    }
  }

  .sf__block.sf__manager_modal {
    padding: 20px 30px;
  }
}
.suburban-presentation {
  @include break-max($breakpoint-sm) {
    gap: 15px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 15px auto;
  }
}

.suburban-presentation__left.suburban-presentation__left_house {
  margin-top: -60px;
  @include break-max($breakpoint-xsm) {
    margin-top: -15px;
  }
}
.suburban-presentation__right.suburban-presentation__right_house {
  margin-top: -60px;
  @include break-max($breakpoint-xsm) {
    margin-top: -15px;
  }
}
