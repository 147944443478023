.card_slider {
  $c: &;
  position: relative;
  background-color: $color-white;
  text-decoration: none;
  color: $color-brand-2;
  display: block;
  &__item{
    @include break-max($breakpoint-sm) {
      width: 100%;
    }
  }
  @include break-max($break-xs) {
    box-shadow: 0px 4px 20px $color-brand-opaque-10;
  }

  &:after {
    content: '';
    position: absolute;
    top: 25px;
    bottom: 25px;
    left: 25px;
    right: 25px;
    box-shadow: 0 2px 0 rgba(143, 149, 172, .4876);
    transition: box-shadow .3s ease-in-out;
    will-change: box-shadow;
    background-color: $color-white;
    z-index: -1;
  }

  &:hover {
    &:after {
      box-shadow: 0 2px 50px rgba(143, 149, 172, .4876);
    }
  }

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 310px;

    @include break-max($breakpoint-sm) {
      @include grid-size(6, $grid-gutter-md);
      height: 100%;
    }
  }

  &__pills {
    @include unlist();
    position: absolute;
    top: 30px;
    left: 32px;
    right: 32px;
    width: 100%;
    display: flex;

    @include break-max($breakpoint-sm) {
      top: 19px;
      left: 19px;
    }
  }

  &__pill {
    background-color: $color-brand-3;
    color: $color-white;
    white-space: nowrap;
    margin-right: 24px;
    line-height: 30px;
    padding: 0 12px;
    border-radius: 4px;
    transition: background-color $transition-default;
    cursor: pointer;

    &:hover {
      background-color: $color-brand-3-hover;
    }
  }

  &__fav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 6;
    position: absolute;
    width: 30px;
    right: 15px;
    bottom: 15px;
    padding: 4px 7px;
    border-radius: 14px;
    background-color: #fff;
    transition: all $transition-default;
    cursor: pointer;

    & .icon {
      fill: $color-brand-3;
      fill-opacity: 0.5;
      transition: all $transition-default;
      font-size: 16px;
    }

    &-attachments {
    }

    &.is-fav {
      width: 70px;

      & .card__fav-add {
        & .icon {
          fill: $color-red;
          fill-opacity: 1;
        }
      }

      .fav-attach {
        opacity: 1;
        visibility: visible;
      }
    }

    &_attached.is-fav {
      width: 60px;
    }

    & .card__fav-add {
      display: flex;

      &:hover {
        & .icon_fav {
          fill-opacity: 0.8;
        }
      }
    }
  }

  &__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $color-white;

    @include break-max($breakpoint-sm) {
      flex-direction: row;
    }

    @include break-max($break-xs) {
      flex-direction: column;
    }
  }

  &__image {
    border-radius: 5px;
    overflow: hidden;
    height: 310px;

    @include break-max($breakpoint-sm) {
      @include grid-size(6, $grid-gutter-md);
      height: auto;
      min-height: 310px;
    }

    & img {
      min-width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      // backface-visibility: hidden;

    }

    @include break-max($break-xs) {
      height: 220px;
      min-height: auto;
      width: 100%;
    }
  }

  &__row {
    display: flex;

    &_distribute {

      &_between {
        justify-content: space-between;
      }
    }


  }

  &__content {
    padding: 40px 32px 32px;
    flex: 1;
    display: flex;
    flex-direction: column;

    @include break-max($breakpoint-sm) {
      padding: 20px;
    }

    @include break-max($breakpoint-xs) {
      padding: 16px 12px 21px 14px;
    }
  }

  &__place {
    max-width: 70%;

    @include break-max($breakpoint-xs) {
      max-width: 100%;
    }
  }

  &__title {
    font-size: 25px;
    line-height: 25px;
    font-weight: 700;

    @include break-max($break-xs) {
      font-size: 21px;
      line-height: 24px;
    }
  }

  &__address {
    line-height: 16px;
    margin-top: 10px;
  }

  &__dates {
    color: $color-red;
    text-align: right;
    line-height: 25px;

    @include break-max($break-xs) {
      align-items: center;
      background: $color-white;
      border-radius: 3px;
      color: $color-brand-2;
      display: flex;
      left: 14px;
      height: 30px;
      padding: 0px 12px;
      position: absolute;
      top: 177px;
    }
  }

  &__grid {
    @include grid();
    line-height: 25px;

    @include break-max($break-xs) {
      flex-wrap: nowrap;
    }
  }

  &__company {
    color: $color-brand-3;
  }

  &__variants {
    margin-top: 10px;

    @include break-max($break-xs) {
      margin-bottom: 0px;
    }
  }

  &__left {
    @include grid-col();
    @include grid-size(8);

    @include break-max($break-lg) {
      @include grid-size(8, $grid-gutter-md);
    }

    @include break-max(1400px) {
      @include grid-size(7, $grid-gutter-md);
    }

    @include break-max($break-md) {
      @include grid-size(8, $grid-gutter-sm);
    }

    @include break-max($break-xs) {
      margin-left: 0;
      width: 66.6%;
    }

    &-full-width {
      @include break-max($break-xs) {
        width: 100%;
      }
    }
  }


  &__right {
    @include grid-col();
    @include grid-size(4);

    @include break-max($break-lg) {
      @include grid-size(4, $grid-gutter-md);
    }

    @include break-max(1400px) {
      @include grid-size(5, $grid-gutter-md);
    }

    @include break-max($break-md) {
      @include grid-size(4, $grid-gutter-sm);
    }

    @include break-max($break-xs) {
      width: 33.3%;
    }
  }

  &__left-inner {
    @include grid();
  }

  &__left-part {
    @include grid-col();
    @include grid-size(6);

    @include break-max($break-lg) {
      @include grid-size(6, $grid-gutter-md);
    }

    @include break-max(1400px) {
      @include grid-size(6, $grid-gutter-md);
    }

    @include break-max($break-md) {
      @include grid-size(6, $grid-gutter-sm);
    }

    @include break-max($break-xs) {
      margin: 0;
      padding-right: 5px;
      width: 50%;
    }

    & span {
      color: $color-brand-3;
    }
  }

  &__routes {
    @include unlist();
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: auto;
    color: $color-brand-3;
    margin-top: 20px;

    @include break-max($break-xs) {
      margin-top: 10px;
    }
  }

  &__routes-item {
    margin-right: 30px;
    margin-bottom: 5px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__metro-color {
    width: 6px;
    height: 6px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 6px;
    margin-right: 10px;

    &_1 {
      background-color: $color-metro-1;
    }

    &_2 {
      background-color: $color-metro-2;
    }

    &_3 {
      background-color: $color-metro-3;
    }

    &_4 {
      background-color: $color-metro-4;
    }

    &_5 {
      background-color: $color-metro-5;
    }
  }

  &__metro-station {
    display: inline-block;
    margin-right: 10px;
  }

  &__route-type {

    & .icon {
      fill: $color-brand-3;
    }

    & .icon_auto {
      font-size: 12px;
      margin-right: 10px;
    }

    & .icon_pedestrian {
      font-size: 16px;
      margin-right: 5px;
    }
  }

  &__options {
    @include unlist();
    display: flex;
    margin-top: 27px;

    position: absolute;
    bottom: 15px;
    left: 34px;

    @include break-max($break-xs) {
      position: relative;
      left: 0;
    }
  }

  &__option {
    line-height: 30px;
    background-color: $color-white;
    padding: 0 12px;
    border-radius: 4px;
    margin-right: 7px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    transition: background-color $transition-default;

    @include break-max($break-xs) {
      background: $color-brand-opaque-12;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      background-color: $color-white;
    }
  }
}
